/// <reference types="./ai_response.d.mts" />
import * as $bright from "../../../../bright/bright.mjs";
import * as $copilot_question from "../../../../common/data/copilot_question.mjs";
import * as $data_source from "../../../../common/data/data_source.mjs";
import * as $month from "../../../../common_common/gleam/time/calendar/month.mjs";
import * as $button from "../../../../ds/ds/components/button.mjs";
import * as $copy_button from "../../../../ds/ds/components/copy_button.mjs";
import * as $input from "../../../../ds/ds/components/input.mjs";
import * as $keyboard from "../../../../ds/ds/components/keyboard.mjs";
import * as $layout from "../../../../ds/ds/components/layout.mjs";
import * as $tooltip from "../../../../ds/ds/components/tooltip.mjs";
import * as $icons from "../../../../ds/ds/icons.mjs";
import * as $ds_utils from "../../../../ds/ds/utils.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../../gleam_stdlib/gleam/dict.mjs";
import * as $float from "../../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $calendar from "../../../../gleam_time/gleam/time/calendar.mjs";
import * as $duration from "../../../../gleam_time/gleam/time/duration.mjs";
import * as $timestamp from "../../../../gleam_time/gleam/time/timestamp.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $mime_types from "../../../../mime_types/mime_types.mjs";
import * as $model from "../../../data/model.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $common from "../../../frontend/view/common.mjs";
import * as $s from "../../../frontend/view/styles/copilot.mjs";
import * as $proposals from "../../../frontend/view/styles/proposals.mjs";
import { toList, prepend as listPrepend, makeError, isEqual } from "../../../gleam.mjs";

function is_before(date, days) {
  let delta = $timestamp.difference(date, $timestamp.system_time());
  let delta_seconds = (() => {
    let _pipe = $duration.to_seconds(delta);
    return $float.round(_pipe);
  })();
  let max_delta = days * 86_400;
  return delta_seconds < max_delta;
}

function group_threads_per_date(model) {
  let data = $bright.data(model);
  let _pipe = data.copilot_threads;
  let _pipe$1 = $list.filter(
    _pipe,
    (thread) => { return !$list.is_empty(thread[1]); },
  );
  let _pipe$2 = $list.group(
    _pipe$1,
    (thread) => {
      let $ = $list.first(thread[1]);
      if (!$.isOk()) {
        throw makeError(
          "let_assert",
          "frontend/view/copilot/ai_response",
          66,
          "",
          "Pattern match failed, no pattern matched the value.",
          { value: $ }
        )
      }
      let question = $[0];
      let time = question.created_at;
      return $bool.guard(
        is_before(time, 1),
        "Today",
        () => {
          return $bool.guard(
            is_before(time, 8),
            "Previous 7 days",
            () => {
              return $bool.guard(
                is_before(time, 31),
                "Previous 30 days",
                () => {
                  let $1 = $timestamp.to_calendar(time, $calendar.utc_offset);
                  let date = $1[0];
                  let $2 = is_before(time, 365);
                  if ($2) {
                    return $month.to_string(date.month);
                  } else {
                    return $int.to_string(date.year);
                  }
                },
              );
            },
          );
        },
      );
    },
  );
  let _pipe$3 = $dict.map_values(
    _pipe$2,
    (_, threads) => {
      return $list.sort(
        threads,
        (a, b) => {
          let $ = $list.first(a[1]);
          if (!$.isOk()) {
            throw makeError(
              "let_assert",
              "frontend/view/copilot/ai_response",
              79,
              "",
              "Pattern match failed, no pattern matched the value.",
              { value: $ }
            )
          }
          let a$1 = $[0];
          let $1 = $list.first(b[1]);
          if (!$1.isOk()) {
            throw makeError(
              "let_assert",
              "frontend/view/copilot/ai_response",
              80,
              "",
              "Pattern match failed, no pattern matched the value.",
              { value: $1 }
            )
          }
          let b$1 = $1[0];
          return $timestamp.compare(b$1.created_at, a$1.created_at);
        },
      );
    },
  );
  return $dict.to_list(_pipe$3);
}

export function history(model) {
  let threads = group_threads_per_date(model);
  return $s.history_column(
    toList([]),
    (() => {
      let skip = $list.is_empty(threads);
      let empty_history = toList([
        $s.history_day_title(toList([]), toList([$h.text("No history")])),
      ]);
      return $bool.guard(
        skip,
        empty_history,
        () => {
          return $list.map(
            threads,
            (_use0) => {
              let when = _use0[0];
              let threads$1 = _use0[1];
              return $el.fragment(
                listPrepend(
                  $s.history_day_title(toList([]), toList([$h.text(when)])),
                  $list.map(
                    threads$1,
                    (thread) => {
                      let thread_id = thread[0];
                      let questions = thread[1];
                      let $ = $list.first(questions);
                      if (!$.isOk()) {
                        throw makeError(
                          "let_assert",
                          "frontend/view/copilot/ai_response",
                          52,
                          "",
                          "Pattern match failed, no pattern matched the value.",
                          { value: $ }
                        )
                      }
                      let question = $[0];
                      return $s.history_question(
                        toList([$a.href("/co-pilot/" + thread_id)]),
                        toList([$h.text(question.content)]),
                      );
                    },
                  ),
                ),
              );
            },
          );
        },
      );
    })(),
  );
}

function question_answer_bubble(question) {
  let answer = $copilot_question.answer(question);
  let $ = question.display.score;
  if ($ instanceof $option.Some && $[0] === 0.0) {
    return $s.error_response_bubble(
      toList([]),
      toList([
        $h.text("Steerlab is unable to get an answer for you.\n"),
        $h.text("Please, ask another question."),
      ]),
    );
  } else if (answer instanceof $option.None) {
    return $s.error_response_bubble(
      toList([]),
      toList([
        $h.text("Steerlab is unable to get an answer for you.\n"),
        $h.text("Please, ask another question."),
      ]),
    );
  } else {
    let answer$1 = answer[0];
    return $layout.row(
      toList([$layout.gap(6), $layout.justify("start")]),
      toList([]),
      toList([
        $s.response_bubble(toList([]), toList([$h.text(answer$1)])),
        $copy_button.copy_button(answer$1),
      ]),
    );
  }
}

function source_mime_type(source) {
  let $ = source.display.mime_type;
  if ($ instanceof $mime_types.Qna) {
    return $h.text("Question bank");
  } else {
    return $h.text(source.display.name);
  }
}

function get_questions_related(data, question_id) {
  let _pipe = data;
  let _pipe$1 = $dict.get(_pipe, question_id);
  return $result.unwrap(_pipe$1, toList([]));
}

function view_sources(model, question) {
  let data = $bright.data(model);
  let qid = question.id;
  let data_sources = get_questions_related(data.elements.data_sources, qid);
  let data_points = get_questions_related(data.elements.data_points, qid);
  let data_points_id = $list.take(question.data_points_id, 3);
  return $layout.column(
    toList([$layout.gap(16)]),
    toList([]),
    toList([
      $s.response_sources_title(toList([]), toList([$h.text("Sources")])),
      $el.fragment(
        $result.values(
          $list.index_map(
            data_points_id,
            (_use0, index) => {
              let id = _use0[0];
              return $result.try$(
                $list.find(data_points, (dp) => { return dp.id === id; }),
                (point) => {
                  return $result.map(
                    $list.find(
                      data_sources,
                      (ds) => { return ds.id === point.source_id; },
                    ),
                    (source) => {
                      let icon = $ds_utils.select_icon(source.display.mime_type);
                      let mime = source_mime_type(source);
                      return $tooltip.tooltip(
                        toList([
                          $a.style(
                            toList([["z-index", $int.to_string(100 - index)]]),
                          ),
                        ]),
                        $s.response_source(
                          toList([]),
                          toList([$common.small_icon(icon), mime]),
                        ),
                        $proposals.question_source_tooltip(
                          toList([]),
                          (() => {
                            let html = $option.unwrap(
                              point.display.as_html,
                              point.text,
                            );
                            let html$1 = $a.attribute(
                              "dangerous-unescaped-html",
                              html,
                            );
                            return toList([$h.div(toList([html$1]), toList([]))]);
                          })(),
                        ),
                      );
                    },
                  );
                },
              );
            },
          ),
        ),
      ),
    ]),
  );
}

function question_answer_sources(model, question) {
  let is_score_zero = isEqual(question.display.score, new $option.Some(0.0));
  let should_skip = $list.is_empty(question.data_points_id) || is_score_zero;
  return $bool.lazy_guard(
    should_skip,
    $el.none,
    () => { return view_sources(model, question); },
  );
}

function questions_answers(model, id) {
  let data = $bright.data(model);
  let thread = $list.key_find(data.copilot_threads, id);
  if (!thread.isOk()) {
    return $layout.column(
      toList([$layout.gap(32), $layout.flex("1")]),
      toList([]),
      toList([]),
    );
  } else {
    let questions = thread[0];
    return $layout.column(
      toList([$layout.gap(32), $layout.flex("1")]),
      toList([]),
      $list.map(
        questions,
        (question) => {
          let answer = $copilot_question.answer(question);
          let is_loading = question.loading && $option.is_none(answer);
          return $layout.column(
            toList([$layout.gap(32)]),
            toList([]),
            toList([
              $s.question_bubble(
                toList([]),
                toList([$h.text(question.content)]),
              ),
              (() => {
                if (is_loading) {
                  return $s.loading_indicator();
                } else {
                  return $el.fragment(
                    toList([
                      question_answer_bubble(question),
                      question_answer_sources(model, question),
                      $layout.column(
                        toList([$layout.align("start")]),
                        toList([]),
                        toList([
                          $button.ghost(
                            toList([
                              (() => {
                                if (answer instanceof $option.None) {
                                  return $a.none();
                                } else {
                                  let answer$1 = answer[0];
                                  return $e.on_click(
                                    new $msg.UserAddedCopilotToQuestionBank(
                                      question.content,
                                      answer$1,
                                    ),
                                  );
                                }
                              })(),
                            ]),
                            toList([$el.text("Add to question bank")]),
                          ),
                        ]),
                      ),
                    ]),
                  );
                }
              })(),
            ]),
          );
        },
      ),
    );
  }
}

export function view(model, id) {
  let data = $bright.data(model);
  let overflow = $layout.overflow("auto");
  return $s.card_wrapper(
    toList([]),
    toList([
      history(model),
      $layout.column(
        toList([$layout.flex("1"), overflow, $layout.gap(32)]),
        toList([]),
        toList([
          questions_answers(model, id),
          $h.form(
            toList([$e.on_submit(new $msg.UserSubmittedCopilotInput())]),
            toList([
              $s.ai_search_pro_tip(
                toList([]),
                toList([
                  $el.text("Pro tip, use "),
                  $keyboard.cmd_shortcut("Enter"),
                  $el.text(" to send your message directly!"),
                ]),
              ),
              $input.input(
                toList([
                  $input.multiline(),
                  $input.placeholder("Ask steerlab a question…"),
                  $input.icon($icons.paper_plane()),
                  $input.on_input(
                    (var0) => { return new $msg.UserEdittedCopilotInput(var0); },
                  ),
                  $input.on_submit(new $msg.UserSubmittedCopilotInput()),
                  $input.value(data.copilot_input.value),
                ]),
              ),
              $s.ai_search_disclaimer(
                toList([]),
                toList([$el.text("Make sure to verify AI generated answers.")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
