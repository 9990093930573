/// <reference types="./tab_bar.d.mts" />
import * as $tab_bar from "../../../../ds/ds/components/tab_bar.mjs";
import * as $window from "../../../../ds/plinth/browser/window.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $uri from "../../../../gleam_stdlib/gleam/uri.mjs";
import * as $attribute from "../../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../../lustre/lustre/element.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $route from "../../../data/route.mjs";
import { toList, CustomType as $CustomType, makeError, isEqual } from "../../../gleam.mjs";

class TabElement extends $CustomType {
  constructor(child) {
    super();
    this.child = child;
  }
}

class Tab extends $CustomType {
  constructor(active, href, disabled, children) {
    super();
    this.active = active;
    this.href = href;
    this.disabled = disabled;
    this.children = children;
  }
}

export function tab(to, children) {
  let $ = (() => {
    let _pipe = $window.location();
    let _pipe$1 = $uri.parse(_pipe);
    return $result.map(_pipe$1, $route.from_uri);
  })();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "frontend/view/components/tab_bar",
      23,
      "tab",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let route = $[0];
  let active = isEqual(route, to);
  let href = (() => {
    let _pipe = $route.to_uri(to);
    return $uri.to_string(_pipe);
  })();
  return new Tab(active, href, false, children);
}

export function disabled(tab, disabled) {
  if (tab instanceof Tab) {
    let active = tab.active;
    let href = tab.href;
    let children = tab.children;
    return new Tab(active, href, disabled, children);
  } else {
    return tab;
  }
}

export function tab_bar(tabs) {
  return $tab_bar.tab_bar(
    toList([]),
    $list.map(
      tabs,
      (tab) => {
        if (tab instanceof TabElement) {
          let element$1 = tab.child;
          return element$1;
        } else {
          let active = tab.active;
          let href = tab.href;
          let disabled$1 = tab.disabled;
          let children = tab.children;
          let href$1 = $attribute.href(href);
          let disabled$2 = $attribute.attribute(
            "disabled",
            $bool.to_string(disabled$1),
          );
          return $tab_bar.tab(active, toList([href$1, disabled$2]), children);
        }
      },
    ),
  );
}

export function element(element) {
  return new TabElement(element);
}
