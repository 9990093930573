/// <reference types="./uuid.d.mts" />
import * as $crypto from "../../gleam_crypto/gleam/crypto.mjs";
import * as $bit_array from "../../gleam_stdlib/gleam/bit_array.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import {
  Ok,
  Error,
  toList,
  CustomType as $CustomType,
  makeError,
  divideInt,
  toBitArray,
  bitArraySlice,
  bitArraySliceToInt,
  sizedInt,
} from "../gleam.mjs";

class Uuid extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class V1 extends $CustomType {}

export class V2 extends $CustomType {}

export class V3 extends $CustomType {}

export class V4 extends $CustomType {}

export class V5 extends $CustomType {}

export class V7 extends $CustomType {}

export class VUnknown extends $CustomType {}

export class ReservedFuture extends $CustomType {}

export class ReservedMicrosoft extends $CustomType {}

export class ReservedNcs extends $CustomType {}

export class Rfc4122 extends $CustomType {}

export class String extends $CustomType {}

export class Hex extends $CustomType {}

export class Urn extends $CustomType {}

export class DefaultNode extends $CustomType {}

export class RandomNode extends $CustomType {}

export class CustomNode extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class RandomClockSeq extends $CustomType {}

export class CustomClockSeq extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

function random_uuid1_clockseq() {
  let $ = $crypto.strong_random_bytes(2);
  if (!($.bitSize == 16)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      181,
      "random_uuid1_clockseq",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let clock_seq = bitArraySliceToInt($, 0, 14, true, false);
  return toBitArray([sizedInt(clock_seq, 14, true)]);
}

function random_uuid1_node() {
  let $ = $crypto.strong_random_bytes(6);
  if (!($.bitSize == 48)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      197,
      "random_uuid1_node",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let rnd_hi = bitArraySliceToInt($, 0, 7, true, false);
  let rnd_low = bitArraySliceToInt($, 8, 48, true, false);
  return toBitArray([
    sizedInt(rnd_hi, 7, true),
    sizedInt(1, 1, true),
    sizedInt(rnd_low, 40, true),
  ]);
}

function md5(data) {
  return $crypto.hash(new $crypto.Md5(), data);
}

function sha1(data) {
  let $ = $crypto.hash(new $crypto.Sha1(), data);
  if (!($.bitSize == 160)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      290,
      "sha1",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let sha = bitArraySliceToInt($, 0, 128, true, false);
  return toBitArray([sizedInt(sha, 128, true)]);
}

export function time(uuid) {
  let $ = uuid.value;
  if (!($.bitSize == 128)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      342,
      "time",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let t_low = bitArraySliceToInt($, 0, 32, true, false);
  let t_mid = bitArraySliceToInt($, 32, 48, true, false);
  let t_hi = bitArraySliceToInt($, 52, 64, true, false);
  let $1 = toBitArray([
    sizedInt(t_hi, 12, true),
    sizedInt(t_mid, 16, true),
    sizedInt(t_low, 32, true),
  ]);
  if (!($1.bitSize == 60)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      343,
      "time",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let t = bitArraySliceToInt($1, 0, 60, true, false);
  return t;
}

export function clock_sequence(uuid) {
  let $ = uuid.value;
  if (!($.bitSize == 128)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      365,
      "clock_sequence",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let clock_seq = bitArraySliceToInt($, 66, 80, true, false);
  return clock_seq;
}

export function node(uuid) {
  let $ = uuid.value;
  if (!($.bitSize == 128)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      372,
      "node",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let a = bitArraySliceToInt($, 80, 84, true, false);
  let b = bitArraySliceToInt($, 84, 88, true, false);
  let c = bitArraySliceToInt($, 88, 92, true, false);
  let d = bitArraySliceToInt($, 92, 96, true, false);
  let e = bitArraySliceToInt($, 96, 100, true, false);
  let f = bitArraySliceToInt($, 100, 104, true, false);
  let g = bitArraySliceToInt($, 104, 108, true, false);
  let h = bitArraySliceToInt($, 108, 112, true, false);
  let i = bitArraySliceToInt($, 112, 116, true, false);
  let j = bitArraySliceToInt($, 116, 120, true, false);
  let k = bitArraySliceToInt($, 120, 124, true, false);
  let l = bitArraySliceToInt($, 124, 128, true, false);
  let _pipe = toList([a, b, c, d, e, f, g, h, i, j, k, l]);
  let _pipe$1 = $list.map(_pipe, $int.to_base16);
  return $string.concat(_pipe$1);
}

function to_string_help(loop$ints, loop$position, loop$acc, loop$separator) {
  while (true) {
    let ints = loop$ints;
    let position = loop$position;
    let acc = loop$acc;
    let separator = loop$separator;
    if (position === 8) {
      loop$ints = ints;
      loop$position = position + 1;
      loop$acc = acc + separator;
      loop$separator = separator;
    } else if (position === 13) {
      loop$ints = ints;
      loop$position = position + 1;
      loop$acc = acc + separator;
      loop$separator = separator;
    } else if (position === 18) {
      loop$ints = ints;
      loop$position = position + 1;
      loop$acc = acc + separator;
      loop$separator = separator;
    } else if (position === 23) {
      loop$ints = ints;
      loop$position = position + 1;
      loop$acc = acc + separator;
      loop$separator = separator;
    } else {
      if (ints.bitSize >= 4) {
        let i = bitArraySliceToInt(ints, 0, 4, true, false);
        let rest = bitArraySlice(ints, 4);
        let string = (() => {
          let _pipe = $int.to_base16(i);
          return $string.lowercase(_pipe);
        })();
        loop$ints = rest;
        loop$position = position + 1;
        loop$acc = acc + string;
        loop$separator = separator;
      } else {
        return acc;
      }
    }
  }
}

export function dns_uuid() {
  return new Uuid(
    toBitArray([
      sizedInt(143_098_242_404_177_361_603_877_621_312_831_893_704, 128, true),
    ]),
  );
}

export function url_uuid() {
  return new Uuid(
    toBitArray([
      sizedInt(143_098_242_483_405_524_118_141_958_906_375_844_040, 128, true),
    ]),
  );
}

export function oid_uuid() {
  return new Uuid(
    toBitArray([
      sizedInt(143_098_242_562_633_686_632_406_296_499_919_794_376, 128, true),
    ]),
  );
}

export function x500_uuid() {
  return new Uuid(
    toBitArray([
      sizedInt(143_098_242_721_090_011_660_934_971_687_007_695_048, 128, true),
    ]),
  );
}

export function to_bit_array(uuid) {
  return uuid.value;
}

function decode_version(int) {
  if (int === 1) {
    return new V1();
  } else if (int === 2) {
    return new V2();
  } else if (int === 3) {
    return new V3();
  } else if (int === 4) {
    return new V4();
  } else if (int === 5) {
    return new V5();
  } else if (int === 7) {
    return new V7();
  } else {
    return new VUnknown();
  }
}

export function version(uuid) {
  let $ = uuid.value;
  if (!($.bitSize == 128)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      324,
      "version",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let ver = bitArraySliceToInt($, 48, 52, true, false);
  return decode_version(ver);
}

export function from_bit_array(bit_array) {
  let uuid = new Uuid(bit_array);
  let $ = $bit_array.byte_size(bit_array);
  if ($ === 16) {
    let $1 = version(uuid);
    if ($1 instanceof VUnknown) {
      return new Error(undefined);
    } else {
      return new Ok(uuid);
    }
  } else {
    return new Error(undefined);
  }
}

function decode_variant(variant_bits) {
  if (bitArraySliceToInt(variant_bits, 0, 1, true, false) === 1 &&
  bitArraySliceToInt(variant_bits, 1, 2, true, false) === 1 &&
  bitArraySliceToInt(variant_bits, 2, 3, true, false) === 1 &&
  variant_bits.bitSize == 3) {
    return new ReservedFuture();
  } else if (bitArraySliceToInt(variant_bits, 0, 1, true, false) === 1 &&
  bitArraySliceToInt(variant_bits, 1, 2, true, false) === 1 &&
  bitArraySliceToInt(variant_bits, 2, 3, true, false) === 0 &&
  variant_bits.bitSize == 3) {
    return new ReservedMicrosoft();
  } else if (bitArraySliceToInt(variant_bits, 0, 1, true, false) === 1 &&
  bitArraySliceToInt(variant_bits, 1, 2, true, false) === 0 &&
  variant_bits.bitSize == 3) {
    return new Rfc4122();
  } else if (bitArraySliceToInt(variant_bits, 0, 1, true, false) === 0 &&
  variant_bits.bitSize == 3) {
    return new ReservedNcs();
  } else {
    return new ReservedNcs();
  }
}

export function variant(uuid) {
  let $ = uuid.value;
  if (!($.bitSize == 128)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      330,
      "variant",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let var$ = bitArraySliceToInt($, 64, 67, true, false);
  return decode_variant(toBitArray([sizedInt(var$, 3, true)]));
}

function hex_to_int(c) {
  let i = (() => {
    if (c === "0") {
      return 0;
    } else if (c === "1") {
      return 1;
    } else if (c === "2") {
      return 2;
    } else if (c === "3") {
      return 3;
    } else if (c === "4") {
      return 4;
    } else if (c === "5") {
      return 5;
    } else if (c === "6") {
      return 6;
    } else if (c === "7") {
      return 7;
    } else if (c === "8") {
      return 8;
    } else if (c === "9") {
      return 9;
    } else if (c === "a") {
      return 10;
    } else if (c === "A") {
      return 10;
    } else if (c === "b") {
      return 11;
    } else if (c === "B") {
      return 11;
    } else if (c === "c") {
      return 12;
    } else if (c === "C") {
      return 12;
    } else if (c === "d") {
      return 13;
    } else if (c === "D") {
      return 13;
    } else if (c === "e") {
      return 14;
    } else if (c === "E") {
      return 14;
    } else if (c === "f") {
      return 15;
    } else if (c === "F") {
      return 15;
    } else {
      return 16;
    }
  })();
  if (i === 16) {
    return new Error(undefined);
  } else {
    let x = i;
    return new Ok(x);
  }
}

function validate_custom_node(loop$str, loop$index, loop$acc) {
  while (true) {
    let str = loop$str;
    let index = loop$index;
    let acc = loop$acc;
    let $ = $string.pop_grapheme(str);
    if (!$.isOk() && !$[0] && (index === 12)) {
      return new Ok(acc);
    } else if ($.isOk() && $[0][0] === ":") {
      let rest = $[0][1];
      loop$str = rest;
      loop$index = index;
      loop$acc = acc;
    } else if ($.isOk()) {
      let c = $[0][0];
      let rest = $[0][1];
      let $1 = hex_to_int(c);
      if ($1.isOk() && (index < 12)) {
        let i = $1[0];
        loop$str = rest;
        loop$index = index + 1;
        loop$acc = toBitArray([acc, sizedInt(i, 4, true)]);
      } else {
        return new Error(undefined);
      }
    } else {
      return new Error(undefined);
    }
  }
}

function to_bitstring_help(loop$str, loop$index, loop$acc) {
  while (true) {
    let str = loop$str;
    let index = loop$index;
    let acc = loop$acc;
    let $ = $string.pop_grapheme(str);
    if (!$.isOk() && !$[0] && (index === 32)) {
      return new Ok(acc);
    } else if ($.isOk() && $[0][0] === "-" && (index < 32)) {
      let rest = $[0][1];
      loop$str = rest;
      loop$index = index;
      loop$acc = acc;
    } else if ($.isOk() && (index < 32)) {
      let c = $[0][0];
      let rest = $[0][1];
      let $1 = hex_to_int(c);
      if ($1.isOk()) {
        let i = $1[0];
        loop$str = rest;
        loop$index = index + 1;
        loop$acc = toBitArray([acc, sizedInt(i, 4, true)]);
      } else {
        return new Error(undefined);
      }
    } else {
      return new Error(undefined);
    }
  }
}

function to_bit_array_helper(str) {
  return to_bitstring_help(str, 0, toBitArray([]));
}

export function from_string(in$) {
  let hex = (() => {
    if (in$.startsWith("urn:uuid:")) {
      let in$1 = in$.slice(9);
      return in$1;
    } else {
      return in$;
    }
  })();
  let $ = to_bit_array_helper(hex);
  if ($.isOk()) {
    let bits = $[0];
    return new Ok(new Uuid(bits));
  } else {
    return new Error(undefined);
  }
}

const nanosec_intervals_offset = 122_192_928_000_000_000;

const nanosec_intervals_factor = 10;

export function time_posix_microsec(uuid) {
  let $ = version(uuid);
  if ($ instanceof V7) {
    let $1 = uuid.value;
    if (!($1.bitSize == 128)) {
      throw makeError(
        "let_assert",
        "youid/uuid",
        355,
        "time_posix_microsec",
        "Pattern match failed, no pattern matched the value.",
        { value: $1 }
      )
    }
    let t = bitArraySliceToInt($1, 0, 48, true, false);
    return t * 1000;
  } else {
    return divideInt(
      (time(uuid) - nanosec_intervals_offset),
      nanosec_intervals_factor
    );
  }
}

export function time_posix_millisec(uuid) {
  let $ = version(uuid);
  if ($ instanceof V1) {
    return divideInt(
      (divideInt(
        (time(uuid) - nanosec_intervals_offset),
        nanosec_intervals_factor
      )),
      1000
    );
  } else {
    let $1 = uuid.value;
    if (!($1.bitSize == 128)) {
      throw makeError(
        "let_assert",
        "youid/uuid",
        401,
        "time_posix_millisec",
        "Pattern match failed, no pattern matched the value.",
        { value: $1 }
      )
    }
    let t = bitArraySliceToInt($1, 0, 48, true, false);
    return t;
  }
}

const rfc_variant = 2;

function hash_to_uuid_value(hash, ver) {
  if (!(hash.bitSize == 128)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      224,
      "hash_to_uuid_value",
      "Pattern match failed, no pattern matched the value.",
      { value: hash }
    )
  }
  let time_low = bitArraySliceToInt(hash, 0, 32, true, false);
  let time_mid = bitArraySliceToInt(hash, 32, 48, true, false);
  let time_hi = bitArraySliceToInt(hash, 52, 64, true, false);
  let clock_seq_hi = bitArraySliceToInt(hash, 66, 72, true, false);
  let clock_seq_low = hash.byteAt(9);
  let node$1 = bitArraySliceToInt(hash, 80, 128, true, false);
  return toBitArray([
    sizedInt(time_low, 32, true),
    sizedInt(time_mid, 16, true),
    sizedInt(ver, 4, true),
    sizedInt(time_hi, 12, true),
    sizedInt(rfc_variant, 2, true),
    sizedInt(clock_seq_hi, 6, true),
    clock_seq_low,
    sizedInt(node$1, 48, true),
  ]);
}

const urn_id = "urn:uuid:";

export function format(uuid, format) {
  let separator = (() => {
    if (format instanceof String) {
      return "-";
    } else {
      return "";
    }
  })();
  let start = (() => {
    if (format instanceof Urn) {
      return urn_id;
    } else {
      return "";
    }
  })();
  return to_string_help(uuid.value, 0, start, separator);
}

export function to_string(uuid) {
  return format(uuid, new String());
}

const v1_version = 1;

const v3_version = 3;

const v4_version = 4;

export function v4() {
  let $ = $crypto.strong_random_bytes(16);
  if (!($.bitSize == 128)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      252,
      "v4",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let a = bitArraySliceToInt($, 0, 48, true, false);
  let b = bitArraySliceToInt($, 52, 64, true, false);
  let c = bitArraySliceToInt($, 66, 128, true, false);
  let value = toBitArray([
    sizedInt(a, 48, true),
    sizedInt(v4_version, 4, true),
    sizedInt(b, 12, true),
    sizedInt(rfc_variant, 2, true),
    sizedInt(c, 62, true),
  ]);
  return new Uuid(value);
}

export function v4_string() {
  let _pipe = v4();
  return format(_pipe, new String());
}

const v5_version = 5;

const v7_version = 7;

export function v7_from_millisec(timestamp) {
  let $ = $crypto.strong_random_bytes(10);
  if (!($.bitSize == 80)) {
    throw makeError(
      "let_assert",
      "youid/uuid",
      306,
      "v7_from_millisec",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let a = bitArraySliceToInt($, 0, 12, true, false);
  let b = bitArraySliceToInt($, 12, 74, true, false);
  let value = toBitArray([
    sizedInt(timestamp, 48, true),
    sizedInt(v7_version, 4, true),
    sizedInt(a, 12, true),
    sizedInt(rfc_variant, 2, true),
    sizedInt(b, 62, true),
  ]);
  return new Uuid(value);
}
