import * as prelude from '#gleam/prelude'

export function clickUploadAttachment() {
  document.getElementById('projects-show-questionnaire-upload')?.click()
}

export function blurTargetWithEscape(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    event.preventDefault()
    const target = event.target as HTMLElement | null
    target?.blur()
  }
}

export function innerText(event: Event) {
  const target = event.target as HTMLElement
  const text = target.innerText
  return new prelude.Ok(text)
}
