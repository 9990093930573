import { Editor } from '@tiptap/core'

export type Option = 'bold' | 'underline' | 'italic'
export function toggle(editor: Editor, option: Option) {
  const from = editor.view.state.selection.from
  const to = editor.view.state.selection.to
  const chain = (() => {
    // prettier-ignore
    switch (option) {
       case 'bold':      return editor.chain().toggleBold()
       case 'underline': return editor.chain().toggleUnderline()
       case 'italic':    return editor.chain().toggleItalic()
     }
  })()
  chain.setTextSelection({ from, to }).run()
  return editor
}

export function isActive(editor: Editor, selector: string) {
  return editor.isActive(selector)
}
