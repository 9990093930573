import TipTapTable from '@tiptap/extension-table'
import * as h from '#editor/html'
import * as a from '#editor/html/attribute'

export const Table = TipTapTable.extend({
  addNodeView() {
    return () => {
      const contentDOM = h.tbody([], [])
      // prettier-ignore
      const dom =
        h.div([a.class_('table-responsive')], [
          h.table([], [contentDOM])
        ])
      return { dom, contentDOM }
    }
  },
})
