/// <reference types="./status.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import { CustomType as $CustomType, isEqual } from "../gleam.mjs";

export class InProgress extends $CustomType {}

export class Won extends $CustomType {}

export class Lost extends $CustomType {}

export class Pending extends $CustomType {}

export class Cancelled extends $CustomType {}

export function is_ongoing(status) {
  let is_pending = isEqual(status, new Pending());
  let is_in_progress = isEqual(status, new InProgress());
  return is_pending || is_in_progress;
}

export function is_finished(status) {
  return !is_ongoing(status);
}

export function to_string(status) {
  if (status instanceof InProgress) {
    return "In progress";
  } else if (status instanceof Won) {
    return "Won";
  } else if (status instanceof Lost) {
    return "Lost";
  } else if (status instanceof Pending) {
    return "Pending";
  } else {
    return "Cancelled";
  }
}

export function decoder() {
  return $decode.then$(
    $decode.string,
    (content) => {
      if (content === "in-progress") {
        return $decode.success(new InProgress());
      } else if (content === "won") {
        return $decode.success(new Won());
      } else if (content === "lost") {
        return $decode.success(new Lost());
      } else if (content === "pending") {
        return $decode.success(new Pending());
      } else if (content === "cancelled") {
        return $decode.success(new Cancelled());
      } else {
        return $decode.failure(new InProgress(), "Status");
      }
    },
  );
}

export function to_json_string(status) {
  if (status instanceof InProgress) {
    return "in-progress";
  } else if (status instanceof Won) {
    return "won";
  } else if (status instanceof Lost) {
    return "lost";
  } else if (status instanceof Pending) {
    return "pending";
  } else {
    return "cancelled";
  }
}

export function encode(status) {
  let _pipe = status;
  let _pipe$1 = to_json_string(_pipe);
  return $json.string(_pipe$1);
}
