/// <reference types="./organization.d.mts" />
import * as $tag from "../../common/data/tag.mjs";
import * as $user from "../../common/data/user.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Organization extends $CustomType {
  constructor(tags, users) {
    super();
    this.tags = tags;
    this.users = users;
  }
}

export function new$() {
  return new Organization(toList([]), toList([]));
}

export function update_user(organization, user_id, function$) {
  let _record = organization;
  return new Organization(
    _record.tags,
    $list.map(
      organization.users,
      (user) => {
        return $bool.guard(
          user.id !== user_id,
          user,
          () => {
            let function$1 = new $option.Some(function$);
            let _record$1 = user;
            return new $user.User(
              _record$1.id,
              _record$1.email,
              _record$1.name,
              _record$1.picture,
              _record$1.initials,
              _record$1.roles,
              function$1,
            );
          },
        );
      },
    ),
  );
}
