import { TiptapEditor } from '#editor/element'
import * as prelude from '#gleam/prelude'

export function escapeHTML(str: string) {
  var p = document.createElement('p')
  p.innerText = str
  return p.innerText
}

export function getDataFromEvent(event: any) {
  if (event.data) return event.data
  return null
}

export async function allSettled<T>(promises: Promise<T>[]) {
  const results = await Promise.allSettled(promises)
  return prelude.List.fromArray(results)
}

export function sendBlockId(answer: string, blockId: string) {
  const els = document.getElementsByTagName('steerlab-editor')
  if (!els[0]) return
  const root = els[0].shadowRoot
  if (!root) return
  const result: TiptapEditor | null = root.querySelector('tiptap-editor')
  if (!result) return
  result.setOutlineAnswer(answer, blockId)
}

export function getProposalId() {
  const pathname = window.location.pathname
  if (!pathname.includes('projects') && !pathname.includes('proposals'))
    return new prelude.Error(undefined)
  const segments = window.location.pathname.split('/')
  return new prelude.Ok(segments[segments.length - 1])
}
