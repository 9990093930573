/// <reference types="./connectors.d.mts" />
import * as $connector from "../../common/data/connector.mjs";
import * as $confluence from "../../common/data/connector/confluence.mjs";
import * as $notion from "../../common/data/connector/notion.mjs";
import * as $connector_settings from "../../common/data/connector_settings.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Connectors extends $CustomType {
  constructor(fetched, confluence, confluence_opened, notion, notion_opened, google_folder_id, settings) {
    super();
    this.fetched = fetched;
    this.confluence = confluence;
    this.confluence_opened = confluence_opened;
    this.notion = notion;
    this.notion_opened = notion_opened;
    this.google_folder_id = google_folder_id;
    this.settings = settings;
  }
}

export function new$() {
  return new Connectors(
    toList([]),
    $confluence.new$(),
    false,
    $notion.new$(),
    false,
    "",
    toList([]),
  );
}
