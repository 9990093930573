/// <reference types="./spreadsheet.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $mime_types from "../../mime_types/mime_types.mjs";
import * as $language from "../data/translate/language.mjs";
import * as $iso639 from "../data/translate/language/iso639.mjs";
import * as $decrypt from "../decrypt.mjs";
import { Ok, toList, CustomType as $CustomType } from "../gleam.mjs";

export class AdditionalInformationColumn extends $CustomType {}

export class QuestionColumn extends $CustomType {}

export class AnswerColumn extends $CustomType {}

export class YesNoColumn extends $CustomType {}

export class MultipleChoiceColumn extends $CustomType {}

export class TagColumn extends $CustomType {}

export class UndefinedColumn extends $CustomType {}

export class SectionRow extends $CustomType {}

export class QuestionRow extends $CustomType {}

export class UndefinedRow extends $CustomType {}

export class Worksheet extends $CustomType {
  constructor(name, start_index, content, columns_type, rows_type) {
    super();
    this.name = name;
    this.start_index = start_index;
    this.content = content;
    this.columns_type = columns_type;
    this.rows_type = rows_type;
  }
}

export class Cell extends $CustomType {
  constructor(value, dropdown) {
    super();
    this.value = value;
    this.dropdown = dropdown;
  }
}

export class Spreadsheet extends $CustomType {
  constructor(id, name, type_, data, language, blob) {
    super();
    this.id = id;
    this.name = name;
    this.type_ = type_;
    this.data = data;
    this.language = language;
    this.blob = blob;
  }
}

export function encode_cell(cell) {
  return $json.object(
    toList([
      ["value", $json.nullable(cell.value, $json.string)],
      ["dropdown", $json.array(cell.dropdown, $json.string)],
    ]),
  );
}

function encode_column_type(column_type) {
  if (column_type instanceof AdditionalInformationColumn) {
    return $json.string("additional_information");
  } else if (column_type instanceof QuestionColumn) {
    return $json.string("question");
  } else if (column_type instanceof AnswerColumn) {
    return $json.string("answer");
  } else if (column_type instanceof YesNoColumn) {
    return $json.string("yes_no");
  } else if (column_type instanceof MultipleChoiceColumn) {
    return $json.string("multiple_choice");
  } else if (column_type instanceof TagColumn) {
    return $json.string("tag");
  } else {
    return $json.string("other");
  }
}

export function encode_columns_type(columns) {
  let _pipe = columns;
  let _pipe$1 = $dict.to_list(_pipe);
  let _pipe$2 = $list.map(
    _pipe$1,
    (s) => { return [$int.to_string(s[0]), encode_column_type(s[1])]; },
  );
  return $json.object(_pipe$2);
}

function encode_row_type(row_type) {
  if (row_type instanceof SectionRow) {
    return $json.string("section");
  } else if (row_type instanceof QuestionRow) {
    return $json.string("question");
  } else {
    return $json.string("other");
  }
}

export function encode_rows_type(rows) {
  let _pipe = rows;
  let _pipe$1 = $dict.to_list(_pipe);
  let _pipe$2 = $list.map(
    _pipe$1,
    (s) => { return [$int.to_string(s[0]), encode_row_type(s[1])]; },
  );
  return $json.object(_pipe$2);
}

export function encode(spreadsheet) {
  return $json.object(
    toList([
      ["id", $json.string(spreadsheet.id)],
      ["name", $json.string(spreadsheet.name)],
      ["mime_type", $json.string($mime_types.to_string(spreadsheet.type_))],
      ["language", $json.nullable(spreadsheet.language, $iso639.encode)],
      [
        "data",
        $json.array(
          spreadsheet.data,
          (data) => {
            return $json.object(
              toList([
                ["name", $json.string(data.name)],
                ["start_index", $json.int(data.start_index)],
                [
                  "worksheet",
                  $json.array(
                    data.content,
                    (content) => {
                      return $json.array(
                        content,
                        (cell) => { return encode_cell(cell); },
                      );
                    },
                  ),
                ],
                ["columns_type", encode_columns_type(data.columns_type)],
                ["rows_type", encode_rows_type(data.rows_type)],
              ]),
            );
          },
        ),
      ],
    ]),
  );
}

export function cell_decoder() {
  return $decode.field(
    "value",
    $decode.optional($decode.string),
    (value) => {
      return $decode.field(
        "dropdown",
        $decode.list($decode.string),
        (dropdown) => { return $decode.success(new Cell(value, dropdown)); },
      );
    },
  );
}

export function type_decoder(parser) {
  let key = $decrypt.int();
  let content = $decode.map($decode.string, parser);
  return $decode.dict(key, content);
}

export function parse_column_type(column_type) {
  if (column_type === "additional_information") {
    return new AdditionalInformationColumn();
  } else if (column_type === "answer") {
    return new AnswerColumn();
  } else if (column_type === "multiple_choice") {
    return new MultipleChoiceColumn();
  } else if (column_type === "question") {
    return new QuestionColumn();
  } else if (column_type === "tag") {
    return new TagColumn();
  } else if (column_type === "yes_no") {
    return new YesNoColumn();
  } else {
    return new UndefinedColumn();
  }
}

export function parse_row_type(row_type) {
  if (row_type === "question") {
    return new QuestionRow();
  } else if (row_type === "section") {
    return new SectionRow();
  } else {
    return new UndefinedRow();
  }
}

function data_decoder() {
  return $decode.list(
    $decode.field(
      "name",
      $decode.string,
      (name) => {
        let index = (() => {
          let _pipe = $decode.optional($decode.int);
          return $decode.map(
            _pipe,
            (_capture) => { return $option.unwrap(_capture, 0); },
          );
        })();
        return $decode.optional_field(
          "start_index",
          0,
          index,
          (start_index) => {
            let worksheet = $decode.list($decode.list(cell_decoder()));
            return $decode.field(
              "worksheet",
              worksheet,
              (content) => {
                let columns_type = type_decoder(parse_column_type);
                return $decode.field(
                  "columns_type",
                  columns_type,
                  (columns_type) => {
                    return $decode.field(
                      "rows_type",
                      type_decoder(parse_row_type),
                      (rows_type) => {
                        return $decode.success(
                          new Worksheet(
                            name,
                            start_index,
                            content,
                            columns_type,
                            rows_type,
                          ),
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
}

export function decoder(blob) {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "name",
        $decode.string,
        (name) => {
          return $decode.field(
            "mime_type",
            $mime_types.decoder(),
            (type_) => {
              return $decode.field(
                "data",
                data_decoder(),
                (data) => {
                  let iso639 = $decode.optional($iso639.decoder());
                  return $decode.optional_field(
                    "language",
                    new $option.None(),
                    iso639,
                    (language) => {
                      return $decode.success(
                        new Spreadsheet(id, name, type_, data, language, blob),
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function decode_row_type() {
  return (dyn) => {
    if (dyn === "question") {
      return new Ok(new QuestionRow());
    } else if (dyn === "section") {
      return new Ok(new SectionRow());
    } else {
      return new Ok(new UndefinedRow());
    }
  };
}

export function decode_column_type() {
  return (dyn) => {
    if (dyn === "additional_information") {
      return new Ok(new AdditionalInformationColumn());
    } else if (dyn === "answer") {
      return new Ok(new AnswerColumn());
    } else if (dyn === "multiple_choice") {
      return new Ok(new MultipleChoiceColumn());
    } else if (dyn === "question") {
      return new Ok(new QuestionColumn());
    } else if (dyn === "tag") {
      return new Ok(new TagColumn());
    } else if (dyn === "yes_no") {
      return new Ok(new YesNoColumn());
    } else {
      return new Ok(new UndefinedColumn());
    }
  };
}
