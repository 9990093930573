/// <reference types="./narrative.d.mts" />
import * as $kind from "../../../common/data/docx/kind.mjs";
import * as $updated from "../../../common/data/ephemeral/document_updated.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $element from "../../../common/data/proposal_element.mjs";
import * as $block from "../../../common/data/proposal_element/block.mjs";
import * as $question from "../../../common/data/proposal_element/question.mjs";
import { Question } from "../../../common/data/proposal_element/question.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import { Data } from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $events from "../../elements/editor/tiptap/events.mjs";
import * as $question_effects from "../../frontend/effects/question.mjs";
import { toList } from "../../gleam.mjs";

function insert_element(data, proposal, node) {
  return $data.upsert_proposal_element(
    data,
    (() => {
      if (node instanceof $updated.QuestionUpdated) {
        let question = node.question;
        let answer = node.answer;
        return new $element.Question(
          new Question(
            node.id,
            proposal.id,
            toList([]),
            new $question.Display(new None()),
            new None(),
            toList([new $question.Long()]),
            toList([]),
            new None(),
            question,
            new $question.Answer(
              new None(),
              new None(),
              new None(),
              new None(),
              new Some(answer),
            ),
            false,
            new $question.SheetQuestion(
              new None(),
              new None(),
              new None(),
              new None(),
              toList([]),
              new None(),
            ),
            false,
            false,
            node.order,
            node.order,
          ),
        );
      } else {
        let content = node.content;
        return new $element.Block(
          new $block.Block(
            new $kind.Other(),
            node.id,
            new None(),
            content,
            node.order,
            new $block.Metadata(),
            proposal.id,
            node.order,
          ),
        );
      }
    })(),
  );
}

function update_element(data, value, node) {
  if (value instanceof $element.Question &&
  node instanceof $updated.QuestionUpdated) {
    let q = value.question;
    let question = node.question;
    let answer = node.answer;
    let custom = new Some(answer);
    let answer$1 = (() => {
      let _record = q.answer;
      return new $question.Answer(
        _record.short,
        _record.long,
        _record.yes_no,
        _record.choice,
        custom,
      );
    })();
    let _pipe = (() => {
      let _record = q;
      return new Question(
        _record.id,
        _record.proposal_id,
        _record.data_points_id,
        _record.display,
        _record.owner,
        _record.kind,
        _record.choices,
        _record.sheet,
        question,
        answer$1,
        _record.validated,
        _record.metadata,
        _record.ai_processing,
        _record.assign_processing,
        _record.qa_order,
        node.order,
      );
    })();
    let _pipe$1 = new $element.Question(_pipe);
    return ((_capture) => {
      return $data.upsert_proposal_element(data, _capture);
    })(_pipe$1);
  } else if (value instanceof $element.Block &&
  node instanceof $updated.BlockUpdated) {
    let b = value.block;
    let content = node.content;
    let _pipe = (() => {
      let _record = b;
      return new $block.Block(
        _record.element_type,
        _record.id,
        _record.owner,
        content,
        node.order,
        _record.metadata,
        _record.proposal_id,
        _record.qa_order,
      );
    })();
    let _pipe$1 = new $element.Block(_pipe);
    return ((_capture) => {
      return $data.upsert_proposal_element(data, _capture);
    })(_pipe$1);
  } else {
    return data;
  }
}

function upsert_elements(data, proposal, modified) {
  let elements = $data.get_proposal_elements(data, proposal.id);
  return $list.fold(
    modified,
    data,
    (data, node) => {
      let $ = $list.key_find(elements, node.id);
      if (!$.isOk()) {
        return insert_element(data, proposal, node);
      } else {
        let value = $[0];
        return update_element(data, value, node);
      }
    },
  );
}

function delete_elements(data, proposal, deleted) {
  return $list.fold(
    deleted,
    data,
    (data, deleted) => {
      return $data.delete_proposal_element(data, proposal.id, deleted);
    },
  );
}

export function document_updated(data, proposal, content) {
  let _pipe = $decode.run(content, $updated.decoder());
  let _pipe$1 = $result.replace_error(_pipe, undefined);
  let _pipe$2 = $result.map(
    _pipe$1,
    (document) => {
      let _pipe$2 = data;
      let _pipe$3 = upsert_elements(_pipe$2, proposal, document.modified);
      let _pipe$4 = delete_elements(_pipe$3, proposal, document.deleted);
      let _pipe$5 = $data.sort_elements_by_doc_id(_pipe$4, proposal.id);
      return $pair.new$(
        _pipe$5,
        $question_effects.update_document_batch(data, proposal.id, document),
      );
    },
  );
  return $result.unwrap(_pipe$2, [data, $effect.none()]);
}

export function update(data, msg) {
  {
    let proposal = msg.proposal;
    let event = msg.event;
    if (event instanceof $events.Updated) {
      let content = event[0];
      return document_updated(data, proposal, content);
    } else {
      return [data, $effect.none()];
    }
  }
}
