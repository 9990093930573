/// <reference types="./document_updated.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class DocumentUpdated extends $CustomType {
  constructor(modified, deleted) {
    super();
    this.modified = modified;
    this.deleted = deleted;
  }
}

export class BlockUpdated extends $CustomType {
  constructor(id, order, content) {
    super();
    this.id = id;
    this.order = order;
    this.content = content;
  }
}

export class QuestionUpdated extends $CustomType {
  constructor(id, order, question, answer) {
    super();
    this.id = id;
    this.order = order;
    this.question = question;
    this.answer = answer;
  }
}

function element_updated_decoder() {
  let type_ = $decode.at(toList(["type"]), $decode.string);
  return $decode.then$(
    type_,
    (type_) => {
      if (type_ === "steerlab-block") {
        return $decode.field(
          "id",
          $decode.string,
          (id) => {
            return $decode.field(
              "order",
              $decode.int,
              (order) => {
                return $decode.field(
                  "content",
                  $decode.string,
                  (content) => {
                    return $decode.success(new BlockUpdated(id, order, content));
                  },
                );
              },
            );
          },
        );
      } else if (type_ === "steerlab-question") {
        return $decode.field(
          "id",
          $decode.string,
          (id) => {
            return $decode.field(
              "order",
              $decode.int,
              (order) => {
                return $decode.field(
                  "question",
                  $decode.string,
                  (question) => {
                    return $decode.field(
                      "answer",
                      $decode.string,
                      (answer) => {
                        return $decode.success(
                          new QuestionUpdated(id, order, question, answer),
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      } else {
        return $decode.failure(new BlockUpdated("", 0, ""), "UpdateNode");
      }
    },
  );
}

export function decoder() {
  let decoder$1 = element_updated_decoder();
  return $decode.field(
    "modified",
    $decode.list(decoder$1),
    (modified) => {
      return $decode.field(
        "deleted",
        $decode.list($decode.string),
        (deleted) => {
          return $decode.success(new DocumentUpdated(modified, deleted));
        },
      );
    },
  );
}

export function encode_element_updated(element_updated) {
  if (element_updated instanceof BlockUpdated) {
    return $json.object(
      toList([
        ["type", $json.string("steerlab-block")],
        ["id", $json.string(element_updated.id)],
        ["order", $json.int(element_updated.order)],
        ["content", $json.string(element_updated.content)],
      ]),
    );
  } else {
    return $json.object(
      toList([
        ["type", $json.string("steerlab-question")],
        ["id", $json.string(element_updated.id)],
        ["order", $json.int(element_updated.order)],
        ["question", $json.string(element_updated.question)],
        ["answer", $json.string(element_updated.answer)],
      ]),
    );
  }
}

export function encode(document_updated) {
  return $json.object(
    toList([
      [
        "modified",
        $json.array(document_updated.modified, encode_element_updated),
      ],
      ["deleted", $json.array(document_updated.deleted, $json.string)],
    ]),
  );
}
