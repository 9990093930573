import FileHandler from '@tiptap-pro/extension-file-handler'
import * as tiptap from '@tiptap/core'
import { Color } from '@tiptap/extension-color'
import Image from '@tiptap/extension-image'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import * as html from '#editor/html'
import { FontSize } from '#editor/modules/font-size.extension'
import { Index } from '#editor/modules/index.extension'
import { LineHeight } from '#editor/modules/line-height.extension'
import { SteerlabBlock } from '#editor/modules/steerlab/block.node'
import { DocumentLabeller } from '#editor/modules/steerlab/document-labeller.node'
import { Keyboard } from '#editor/modules/steerlab/keyboard.extension'
import { AnswerBlock } from '#editor/modules/steerlab/question/answer-block.node'
import { QuestionBlock } from '#editor/modules/steerlab/question/question-block.node'
import { SubjectBlock } from '#editor/modules/steerlab/question/subject-block.node'
import { Table } from '#editor/modules/table.extend'
import * as styles from '#editor/stylesheets/inline'
import * as utils from '#editor/utils'
import * as images from '#editor/utils/images'
import { Attribute } from '#editor/utils/utils'

export function register() {
  customElements.define('tiptap-editor', TiptapEditor)
}

export class TiptapEditor extends HTMLElement {
  #shadow: ShadowRoot
  #container: HTMLElement
  #editor?: tiptap.Editor
  #interval?: NodeJS.Timeout
  #contents: { [key: string]: [string, number] }
  content: string

  static get observedAttributes() {
    const observed = Object.values(utils.attributes)
    return observed
  }

  constructor() {
    super()
    this.content ??= ''
    this.#shadow = this.attachShadow({ mode: 'open' })
    this.#container = html.div([], [])
    this.#container.style.setProperty('--ratio', '1.0')
    this.#container.style.setProperty('--font-size', '1.0rem')
    this.#container.style.setProperty('--border-color', '#ccc')
    this.#container.style.setProperty('--header-background', '#eee')
    this.#container.style.setProperty('--selected-cell-background', '#ccf2')
    this.#container.setAttribute('class', 'tiptap-editor')
    this.#shadow.appendChild(this.#container)
    this.#contents = {}
    this.#appendStyles()
  }

  connectedCallback() {
    const types = ['heading', 'paragraph', 'textStyle']
    const editable = this.#readEditableState()
    if (editable) this.#setupEditableEditor()
    this.#editor = new tiptap.Editor({
      element: this.#container,
      extensions: [
        AnswerBlock,
        Color,
        DocumentLabeller,
        // FileHandler.configure({
        //   allowedMimeTypes: [
        //     'image/png',
        //     'image/jpeg',
        //     'image/gif',
        //     'image/webp',
        //   ],
        //   onDrop: async (_editor, files, anchor) => {
        //     const imgs = await Promise.all(files.map(i => images.fromBlob(i)))
        //     const srcs = await Promise.all(imgs.map(i => images.upload(i)))
        //     await Promise.all(srcs.map(i => this.#insertImage(i, anchor)))
        //   },
        //   onPaste: async (_editor, files, htmlContent) => {
        //     if (htmlContent) return
        //     const imgs = await Promise.all(files.map(i => images.fromBlob(i)))
        //     const srcs = await Promise.all(imgs.map(i => images.upload(i)))
        //     await Promise.all(srcs.map(i => this.#insertImage(i)))
        //   },
        // }),
        FontSize.configure({ types }),
        Image,
        Index,
        Keyboard,
        LineHeight.configure({ types }),
        QuestionBlock,
        StarterKit.configure({
          dropcursor: false,
        }),
        SteerlabBlock,
        SubjectBlock,
        Table.configure({ resizable: true }),
        TableCell,
        TableHeader,
        TableRow,
        TextAlign.configure({ types }),
        TextStyle,
        Underline,
      ],
      content: this.content,
      injectCSS: false,
      editable,
      onBeforeCreate: () => this.#dispatchEvent('beforecreate'),
      onBlur: p => this.#dispatchEvent('blur', { transaction: p.transaction }),
      onContentError: () => this.#dispatchEvent('contenterror'),
      onCreate: () => this.#dispatchEvent('create'),
      onDestroy: () => this.#dispatchEvent('destroy'),
      onDrop: () => this.#dispatchEvent('drop'),
      onFocus: p =>
        this.#dispatchEvent('focus', { transaction: p.transaction }),
      onPaste: () => this.#dispatchEvent('paste'),
      onSelectionUpdate: p =>
        this.#dispatchEvent('selectionupdate', { transaction: p.transaction }),
      onTransaction: p =>
        this.#dispatchEvent('transaction', { transaction: p.transaction }),
      onUpdate: p =>
        this.#dispatchEvent('update', { transaction: p.transaction }),
    })
    this.#editor.on('save', () => this.#saveContent())
    this.#dispatchEvent('connected')
  }

  attributeChangedCallback(name: Attribute, _previous: string, next: string) {
    switch (name) {
      case utils.attributes.ratio: {
        const ratio = next
        this.#container.style.setProperty('--ratio', ratio)
        return Object.entries(utils.styles(ratio)).forEach(([key, value]) => {
          this.#container.style.setProperty(key, value)
        })
      }
      default:
        return this.#setProperty(name, next)
    }
  }

  disconnectedCallback() {
    console.log('disconnected')
    clearInterval(this.#interval)
    this.#saveContent()
    this.#editor?.destroy()
  }

  getContent() {
    return this.#editor?.getHTML()
  }

  setOutlineAnswer(answer: string, blockId: string) {
    const node = this.#editor?.$node('steerlabBlock', { id: blockId })
    if (!node?.after) return
    const id = crypto.randomUUID()
    const node_ = `<steerlab-block data-id="${id}"><p>${answer}</p></steerlab-block>`
    this.#editor?.commands.insertContentAt(node.to - 1, node_)
  }

  async #appendStyles() {
    const stylesheet = new CSSStyleSheet()
    await stylesheet.replace(styles.main)
    this.#shadow.adoptedStyleSheets.push(stylesheet)
    if (import.meta.env.VITE_EDITOR_DEBUG === 'true') {
      const stylesheet = new CSSStyleSheet()
      await stylesheet.replace(styles.debug)
      this.#shadow.adoptedStyleSheets.push(stylesheet)
    }
  }

  #dispatchEvent(name: string, details: object = {}) {
    const content = { detail: { editor: this.#editor, ...details } }
    const event = new CustomEvent(name, content)
    this.dispatchEvent(event)
  }

  #setProperty(name: string, value: string) {
    const property = utils.getProperty(name)
    this.#container.style.setProperty(property, value)
  }

  #readEditableState() {
    const attribute = this.attributes.getNamedItem('editable')
    const value = attribute?.value
    if (value === 'false') return false
    return true
  }

  #setupEditableEditor() {
    const dom = new DOMParser().parseFromString(this.content, 'text/html')
    const children = [...dom.body.children]
    children.forEach((child, order) => {
      const id = child.getAttribute('data-id')
      if (id) this.#contents[id] = [child.innerHTML, order]
    })
    this.#interval = setInterval(() => this.#saveContent(), 2000)
    this.#saveContent()
  }

  #saveContent() {
    const content = this.#editor?.getHTML() ?? ''
    const dom = new DOMParser().parseFromString(content, 'text/html')
    const previous = new Set<string>()
    const children = [...dom.body.children]
    const modified = children.reduce((acc, child, order) => {
      const id = child.getAttribute('data-id')
      if (!id) return acc
      const type = child.tagName.toLowerCase()
      const [previousContent, previousOrder] = this.#contents[id] ?? []
      const innerHTML = child.innerHTML
      previous.add(id)
      if (previousContent === innerHTML && order === previousOrder) return acc
      this.#contents[id] = [innerHTML, order]
      switch (type) {
        case 'steerlab-block':
          return [...acc, { type, id, content: innerHTML, order }]
        case 'steerlab-question': {
          const subject = child.getElementsByTagName('steerlab-subject')[0]
          const answer_ = child.getElementsByTagName('steerlab-answer')[0]
          if (subject && answer_) {
            const question = subject.innerHTML
            const answer = answer_.innerHTML
            return [...acc, { type, id, question, answer, order }]
          }
          return acc
        }
        default:
          return acc
      }
    }, [] as Object[])
    const deleted = Object.keys(this.#contents).filter(k => !previous.has(k))
    for (const id of deleted) delete this.#contents[id]
    if (modified.length > 0 || deleted.length > 0) {
      const detail = { modified, deleted }
      const event = new CustomEvent('updated', { detail })
      this.dispatchEvent(event)
    }
  }

  #insertImage({ src }: images.Image, anchor?: number) {
    if (!this.#editor || !src) return
    anchor ??= this.#editor.state.selection.anchor
    if (!anchor) return
    const img = { type: 'image', attrs: { src } }
    this.#editor.chain().insertContentAt(anchor, img).focus().run()
  }
}
