/// <reference types="./document_selector.d.mts" />
import * as $docx from "../../../../common/data/docx.mjs";
import * as $document_selector from "../../../../common/data/ephemeral/document_selector.mjs";
import * as $button from "../../../../ds/ds/components/button.mjs";
import * as $label from "../../../../ds/ds/components/label.mjs";
import * as $layout from "../../../../ds/ds/components/layout.mjs";
import * as $icons from "../../../../ds/ds/icons.mjs";
import * as $outline from "../../../../ds/ds/outline.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $editor from "../../../elements/editor.mjs";
import { toList } from "../../../gleam.mjs";

function labelling_statuses(selector) {
  let questions_count = $int.to_string(selector.questions_count);
  let others_count = $int.to_string(selector.others_count);
  let uncategorised_count = $int.to_string(selector.uncategorised_count);
  return $layout.row(
    toList([$layout.gap(20)]),
    toList([]),
    toList([
      $label.label(
        toList([
          $label.status(new $label.Success()),
          $label.content(questions_count + " Questions"),
          $label.icon($icons.small($icons.checkmark())),
          $label.large(),
        ]),
      ),
      $label.label(
        toList([
          $label.status(new $label.Current()),
          $label.content(others_count + " Not questions"),
          $label.icon($icons.small($outline.line())),
          $label.large(),
        ]),
      ),
      $label.label(
        toList([
          $label.status(new $label.Neutral()),
          $label.content(uncategorised_count + " Not sure"),
          $label.icon($icons.small($icons.question_mark())),
          $label.large(),
        ]),
      ),
    ]),
  );
}

function to_content(document) {
  let _pipe = $docx.to_labelling(document);
  let _pipe$1 = $list.map(_pipe, $el.to_string);
  return $string.join(_pipe$1, "\n");
}

export function select_document(selector) {
  let $ = selector.document;
  if ($ instanceof $option.None) {
    return $el.none();
  } else {
    let document = $[0];
    let _pipe = toList([
      $layout.gap(20),
      $layout.overflow("hidden"),
      $layout.flex("1"),
    ]);
    return $layout.column(
      _pipe,
      toList([]),
      toList([
        labelling_statuses(selector),
        $editor.view(
          toList([
            $editor.content(to_content(document)),
            $editor.editable(false),
            $editor.on_event(
              (var0) => { return new $msg.UserIssuedTransaction(var0); },
            ),
          ]),
        ),
        $layout.row(
          toList([$layout.justify("end")]),
          toList([]),
          toList([
            $button.primary(
              toList([$e.on_click(new $msg.UserSubmittedDocument())]),
              toList([$el.text("Generate your responses")]),
            ),
          ]),
        ),
      ]),
    );
  }
}
