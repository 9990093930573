/// <reference types="./requirements.d.mts" />
import * as $project from "../../../../../common/data/project.mjs";
import { Project } from "../../../../../common/data/project.mjs";
import * as $proposal from "../../../../../common/data/proposal.mjs";
import { Proposal } from "../../../../../common/data/proposal.mjs";
import * as $colors from "../../../../../ds/ds/colors.mjs";
import * as $display from "../../../../../ds/ds/typography/display.mjs";
import * as $text from "../../../../../ds/ds/typography/text.mjs";
import * as $list from "../../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../../../../gleam_stdlib/gleam/option.mjs";
import * as $el from "../../../../../lustre/lustre/element.mjs";
import * as $css from "../../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../../sketch/sketch/css/length.mjs";
import { px } from "../../../../../sketch/sketch/css/length.mjs";
import * as $html from "../../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $model from "../../../../data/model.mjs";
import * as $projects_common from "../../../../frontend/view/projects/show/common.mjs";
import { toList } from "../../../../gleam.mjs";

function not_found() {
  let _pipe = $css.class$(
    toList([
      $css.max_width(px(1000)),
      $css.margin_("auto"),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(24)),
      $css.text_align("center"),
    ]),
  );
  return $html.div(
    _pipe,
    toList([]),
    toList([
      (() => {
        let _pipe$1 = $css.class$(
          toList([
            $css.compose($display.md()),
            $css.color($colors.light.paragraph),
          ]),
        );
        return $html.div(
          _pipe$1,
          toList([]),
          toList([$el.text("No requirements found")]),
        );
      })(),
      (() => {
        let _pipe$1 = $css.class$(
          toList([
            $css.compose($display.sm()),
            $css.color($colors.light.grey_800),
          ]),
        );
        return $html.div(
          _pipe$1,
          toList([]),
          toList([$el.text("Please contact Steerlab team")]),
        );
      })(),
    ]),
  );
}

function requirements_view(requirements) {
  let _pipe = $css.class$(
    toList([
      $css.max_width(px(1000)),
      $css.margin_("0 auto"),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(24)),
    ]),
  );
  return $html.div(
    _pipe,
    toList([]),
    toList([
      (() => {
        let _pipe$1 = $css.class$(toList([$css.compose($display.md())]));
        return $html.div(
          _pipe$1,
          toList([]),
          toList([$el.text("Requirements used")]),
        );
      })(),
      (() => {
        let _pipe$1 = $css.class$(
          toList([
            $css.background($colors.light.grey_200),
            $css.border_radius(px(8)),
            $css.padding(px(18)),
            $css.display("flex"),
            $css.flex_direction("column"),
            $css.gap(px(12)),
          ]),
        );
        return $html.div(
          _pipe$1,
          toList([]),
          $list.map(
            requirements,
            (requirement) => {
              let _pipe$2 = $css.class$(
                toList([
                  $css.background($colors.light.white),
                  $css.border_radius(px(6)),
                  $css.padding(px(18)),
                  $css.color($colors.light.paragraph),
                  $css.compose($text.md($text.regular)),
                ]),
              );
              return $html.div(
                _pipe$2,
                toList([]),
                toList([$el.text(requirement)]),
              );
            },
          ),
        );
      })(),
    ]),
  );
}

export function view(model, project, proposal) {
  return $el.fragment(
    toList([
      $projects_common.header(project, proposal),
      $projects_common.tab_actions_bar(model, project, proposal),
      (() => {
        let $ = proposal.metadata;
        if ($ instanceof $proposal.Narrative &&
        $.requirements instanceof $option.Some) {
          let requirements = $.requirements[0];
          return requirements_view(requirements);
        } else {
          return not_found();
        }
      })(),
    ]),
  );
}
