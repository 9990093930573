import { jwtDecode } from 'jwt-decode'
import * as prelude from '#gleam/prelude'

export function readPermissions(token: string) {
  try {
    const decoded: any = jwtDecode(token)
    return new prelude.Ok(decoded.permissions)
  } catch (error) {
    return new prelude.Error(undefined)
  }
}
