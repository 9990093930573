/// <reference types="./copilot.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $route from "../../data/route.mjs";
import * as $ai_response from "../../frontend/view/copilot/ai_response.mjs";
import * as $ai_search from "../../frontend/view/copilot/ai_search.mjs";
import { toList, makeError } from "../../gleam.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = data.route;
  if (!($1 instanceof $route.CoPilot)) {
    throw makeError(
      "let_assert",
      "frontend/view/copilot",
      11,
      "view",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let route = $1[0];
  let _pipe = $el.fragment(
    toList([
      (() => {
        if (route instanceof $route.AiSearch) {
          return $ai_search.view(model);
        } else {
          let id = route[0];
          return $ai_response.view(model, id);
        }
      })(),
    ]),
  );
  return $el.map(_pipe, (var0) => { return new $msg.Copilot(var0); });
}
