/// <reference types="./cmd.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function cmd() {
  let content = "<path xmlns=\"http://www.w3.org/2000/svg\" d=\"M68.5,57h-5.5v-14h5.5c6.34,0,11.5-5.16,11.5-11.5s-5.16-11.5-11.5-11.5-11.5,5.16-11.5,11.5v5.5h-14v-5.5c0-6.34-5.16-11.5-11.5-11.5s-11.5,5.16-11.5,11.5,5.16,11.5,11.5,11.5h5.5v14h-5.5c-6.34,0-11.5,5.16-11.5,11.5s5.16,11.5,11.5,11.5,11.5-5.16,11.5-11.5v-5.5h14v5.5c0,6.34,5.16,11.5,11.5,11.5s11.5-5.16,11.5-11.5-5.16-11.5-11.5-11.5Zm-5.5-25.5c0-3.03,2.47-5.5,5.5-5.5s5.5,2.47,5.5,5.5-2.47,5.5-5.5,5.5h-5.5v-5.5Zm-26,37c0,3.03-2.47,5.5-5.5,5.5s-5.5-2.47-5.5-5.5,2.47-5.5,5.5-5.5h5.5v5.5Zm0-31.5h-5.5c-3.03,0-5.5-2.47-5.5-5.5s2.47-5.5,5.5-5.5,5.5,2.47,5.5,5.5v5.5Zm20,20h-14v-14h14v14Zm11.5,17c-3.03,0-5.5-2.47-5.5-5.5v-5.5h5.5c3.03,0,5.5,2.47,5.5,5.5s-2.47,5.5-5.5,5.5Z\" fill=\"currentColor\" />";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 100 100"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
