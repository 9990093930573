/// <reference types="./content_library.d.mts" />
import * as $connector from "../../../common/data/connector.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $insight from "../../../common/data/insight.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $level from "../../../grille_pain/grille_pain/toast/level.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $modem from "../../../modem/modem.mjs";
import * as $content_library from "../../data/content_library.mjs";
import { ContentLibrary } from "../../data/content_library.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $route from "../../data/route.mjs";
import * as $loading from "../../data/ui/loading.mjs";
import * as $effects from "../../frontend/effects.mjs";
import * as $content_library_effects from "../../frontend/effects/content_library.mjs";
import * as $modal_effects from "../../frontend/effects/modal.mjs";
import * as $spreadsheet_selector from "../../frontend/update/proposal_builder/spreadsheet_selector.mjs";
import { toList, prepend as listPrepend, isEqual } from "../../gleam.mjs";

function handle_content_library_spreadsheet_selector(data, msg) {
  if (msg instanceof $msg.UserSubmittedXlsx) {
    let _pipe = (() => {
      let _record = data.content_library;
      return new ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.conflicts_data_points,
        _record.content_library_block_page_size,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        true,
        _record.ingestion_spreadsheet,
        _record.insights,
        _record.insight_filter_selected,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        _record.search,
        _record.selected,
        _record.selected_insight_data_point_id,
        _record.selected_insight_id,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(
      _pipe$1,
      $content_library_effects.ingest_question_bank(data),
    );
  } else if (msg instanceof $msg.UserCancelledSpreadsheetSelection) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.reset_ingestion_spreadsheet(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $modem.back(1));
  } else {
    let msg$1 = msg;
    let $ = data.content_library;
    let ingestion_spreadsheet = $.ingestion_spreadsheet;
    let was_processing = ingestion_spreadsheet.processing;
    let $1 = $spreadsheet_selector.update(data, ingestion_spreadsheet, msg$1);
    let ingestion_spreadsheet$1 = $1[0];
    let effect = $1[1];
    let _pipe = $effect.map(
      effect,
      (var0) => {
        return new $msg.ContentLibraryHandledSpreadsheetSelector(var0);
      },
    );
    let _pipe$1 = $effect.map(
      _pipe,
      (var0) => { return new $msg.ContentLibrary(var0); },
    );
    let _pipe$2 = (() => {
      let $2 = was_processing && !ingestion_spreadsheet$1.processing;
      if (!$2) {
        return $list.wrap;
      } else {
        return (_capture) => {
          return $list.prepend(
            toList([
              $route.push(new $route.ContentLibrary(new $route.Ingestion())),
            ]),
            _capture,
          );
        };
      }
    })()(_pipe$1);
    let _pipe$3 = $effect.batch(_pipe$2);
    return ((_capture) => {
      return $pair.new$(
        (() => {
          let _pipe$4 = (() => {
            let _record = data.content_library;
            return new ContentLibrary(
              _record.change_expiration_date_input,
              _record.change_expiration_date_popup_opened,
              _record.change_owner_popup_opened,
              _record.change_status_popup_opened,
              _record.change_tags_popup_opened,
              _record.conflicts_data_points,
              _record.content_library_block_page_size,
              _record.dropdown_unsubscriber,
              _record.filter_selected,
              _record.ingestion_loading,
              ingestion_spreadsheet$1,
              _record.insights,
              _record.insight_filter_selected,
              _record.new_qna,
              _record.non_qna_sources,
              _record.non_qna_status,
              _record.qna_sources,
              _record.qna_status,
              _record.search,
              _record.selected,
              _record.selected_insight_data_point_id,
              _record.selected_insight_id,
            );
          })();
          return ((_capture) => {
            return $data.set_content_library(data, _capture);
          })(_pipe$4);
        })(),
        _capture,
      );
    })(_pipe$3);
  }
}

function update_selected_id(id, selected_id) {
  if (selected_id instanceof $option.Some) {
    let selected_id$1 = selected_id[0];
    let $ = selected_id$1 === id;
    if ($) {
      return new $option.None();
    } else {
      return new $option.Some(id);
    }
  } else {
    return new $option.Some(id);
  }
}

export function update(data, msg) {
  if (msg instanceof $msg.ApiResolvedInsight) {
    let insight = msg.insight;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.remove_insight(_pipe, insight);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedSource) {
    let source = msg.source;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.upsert_data_source(_pipe, source);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedSources) {
    let sources = msg.sources;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.set_data_sources(_pipe, sources);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    let _pipe$3 = $data.mark_as_loaded(_pipe$2, $loading.content_library);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.ApiUpsertedDataSources) {
    let sources = msg.sources;
    let _pipe = sources;
    let _pipe$1 = $list.fold(
      _pipe,
      data.content_library,
      $content_library.upsert_data_source,
    );
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserSelectedSource) {
    let id = msg.id;
    let checked = msg.checked;
    let handler = (() => {
      if (checked) {
        return $content_library.select;
      } else {
        return $content_library.deselect;
      }
    })();
    let _pipe = data.content_library;
    let _pipe$1 = handler(_pipe, id);
    let _pipe$2 = $content_library.close_popups(_pipe$1);
    let _pipe$3 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$2);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserToggledChangeOwnerPopupInContentLibrary) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.toggle_change_owner(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return ((data) => {
      return [data, $content_library_effects.subscribe_dom_click(data)];
    })(_pipe$2);
  } else if (msg instanceof $msg.UserToggledChangeStatusPopupInContentLibrary) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.toggle_change_status(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return ((data) => {
      return [data, $content_library_effects.subscribe_dom_click(data)];
    })(_pipe$2);
  } else if (msg instanceof $msg.UserToggledChangeTags) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.toggle_change_tags(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return ((data) => {
      return [data, $content_library_effects.subscribe_dom_click(data)];
    })(_pipe$2);
  } else if (msg instanceof $msg.UserToggledChangeExpirationDatePopup) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.toggle_change_expiration_date(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return ((data) => {
      return [data, $content_library_effects.subscribe_dom_click(data)];
    })(_pipe$2);
  } else if (msg instanceof $msg.UserClickedOutsideInContentLibrary) {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.close_popups(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.ContentLibraryReturnedUnsubscriber) {
    let dropdown_unsubscriber = msg[0];
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.set_dropdown_unsubscriber(
      _pipe,
      new Some(dropdown_unsubscriber),
    );
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserClickedGlobalCheckbox) {
    let all_selected = msg.all_selected;
    let sources = msg.sources;
    let selected = $bool.guard(
      all_selected,
      $dict.new$(),
      () => {
        return $list.fold(
          sources,
          $dict.new$(),
          (selected, source) => {
            return $dict.insert(selected, source.id, true);
          },
        );
      },
    );
    let _pipe = (() => {
      let _record = data.content_library;
      return new $content_library.ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.conflicts_data_points,
        _record.content_library_block_page_size,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        _record.ingestion_loading,
        _record.ingestion_spreadsheet,
        _record.insights,
        _record.insight_filter_selected,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        _record.search,
        selected,
        _record.selected_insight_data_point_id,
        _record.selected_insight_id,
      );
    })();
    let _pipe$1 = $content_library.close_popups(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserAssignedStatusInContentLibrary) {
    let status = msg.status;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.attribute_status(_pipe, status);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $effect.batch(
        toList([
          $content_library_effects.unsubscribe_previous_dom_click(data),
          $content_library_effects.update_data_sources_status(
            status,
            $dict.fold(
              data.content_library.selected,
              toList([]),
              (ids, key, value) => {
                return $bool.guard(
                  !value,
                  ids,
                  () => { return listPrepend(key, ids); },
                );
              },
            ),
          ),
        ]),
      ),
    );
  } else if (msg instanceof $msg.UserToggledAssignUserPopupInContentLibrary) {
    let user_id = msg.user_id;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.attribute_source(_pipe, user_id);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.update_data_sources_owner(data, user_id),
    );
  } else if (msg instanceof $msg.UserAssignedTag) {
    let tag = msg.tag;
    let add_remove = msg.add_remove;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.attribute_tag(_pipe, tag, add_remove);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.add_remove_data_sources_tag(
        data,
        tag,
        add_remove,
      ),
    );
  } else if (msg instanceof $msg.UserAssignedExpirationDate) {
    let expiration_date = msg.expiration_date;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.attribute_expiration_date(
      _pipe,
      expiration_date,
    );
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    let _pipe$3 = update(
      _pipe$2,
      new $msg.UserToggledChangeExpirationDatePopup(),
    );
    return $pair.map_second(
      _pipe$3,
      (effects) => {
        let unsub = $content_library_effects.unsubscribe_previous_dom_click(
          data,
        );
        let _pipe$4 = expiration_date;
        let _pipe$5 = ((_capture) => {
          return $content_library_effects.update_data_sources_expiration_date(
            data,
            _capture,
          );
        })(_pipe$4);
        let _pipe$6 = ((_capture) => {
          return $list.prepend(toList([effects, unsub]), _capture);
        })(_pipe$5);
        return $effect.batch(_pipe$6);
      },
    );
  } else if (msg instanceof $msg.UserSelectedInsightCard) {
    let id = msg.id;
    let insight_id = msg.insight_id;
    let id$1 = update_selected_id(
      id,
      data.content_library.selected_insight_data_point_id,
    );
    let insight_id$1 = new $option.Some(insight_id);
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.set_selected_insight_data_point_id(
      _pipe,
      id$1,
    );
    let _pipe$2 = $content_library.set_selected_insight_id(
      _pipe$1,
      insight_id$1,
    );
    let _pipe$3 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$2);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserSelectedContentLibraryPage) {
    let page = msg.page;
    let _pipe = (() => {
      let $ = data.route;
      if ($ instanceof $route.ContentLibrary &&
      $[0] instanceof $route.DataSources) {
        let ds = $[0];
        let _pipe = new $route.ContentLibrary(
          (() => {
            let _record = ds;
            return new $route.DataSources(page, _record.source_id);
          })(),
        );
        return $route.replace(_pipe);
      } else if ($ instanceof $route.ContentLibrary &&
      $[0] instanceof $route.QuestionBank) {
        let qb = $[0];
        let _pipe = new $route.ContentLibrary(
          (() => {
            let _record = qb;
            return new $route.QuestionBank(page, _record.source_id);
          })(),
        );
        return $route.replace(_pipe);
      } else {
        return $effect.none();
      }
    })();
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else if (msg instanceof $msg.UserSelectedInsightFilter) {
    let filter = msg.filter;
    let _pipe = $content_library.set_insight_filter(
      data.content_library,
      filter,
    );
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserSelectedFilter) {
    let filter = msg.filter;
    let filter$1 = new Some(filter);
    let _pipe = (() => {
      let $ = isEqual(data.content_library.filter_selected, filter$1);
      if ($) {
        return (_capture) => {
          return $content_library.set_filter(_capture, new $option.None());
        };
      } else {
        return (_capture) => {
          return $content_library.set_filter(_capture, filter$1);
        };
      }
    })();
    let _pipe$1 = ((f) => { return f(data.content_library); })(_pipe);
    let _pipe$2 = $content_library.deselect_all(_pipe$1);
    let _pipe$3 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$2);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserSkippedInsight) {
    let insight = msg.insight;
    let _pipe = (() => {
      let _record = insight;
      return new $insight.Insight(
        _record.id,
        _record.org_id,
        _record.source_id,
        _record.content,
        _record.kind,
        _record.chosen_id,
        new $insight.Skipped(),
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $content_library_effects.resolve_insight(data, _capture);
    })(_pipe);
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe$1);
  } else if (msg instanceof $msg.UserFixedConflict) {
    let insight = msg.insight;
    let chosen_id = msg.chosen_id;
    let deleted_id = msg.deleted_id;
    let insight$1 = (() => {
      let _record = insight;
      return new $insight.Insight(
        _record.id,
        _record.org_id,
        _record.source_id,
        _record.content,
        _record.kind,
        new $option.Some(chosen_id),
        new $insight.Fixed(),
      );
    })();
    let _pipe = $effect.batch(
      toList([
        $content_library_effects.resolve_insight(data, insight$1),
        $content_library_effects.delete_data_point(data, deleted_id),
      ]),
    );
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else if (msg instanceof $msg.UserFixedDuplicate) {
    let insight = msg.insight;
    let chosen_id = msg.chosen_id;
    let deleted_id = msg.deleted_id;
    let insight$1 = (() => {
      let _record = insight;
      return new $insight.Insight(
        _record.id,
        _record.org_id,
        _record.source_id,
        _record.content,
        _record.kind,
        new $option.Some(chosen_id),
        new $insight.Fixed(),
      );
    })();
    let _pipe = $effect.batch(
      toList([
        $content_library_effects.resolve_insight(data, insight$1),
        $content_library_effects.update_data_sources_status(
          new $data_source.Outdated(),
          toList([deleted_id]),
        ),
      ]),
    );
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else if (msg instanceof $msg.UserFixedIrrelevant) {
    let insight = msg.insight;
    let insight$1 = (() => {
      let _record = insight;
      return new $insight.Insight(
        _record.id,
        _record.org_id,
        _record.source_id,
        _record.content,
        _record.kind,
        _record.chosen_id,
        new $insight.Fixed(),
      );
    })();
    let _pipe = $effect.batch(
      toList([
        $content_library_effects.resolve_insight(data, insight$1),
        $content_library_effects.update_data_sources_status(
          new $data_source.Outdated(),
          toList([insight$1.source_id]),
        ),
      ]),
    );
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else if (msg instanceof $msg.UserUpdatedSearch) {
    let search = msg.search;
    let _pipe = (() => {
      let _record = data.content_library;
      return new ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.conflicts_data_points,
        _record.content_library_block_page_size,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        _record.ingestion_loading,
        _record.ingestion_spreadsheet,
        _record.insights,
        _record.insight_filter_selected,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        search,
        _record.selected,
        _record.selected_insight_data_point_id,
        _record.selected_insight_id,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedExpirationDateInput) {
    let value = msg.value;
    let _pipe = (() => {
      let _record = data.content_library;
      return new ContentLibrary(
        value,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.conflicts_data_points,
        _record.content_library_block_page_size,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        _record.ingestion_loading,
        _record.ingestion_spreadsheet,
        _record.insights,
        _record.insight_filter_selected,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        _record.search,
        _record.selected,
        _record.selected_insight_data_point_id,
        _record.selected_insight_id,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUploadedSourceFiles) {
    let sources = msg.sources;
    let _pipe = $content_library_effects.upload_content_library_sources(
      data,
      sources,
    );
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else if (msg instanceof $msg.ApiSuccessfullyProcessedFiles) {
    let total = msg.total;
    let _pipe = $content_library.set_non_qna_status(
      data.content_library,
      (() => {
        let _record = data.content_library.non_qna_status;
        return new $data_source.StatusStats(
          _record.verified,
          _record.pending,
          data.content_library.non_qna_status.not_verified + total,
          _record.outdated,
        );
      })(),
    );
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedQuestionBankEditQuestion) {
    let question = msg.question;
    let _pipe = (() => {
      let _record = data.content_library.new_qna;
      return new $content_library.NewQna(
        _record.answer,
        _record.existing_document,
        _record.loading,
        question,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(data.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedQuestionBankEditAnswer) {
    let answer = msg.answer;
    let _pipe = (() => {
      let _record = data.content_library.new_qna;
      return new $content_library.NewQna(
        answer,
        _record.existing_document,
        _record.loading,
        _record.question,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(data.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserSubmittedQuestionBankEdit) {
    let _pipe = (() => {
      let _record = data.content_library.new_qna;
      return new $content_library.NewQna(
        _record.answer,
        _record.existing_document,
        true,
        _record.question,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(data.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.upload_question_bank_question(data),
    );
  } else if (msg instanceof $msg.ApiRejectedUploadingQuestion) {
    let cl = (() => {
      let _record = data.content_library;
      return new ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.conflicts_data_points,
        _record.content_library_block_page_size,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        false,
        _record.ingestion_spreadsheet,
        _record.insights,
        _record.insight_filter_selected,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        _record.search,
        _record.selected,
        _record.selected_insight_data_point_id,
        _record.selected_insight_id,
      );
    })();
    let _pipe = (() => {
      let _record = data.content_library.new_qna;
      return new $content_library.NewQna(
        _record.answer,
        _record.existing_document,
        false,
        _record.question,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(cl, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      (() => {
        let _pipe$3 = "Internal server error. Please, retry later.";
        return $effects.toast(_pipe$3, new $level.Error());
      })(),
    );
  } else if (msg instanceof $msg.ApiReturnedUploadingQuestion) {
    let ingestion = msg.ingestion;
    let text = (() => {
      if (ingestion) {
        return "Questions";
      } else {
        return "Question";
      }
    })();
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.reset_ingestion_spreadsheet(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $effect.batch(
        toList([
          $effects.toast(text + " successfully added!", new $level.Success()),
          (() => {
            let $ = data.route;
            if ($ instanceof $route.ContentLibrary &&
            $[0] instanceof $route.NewQuestion) {
              return $modem.back(1);
            } else if ($ instanceof $route.ContentLibrary &&
            $[0] instanceof $route.Ingestion) {
              return $modem.back(1);
            } else {
              return $effect.none();
            }
          })(),
        ]),
      ),
    );
  } else if (msg instanceof $msg.UserCreatedQuestion) {
    let _pipe = $content_library.new_qna();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(data.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $route.push(new $route.ContentLibrary(new $route.NewQuestion())),
    );
  } else if (msg instanceof $msg.UserEdittedQuestion) {
    let question_id = msg.question_id;
    let all = data.content_library.qna_sources;
    let _pipe = $list.find(all, (s) => { return s.id === question_id; });
    let _pipe$1 = $result.map(
      _pipe,
      (source) => {
        let qna = source.display.qna;
        let map = (mapper) => {
          let _pipe$1 = $option.map(qna, mapper);
          return $option.unwrap(_pipe$1, "");
        };
        let question = map((q) => { return q.question; });
        let answer = map((q) => { return q.answer; });
        let _pipe$1 = new $content_library.NewQna(
          answer,
          new Some(source),
          false,
          question,
        );
        let _pipe$2 = ((_capture) => {
          return $content_library.set_new_qna(data.content_library, _capture);
        })(_pipe$1);
        let _pipe$3 = ((_capture) => {
          return $data.set_content_library(data, _capture);
        })(_pipe$2);
        return $pair.new$(
          _pipe$3,
          $route.push(new $route.ContentLibrary(new $route.NewQuestion())),
        );
      },
    );
    return $result.unwrap(_pipe$1, [data, $effect.none()]);
  } else if (msg instanceof $msg.ContentLibraryHandledSpreadsheetSelector) {
    let msg$1 = msg.msg;
    return handle_content_library_spreadsheet_selector(data, msg$1);
  } else if (msg instanceof $msg.ApiReturnedStatusStats) {
    let non_qna_status = msg[0][0];
    let qna_status = msg[0][1];
    let _pipe = (() => {
      let _record = data.content_library;
      return new ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.conflicts_data_points,
        _record.content_library_block_page_size,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        _record.ingestion_loading,
        _record.ingestion_spreadsheet,
        _record.insights,
        _record.insight_filter_selected,
        _record.new_qna,
        _record.non_qna_sources,
        non_qna_status,
        _record.qna_sources,
        qna_status,
        _record.search,
        _record.selected,
        _record.selected_insight_data_point_id,
        _record.selected_insight_id,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedInsights) {
    let insights = msg.insights;
    let conflicts_data_points = msg.data_points;
    let _pipe = (() => {
      let _record = data.content_library;
      return new ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        conflicts_data_points,
        _record.content_library_block_page_size,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        _record.ingestion_loading,
        _record.ingestion_spreadsheet,
        insights,
        _record.insight_filter_selected,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        _record.search,
        _record.selected,
        _record.selected_insight_data_point_id,
        _record.selected_insight_id,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.ApiUpsertedInsight) {
    let insight = msg[0];
    let conflicts_data_points = msg[1];
    let _pipe = data;
    let _pipe$1 = $data.upsert_insight(_pipe, insight);
    let _pipe$2 = $data.upsert_conflicts_data_points(
      _pipe$1,
      conflicts_data_points,
    );
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserDeletedDataSource) {
    let id = msg.id;
    let sources = $content_library.sources(data.content_library);
    let $ = $list.find(sources, (ds) => { return ds.id === id; });
    if (!$.isOk()) {
      return [data, $effect.none()];
    } else {
      let data_source = $[0];
      let by_id = (ds) => { return ds.id !== id; };
      let $1 = (() => {
        let $2 = data_source.kind;
        if ($2 instanceof $connector.SteerlabQna) {
          return [
            data.content_library.qna_sources,
            $content_library.set_qna_sources,
          ];
        } else {
          return [
            data.content_library.non_qna_sources,
            $content_library.set_non_qna_sources,
          ];
        }
      })();
      let sources$1 = $1[0];
      let set_sources_in = $1[1];
      let _pipe = sources$1;
      let _pipe$1 = $list.filter(_pipe, by_id);
      let _pipe$2 = ((_capture) => {
        return set_sources_in(data.content_library, _capture);
      })(_pipe$1);
      let _pipe$3 = ((_capture) => {
        return $data.set_content_library(data, _capture);
      })(_pipe$2);
      return $pair.new$(
        _pipe$3,
        $effect.batch(
          toList([
            $content_library_effects.delete_data_source(data, id),
            $modal_effects.close(),
          ]),
        ),
      );
    }
  } else {
    let id = msg.id;
    let modal = new $data.DeleteDataSource(id);
    let data$1 = (() => {
      let _record = data;
      return new $data.Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        modal,
        _record.modal_unsubscriber,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.elements,
        _record.route,
        _record.language,
        _record.running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    })();
    return [data$1, $modal_effects.subscribe_close()];
  }
}
