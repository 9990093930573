/// <reference types="./translate.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $language from "../data/translate/language.mjs";
import * as $iso639 from "../data/translate/language/iso639.mjs";
import * as $decrypt from "../decrypt.mjs";
import { Ok, Error, toList, CustomType as $CustomType, isEqual } from "../gleam.mjs";

export class Translate extends $CustomType {
  constructor(content, id, kind, org_id, target_language) {
    super();
    this.content = content;
    this.id = id;
    this.kind = kind;
    this.org_id = org_id;
    this.target_language = target_language;
  }
}

export class DataPoint extends $CustomType {}

export class Question extends $CustomType {}

export class Answer extends $CustomType {}

export class None extends $CustomType {}

export class Yes extends $CustomType {}

export class No extends $CustomType {}

export class NA extends $CustomType {}

export class TranslationMetadata extends $CustomType {
  constructor(target_type) {
    super();
    this.target_type = target_type;
  }
}

export class Translation extends $CustomType {
  constructor(id, target_id, content, language, metadata) {
    super();
    this.id = id;
    this.target_id = target_id;
    this.content = content;
    this.language = language;
    this.metadata = metadata;
  }
}

export class Translated extends $CustomType {
  constructor(org_id, id, content, target_language, kind) {
    super();
    this.org_id = org_id;
    this.id = id;
    this.content = content;
    this.target_language = target_language;
    this.kind = kind;
  }
}

export function yes_no_answer(answer, l) {
  if (answer instanceof Yes) {
    return $language.to_yes(l);
  } else if (answer instanceof No) {
    return $language.to_no(l);
  } else {
    return $language.to_na(l);
  }
}

export function parse_yes_no(yes_no) {
  let $ = $string.lowercase(yes_no);
  if ($ === "yes") {
    return new Ok(new Yes());
  } else if ($ === "no") {
    return new Ok(new No());
  } else if ($ === "na") {
    return new Ok(new NA());
  } else {
    return new Error(undefined);
  }
}

export function yes_no_to_string(yes_no) {
  if (yes_no instanceof NA) {
    return "NA";
  } else if (yes_no instanceof No) {
    return "NO";
  } else {
    return "YES";
  }
}

function display_non_default_language(language) {
  let emoji = $language.to_emoji(language);
  let language$1 = $string.capitalise($language.to_string(language));
  return $string.join(toList([emoji, language$1]), " ");
}

function display_default_language(language) {
  let emoji = $language.to_emoji(language);
  let language$1 = $string.capitalise($language.to_string(language));
  return $string.join(toList([emoji, language$1, "(default)"]), " ");
}

export function display_language(language, default_language) {
  let is_default_language = (() => {
    if (default_language instanceof $option.Some) {
      let default_language$1 = default_language[0];
      return isEqual(default_language$1, language);
    } else {
      return false;
    }
  })();
  if (is_default_language) {
    return display_default_language(language);
  } else {
    return display_non_default_language(language);
  }
}

export function translation_target_to_small_string(target) {
  if (target instanceof Question) {
    return "pq";
  } else if (target instanceof Answer) {
    return "pa";
  } else if (target instanceof DataPoint) {
    return "dp";
  } else {
    return "no";
  }
}

export function translation_target_small_decoder() {
  return $decode.then$(
    $decode.string,
    (target) => {
      if (target === "pr") {
        return $decode.success(new Question());
      } else if (target === "pa") {
        return $decode.success(new Answer());
      } else if (target === "dp") {
        return $decode.success(new DataPoint());
      } else if (target === "no") {
        return $decode.success(new None());
      } else {
        return $decode.failure(new Question(), "TranslationTargetKind");
      }
    },
  );
}

export function translation_target_decoder() {
  return $decode.then$(
    $decode.string,
    (language) => {
      if (language === "question") {
        return $decode.success(new Question());
      } else if (language === "answer") {
        return $decode.success(new Answer());
      } else if (language === "data_point") {
        return $decode.success(new DataPoint());
      } else if (language === "none") {
        return $decode.success(new None());
      } else {
        return $decode.failure(new Question(), "TranslationTarget");
      }
    },
  );
}

export function translation_target_to_string(target) {
  if (target instanceof Answer) {
    return "answer";
  } else if (target instanceof Question) {
    return "question";
  } else if (target instanceof DataPoint) {
    return "data_point";
  } else {
    return "none";
  }
}

export function encode_translation_target(target) {
  if (target === "answer") {
    return new Answer();
  } else if (target === "question") {
    return new Question();
  } else if (target === "data_point") {
    return new DataPoint();
  } else {
    return new None();
  }
}

export function translation_metadata_decoder() {
  return $decode.field(
    "target_type",
    translation_target_decoder(),
    (target_type) => {
      return $decode.success(new TranslationMetadata(target_type));
    },
  );
}

export function translation_decoder() {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "content",
        $decode.string,
        (content) => {
          return $decode.field(
            "target_id",
            $decode.string,
            (target_id) => {
              return $decode.field(
                "language",
                $iso639.decoder(),
                (language) => {
                  let metadata = $decrypt.json(translation_metadata_decoder());
                  return $decode.field(
                    "metadata",
                    metadata,
                    (metadata) => {
                      return $decode.success(
                        new Translation(
                          id,
                          target_id,
                          content,
                          language,
                          metadata,
                        ),
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function encode_translation_metadata(metadata) {
  let target_type = translation_target_to_string(metadata.target_type);
  return $json.object(toList([["target_type", $json.string(target_type)]]));
}

export function encode_translation(translation) {
  return $json.object(
    toList([
      ["content", $json.string(translation.content)],
      ["id", $json.string(translation.id)],
      ["target_id", $json.string(translation.target_id)],
      ["language", $json.string($iso639.to_string(translation.language))],
      ["metadata", encode_translation_metadata(translation.metadata)],
    ]),
  );
}

function kind_decoder() {
  return $decode.then$(
    $decode.string,
    (kind) => {
      if (kind === "data_point") {
        return $decode.success(new DataPoint());
      } else if (kind === "question") {
        return $decode.success(new Question());
      } else {
        return $decode.failure(new DataPoint(), "TranslateKind");
      }
    },
  );
}

export function decoder() {
  return $decode.field(
    "content",
    $decode.string,
    (content) => {
      return $decode.field(
        "id",
        $decode.string,
        (id) => {
          return $decode.field(
            "kind",
            kind_decoder(),
            (kind) => {
              return $decode.field(
                "org_id",
                $decode.string,
                (org_id) => {
                  return $decode.field(
                    "target_language",
                    $iso639.decoder(),
                    (target_language) => {
                      return $decode.success(
                        new Translate(
                          content,
                          id,
                          kind,
                          org_id,
                          target_language,
                        ),
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function translate_to_json(data) {
  return $json.object(
    toList([
      ["content", $json.string(data.content)],
      ["id", $json.string(data.id)],
      ["kind", $json.string(translation_target_to_small_string(data.kind))],
      ["org_id", $json.string(data.org_id)],
      ["target_language", $json.string($iso639.to_string(data.target_language))],
    ]),
  );
}
