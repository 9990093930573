/// <reference types="./data.d.mts" />
import * as $auth0 from "../../../auth0_client/auth0/client.mjs";
import * as $copilot_question from "../../../common/data/copilot_question.mjs";
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $proposal_builder from "../../../common/data/ephemeral/proposal_builder.mjs";
import * as $insight from "../../../common/data/insight.mjs";
import * as $metrics from "../../../common/data/metrics.mjs";
import * as $notification from "../../../common/data/notification.mjs";
import * as $project from "../../../common/data/project.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $proposal_element from "../../../common/data/proposal_element.mjs";
import * as $question from "../../../common/data/proposal_element/question.mjs";
import * as $qualification_matrix from "../../../common/data/qualification_matrix.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $language from "../../../common/data/translate/language.mjs";
import * as $extra from "../../../common_common/gleam/list/extra.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $timestamp from "../../../gleam_time/gleam/time/timestamp.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $connectors from "../../data/connectors.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $organization from "../../data/organization.mjs";
import * as $route from "../../data/route.mjs";
import * as $copilot_input from "../../data/ui/copilot_input.mjs";
import * as $loading from "../../data/ui/loading.mjs";
import * as $feature_flags from "../../feature_flags.mjs";
import * as $navigator from "../../frontend/browser/navigator.mjs";
import {
  Ok,
  Error,
  toList,
  prepend as listPrepend,
  CustomType as $CustomType,
  makeError,
  divideFloat,
  isEqual,
} from "../../gleam.mjs";
import { readPermissions as read_permissions } from "./data.ffi.ts";

export class Data extends $CustomType {
  constructor(access_token, client, collaborators_proposal_opened, connectors, organization, content_library, copilot_input, proposal_filters, proposal_selected_questions, copilot_threads, custom_rewording_input, modal, modal_unsubscriber, feed_opened, loading, more_proposal_opened, more_proposal_unsubscriber, proposal_builder, filter_proposal_opened, notifications, notifications_unread, permissions, projects, proposals, proposal_block_page_size, elements, route, language, running_requests, translations, user, qualification_matrix, collapsed_navbar, opened_projects, proposal_toggles, dropdown_unsubscriber, analytics) {
    super();
    this.access_token = access_token;
    this.client = client;
    this.collaborators_proposal_opened = collaborators_proposal_opened;
    this.connectors = connectors;
    this.organization = organization;
    this.content_library = content_library;
    this.copilot_input = copilot_input;
    this.proposal_filters = proposal_filters;
    this.proposal_selected_questions = proposal_selected_questions;
    this.copilot_threads = copilot_threads;
    this.custom_rewording_input = custom_rewording_input;
    this.modal = modal;
    this.modal_unsubscriber = modal_unsubscriber;
    this.feed_opened = feed_opened;
    this.loading = loading;
    this.more_proposal_opened = more_proposal_opened;
    this.more_proposal_unsubscriber = more_proposal_unsubscriber;
    this.proposal_builder = proposal_builder;
    this.filter_proposal_opened = filter_proposal_opened;
    this.notifications = notifications;
    this.notifications_unread = notifications_unread;
    this.permissions = permissions;
    this.projects = projects;
    this.proposals = proposals;
    this.proposal_block_page_size = proposal_block_page_size;
    this.elements = elements;
    this.route = route;
    this.language = language;
    this.running_requests = running_requests;
    this.translations = translations;
    this.user = user;
    this.qualification_matrix = qualification_matrix;
    this.collapsed_navbar = collapsed_navbar;
    this.opened_projects = opened_projects;
    this.proposal_toggles = proposal_toggles;
    this.dropdown_unsubscriber = dropdown_unsubscriber;
    this.analytics = analytics;
  }
}

export class NoModal extends $CustomType {}

export class DeleteDataSource extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class DeleteProposal extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class DeleteProposalQuestion extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class DangerousQualificationMatrix extends $CustomType {}

export class IncompleteQualificationMatrix extends $CustomType {}

export class Elements extends $CustomType {
  constructor(by_proposal, data_sources, data_points) {
    super();
    this.by_proposal = by_proposal;
    this.data_sources = data_sources;
    this.data_points = data_points;
  }
}

export class ProposalToggles extends $CustomType {
  constructor(change_owner_popup_opened, change_status_popup_opened) {
    super();
    this.change_owner_popup_opened = change_owner_popup_opened;
    this.change_status_popup_opened = change_status_popup_opened;
  }
}

export function new$(client, route, collapsed_navbar, project_id) {
  let language = (() => {
    let _pipe = $navigator.language();
    return $result.unwrap(_pipe, new $language.English());
  })();
  return new Data(
    new $option.None(),
    client,
    new $option.None(),
    $connectors.new$(),
    $organization.new$(),
    $content_library.new$(),
    $copilot_input.new$(),
    new $proposal.ProposalFilters(new $proposal.AllStatus(), new $option.None()),
    $dict.new$(),
    toList([]),
    "",
    new NoModal(),
    new $option.None(),
    false,
    $loading.new$(),
    new $option.None(),
    new $option.None(),
    $proposal_builder.init(project_id),
    false,
    toList([]),
    false,
    toList([]),
    toList([]),
    toList([]),
    40,
    new Elements($dict.new$(), $dict.new$(), $dict.new$()),
    route,
    language,
    toList([]),
    $dict.new$(),
    new $option.None(),
    toList([]),
    collapsed_navbar,
    $dict.new$(),
    new ProposalToggles(false, false),
    new $option.None(),
    toList([]),
  );
}

export function set_modal(data, modal) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function update_route(data, route) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function update_copilot_input(data, value) {
  let copilot_input = (() => {
    let _record = data.copilot_input;
    return new $copilot_input.CopilotInput(value, _record.loading);
  })();
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function update_custom_rewording_input(data, value) {
  let custom_rewording_input = value;
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function mark_as_loaded(data, mapper) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    mapper(data.loading),
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function set_proposal_filters(model, proposal_filters) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function toggle_filter_proposal_opened(model) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    !model.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function close_filter_proposal(model) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    false,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function update_access_token(data, access_token) {
  let _pipe = read_permissions(access_token);
  let _pipe$1 = $result.replace_error(_pipe, toList([]));
  let _pipe$2 = $result.then$(
    _pipe$1,
    (_capture) => { return $decode.run(_capture, $decode.list($decode.string)); },
  );
  let _pipe$3 = $result.unwrap(_pipe$2, toList([]));
  return ((permissions) => {
    let _record = data;
    return new Data(
      new Some(access_token),
      _record.client,
      _record.collaborators_proposal_opened,
      _record.connectors,
      _record.organization,
      _record.content_library,
      _record.copilot_input,
      _record.proposal_filters,
      _record.proposal_selected_questions,
      _record.copilot_threads,
      _record.custom_rewording_input,
      _record.modal,
      _record.modal_unsubscriber,
      _record.feed_opened,
      _record.loading,
      _record.more_proposal_opened,
      _record.more_proposal_unsubscriber,
      _record.proposal_builder,
      _record.filter_proposal_opened,
      _record.notifications,
      _record.notifications_unread,
      permissions,
      _record.projects,
      _record.proposals,
      _record.proposal_block_page_size,
      _record.elements,
      _record.route,
      _record.language,
      _record.running_requests,
      _record.translations,
      _record.user,
      _record.qualification_matrix,
      _record.collapsed_navbar,
      _record.opened_projects,
      _record.proposal_toggles,
      _record.dropdown_unsubscriber,
      _record.analytics,
    );
  })(_pipe$3);
}

export function update_connected_user(data, user) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    new Some(user),
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

function clear_popup(model) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    new $option.None(),
    _record.connectors,
    _record.organization,
    (() => {
      let _record$1 = model.content_library;
      return new $content_library.ContentLibrary(
        _record$1.change_expiration_date_input,
        _record$1.change_expiration_date_popup_opened,
        _record$1.change_owner_popup_opened,
        _record$1.change_status_popup_opened,
        _record$1.change_tags_popup_opened,
        _record$1.conflicts_data_points,
        _record$1.content_library_block_page_size,
        _record$1.dropdown_unsubscriber,
        _record$1.filter_selected,
        _record$1.ingestion_loading,
        _record$1.ingestion_spreadsheet,
        _record$1.insights,
        _record$1.insight_filter_selected,
        _record$1.new_qna,
        _record$1.non_qna_sources,
        _record$1.non_qna_status,
        _record$1.qna_sources,
        _record$1.qna_status,
        _record$1.search,
        $dict.new$(),
        _record$1.selected_insight_data_point_id,
        _record$1.selected_insight_id,
      );
    })(),
    _record.copilot_input,
    _record.proposal_filters,
    $dict.new$(),
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    false,
    _record.loading,
    new $option.None(),
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    model.filter_proposal_opened && $result.is_ok(
      $route.current_proposal_id(model.route),
    ),
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

function remove_same_id(val, id) {
  if (val instanceof Some && (isEqual(val[0], id))) {
    let old_id = val[0];
    return new $option.None();
  } else {
    let value = val;
    return value;
  }
}

export function empty_popup(model, id) {
  let is_feed = id === "feed";
  return $bool.guard(
    is_feed,
    (() => {
      let _record = model;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.modal,
        _record.modal_unsubscriber,
        false,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.elements,
        _record.route,
        _record.language,
        _record.running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    })(),
    () => {
      return $bool.lazy_guard(
        id === "all",
        () => { return clear_popup(model); },
        () => {
          if (id.startsWith("collaborator-")) {
            let id$1 = id.slice(13);
            let collaborators_proposal_opened = remove_same_id(
              model.collaborators_proposal_opened,
              id$1,
            );
            let _record = model;
            return new Data(
              _record.access_token,
              _record.client,
              collaborators_proposal_opened,
              _record.connectors,
              _record.organization,
              _record.content_library,
              _record.copilot_input,
              _record.proposal_filters,
              _record.proposal_selected_questions,
              _record.copilot_threads,
              _record.custom_rewording_input,
              _record.modal,
              _record.modal_unsubscriber,
              _record.feed_opened,
              _record.loading,
              _record.more_proposal_opened,
              _record.more_proposal_unsubscriber,
              _record.proposal_builder,
              _record.filter_proposal_opened,
              _record.notifications,
              _record.notifications_unread,
              _record.permissions,
              _record.projects,
              _record.proposals,
              _record.proposal_block_page_size,
              _record.elements,
              _record.route,
              _record.language,
              _record.running_requests,
              _record.translations,
              _record.user,
              _record.qualification_matrix,
              _record.collapsed_navbar,
              _record.opened_projects,
              _record.proposal_toggles,
              _record.dropdown_unsubscriber,
              _record.analytics,
            );
          } else if (id.startsWith("ai-")) {
            let id$1 = id.slice(3);
            let more_proposal_opened = remove_same_id(
              model.more_proposal_opened,
              id$1,
            );
            let _record = model;
            return new Data(
              _record.access_token,
              _record.client,
              _record.collaborators_proposal_opened,
              _record.connectors,
              _record.organization,
              _record.content_library,
              _record.copilot_input,
              _record.proposal_filters,
              _record.proposal_selected_questions,
              _record.copilot_threads,
              _record.custom_rewording_input,
              _record.modal,
              _record.modal_unsubscriber,
              _record.feed_opened,
              _record.loading,
              more_proposal_opened,
              _record.more_proposal_unsubscriber,
              _record.proposal_builder,
              _record.filter_proposal_opened,
              _record.notifications,
              _record.notifications_unread,
              _record.permissions,
              _record.projects,
              _record.proposals,
              _record.proposal_block_page_size,
              _record.elements,
              _record.route,
              _record.language,
              _record.running_requests,
              _record.translations,
              _record.user,
              _record.qualification_matrix,
              _record.collapsed_navbar,
              _record.opened_projects,
              _record.proposal_toggles,
              _record.dropdown_unsubscriber,
              _record.analytics,
            );
          } else if (id === "proposal-filter") {
            let _record = model;
            return new Data(
              _record.access_token,
              _record.client,
              _record.collaborators_proposal_opened,
              _record.connectors,
              _record.organization,
              _record.content_library,
              _record.copilot_input,
              _record.proposal_filters,
              _record.proposal_selected_questions,
              _record.copilot_threads,
              _record.custom_rewording_input,
              _record.modal,
              _record.modal_unsubscriber,
              _record.feed_opened,
              _record.loading,
              _record.more_proposal_opened,
              _record.more_proposal_unsubscriber,
              _record.proposal_builder,
              false,
              _record.notifications,
              _record.notifications_unread,
              _record.permissions,
              _record.projects,
              _record.proposals,
              _record.proposal_block_page_size,
              _record.elements,
              _record.route,
              _record.language,
              _record.running_requests,
              _record.translations,
              _record.user,
              _record.qualification_matrix,
              _record.collapsed_navbar,
              _record.opened_projects,
              _record.proposal_toggles,
              _record.dropdown_unsubscriber,
              _record.analytics,
            );
          } else {
            return model;
          }
        },
      );
    },
  );
}

export function is_connected(model) {
  return $option.is_some(model.access_token);
}

export function add_proposal_questions(model, elements) {
  let $ = $list.first(elements);
  if (!$.isOk()) {
    return model;
  } else {
    let element = $[0];
    let proposal_id = $proposal_element.proposal_id(element);
    let elements$1 = $list.map(
      elements,
      (q) => { return [$proposal_element.id(q), q]; },
    );
    let _pipe = model.elements.by_proposal;
    let _pipe$1 = $dict.insert(_pipe, proposal_id, elements$1);
    return ((by_proposal) => {
      let _record = model;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.modal,
        _record.modal_unsubscriber,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        (() => {
          let _record$1 = model.elements;
          return new Elements(
            by_proposal,
            _record$1.data_sources,
            _record$1.data_points,
          );
        })(),
        _record.route,
        _record.language,
        _record.running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    })(_pipe$1);
  }
}

export function upsert_translations(data, t) {
  let translations = (() => {
    let _pipe = t;
    let _pipe$1 = $list.map(_pipe, (t) => { return [t.id, t]; });
    let _pipe$2 = $dict.from_list(_pipe$1);
    return $dict.combine(
      _pipe$2,
      data.translations,
      (new$, _) => { return new$; },
    );
  })();
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function delete_translations(model, s) {
  let translations = (() => {
    let _pipe = model.translations;
    return $dict.filter(
      _pipe,
      (k, _) => {
        let $ = $string.split(k, "_");
        if (!$.hasLength(3)) {
          throw makeError(
            "let_assert",
            "data/model/data",
            303,
            "",
            "Pattern match failed, no pattern matched the value.",
            { value: $ }
          )
        }
        let id = $.tail.tail.head;
        return id !== s;
      },
    );
  })();
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function reset_copilot_input(model) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    $copilot_input.new$(),
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function reset_custom_rewording_input(model) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    "",
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function add_questions_data_sources(model, data_sources) {
  let _pipe = model.elements.data_sources;
  let _pipe$1 = $dict.merge(_pipe, data_sources);
  return ((data_sources) => {
    let _record = model;
    return new Data(
      _record.access_token,
      _record.client,
      _record.collaborators_proposal_opened,
      _record.connectors,
      _record.organization,
      _record.content_library,
      _record.copilot_input,
      _record.proposal_filters,
      _record.proposal_selected_questions,
      _record.copilot_threads,
      _record.custom_rewording_input,
      _record.modal,
      _record.modal_unsubscriber,
      _record.feed_opened,
      _record.loading,
      _record.more_proposal_opened,
      _record.more_proposal_unsubscriber,
      _record.proposal_builder,
      _record.filter_proposal_opened,
      _record.notifications,
      _record.notifications_unread,
      _record.permissions,
      _record.projects,
      _record.proposals,
      _record.proposal_block_page_size,
      (() => {
        let _record$1 = model.elements;
        return new Elements(
          _record$1.by_proposal,
          data_sources,
          _record$1.data_points,
        );
      })(),
      _record.route,
      _record.language,
      _record.running_requests,
      _record.translations,
      _record.user,
      _record.qualification_matrix,
      _record.collapsed_navbar,
      _record.opened_projects,
      _record.proposal_toggles,
      _record.dropdown_unsubscriber,
      _record.analytics,
    );
  })(_pipe$1);
}

export function add_questions_data_points(model, data_points) {
  let _pipe = model.elements.data_points;
  let _pipe$1 = $dict.merge(_pipe, data_points);
  return ((data_points) => {
    let _record = model;
    return new Data(
      _record.access_token,
      _record.client,
      _record.collaborators_proposal_opened,
      _record.connectors,
      _record.organization,
      _record.content_library,
      _record.copilot_input,
      _record.proposal_filters,
      _record.proposal_selected_questions,
      _record.copilot_threads,
      _record.custom_rewording_input,
      _record.modal,
      _record.modal_unsubscriber,
      _record.feed_opened,
      _record.loading,
      _record.more_proposal_opened,
      _record.more_proposal_unsubscriber,
      _record.proposal_builder,
      _record.filter_proposal_opened,
      _record.notifications,
      _record.notifications_unread,
      _record.permissions,
      _record.projects,
      _record.proposals,
      _record.proposal_block_page_size,
      (() => {
        let _record$1 = model.elements;
        return new Elements(
          _record$1.by_proposal,
          _record$1.data_sources,
          data_points,
        );
      })(),
      _record.route,
      _record.language,
      _record.running_requests,
      _record.translations,
      _record.user,
      _record.qualification_matrix,
      _record.collapsed_navbar,
      _record.opened_projects,
      _record.proposal_toggles,
      _record.dropdown_unsubscriber,
      _record.analytics,
    );
  })(_pipe$1);
}

export function empty_proposal_builder(model) {
  let $ = model.route;
  if ($ instanceof $route.Projects &&
  $[0] instanceof $route.AddProposal &&
  !$[0].loading) {
    let project_id = $[0].project_id;
    let _record = model;
    return new Data(
      _record.access_token,
      _record.client,
      _record.collaborators_proposal_opened,
      _record.connectors,
      _record.organization,
      _record.content_library,
      _record.copilot_input,
      _record.proposal_filters,
      _record.proposal_selected_questions,
      _record.copilot_threads,
      _record.custom_rewording_input,
      _record.modal,
      _record.modal_unsubscriber,
      _record.feed_opened,
      _record.loading,
      _record.more_proposal_opened,
      _record.more_proposal_unsubscriber,
      $proposal_builder.init(project_id),
      _record.filter_proposal_opened,
      _record.notifications,
      _record.notifications_unread,
      _record.permissions,
      _record.projects,
      _record.proposals,
      _record.proposal_block_page_size,
      _record.elements,
      _record.route,
      _record.language,
      _record.running_requests,
      _record.translations,
      _record.user,
      _record.qualification_matrix,
      _record.collapsed_navbar,
      _record.opened_projects,
      _record.proposal_toggles,
      _record.dropdown_unsubscriber,
      _record.analytics,
    );
  } else {
    return model;
  }
}

export function empty_opened_projects(data) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    $dict.new$(),
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function set_proposal_builder(model, proposal_builder) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function set_content_library(model, content_library) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function set_connectors(model, connectors) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

function count_validated_questions(questions) {
  return $list.fold(
    questions,
    [0, 0],
    (_use0, _use1) => {
      let validated = _use0[0];
      let total = _use0[1];
      let question = _use1[1];
      if (question instanceof $proposal_element.Block) {
        return [validated, total];
      } else {
        let question$1 = question.question;
        let $ = question$1.validated;
        if ($) {
          return [validated + 1, total + 1];
        } else {
          return [validated, total + 1];
        }
      }
    },
  );
}

function compute_proposal_progress(elements) {
  let $ = count_validated_questions(elements);
  let validated = $[0];
  let total = $[1];
  let _pipe = (divideFloat(
    $int.to_float(validated),
    $int.to_float($int.max(total, 1))
  ));
  let _pipe$1 = $float.multiply(_pipe, 100.0);
  return $float.round(_pipe$1);
}

export function set_proposal_elements(model, proposal_id, elements) {
  let progress = compute_proposal_progress(elements);
  let by_proposal = $dict.insert(
    model.elements.by_proposal,
    proposal_id,
    elements,
  );
  let elements$1 = (() => {
    let _record = model.elements;
    return new Elements(by_proposal, _record.data_sources, _record.data_points);
  })();
  let proposals = (() => {
    let _pipe = model.proposals;
    let _pipe$1 = $list.key_find(_pipe, proposal_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (p) => {
        let _record = p;
        return new $proposal.Proposal(
          _record.id,
          _record.display,
          _record.org_id,
          _record.name,
          _record.deadline,
          _record.owner,
          _record.client,
          _record.collaborators,
          progress,
          _record.status,
          _record.metadata,
          _record.created_at,
          _record.kind,
          _record.project_id,
          _record.google_sheet_id,
          _record.link_id,
        );
      },
    );
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => {
        return $list.key_set(model.proposals, proposal_id, _capture);
      },
    );
    return $result.unwrap(_pipe$3, model.proposals);
  })();
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    proposals,
    _record.proposal_block_page_size,
    elements$1,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function upsert_proposals(model, proposals) {
  let _pipe = $list.fold(
    proposals,
    model.proposals,
    (proposals, proposal) => {
      return $list.key_set(proposals, proposal.id, proposal);
    },
  );
  let _pipe$1 = ((proposals) => {
    let _record = model;
    return new Data(
      _record.access_token,
      _record.client,
      _record.collaborators_proposal_opened,
      _record.connectors,
      _record.organization,
      _record.content_library,
      _record.copilot_input,
      _record.proposal_filters,
      _record.proposal_selected_questions,
      _record.copilot_threads,
      _record.custom_rewording_input,
      _record.modal,
      _record.modal_unsubscriber,
      _record.feed_opened,
      _record.loading,
      _record.more_proposal_opened,
      _record.more_proposal_unsubscriber,
      _record.proposal_builder,
      _record.filter_proposal_opened,
      _record.notifications,
      _record.notifications_unread,
      _record.permissions,
      _record.projects,
      proposals,
      _record.proposal_block_page_size,
      _record.elements,
      _record.route,
      _record.language,
      _record.running_requests,
      _record.translations,
      _record.user,
      _record.qualification_matrix,
      _record.collapsed_navbar,
      _record.opened_projects,
      _record.proposal_toggles,
      _record.dropdown_unsubscriber,
      _record.analytics,
    );
  })(_pipe);
  return mark_as_loaded(_pipe$1, $loading.proposals);
}

export function upsert_projects(model, projects) {
  let _pipe = $list.fold(
    projects,
    model.projects,
    (projects, project) => {
      return $list.key_set(projects, project.id, project);
    },
  );
  let _pipe$1 = $list.sort(
    _pipe,
    (a, b) => { return $timestamp.compare(b[1].created_at, a[1].created_at); },
  );
  let _pipe$2 = ((projects) => {
    let _record = model;
    return new Data(
      _record.access_token,
      _record.client,
      _record.collaborators_proposal_opened,
      _record.connectors,
      _record.organization,
      _record.content_library,
      _record.copilot_input,
      _record.proposal_filters,
      _record.proposal_selected_questions,
      _record.copilot_threads,
      _record.custom_rewording_input,
      _record.modal,
      _record.modal_unsubscriber,
      _record.feed_opened,
      _record.loading,
      _record.more_proposal_opened,
      _record.more_proposal_unsubscriber,
      _record.proposal_builder,
      _record.filter_proposal_opened,
      _record.notifications,
      _record.notifications_unread,
      _record.permissions,
      projects,
      _record.proposals,
      _record.proposal_block_page_size,
      _record.elements,
      _record.route,
      _record.language,
      _record.running_requests,
      _record.translations,
      _record.user,
      _record.qualification_matrix,
      _record.collapsed_navbar,
      _record.opened_projects,
      _record.proposal_toggles,
      _record.dropdown_unsubscriber,
      _record.analytics,
    );
  })(_pipe$1);
  return mark_as_loaded(_pipe$2, $loading.projects);
}

export function delete_project(model, project_id) {
  let _pipe = model.projects;
  let _pipe$1 = $list.filter(
    _pipe,
    (project) => { return project[0] !== project_id; },
  );
  let _pipe$2 = ((projects) => {
    let _record = model;
    return new Data(
      _record.access_token,
      _record.client,
      _record.collaborators_proposal_opened,
      _record.connectors,
      _record.organization,
      _record.content_library,
      _record.copilot_input,
      _record.proposal_filters,
      _record.proposal_selected_questions,
      _record.copilot_threads,
      _record.custom_rewording_input,
      _record.modal,
      _record.modal_unsubscriber,
      _record.feed_opened,
      _record.loading,
      _record.more_proposal_opened,
      _record.more_proposal_unsubscriber,
      _record.proposal_builder,
      _record.filter_proposal_opened,
      _record.notifications,
      _record.notifications_unread,
      _record.permissions,
      projects,
      _record.proposals,
      _record.proposal_block_page_size,
      _record.elements,
      _record.route,
      _record.language,
      _record.running_requests,
      _record.translations,
      _record.user,
      _record.qualification_matrix,
      _record.collapsed_navbar,
      _record.opened_projects,
      _record.proposal_toggles,
      _record.dropdown_unsubscriber,
      _record.analytics,
    );
  })(_pipe$1);
  return mark_as_loaded(_pipe$2, $loading.projects);
}

export function get_proposal_questions(model, proposal_id) {
  let _pipe = model.elements.by_proposal;
  let _pipe$1 = $dict.get(_pipe, proposal_id);
  let _pipe$2 = $result.map(
    _pipe$1,
    (_capture) => {
      return $list.filter_map(
        _capture,
        (element) => {
          let $ = element[1];
          if ($ instanceof $proposal_element.Block) {
            return new Error(undefined);
          } else {
            let q = $.question;
            return new Ok([element[0], q]);
          }
        },
      );
    },
  );
  return $result.unwrap(_pipe$2, toList([]));
}

export function get_proposal_elements(model, proposal_id) {
  let _pipe = model.elements.by_proposal;
  let _pipe$1 = $dict.get(_pipe, proposal_id);
  return $result.unwrap(_pipe$1, toList([]));
}

export function upsert_proposal_element(model, element) {
  let id = $proposal_element.id(element);
  let proposal_id = $proposal_element.proposal_id(element);
  let _pipe = get_proposal_elements(model, proposal_id);
  let _pipe$1 = $list.key_set(_pipe, id, element);
  return ((_capture) => {
    return set_proposal_elements(model, proposal_id, _capture);
  })(_pipe$1);
}

export function delete_proposal_element(data, proposal_id, element_id) {
  let _pipe = get_proposal_elements(data, proposal_id);
  let _pipe$1 = $list.filter(
    _pipe,
    (element) => { return element[0] !== element_id; },
  );
  return ((_capture) => {
    return set_proposal_elements(data, proposal_id, _capture);
  })(_pipe$1);
}

export function set_notifications(model, notifications) {
  let notifications_unread = $list.any(
    notifications,
    (n) => { return !n.read; },
  );
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    notifications,
    notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function recompute_qa_order(elements) {
  return $list.index_map(
    elements,
    (element, qa_order) => {
      let $ = element[1];
      if ($ instanceof $proposal_element.Block) {
        return element;
      } else {
        let q = $.question;
        let question = (() => {
          let _record = q;
          return new $question.Question(
            _record.id,
            _record.proposal_id,
            _record.data_points_id,
            _record.display,
            _record.owner,
            _record.kind,
            _record.choices,
            _record.sheet,
            _record.content,
            _record.answer,
            _record.validated,
            _record.metadata,
            _record.ai_processing,
            _record.assign_processing,
            qa_order,
            _record.doc_id_order,
          );
        })();
        return [element[0], new $proposal_element.Question(question)];
      }
    },
  );
}

export function remove_question(model, proposal_id, question_id) {
  let by_proposal = (() => {
    let _pipe = model.elements.by_proposal;
    let _pipe$1 = $dict.get(_pipe, proposal_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (_capture) => {
        return $list.filter(_capture, (q) => { return q[0] !== question_id; });
      },
    );
    let _pipe$3 = $result.map(_pipe$2, recompute_qa_order);
    let _pipe$4 = $result.map(
      _pipe$3,
      (_capture) => {
        return $dict.insert(model.elements.by_proposal, proposal_id, _capture);
      },
    );
    return $result.unwrap(_pipe$4, model.elements.by_proposal);
  })();
  let progress = (() => {
    let _pipe = by_proposal;
    let _pipe$1 = $dict.get(_pipe, proposal_id);
    let _pipe$2 = $result.unwrap(_pipe$1, toList([]));
    return compute_proposal_progress(_pipe$2);
  })();
  let proposals = (() => {
    let _pipe = model.proposals;
    let _pipe$1 = $list.key_find(_pipe, proposal_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (p) => {
        let _record = p;
        return new $proposal.Proposal(
          _record.id,
          _record.display,
          _record.org_id,
          _record.name,
          _record.deadline,
          _record.owner,
          _record.client,
          _record.collaborators,
          progress,
          _record.status,
          _record.metadata,
          _record.created_at,
          _record.kind,
          _record.project_id,
          _record.google_sheet_id,
          _record.link_id,
        );
      },
    );
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => {
        return $list.key_set(model.proposals, proposal_id, _capture);
      },
    );
    return $result.unwrap(_pipe$3, model.proposals);
  })();
  let elements = (() => {
    let _record = model.elements;
    return new Elements(by_proposal, _record.data_sources, _record.data_points);
  })();
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    proposals,
    _record.proposal_block_page_size,
    elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

function upsert_notification(notifications, notification) {
  if (notifications.atLeastLength(1)) {
    let n = notifications.head;
    let rest = notifications.tail;
    let $ = n.id === notification.id;
    if ($) {
      return new Ok(listPrepend(notification, rest));
    } else {
      let _pipe = upsert_notification(rest, notification);
      return $result.map(_pipe, (value) => { return listPrepend(n, value); });
    }
  } else {
    return new Error(undefined);
  }
}

function upsert(qs, question) {
  if (qs.atLeastLength(1)) {
    let q = qs.head;
    let rest = qs.tail;
    let $ = q.id === question.id;
    if ($) {
      return listPrepend(question, rest);
    } else {
      return listPrepend(q, upsert(rest, question));
    }
  } else {
    return toList([question]);
  }
}

export function add_copilot_questions(model, questions) {
  let _pipe = $list.fold(
    questions,
    model.copilot_threads,
    (threads, question) => {
      let q = (() => {
        let _pipe = $list.key_find(threads, question.thread_id);
        return $result.unwrap(_pipe, toList([]));
      })();
      let _pipe = upsert(q, question);
      let _pipe$1 = $list.sort(
        _pipe,
        (a, b) => { return $timestamp.compare(a.created_at, b.created_at); },
      );
      return ((_capture) => {
        return $list.key_set(threads, question.thread_id, _capture);
      })(_pipe$1);
    },
  );
  return ((copilot_threads) => {
    let _record = model;
    return new Data(
      _record.access_token,
      _record.client,
      _record.collaborators_proposal_opened,
      _record.connectors,
      _record.organization,
      _record.content_library,
      _record.copilot_input,
      _record.proposal_filters,
      _record.proposal_selected_questions,
      copilot_threads,
      _record.custom_rewording_input,
      _record.modal,
      _record.modal_unsubscriber,
      _record.feed_opened,
      _record.loading,
      _record.more_proposal_opened,
      _record.more_proposal_unsubscriber,
      _record.proposal_builder,
      _record.filter_proposal_opened,
      _record.notifications,
      _record.notifications_unread,
      _record.permissions,
      _record.projects,
      _record.proposals,
      _record.proposal_block_page_size,
      _record.elements,
      _record.route,
      _record.language,
      _record.running_requests,
      _record.translations,
      _record.user,
      _record.qualification_matrix,
      _record.collapsed_navbar,
      _record.opened_projects,
      _record.proposal_toggles,
      _record.dropdown_unsubscriber,
      _record.analytics,
    );
  })(_pipe);
}

export function set_notification(model, notification) {
  let _pipe = upsert_notification(model.notifications, notification);
  let _pipe$1 = $result.lazy_unwrap(
    _pipe,
    () => { return listPrepend(notification, model.notifications); },
  );
  return ((_capture) => { return set_notifications(model, _capture); })(_pipe$1);
}

export function find_current_user(model) {
  return $list.find(
    model.organization.users,
    (user) => {
      let connected_user = $option.map(model.user, (u) => { return u.sub; });
      return isEqual(new Some(user.id), connected_user);
    },
  );
}

export function find_current_user_id(model) {
  let user = find_current_user(model);
  return $result.map(user, (u) => { return u.id; });
}

export function current_org_id(model) {
  return $option.then$(model.user, (u) => { return u.org_id; });
}

export function is_organization_allowed(model, feature_flag) {
  let _pipe = current_org_id(model);
  let _pipe$1 = $option.map(
    _pipe,
    (_capture) => { return $feature_flags.authorized(_capture, feature_flag); },
  );
  return $option.unwrap(_pipe$1, false);
}

export function is_qualification_matrix_hidden(model) {
  return !is_organization_allowed(
    model,
    new $feature_flags.QualificationMatrix(),
  );
}

export function are_insights_hidden(model) {
  return !is_organization_allowed(model, new $feature_flags.Insights());
}

export function is_word_hidden(model) {
  return !is_organization_allowed(model, new $feature_flags.WordSupport());
}

export function is_narrative_hidden(model) {
  return !is_organization_allowed(model, new $feature_flags.NarrativeSupport());
}

export function is_translations_hidden(model) {
  return !is_organization_allowed(model, new $feature_flags.Translations());
}

export function sort_elements_by_doc_id(data, proposal_id) {
  let _pipe = get_proposal_elements(data, proposal_id);
  let _pipe$1 = $list.sort(
    _pipe,
    (e1, e2) => {
      let e1$1 = $proposal_element.doc_id_order(e1[1]);
      let e2$1 = $proposal_element.doc_id_order(e2[1]);
      return $int.compare(e1$1, e2$1);
    },
  );
  return ((_capture) => {
    return set_proposal_elements(data, proposal_id, _capture);
  })(_pipe$1);
}

export function upsert_insight(data, insight) {
  let insights = (() => {
    let _pipe = data.content_library.insights;
    return $extra.push(_pipe, insight);
  })();
  let _pipe = (() => {
    let _record = data.content_library;
    return new $content_library.ContentLibrary(
      _record.change_expiration_date_input,
      _record.change_expiration_date_popup_opened,
      _record.change_owner_popup_opened,
      _record.change_status_popup_opened,
      _record.change_tags_popup_opened,
      _record.conflicts_data_points,
      _record.content_library_block_page_size,
      _record.dropdown_unsubscriber,
      _record.filter_selected,
      _record.ingestion_loading,
      _record.ingestion_spreadsheet,
      insights,
      _record.insight_filter_selected,
      _record.new_qna,
      _record.non_qna_sources,
      _record.non_qna_status,
      _record.qna_sources,
      _record.qna_status,
      _record.search,
      _record.selected,
      _record.selected_insight_data_point_id,
      _record.selected_insight_id,
    );
  })();
  return ((_capture) => { return set_content_library(data, _capture); })(_pipe);
}

export function upsert_conflicts_data_points(data, conflicts_data_points) {
  let conflicts_data_points$1 = (() => {
    let _pipe = conflicts_data_points;
    let _pipe$1 = $list.map(_pipe, (d) => { return [d.id, d]; });
    let _pipe$2 = $dict.from_list(_pipe$1);
    return $dict.combine(
      _pipe$2,
      data.content_library.conflicts_data_points,
      (new$, _) => { return new$; },
    );
  })();
  let _pipe = (() => {
    let _record = data.content_library;
    return new $content_library.ContentLibrary(
      _record.change_expiration_date_input,
      _record.change_expiration_date_popup_opened,
      _record.change_owner_popup_opened,
      _record.change_status_popup_opened,
      _record.change_tags_popup_opened,
      conflicts_data_points$1,
      _record.content_library_block_page_size,
      _record.dropdown_unsubscriber,
      _record.filter_selected,
      _record.ingestion_loading,
      _record.ingestion_spreadsheet,
      _record.insights,
      _record.insight_filter_selected,
      _record.new_qna,
      _record.non_qna_sources,
      _record.non_qna_status,
      _record.qna_sources,
      _record.qna_status,
      _record.search,
      _record.selected,
      _record.selected_insight_data_point_id,
      _record.selected_insight_id,
    );
  })();
  return ((_capture) => { return set_content_library(data, _capture); })(_pipe);
}

export function proposal_question_is_selected(data, id) {
  let _pipe = $dict.get(data.proposal_selected_questions, id);
  return $result.unwrap(_pipe, false);
}

export function select_proposal_question(data, id) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    $dict.insert(data.proposal_selected_questions, id, true),
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function deselect_proposal_question(data, id) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    $dict.delete$(data.proposal_selected_questions, id),
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function close_popups(data) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    new ProposalToggles(false, false),
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function toggle_change_owner_in_proposal(data) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    new ProposalToggles(!data.proposal_toggles.change_owner_popup_opened, false),
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function toggle_change_status_in_proposal(data) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    new ProposalToggles(
      false,
      !data.proposal_toggles.change_status_popup_opened,
    ),
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}

export function set_dropdown_unsubscriber(data, dropdown_unsubscriber) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    _record.organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    dropdown_unsubscriber,
    _record.analytics,
  );
}

export function should_subscribe_dom_click(data) {
  return data.proposal_toggles.change_owner_popup_opened || data.proposal_toggles.change_status_popup_opened;
}

export function selected_elements_ids(data) {
  return $dict.fold(
    data.proposal_selected_questions,
    toList([]),
    (ids, key, value) => {
      return $bool.guard(!value, ids, () => { return listPrepend(key, ids); });
    },
  );
}

export function set_organization(data, organization) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.collaborators_proposal_opened,
    _record.connectors,
    organization,
    _record.content_library,
    _record.copilot_input,
    _record.proposal_filters,
    _record.proposal_selected_questions,
    _record.copilot_threads,
    _record.custom_rewording_input,
    _record.modal,
    _record.modal_unsubscriber,
    _record.feed_opened,
    _record.loading,
    _record.more_proposal_opened,
    _record.more_proposal_unsubscriber,
    _record.proposal_builder,
    _record.filter_proposal_opened,
    _record.notifications,
    _record.notifications_unread,
    _record.permissions,
    _record.projects,
    _record.proposals,
    _record.proposal_block_page_size,
    _record.elements,
    _record.route,
    _record.language,
    _record.running_requests,
    _record.translations,
    _record.user,
    _record.qualification_matrix,
    _record.collapsed_navbar,
    _record.opened_projects,
    _record.proposal_toggles,
    _record.dropdown_unsubscriber,
    _record.analytics,
  );
}
