/// <reference types="./notification.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $calendar from "../../gleam_time/gleam/time/calendar.mjs";
import * as $timestamp from "../../gleam_time/gleam/time/timestamp.mjs";
import * as $decrypt from "../decrypt.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Notification extends $CustomType {
  constructor(id, read, content, date) {
    super();
    this.id = id;
    this.read = read;
    this.content = content;
    this.date = date;
  }
}

export class Questions extends $CustomType {
  constructor(project_id, proposal_id, questions_ids) {
    super();
    this.project_id = project_id;
    this.proposal_id = proposal_id;
    this.questions_ids = questions_ids;
  }
}

export class ProjectAssigned extends $CustomType {
  constructor(project_id) {
    super();
    this.project_id = project_id;
  }
}

export class ProposalAssigned extends $CustomType {
  constructor(project_id, proposal_id) {
    super();
    this.project_id = project_id;
    this.proposal_id = proposal_id;
  }
}

export class ProposalCompleted extends $CustomType {
  constructor(project_id, proposal_id) {
    super();
    this.project_id = project_id;
    this.proposal_id = proposal_id;
  }
}

export function question_decoder() {
  return $decode.field(
    "type",
    $decrypt.type_("question"),
    (_) => {
      return $decode.field(
        "project_id",
        $decode.string,
        (project_id) => {
          return $decode.field(
            "proposal_id",
            $decode.string,
            (proposal_id) => {
              return $decode.field(
                "question_id",
                $decode.string,
                (question_id) => {
                  return $decode.success(
                    new Questions(
                      project_id,
                      proposal_id,
                      toList([question_id]),
                    ),
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function questions_decoder() {
  return $decode.field(
    "type",
    $decrypt.type_("questions"),
    (_) => {
      return $decode.field(
        "project_id",
        $decode.string,
        (project_id) => {
          return $decode.field(
            "proposal_id",
            $decode.string,
            (proposal_id) => {
              return $decode.field(
                "questions_ids",
                $decode.list($decode.string),
                (questions_ids) => {
                  return $decode.success(
                    new Questions(project_id, proposal_id, questions_ids),
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function proposal_assigned_decoder() {
  return $decode.field(
    "type",
    $decrypt.type_("proposal-assigned"),
    (_) => {
      return $decode.field(
        "project_id",
        $decode.string,
        (project_id) => {
          return $decode.field(
            "proposal_id",
            $decode.string,
            (proposal_id) => {
              return $decode.success(
                new ProposalAssigned(project_id, proposal_id),
              );
            },
          );
        },
      );
    },
  );
}

export function project_assigned_decoder() {
  return $decode.field(
    "type",
    $decrypt.type_("project-assigned"),
    (_) => {
      return $decode.field(
        "project_id",
        $decode.string,
        (project_id) => {
          return $decode.success(new ProjectAssigned(project_id));
        },
      );
    },
  );
}

export function proposal_completed_decoder() {
  return $decode.field(
    "type",
    $decrypt.type_("proposal-completed"),
    (_) => {
      return $decode.field(
        "project_id",
        $decode.string,
        (project_id) => {
          return $decode.field(
            "proposal_id",
            $decode.string,
            (proposal_id) => {
              return $decode.success(
                new ProposalCompleted(project_id, proposal_id),
              );
            },
          );
        },
      );
    },
  );
}

export function content_decoder() {
  return $decode.one_of(
    question_decoder(),
    toList([
      questions_decoder(),
      proposal_assigned_decoder(),
      project_assigned_decoder(),
      proposal_completed_decoder(),
    ]),
  );
}

export function decoder() {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "read",
        $decode.bool,
        (read) => {
          return $decode.field(
            "content",
            $decrypt.json(content_decoder()),
            (content) => {
              return $decode.field(
                "date",
                $decrypt.timestamp(),
                (date) => {
                  return $decode.success(
                    new Notification(id, read, content, date),
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function encode_content(content) {
  if (content instanceof Questions) {
    let project_id = content.project_id;
    let proposal_id = content.proposal_id;
    let questions_ids = content.questions_ids;
    return $json.object(
      toList([
        ["type", $json.string("questions")],
        ["questions_ids", $json.array(questions_ids, $json.string)],
        ["proposal_id", $json.string(proposal_id)],
        ["project_id", $json.string(project_id)],
      ]),
    );
  } else if (content instanceof ProposalAssigned) {
    let project_id = content.project_id;
    let proposal_id = content.proposal_id;
    return $json.object(
      toList([
        ["type", $json.string("proposal-assigned")],
        ["project_id", $json.string(project_id)],
        ["proposal_id", $json.string(proposal_id)],
      ]),
    );
  } else if (content instanceof ProjectAssigned) {
    let project_id = content.project_id;
    return $json.object(
      toList([
        ["type", $json.string("project-assigned")],
        ["project_id", $json.string(project_id)],
      ]),
    );
  } else {
    let project_id = content.project_id;
    let proposal_id = content.proposal_id;
    return $json.object(
      toList([
        ["type", $json.string("proposal-completed")],
        ["project_id", $json.string(project_id)],
        ["proposal_id", $json.string(proposal_id)],
      ]),
    );
  }
}

export function encode(notification) {
  return $json.object(
    toList([
      ["id", $json.string(notification.id)],
      ["read", $json.bool(notification.read)],
      ["content", encode_content(notification.content)],
      [
        "date",
        $json.string(
          $timestamp.to_rfc3339(notification.date, $calendar.utc_offset),
        ),
      ],
    ]),
  );
}
