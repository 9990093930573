/// <reference types="./info.d.mts" />
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $toast from "../../../grille_pain/grille_pain/lustre/toast.mjs";
import * as $level from "../../../grille_pain/grille_pain/toast/level.mjs";
import * as $msg from "../../data/msg.mjs";

export function success_uploading(dispatch, count) {
  let options = (() => {
    let _pipe = $toast.options();
    let _pipe$1 = $toast.timeout(_pipe, 8000);
    return $toast.level(_pipe$1, new $level.Success());
  })();
  let count$1 = $int.to_string(count);
  return dispatch(
    new $msg.ApplicationRequestedToDisplayCustomToast(
      options,
      count$1 + " files correctly uploaded!",
    ),
  );
}
