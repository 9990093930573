/// <reference types="./ai_search.d.mts" />
import * as $bright from "../../../../bright/bright.mjs";
import * as $input from "../../../../ds/ds/components/input.mjs";
import * as $keyboard from "../../../../ds/ds/components/keyboard.mjs";
import * as $layout from "../../../../ds/ds/components/layout.mjs";
import { align, flex, gap, justify } from "../../../../ds/ds/components/layout.mjs";
import * as $icons from "../../../../ds/ds/icons.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $model from "../../../data/model.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $ai_response from "../../../frontend/view/copilot/ai_response.mjs";
import * as $s from "../../../frontend/view/styles/copilot.mjs";
import { toList } from "../../../gleam.mjs";

function body() {
  return $layout.column(
    toList([justify("center"), align("center"), gap(32), flex("1")]),
    toList([]),
    toList([
      $layout.column(
        toList([gap(24), align("center")]),
        toList([]),
        toList([
          $s.highlight_question(toList([]), toList([$icons.lightbulb_flash()])),
          $layout.column(
            toList([align("center"), gap(16)]),
            toList([]),
            toList([
              $s.ai_search_title(
                toList([]),
                toList([$el.text("Ask Steerlab a question")]),
              ),
              $s.ai_search_subtitle(
                toList([]),
                toList([
                  $el.text(
                    "Ask a question to Steerlab, to get answers about your data.",
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $el.fragment(
    toList([
      $s.card_wrapper(
        toList([]),
        toList([
          $ai_response.history(model),
          $layout.column(
            toList([flex("1")]),
            toList([]),
            toList([
              body(),
              $h.form(
                toList([$e.on_submit(new $msg.UserSubmittedCopilotInput())]),
                toList([
                  $s.ai_search_pro_tip(
                    toList([]),
                    toList([
                      $el.text("Pro tip, use "),
                      $keyboard.cmd_shortcut("Enter"),
                      $el.text(" to send your message directly!"),
                    ]),
                  ),
                  $input.input(
                    toList([
                      $input.multiline(),
                      $input.placeholder("Ask steerlab a question…"),
                      $input.icon($icons.paper_plane()),
                      $input.on_input(
                        (var0) => {
                          return new $msg.UserEdittedCopilotInput(var0);
                        },
                      ),
                      $input.on_submit(new $msg.UserSubmittedCopilotInput()),
                      $input.value(data.copilot_input.value),
                    ]),
                  ),
                ]),
              ),
              $s.ai_search_disclaimer(
                toList([]),
                toList([$el.text("Make sure to verify AI generated answers.")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
