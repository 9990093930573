/// <reference types="./confluence.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Token extends $CustomType {
  constructor(api_key, domain, user) {
    super();
    this.api_key = api_key;
    this.domain = domain;
    this.user = user;
  }
}

export function new$() {
  let api_key = "";
  let domain = "";
  let user = "";
  return new Token(api_key, domain, user);
}

export function encode(token) {
  return $json.object(
    toList([
      ["api_key", $json.string(token.api_key)],
      ["domain", $json.string(token.domain)],
      ["user", $json.string(token.user)],
    ]),
  );
}

export function decoder() {
  return $decode.field(
    "api_key",
    $decode.string,
    (api_key) => {
      return $decode.field(
        "domain",
        $decode.string,
        (domain) => {
          return $decode.field(
            "user",
            $decode.string,
            (user) => {
              return $decode.success(new Token(api_key, domain, user));
            },
          );
        },
      );
    },
  );
}

export function is_submit_disabled(token) {
  return ((token.api_key === "") || (token.domain === "")) || (token.user === "");
}
