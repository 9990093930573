/// <reference types="./common.d.mts" />
import * as $bright from "../../../../bright/bright.mjs";
import * as $data_source from "../../../../common/data/data_source.mjs";
import * as $insight from "../../../../common/data/insight.mjs";
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $input from "../../../../ds/ds/components/input.mjs";
import * as $layout from "../../../../ds/ds/components/layout.mjs";
import * as $skeleton from "../../../../ds/ds/components/skeleton.mjs";
import * as $table from "../../../../ds/ds/components/table.mjs";
import * as $icons from "../../../../ds/ds/icons.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $model from "../../../data/model.mjs";
import * as $data from "../../../data/model/data.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $pagination from "../../../frontend/view/components/pagination.mjs";
import * as $s from "../../../frontend/view/styles/content_library.mjs";
import * as $p_s from "../../../frontend/view/styles/pagination.mjs";
import { toList, prepend as listPrepend } from "../../../gleam.mjs";

export function choose_settings(state, active) {
  let light = $colors.light;
  if (state === "green") {
    return [light.success_800, light.success_50, light.success_800, active];
  } else if (state === "blue") {
    return [light.blue_800, light.blue_50, light.blue_800, active];
  } else if (state === "red") {
    return [light.error_800, light.error_50, light.error_800, active];
  } else if (state === "grey") {
    return [light.grey_800, light.grey_50, light.grey_800, active];
  } else if (state === "purple") {
    return [light.purple_800, light.purple_50, light.purple_800, active];
  } else if (state === "yellow") {
    return [light.yellow_800, light.yellow_50, light.yellow_800, active];
  } else {
    return [light.grey_800, light.grey_50, light.grey_50, active];
  }
}

function empty_cell(empty_text) {
  return $s.empty_cell($s.empty_text_content($h.text(empty_text)));
}

export function view_empty_table(empty_text) {
  return $table.body(
    toList([
      (() => {
        let _pipe = $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(empty_cell(empty_text));
              return $table.span(_pipe, 7);
            })(),
          ]),
        );
        return $table.hover(_pipe, false);
      })(),
    ]),
  );
}

export function view_loading_table(num_columns) {
  return $table.body(
    (() => {
      let _pipe = $table.row(
        listPrepend(
          $table.cell($el.none()),
          (() => {
            let _pipe = $table.cell($skeleton.skeleton(100, 25));
            return $list.repeat(_pipe, num_columns);
          })(),
        ),
      );
      return $list.repeat(_pipe, 5);
    })(),
  );
}

export function state_card(color, count, status, active) {
  let content = (() => {
    if (status instanceof $data_source.Verified) {
      return "Verified";
    } else if (status instanceof $data_source.NotVerified) {
      return "Not verified";
    } else if (status instanceof $data_source.Pending) {
      return "Pending";
    } else if (status instanceof $data_source.Outdated) {
      return "Outdated";
    } else {
      return "??";
    }
  })();
  let handler = $e.on_click(
    new $msg.ContentLibrary(new $msg.UserSelectedFilter(status)),
  );
  return $s.filter_card(
    choose_settings(color, active),
    toList([handler]),
    toList([
      $s.filter_card_title(toList([]), toList([$h.text($int.to_string(count))])),
      $s.filter_card_subtitle(toList([]), toList([$h.text(content)])),
    ]),
  );
}

export function insight_state_card(color, count, kind, active) {
  let content = (() => {
    if (kind instanceof $insight.Conflict) {
      return "Conflictual information";
    } else if (kind instanceof $insight.Duplicate) {
      return "Duplicates";
    } else {
      return "Irrelevant Documents";
    }
  })();
  let handler = $e.on_click(
    new $msg.ContentLibrary(new $msg.UserSelectedInsightFilter(kind)),
  );
  return $s.filter_card(
    choose_settings(color, active),
    toList([handler]),
    toList([
      $s.filter_card_title(toList([]), toList([$h.text($int.to_string(count))])),
      $s.filter_card_subtitle(toList([]), toList([$h.text(content)])),
    ]),
  );
}

export function view_content_library_filters(model, upload_element) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $layout.row(
    toList([$layout.gap(32), $layout.justify("space-between")]),
    toList([]),
    toList([
      $layout.row(
        toList([$layout.gap(16), $layout.align("center")]),
        toList([]),
        toList([
          $input.input(
            toList([
              $input.placeholder("Search"),
              $input.icon($icons.magnifying_glass()),
              $input.value(data.content_library.search),
              $input.on_input(
                (var0) => { return new $msg.UserUpdatedSearch(var0); },
              ),
            ]),
          ),
        ]),
      ),
      upload_element,
    ]),
  );
}

function make_counter_text(page, page_size) {
  return ((("(" + $int.to_string(page * page_size)) + ", ") + $int.to_string(
    (page + 1) * page_size,
  )) + ")";
}

export function footer(data, page, total_count) {
  let on_select_page = (current_page) => {
    return new $msg.ContentLibrary(
      new $msg.UserSelectedContentLibraryPage(current_page),
    );
  };
  let max_page_size = data.content_library.content_library_block_page_size;
  let page_max = $model.compute_page_max(total_count, max_page_size);
  let page_selector_elements = $pagination.page_selector(
    page,
    page_max,
    on_select_page,
    false,
  );
  let counter_element = $p_s.page_selector_counter(
    toList([]),
    toList([$h.text(make_counter_text(page, max_page_size))]),
  );
  return $table.footer(
    total_count <= data.content_library.content_library_block_page_size,
    toList([counter_element, page_selector_elements]),
  );
}
