/// <reference types="./question.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $data_point from "../../data/data_point.mjs";
import * as $kind from "../../data/docx/kind.mjs";
import * as $one_trust from "../../data/proposal_element/portal/one_trust.mjs";
import * as $translate from "../../data/translate.mjs";
import * as $language from "../../data/translate/language.mjs";
import * as $iso639 from "../../data/translate/language/iso639.mjs";
import * as $decrypt from "../../decrypt.mjs";
import { Ok, toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Long extends $CustomType {}

export class YesNo extends $CustomType {}

export class MultipleChoice extends $CustomType {}

export class Display extends $CustomType {
  constructor(score) {
    super();
    this.score = score;
  }
}

export class Answer extends $CustomType {
  constructor(short, long, yes_no, choice, custom) {
    super();
    this.short = short;
    this.long = long;
    this.yes_no = yes_no;
    this.choice = choice;
    this.custom = custom;
  }
}

export class Attachment extends $CustomType {
  constructor(name, mime_type, id) {
    super();
    this.name = name;
    this.mime_type = mime_type;
    this.id = id;
  }
}

export class PortalQuestion extends $CustomType {
  constructor(question, section_name, language, additional_information, attachments) {
    super();
    this.question = question;
    this.section_name = section_name;
    this.language = language;
    this.additional_information = additional_information;
    this.attachments = attachments;
  }
}

export class SheetQuestion extends $CustomType {
  constructor(x, y, language, additional_information, attachments, parent_id) {
    super();
    this.x = x;
    this.y = y;
    this.language = language;
    this.additional_information = additional_information;
    this.attachments = attachments;
    this.parent_id = parent_id;
  }
}

export class Question extends $CustomType {
  constructor(id, proposal_id, data_points_id, display, owner, kind, choices, sheet, content, answer, validated, metadata, ai_processing, assign_processing, qa_order, doc_id_order) {
    super();
    this.id = id;
    this.proposal_id = proposal_id;
    this.data_points_id = data_points_id;
    this.display = display;
    this.owner = owner;
    this.kind = kind;
    this.choices = choices;
    this.sheet = sheet;
    this.content = content;
    this.answer = answer;
    this.validated = validated;
    this.metadata = metadata;
    this.ai_processing = ai_processing;
    this.assign_processing = assign_processing;
    this.qa_order = qa_order;
    this.doc_id_order = doc_id_order;
  }
}

export function translate_question_and_answer(q, translations) {
  let language = q.metadata.language;
  if (language instanceof $option.Some) {
    let language$1 = language[0];
    let language$2 = $iso639.to_string(language$1);
    let s = $translate.translation_target_to_small_string(
      new $translate.Question(),
    );
    let content = (() => {
      let _pipe = translations;
      let _pipe$1 = $dict.get(
        _pipe,
        $string.join(toList([language$2, s, q.id]), "_"),
      );
      let _pipe$2 = $result.then$(_pipe$1, (t) => { return new Ok(t.content); });
      let _pipe$3 = $option.from_result(_pipe$2);
      return $option.unwrap(_pipe$3, q.content);
    })();
    let a = $translate.translation_target_to_small_string(
      new $translate.Answer(),
    );
    let custom = (() => {
      let _pipe = translations;
      let _pipe$1 = $dict.get(
        _pipe,
        $string.join(toList([language$2, a, q.id]), "_"),
      );
      let _pipe$2 = $result.then$(_pipe$1, (t) => { return new Ok(t.content); });
      let _pipe$3 = $option.from_result(_pipe$2);
      return $option.or(_pipe$3, q.answer.custom);
    })();
    let _record = q;
    return new Question(
      _record.id,
      _record.proposal_id,
      _record.data_points_id,
      _record.display,
      _record.owner,
      _record.kind,
      _record.choices,
      _record.sheet,
      content,
      (() => {
        let _record$1 = q.answer;
        return new Answer(
          _record$1.short,
          _record$1.long,
          _record$1.yes_no,
          _record$1.choice,
          custom,
        );
      })(),
      _record.validated,
      _record.metadata,
      _record.ai_processing,
      _record.assign_processing,
      _record.qa_order,
      _record.doc_id_order,
    );
  } else {
    return q;
  }
}

function attachment_decoder() {
  return $decode.field(
    "name",
    $decode.string,
    (name) => {
      return $decode.field(
        "mime_type",
        $decode.string,
        (mime_type) => {
          return $decode.field(
            "id",
            $decode.string,
            (id) => {
              return $decode.success(new Attachment(name, mime_type, id));
            },
          );
        },
      );
    },
  );
}

function metadata_portal_question_decoder() {
  return $decode.field(
    "type",
    $decrypt.type_("portal"),
    (_) => {
      return $decode.field(
        "question",
        $one_trust.question_decoder(),
        (question) => {
          return $decode.field(
            "section_name",
            $decode.string,
            (section_name) => {
              return $decode.field(
                "language",
                $decode.optional($iso639.decoder()),
                (language) => {
                  return $decrypt.optional_field(
                    "additional_information",
                    $decode.string,
                    (additional_information) => {
                      let attachments = $decode.list(attachment_decoder());
                      return $decrypt.optional_field(
                        "attachments",
                        attachments,
                        (attachments) => {
                          let attachments$1 = $option.unwrap(
                            attachments,
                            toList([]),
                          );
                          return $decode.success(
                            new PortalQuestion(
                              question,
                              section_name,
                              language,
                              additional_information,
                              attachments$1,
                            ),
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function metadata_sheet_question_decoder() {
  return $decode.optional_field(
    "type",
    "question",
    $decrypt.optional_type_("question"),
    (_) => {
      return $decode.field(
        "x",
        $decode.optional($decode.int),
        (x) => {
          return $decode.field(
            "y",
            $decode.optional($decode.int),
            (y) => {
              return $decode.field(
                "language",
                $decode.optional($iso639.decoder()),
                (language) => {
                  return $decode.field(
                    "parent_id",
                    $decode.optional($decode.string),
                    (parent_id) => {
                      return $decrypt.optional_field(
                        "additional_information",
                        $decode.string,
                        (additional_information) => {
                          let attachments = $decode.list(attachment_decoder());
                          return $decrypt.optional_field(
                            "attachments",
                            attachments,
                            (attachments) => {
                              let attachments$1 = $option.unwrap(
                                attachments,
                                toList([]),
                              );
                              return $decode.success(
                                new SheetQuestion(
                                  x,
                                  y,
                                  language,
                                  additional_information,
                                  attachments$1,
                                  parent_id,
                                ),
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function metadata_decoder() {
  return $decode.one_of(
    metadata_sheet_question_decoder(),
    toList([metadata_portal_question_decoder()]),
  );
}

export function display_decoder() {
  return $decode.field(
    "score",
    $decode.optional($decrypt.float()),
    (score) => { return $decode.success(new Display(score)); },
  );
}

export function answer_decoder() {
  return $decode.map(
    $decode.optional(
      $decode.field(
        "short",
        $decode.optional($decode.string),
        (short) => {
          return $decode.field(
            "long",
            $decode.optional($decode.string),
            (long) => {
              return $decode.field(
                "yes_no",
                $decode.optional($decode.string),
                (yes_no) => {
                  return $decode.field(
                    "choice",
                    $decode.optional($decode.string),
                    (choice) => {
                      return $decode.field(
                        "custom",
                        $decode.optional($decode.string),
                        (custom) => {
                          return $decode.success(
                            new Answer(short, long, yes_no, choice, custom),
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      ),
    ),
    (content) => {
      let _pipe = new Answer(
        new None(),
        new None(),
        new None(),
        new None(),
        new None(),
      );
      return ((_capture) => { return $option.unwrap(content, _capture); })(
        _pipe,
      );
    },
  );
}

export function question_type_decoder() {
  return $decode.then$(
    $decode.string,
    (question_type) => {
      if (question_type === "long") {
        return $decode.success(new Long());
      } else if (question_type === "yes-no") {
        return $decode.success(new YesNo());
      } else if (question_type === "multiple-choice") {
        return $decode.success(new MultipleChoice());
      } else {
        return $decode.failure(new Long(), "QuestionType");
      }
    },
  );
}

export function decoder() {
  return $decode.field(
    "element_type",
    $kind.question_decoder(),
    (_) => {
      return $decode.field(
        "id",
        (() => {
          let _pipe = $decode.string;
          return $decode.map(_pipe, $string.lowercase);
        })(),
        (id) => {
          let proposal_id = $decode.map($decode.string, $string.lowercase);
          return $decode.field(
            "proposal_id",
            proposal_id,
            (proposal_id) => {
              return $decode.field(
                "data_points_id",
                (() => {
                  let _pipe = $decode.optional(
                    $decrypt.json($data_point.ids_decoder()),
                  );
                  return $decode.map(
                    _pipe,
                    (_capture) => {
                      return $option.unwrap(_capture, toList([]));
                    },
                  );
                })(),
                (data_points_id) => {
                  return $decode.field(
                    "owner",
                    $decode.optional($decode.string),
                    (owner) => {
                      return $decode.field(
                        "display",
                        $decrypt.json(display_decoder()),
                        (display) => {
                          return $decode.field(
                            "kind",
                            $decode.list(question_type_decoder()),
                            (kind) => {
                              return $decode.field(
                                "choices",
                                $decode.list($decode.string),
                                (choices) => {
                                  return $decode.field(
                                    "sheet",
                                    $decode.optional($decode.string),
                                    (sheet) => {
                                      return $decode.field(
                                        "content",
                                        $decode.string,
                                        (content) => {
                                          return $decode.field(
                                            "answer",
                                            $decrypt.json(answer_decoder()),
                                            (answer) => {
                                              return $decode.field(
                                                "validated",
                                                $decode.bool,
                                                (validated) => {
                                                  return $decode.field(
                                                    "metadata",
                                                    $decrypt.json(
                                                      metadata_decoder(),
                                                    ),
                                                    (metadata) => {
                                                      return $decode.field(
                                                        "ai_processing",
                                                        $decode.bool,
                                                        (ai_processing) => {
                                                          return $decode.field(
                                                            "assign_processing",
                                                            $decode.bool,
                                                            (assign_processing) => {
                                                              return $decode.field(
                                                                "qa_order",
                                                                $decode.int,
                                                                (qa_order) => {
                                                                  return $decode.field(
                                                                    "doc_id_order",
                                                                    $decode.int,
                                                                    (
                                                                        doc_id_order
                                                                      ) => {
                                                                      return $decode.success(
                                                                        new Question(
                                                                          id,
                                                                          proposal_id,
                                                                          data_points_id,
                                                                          display,
                                                                          owner,
                                                                          kind,
                                                                          choices,
                                                                          sheet,
                                                                          content,
                                                                          answer,
                                                                          validated,
                                                                          metadata,
                                                                          ai_processing,
                                                                          assign_processing,
                                                                          qa_order,
                                                                          doc_id_order,
                                                                        ),
                                                                      );
                                                                    },
                                                                  );
                                                                },
                                                              );
                                                            },
                                                          );
                                                        },
                                                      );
                                                    },
                                                  );
                                                },
                                              );
                                            },
                                          );
                                        },
                                      );
                                    },
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function encode_attachment(attachment) {
  return $json.object(
    toList([
      ["name", $json.string(attachment.name)],
      ["mime_type", $json.string(attachment.mime_type)],
      ["id", $json.string(attachment.id)],
    ]),
  );
}

export function encode_metadata(metadata) {
  if (metadata instanceof PortalQuestion) {
    return $json.object(
      toList([
        ["type", $json.string("portal")],
        ["question", $one_trust.encode_question(metadata.question)],
        ["section_name", $json.string(metadata.section_name)],
        [
          "language",
          $json.nullable(
            metadata.language,
            (language) => { return $json.string($iso639.to_string(language)); },
          ),
        ],
      ]),
    );
  } else {
    return $json.object(
      toList([
        ["type", $json.string("question")],
        ["x", $json.nullable(metadata.x, $json.int)],
        ["y", $json.nullable(metadata.y, $json.int)],
        ["parent_id", $json.nullable(metadata.parent_id, $json.string)],
        [
          "language",
          $json.nullable(
            metadata.language,
            (language) => { return $json.string($iso639.to_string(language)); },
          ),
        ],
        [
          "additional_information",
          $json.nullable(metadata.additional_information, $json.string),
        ],
        [
          "attachments",
          $json.array(
            metadata.attachments,
            (attachment) => { return encode_attachment(attachment); },
          ),
        ],
      ]),
    );
  }
}

export function encode_answer(answer) {
  return $json.object(
    toList([
      ["short", $json.nullable(answer.short, $json.string)],
      ["long", $json.nullable(answer.long, $json.string)],
      ["custom", $json.nullable(answer.custom, $json.string)],
      ["yes_no", $json.nullable(answer.yes_no, $json.string)],
      ["choice", $json.nullable(answer.choice, $json.string)],
    ]),
  );
}

export function encode_display(display) {
  return $json.object(
    toList([["score", $json.nullable(display.score, $json.float)]]),
  );
}

export function question_type_to_string(q) {
  if (q instanceof Long) {
    return "long";
  } else if (q instanceof YesNo) {
    return "yes-no";
  } else {
    return "multiple-choice";
  }
}

export function encode_question_type(q) {
  let _pipe = question_type_to_string(q);
  return $json.string(_pipe);
}

export function encode(question) {
  return $json.object(
    toList([
      ["element_type", $kind.encode(new $kind.Question())],
      ["id", $json.string(question.id)],
      ["proposal_id", $json.string(question.proposal_id)],
      [
        "data_points_id",
        $json.array(
          question.data_points_id,
          (_use0) => {
            let id = _use0[0];
            let score = _use0[1];
            return $json.object(
              toList([
                ["data_point_id", $json.string(id)],
                ["score", $json.float(score)],
              ]),
            );
          },
        ),
      ],
      ["owner", $json.nullable(question.owner, $json.string)],
      ["display", encode_display(question.display)],
      ["kind", $json.array(question.kind, encode_question_type)],
      ["choices", $json.array(question.choices, $json.string)],
      ["sheet", $json.nullable(question.sheet, $json.string)],
      ["content", $json.string(question.content)],
      ["answer", encode_answer(question.answer)],
      ["validated", $json.bool(question.validated)],
      ["metadata", encode_metadata(question.metadata)],
      ["ai_processing", $json.bool(question.ai_processing)],
      ["assign_processing", $json.bool(question.assign_processing)],
      ["qa_order", $json.int(question.qa_order)],
      ["doc_id_order", $json.int(question.doc_id_order)],
    ]),
  );
}

export function update_custom_answer(question, custom) {
  let custom$1 = new $option.Some(custom);
  let answer = (() => {
    let _record = question.answer;
    return new Answer(
      _record.short,
      _record.long,
      _record.yes_no,
      _record.choice,
      custom$1,
    );
  })();
  let _record = question;
  return new Question(
    _record.id,
    _record.proposal_id,
    _record.data_points_id,
    _record.display,
    _record.owner,
    _record.kind,
    _record.choices,
    _record.sheet,
    _record.content,
    answer,
    _record.validated,
    _record.metadata,
    _record.ai_processing,
    _record.assign_processing,
    _record.qa_order,
    _record.doc_id_order,
  );
}

export function get_answer_text(answer) {
  if (answer instanceof Answer && answer.custom instanceof $option.Some) {
    let q = answer.custom[0];
    return q;
  } else if (answer instanceof Answer && answer.short instanceof $option.Some) {
    let q = answer.short[0];
    return q;
  } else if (answer instanceof Answer &&
  answer.short instanceof $option.None &&
  answer.long instanceof $option.Some) {
    let q = answer.long[0];
    return q;
  } else if (answer instanceof Answer &&
  answer.short instanceof $option.None &&
  answer.long instanceof $option.None &&
  answer.yes_no instanceof $option.Some) {
    let q = answer.yes_no[0];
    return q;
  } else if (answer instanceof Answer &&
  answer.short instanceof $option.None &&
  answer.long instanceof $option.None &&
  answer.yes_no instanceof $option.None &&
  answer.choice instanceof $option.Some) {
    let q = answer.choice[0];
    return q;
  } else {
    return "";
  }
}

export function get_answer(answer, type_, default_language) {
  if (type_ instanceof MultipleChoice) {
    return answer.choice;
  } else if (type_ instanceof Long) {
    return $option.or(answer.long, answer.custom);
  } else {
    let _pipe = answer.yes_no;
    let _pipe$1 = $option.map(_pipe, $translate.parse_yes_no);
    let _pipe$2 = $option.then$(_pipe$1, $option.from_result);
    return $option.then$(
      _pipe$2,
      (yes_no_value) => {
        return new $option.Some(
          $translate.yes_no_answer(yes_no_value, default_language),
        );
      },
    );
  }
}

export function default_kinds(kinds) {
  let $ = $list.is_empty(kinds);
  if ($) {
    return toList([new Long()]);
  } else {
    return kinds;
  }
}

export function is_hint(question) {
  return (($option.is_none(question.answer.custom) && $option.is_none(
    question.answer.short,
  )) && $option.is_none(question.answer.long)) && $option.is_none(
    question.answer.yes_no,
  );
}

export function update_language(metadata, language) {
  if (metadata instanceof SheetQuestion) {
    let _record = metadata;
    return new SheetQuestion(
      _record.x,
      _record.y,
      language,
      _record.additional_information,
      _record.attachments,
      _record.parent_id,
    );
  } else {
    let _record = metadata;
    return new PortalQuestion(
      _record.question,
      _record.section_name,
      language,
      _record.additional_information,
      _record.attachments,
    );
  }
}
