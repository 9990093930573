/// <reference types="./analytics.d.mts" />
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $shadows from "../../../../ds/ds/shadows.mjs";
import * as $display from "../../../../ds/ds/typography/display.mjs";
import * as $text from "../../../../ds/ds/typography/text.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $common from "../../../frontend/view/common.mjs";
import { toList, CustomType as $CustomType } from "../../../gleam.mjs";

export class Validated extends $CustomType {}

export class InReview extends $CustomType {}

export function section(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.align_items("start"),
      $css.gap(px(16)),
    ]),
  );
  return $h.section(_pipe, attrs, children);
}

export function section_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

export function section_content(attrs, children) {
  let _pipe = $css.class$(toList([$css.width(percent(100))]));
  return $h.div(_pipe, attrs, children);
}

export function statistics_content(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($common.card()),
      $css.padding(px(64)),
      $css.display("flex"),
      $css.width(percent(100)),
      $css.justify_content("center"),
      $css.gap(px(48)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(16)),
      $css.justify_content("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_empty_text(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.color($colors.light.grey_800),
      $css.max_width(px(200)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_value(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("space-between"),
      $css.gap(px(48)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_label(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(12)),
      $css.color($colors.light.paragraph),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_color(color) {
  let _pipe = $css.class$(
    toList([
      $css.background(color),
      $css.width(px(16)),
      $css.height(px(16)),
      $css.border_radius(px(4)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function statistics_colored_label(color, attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose($text.xs($text.regular)), $css.color(color)]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_content_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.gap(px(64)), $css.min_width(px(550))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_wrapper(attrs, children) {
  let _pipe = $css.class$(toList([$css.width(px(20)), $css.height(px(20))]));
  return $h.div(_pipe, attrs, children);
}

export function progress_bar_element(color, kind, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.regular)),
      $css.text_align("center"),
      $css.background(color),
      $css.white_space("nowrap"),
      $css.overflow("hidden"),
      (() => {
        if (kind instanceof Validated) {
          return $css.border_top_left_radius(px(16));
        } else {
          return $css.border_top_right_radius(px(16));
        }
      })(),
      (() => {
        if (kind instanceof Validated) {
          return $css.border_bottom_left_radius(px(16));
        } else {
          return $css.border_bottom_right_radius(px(16));
        }
      })(),
      $css.text_overflow("ellipsis"),
      $css.padding(px(8)),
    ]),
  );
  return $h.div(_pipe, toList([]), children);
}

export function progress_bar(attrs, children) {
  let _pipe = $css.class$(toList([$css.display("flex")]));
  return $h.div(_pipe, attrs, children);
}

export function progress_bar_label(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.align_items("center")]),
  );
  return $h.div(_pipe, attrs, children);
}

export function progress_collaborator(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.overflow("hidden"),
      $css.gap(px(16)),
      $css.justify_content("space-between"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function progress_content_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("grid"),
      $css.grid_template_columns("auto 1fr"),
      $css.width(percent(100)),
      $css.flex_direction("column"),
      $css.gap(px(64)),
      $css.min_width(px(550)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function progress_bar_tooltip(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.padding(px(12)),
      $css.gap(px(12)),
      $css.border_radius(px(8)),
      $css.background($colors.light.grey_50),
      $css.box_shadow($shadows.m()),
      $css.color($colors.light.grey_800),
      $css.max_height(px(350)),
      $css.overflow("auto"),
      $css.white_space("pre-wrap"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
