/// <reference types="./notifications.d.mts" />
import * as $notification from "../../../common/data/notification.mjs";
import { Notification } from "../../../common/data/notification.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import { Data } from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $route from "../../data/route.mjs";
import * as $scaffold from "../../frontend/browser/scaffold.mjs";
import * as $dom from "../../frontend/effects/dom.mjs";
import * as $user_effects from "../../frontend/effects/user.mjs";
import { toList } from "../../gleam.mjs";

export function update_notification(data, notification) {
  let notification_id = notification.id;
  let effs = (() => {
    let $ = notification.content;
    if ($ instanceof $notification.ProposalAssigned) {
      let project_id = $.project_id;
      let proposal_id = $.proposal_id;
      let _pipe = new $route.ShowProposal(
        proposal_id,
        new None(),
        new None(),
        0,
      );
      let _pipe$1 = ((_capture) => {
        return new $route.Proposals(project_id, _capture);
      })(_pipe);
      let _pipe$2 = new $route.Projects(_pipe$1);
      return $route.push(_pipe$2);
    } else if ($ instanceof $notification.ProjectAssigned) {
      let project_id = $.project_id;
      let _pipe = new $route.ShowProject(project_id);
      let _pipe$1 = new $route.Projects(_pipe);
      return $route.push(_pipe$1);
    } else if ($ instanceof $notification.Questions) {
      let project_id = $.project_id;
      let proposal_id = $.proposal_id;
      let questions_ids = $.questions_ids;
      if (questions_ids.atLeastLength(1)) {
        let question_id = questions_ids.head;
        return $effect.batch(
          toList([
            (() => {
              let _pipe = new $route.ShowProposal(
                proposal_id,
                new None(),
                new None(),
                0,
              );
              let _pipe$1 = ((_capture) => {
                return new $route.Proposals(project_id, _capture);
              })(_pipe);
              let _pipe$2 = new $route.Projects(_pipe$1);
              return $route.push(_pipe$2);
            })(),
            $effect.from(
              (_) => { return $scaffold.scroll_to_question(question_id); },
            ),
          ]),
        );
      } else {
        return $effect.none();
      }
    } else {
      let project_id = $.project_id;
      let proposal_id = $.proposal_id;
      let _pipe = new $route.ShowProposal(
        proposal_id,
        new None(),
        new None(),
        0,
      );
      let _pipe$1 = ((_capture) => {
        return new $route.Proposals(project_id, _capture);
      })(_pipe);
      let _pipe$2 = new $route.Projects(_pipe$1);
      return $route.push(_pipe$2);
    }
  })();
  let notification$1 = $list.find(
    data.notifications,
    (n) => { return n.id === notification_id; },
  );
  let _pipe = data;
  let _pipe$1 = $data.empty_popup(_pipe, "all");
  let _pipe$2 = (() => {
    if (notification$1.isOk()) {
      let n = notification$1[0];
      return (_capture) => {
        return $data.set_notification(
          _capture,
          (() => {
            let _record = n;
            return new Notification(
              _record.id,
              true,
              _record.content,
              _record.date,
            );
          })(),
        );
      };
    } else {
      return $function.identity;
    }
  })()(_pipe$1);
  return $pair.new$(
    _pipe$2,
    $effect.batch(
      toList([
        effs,
        $user_effects.mark_notification_as_read(data, notification_id),
      ]),
    ),
  );
}

export function update(data, msg) {
  if (msg instanceof $msg.UserClickedNotification) {
    let notification = msg.notification;
    return update_notification(data, notification);
  } else if (msg instanceof $msg.UserMarkedAllNotificationsAsRead) {
    let _pipe = data.notifications;
    let _pipe$1 = $list.map(
      _pipe,
      (n) => {
        let _record = n;
        return new Notification(_record.id, true, _record.content, _record.date);
      },
    );
    let _pipe$2 = ((_capture) => {
      return $data.set_notifications(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $user_effects.mark_all_notifications_as_read(data),
    );
  } else if (msg instanceof $msg.UserMarkedNotificationAsRead) {
    let notification_id = msg.notification_id;
    let eff = $user_effects.mark_notification_as_read(data, notification_id);
    let $ = $list.find(
      data.notifications,
      (n) => { return n.id === notification_id; },
    );
    if (!$.isOk()) {
      return [data, eff];
    } else {
      let notification = $[0];
      let _pipe = (() => {
        let _record = notification;
        return new Notification(_record.id, true, _record.content, _record.date);
      })();
      let _pipe$1 = ((_capture) => {
        return $data.set_notification(data, _capture);
      })(_pipe);
      return $pair.new$(_pipe$1, eff);
    }
  } else if (msg instanceof $msg.UserToggledNotificationFeed) {
    let feed_opened = !data.feed_opened;
    return [
      (() => {
        let _record = data;
        return new Data(
          _record.access_token,
          _record.client,
          _record.collaborators_proposal_opened,
          _record.connectors,
          _record.organization,
          _record.content_library,
          _record.copilot_input,
          _record.proposal_filters,
          _record.proposal_selected_questions,
          _record.copilot_threads,
          _record.custom_rewording_input,
          _record.modal,
          _record.modal_unsubscriber,
          feed_opened,
          _record.loading,
          _record.more_proposal_opened,
          _record.more_proposal_unsubscriber,
          _record.proposal_builder,
          _record.filter_proposal_opened,
          _record.notifications,
          _record.notifications_unread,
          _record.permissions,
          _record.projects,
          _record.proposals,
          _record.proposal_block_page_size,
          _record.elements,
          _record.route,
          _record.language,
          _record.running_requests,
          _record.translations,
          _record.user,
          _record.qualification_matrix,
          _record.collapsed_navbar,
          _record.opened_projects,
          _record.proposal_toggles,
          _record.dropdown_unsubscriber,
          _record.analytics,
        );
      })(),
      (() => {
        if (!feed_opened) {
          return $effect.none();
        } else {
          let _pipe = $dom.on_click(new $msg.UserClosedPopup("feed"));
          let _pipe$1 = $dom.once(_pipe);
          return $dom.subscribe(_pipe$1);
        }
      })(),
    ];
  } else if (msg instanceof $msg.ApiReturnedNotifications) {
    let notifications = msg.notifications;
    let _pipe = data;
    let _pipe$1 = $data.set_notifications(_pipe, notifications);
    return $pair.new$(_pipe$1, $effect.none());
  } else {
    let notification = msg.notification;
    let _pipe = data;
    let _pipe$1 = $data.set_notification(_pipe, notification);
    return $pair.new$(_pipe$1, $effect.none());
  }
}
