import app_config/websocket
import auth0/client as auth0
import bright
import data/model.{type Model}
import data/model/computed
import data/model/data.{type Data, Data}
import data/msg.{type Msg}
import data/route
import data/ui/loading
import ds
import elements/editor
import frontend/browser/navigator/service_worker
import frontend/effects
import frontend/effects/content_library as content_library_effects
import frontend/effects/proposal as proposal_effects
import frontend/effects/question as question_effects
import frontend/effects/window
import frontend/init
import frontend/middleware.{require_not_modifying_question}
import frontend/update/connectors.{update as update_connectors} as _
import frontend/update/content_library.{update as update_content_library} as _
import frontend/update/copilot.{update as update_copilot} as _
import frontend/update/narrative.{update as update_narrative} as _
import frontend/update/notifications.{update as update_notifications} as _
import frontend/update/organization.{update as update_organization} as _
import frontend/update/project.{update as update_project} as _
import frontend/update/proposal.{update as update_proposal} as _
import frontend/update/proposal_builder.{update as update_proposal_builder} as _
import frontend/view/layout
import frontend/view/login
import frontend/view/slack_login
import frontend/window/local_storage
import gleam/json
import gleam/list
import gleam/option.{None, Some}
import gleam/pair
import gleam/result
import gleam/string
import grille_pain
import grille_pain/lustre/toast
import lustre
import lustre/effect
import modem
import sketch/lustre/experimental
import utils

pub fn main() {
  let assert Ok(_) = ds.setup()
  let assert Ok(_) = editor.register()
  let assert Ok(_) = grille_pain.simple()
  let assert Ok(_) = init.sentry()
  let assert Ok(flags) = init.flags()
  let container = experimental.document()
  let assert Ok(dispatch) =
    view(_, container)
    |> lustre.application(init.init, update, _)
    |> lustre.start("#app", flags)
  let assert Ok(_) = websocket.setup(msg.incoming_decoder(), dispatch)
}

fn update(model: Model, msg: Msg) -> #(Model, effect.Effect(Msg)) {
  use model <- bright.start(model)
  model
  |> bright.update(update_data(_, msg))
  |> bright.compute(computed.displayed_questions)
  |> bright.compute(computed.sheet_questions)
  |> bright.compute(computed.projects)
  |> bright.lazy_compute(computed.sources_selector, computed.sources)
  |> bright.lazy_compute(computed.insights_selector, computed.insights)
  |> bright.schedule(model.replace_proposal_question_page)
  |> bright.schedule(model.replace_proposal_tab)
  |> bright.lazy_schedule(
    computed.content_library_loading_selector,
    model.replace_content_library_page,
  )
  |> bright.lazy_schedule(
    computed.sources_selector,
    model.scroll_to_source_effect,
  )
}

fn update_data(data: Data, msg: Msg) -> #(Data, effect.Effect(Msg)) {
  use <- require_not_modifying_question(data)
  case msg {
    msg.ApplicationStoredAccessToken -> #(data, effects.on_connected_user(data))

    msg.ApiSentOutline(outline) -> {
      let outline = msg.ApiFetchedOutline(outline)
      let msg = msg.ProposalBuilderHandledPdfSelector(outline)
      update_proposal_builder(data, msg)
    }

    msg.ApiSentOutlineGeneration(answer, block_id) -> {
      #(data, {
        use _ <- effect.from()
        utils.send_block_id(answer, block_id)
      })
    }

    msg.ApplicationChangedAuthState(user) -> {
      case user {
        None -> {
          let Data(route:, client:, collapsed_navbar:, ..) = data
          let token = option.None
          let id = route.get_project_id(route)
          let data = data.new(client, route, collapsed_navbar, id)
          let save = model.save_access_token(token)
          let worker = service_worker.send_access_token(token)
          #(data, effect.batch([save, worker]))
        }
        Some(#(access_token, user)) -> {
          let token = option.Some(access_token)
          let data = data.update_access_token(data, access_token)
          let data = data.update_connected_user(data, user)
          let save = model.save_access_token(token)
          let worker = service_worker.send_access_token(token)
          #(data, effect.batch([save, worker]))
        }
      }
    }

    msg.ApplicationChangedRoute(route) -> {
      let new_data = Data(..data, route: route.from_uri(route))
      new_data
      |> data.empty_opened_projects
      |> data.empty_proposal_builder
      |> data.empty_popup("all")
      |> pair.new({
        effect.batch([
          window.update_page_title(new_data.route),
          effects.on_route(new_data, data.route),
        ])
      })
    }

    msg.ApiReturnedAnalytics(analytics) -> {
      #(data.Data(..data, analytics:), effect.none())
    }

    msg.ApplicationCompletedRequest(id) -> {
      let is_id = fn(i) { i != id }
      let running_requests = list.filter(data.running_requests, is_id)
      let data = Data(..data, running_requests:)
      #(data, effect.none())
    }

    msg.ApplicationQueriedProposalElements(timeout, proposal_id) -> {
      case data.route {
        route.Projects(route.Proposals(_, route.ShowProposal(pid, ..)))
          if pid == proposal_id
        ->
          data
          |> data.mark_as_loaded(loading.questions_loading)
          |> pair.new({
            question_effects.query_proposal_questions(data, timeout, pid)
          })
        _ -> #(data, effect.none())
      }
    }

    msg.ApplicationRequestedFetchUserData -> {
      #(data, effects.fetch_user_data(data))
    }

    msg.ApplicationRequestedToDisplayCustomToast(options:, content:) -> {
      #(data, toast.custom(options, content))
    }

    msg.ApplicationRequestedToDisplayToast(level:, message:) -> #(data, {
      toast.options() |> toast.level(level) |> toast.custom(message)
    })

    msg.ApplicationRequestedToHideToast(id) -> {
      #(data, toast.hide(id))
    }

    msg.UserClickedBackArrow -> {
      #(data, modem.back(1))
    }

    msg.UserClickedToOpenLink(link: None) -> #(data, effect.none())
    msg.UserClickedToOpenLink(link: Some(#(filename, link))) -> {
      #(data, content_library_effects.open_link(data, filename, link))
    }

    msg.UserClosedModal -> {
      let modal = data.NoModal
      let modal_unsubscriber = option.None
      let data = Data(..data, modal:, modal_unsubscriber:)
      #(data, option.unwrap(data.modal_unsubscriber, effect.none()))
    }

    msg.UserClosedPopup(id) -> {
      data
      |> data.empty_popup(id)
      |> pair.new(model.unsubscribe_more_proposal(data))
    }

    msg.UserReplacedPage(route) -> {
      #(data, route.replace(route))
    }

    msg.UserToggledCollapsedNavbar -> {
      let collapsed_navbar = !data.collapsed_navbar
      Data(..data, collapsed_navbar:)
      |> pair.new({
        use _ <- effect.from
        json.bool(collapsed_navbar)
        |> local_storage.write("collapsed-navbar")
        |> result.unwrap_both
      })
    }

    msg.ModalReturnedUnsubscriber(unsubscriber) -> {
      let modal_unsubscriber = option.Some(unsubscriber)
      let data = Data(..data, modal_unsubscriber:)
      #(data, effect.none())
    }

    msg.MoreButtonReturnedUnsubscriber(unsubscriber) -> {
      let more_proposal_unsubscriber = Some(unsubscriber)
      Data(..data, more_proposal_unsubscriber:) |> pair.new(effect.none())
    }

    msg.ProposalReturnedUnsubscriber(dropdown_unsubscriber) -> {
      data
      |> data.set_dropdown_unsubscriber(Some(dropdown_unsubscriber))
      |> pair.new(effect.none())
    }

    msg.Connectors(msg) -> update_connectors(data, msg)
    msg.ContentLibrary(msg) -> update_content_library(data, msg)
    msg.Copilot(msg) -> update_copilot(data, msg)
    msg.Narrative(msg) -> update_narrative(data, msg)
    msg.Notifications(msg) -> update_notifications(data, msg)
    msg.Organization(msg) -> update_organization(data, msg)
    msg.Project(msg) -> update_project(data, msg)
    msg.Proposal(msg) -> update_proposal(data, msg)
    msg.ProposalBuilder(msg) -> update_proposal_builder(data, msg)
    msg.Xlsx(msg) -> update_xlsx(data, msg)
    msg.Auth0(auth0) ->
      case auth0 {
        auth0.Authorize(_) -> #(data, effect.none())
        auth0.Authorized(_) -> #(data, effect.none())
        auth0.Login -> {
          let handler = case data.route {
            route.SlackLogin(..) -> auth0.login_with_popup
            _ -> auth0.login_with_redirect
          }
          data.client
          |> handler(msg.ApplicationChangedAuthState)
          |> pair.new(data, _)
        }
        auth0.Logout ->
          data.client
          |> auth0.logout(msg.ApplicationChangedAuthState)
          |> pair.new(data, _)
      }
  }
}

fn update_xlsx(data: Data, msg: msg.Xlsx) {
  case msg {
    msg.DownloadXlsx(proposal_id:, toast_id:) -> {
      let proposal = data.proposals |> list.key_find(proposal_id)
      use proposal <- result.try(proposal)
      let project = data.projects |> list.key_find(proposal.project_id)
      use project <- result.map(project)
      proposal_effects.download_xlsx(data, proposal.id, toast_id, project.name)
    }
    msg.GenerateXlsx(proposal_id:) -> {
      let proposal = list.key_find(data.proposals, proposal_id)
      use proposal <- result.map(proposal)
      let name = "\"" <> proposal.name <> "\""
      let generating = "Generating XLSX file for proposal " <> name <> "."
      let msg = [generating, "Please, wait…"] |> string.join("\n")
      toast.options()
      |> toast.sticky()
      |> toast.notify(fn(t) { msg.Xlsx(msg.DownloadXlsx(proposal_id, t)) })
      |> toast.custom(msg)
    }
  }
  |> result.unwrap(effect.none())
  |> pair.new(data, _)
}

fn view(model: Model, container: experimental.Container) {
  use <- experimental.render([container, experimental.node()])
  let #(data, _) = bright.unwrap(model)
  case data.route, data.is_connected(data) {
    route.Login(..), _ -> login.invite()
    _, False -> login.view()
    route.SlackLogin(..), True -> slack_login.view(model)
    _, True -> layout.view(model)
  }
}
