/// <reference types="./copilot.d.mts" />
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $display from "../../../../ds/ds/typography/display.mjs";
import * as $text from "../../../../ds/ds/typography/text.mjs";
import * as $element from "../../../../lustre/lustre/element.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $common from "../../../frontend/view/common.mjs";
import { toList } from "../../../gleam.mjs";

export function card(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose($common.card()), $css.flex("1")]),
  );
  return $h.div(_pipe, attrs, children);
}

export function card_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($common.card()),
      $css.flex("1"),
      $css.display("flex"),
      $css.gap(px(16)),
      $css.overflow("hidden"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function card_title(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose($display.xs()), $css.color($colors.light.onyx)]),
  );
  return $h.div(_pipe, attrs, children);
}

export function card_subtitle(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.color($colors.light.paragraph),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function ai_search_title(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose($display.md()), $css.color($colors.light.onyx)]),
  );
  return $h.div(_pipe, attrs, children);
}

export function ai_search_subtitle(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($text.md($text.regular))]));
  return $h.div(_pipe, attrs, children);
}

export function icon_wrapper(attrs, children) {
  let _pipe = $css.class$(toList([$css.width(px(20)), $css.height(px(20))]));
  return $h.div(_pipe, attrs, children);
}

export function highlight_question(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.width(px(64)),
      $css.height(px(64)),
      $css.padding(px(12)),
      $css.background($colors.light.foam),
      $css.border_radius(px(500)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function buttons_grid(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("grid"),
      $css.gap(px(16)),
      $css.grid_template_rows("repeat(2, 1fr)"),
      $css.grid_template_columns("repeat(2, 1fr)"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function ai_search_disclaimer(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.color($colors.light.grey_800),
      $css.padding_top(px(8)),
      $css.display("flex"),
      $css.justify_content("space-between"),
      $css.align_items("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function ai_search_pro_tip(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.regular)),
      $css.color($colors.light.grey_800),
      $css.padding_bottom(px(8)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(6)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function history_column(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.width(px(250)),
      $css.background($colors.light.grey_100),
      $css.border_right("1px solid " + $colors.light.grey_400),
      $css.margin(px(-32)),
      $css.margin_right(px(16)),
      $css.padding(px(24)),
      $css.overflow("auto"),
      $css.gap(px(16)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function history_day_title(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function history_question(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.color($colors.light.paragraph),
      $css.text_decoration("none"),
    ]),
  );
  return $h.a(_pipe, attrs, children);
}

export function question_bubble(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.color($colors.light.paragraph),
      $css.background($colors.light.grey_100),
      $css.align_self("end"),
      $css.padding_("10px 24px"),
      $css.border_radius(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function response_bubble(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.color($colors.light.paragraph),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function error_response_bubble(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.color($colors.light.grey_800),
      $css.font_style("italic"),
      $css.white_space("pre-line"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function loading_indicator() {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.color($colors.light.grey_800),
      $css.font_style("italic"),
      $css.white_space("pre-line"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([$element.text("Loading…")]));
}

export function response_sources_title(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.medium)),
      $css.text_transform("uppercase"),
      $css.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function response_sources(attrs, children) {
  return $h.div_(attrs, children);
}

export function response_source(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.display("flex"),
      $css.gap(px(12)),
      $css.color($colors.light.grey_800),
      $css.align_items("center"),
      $css.cursor("default"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
