/// <reference types="./loader.d.mts" />
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../ds/colors.mjs";
import * as $layout from "../../ds/components/layout.mjs";
import * as $skeleton from "../../ds/components/skeleton.mjs";
import * as $display from "../../ds/typography/display.mjs";
import { toList } from "../../gleam.mjs";

function movable_text(delay) {
  return $css.class$(
    toList([
      $css.compose($display.sm()),
      $css.position("absolute"),
      $css.animation_name("fading-text"),
      $css.animation_timing_function("ease-in"),
      $css.animation_fill_mode("both"),
      $css.animation_delay($int.to_string(delay) + "ms"),
      $css.animation_duration("20s"),
      $css.animation_play_state("running"),
      $css.animation_iteration_count("infinite"),
      $css.white_space("nowrap"),
      $css.left(percent(50)),
      $css.color($colors.light.grey_800),
    ]),
  );
}

export function loader(items) {
  return $layout.row(
    toList([$layout.justify("center")]),
    toList([]),
    toList([
      $html.div(
        toList([]),
        toList([
          $h.div(
            $css.class$(
              toList([$css.position("relative"), $css.height(px(60))]),
            ),
            toList([]),
            $list.index_map(
              items,
              (item, index) => {
                return $h.div(
                  movable_text(index * 4000),
                  toList([]),
                  toList([$h.text(item)]),
                );
              },
            ),
          ),
          $skeleton.skeleton(300, 10),
        ]),
      ),
    ]),
  );
}
