import { Editor, Extension } from '@tiptap/core'
import { EditorState, Plugin, Transaction } from '@tiptap/pm/state'
import { findSteerlabBlockPosition } from '#editor/utils/utils'

/** Add `save` event in `EditorEvents`. */
declare module '@tiptap/core' {
  interface EditorEvents {
    save: { editor: Editor }
  }
}

export const Keyboard = Extension.create({
  name: 'keyboard',
  addKeyboardShortcuts: () => ({ 'Mod-s': ModS, Enter, Backspace }),
  addProseMirrorPlugins: () => {
    const edition = new Plugin({ filterTransaction: cancelSubjectEdition })
    return [edition]
  },
})

/** Cancels every operation happening on `<steerlab-subject>` nodes.
 * By design, a question can not be edited as of now.
 * For each step in the transaction, a check is performed to determine if it
 * potentially affects a `<steerlab-subject>`. If that's the case, the
 * transaction is simply cancelled. */
function cancelSubjectEdition(tr: Transaction, state: EditorState) {
  let shouldContinue = true
  tr.steps.forEach(step => {
    step.getMap().forEach((oldStart, oldEnd) => {
      state.doc.nodesBetween(oldStart, oldEnd, node => {
        if (node.type.name === 'steerlabSubject') shouldContinue = false
      })
    })
  })
  return shouldContinue
}

/**  To conform with editor usual standards, pressing `Cmd + s` saves the
 * content of the editor. Even if the content is saved on a regular basis,
 * mapping the command avoid the "Save the page" popup to disturb the user,
 * and potentially force a save for power-users. */
function ModS({ editor }: { editor: Editor }) {
  if (import.meta.env.DEV) console.log('[editor] handleSave')
  editor.emit('save', { editor })
  return true
}

/** Prevent enter behaviour in case cursor is placed in the last position
 * of a block. Due to the behaviour of content editable nodes, when the
 * cursor is at the end of the editable content and a newline is entered,
 * the content will simply jump out of the `<steerlab-block>`.
 *
 * ```html
 * <steerlab-block>
 *   <p>Dummy content for example.</p>
 * </steerlab-block>
 * <p>
 *   Instead of creating a correct new steerlab block,
 *   new content will be appended here.
 * </p>
 * ```
 *
 * To avoid such a problem, the handler prevents the default behaviour,
 * creates a correct `<steerlab-block>` node, and append it at the
 * appropriate location. In case the cursor is anywhere else than the last
 * position of a `<steerlab-block>`, default behaviour is preserved. */
function Enter({ editor }: { editor: Editor }) {
  if (import.meta.env.DEV) console.log('[editor] handleEnter')
  const result = findSteerlabBlockPosition(editor)
  if (!result) return false
  const { block, pos, depth } = result
  const endIndex = pos + block.content.size
  const toIndex = editor.state.selection.to + 1
  const isEnd = endIndex === toIndex
  if (!isEnd) return false
  const id = crypto.randomUUID()
  const after = editor.state.selection.$from.after(depth)
  const content = `<steerlab-block data-id="${id}"><p></p></steerlab-block>`
  editor.commands.insertContentAt(after, content)
  return true
}

/** Prevent backspace behaviour in case cursor is placed in the first position
 * of an answer. Due to the behaviour of content editable nodes, when the
 * cursor is at the beginning of the editable content and backspace is entered,
 * the content will simply jump out of the `<steerlab-answer>` by destroying it.
 *
 * ```html
 * <steerlab-question>
 *   <steerlab-subject>Dummy subject</steerlab-subject>
 *   <steerlab-answer>Dummy answer</steerlab-answer>
 * </steerlab-block>
 * ```
 *
 * To avoid such a problem, the handler prevents the default behaviour, by doing
 * nothing. In case the cursor is anywhere else than the first
 * position of a `<steerlab-answer>`, default behaviour is preserved. */
function Backspace({ editor }: { editor: Editor }) {
  if (import.meta.env.DEV) console.log('[editor] handleBackspace')
  const result = findSteerlabBlockPosition(editor)
  if (!result) return false
  const { block, pos } = result
  const startIndex = pos
  const toIndex = editor.state.selection.to - 1
  const isStart = startIndex === toIndex
  if (!isStart) return false
  if (pos === 1) return true
  if (block.type.name === 'steerlabAnswer') return true
  editor.commands.deleteRange({ from: pos - 2, to: pos })
  return false
}
