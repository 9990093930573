/// <reference types="./block.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $kind from "../../data/docx/kind.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Block extends $CustomType {
  constructor(element_type, id, owner, content, doc_id_order, metadata, proposal_id, qa_order) {
    super();
    this.element_type = element_type;
    this.id = id;
    this.owner = owner;
    this.content = content;
    this.doc_id_order = doc_id_order;
    this.metadata = metadata;
    this.proposal_id = proposal_id;
    this.qa_order = qa_order;
  }
}

export class Metadata extends $CustomType {}

function metadata_decoder() {
  return $decode.success(new Metadata());
}

export function decoder() {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "content",
        $decode.string,
        (content) => {
          return $decode.field(
            "element_type",
            $kind.decoder(),
            (element_type) => {
              return $decode.field(
                "doc_id_order",
                $decode.int,
                (doc_id_order) => {
                  return $decode.field(
                    "metadata",
                    metadata_decoder(),
                    (metadata) => {
                      return $decode.field(
                        "proposal_id",
                        $decode.string,
                        (proposal_id) => {
                          return $decode.field(
                            "owner",
                            $decode.optional($decode.string),
                            (owner) => {
                              return $decode.field(
                                "qa_order",
                                $decode.int,
                                (qa_order) => {
                                  return $decode.success(
                                    new Block(
                                      element_type,
                                      id,
                                      owner,
                                      content,
                                      doc_id_order,
                                      metadata,
                                      proposal_id,
                                      qa_order,
                                    ),
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function encode_metadata(_) {
  return $json.object(toList([]));
}

export function encode(element) {
  return $json.object(
    toList([
      ["element_type", $json.string($kind.to_string(element.element_type))],
      ["id", $json.string(element.id)],
      ["content", $json.string(element.content)],
      ["doc_id_order", $json.int(element.doc_id_order)],
      ["metadata", encode_metadata(element.metadata)],
      ["proposal_id", $json.string(element.proposal_id)],
      ["owner", $json.nullable(element.owner, $json.string)],
      ["qa_order", $json.int(element.qa_order)],
    ]),
  );
}
