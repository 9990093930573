/// <reference types="./feature_flags.d.mts" />
import * as $bool from "../gleam_stdlib/gleam/bool.mjs";
import { authorized as do_authorized } from "./feature-flags.ffi.ts";
import { CustomType as $CustomType } from "./gleam.mjs";

export class Analytics extends $CustomType {}

export class QualificationMatrix extends $CustomType {}

export class Translations extends $CustomType {}

export class Insights extends $CustomType {}

export class WordSupport extends $CustomType {}

export class NarrativeSupport extends $CustomType {}

function to_string(flag) {
  if (flag instanceof Analytics) {
    return "analytics";
  } else if (flag instanceof QualificationMatrix) {
    return "qualification-matrix";
  } else if (flag instanceof Translations) {
    return "translations";
  } else if (flag instanceof Insights) {
    return "insights";
  } else if (flag instanceof WordSupport) {
    return "word-support";
  } else {
    return "narrative-support";
  }
}

export function authorized(org_id, flag) {
  let flag$1 = to_string(flag);
  return do_authorized(flag$1, org_id);
}

export function guard(org_id, flag, default$, next) {
  let is_authorized = authorized(org_id, flag);
  return $bool.guard(!is_authorized, default$, () => { return next(); });
}
