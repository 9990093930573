/// <reference types="./questionnaire.d.mts" />
import * as $bright from "../../../../../bright/bright.mjs";
import * as $data_point from "../../../../../common/data/data_point.mjs";
import * as $data_source from "../../../../../common/data/data_source.mjs";
import * as $project from "../../../../../common/data/project.mjs";
import { Project } from "../../../../../common/data/project.mjs";
import * as $proposal from "../../../../../common/data/proposal.mjs";
import { Proposal } from "../../../../../common/data/proposal.mjs";
import * as $question from "../../../../../common/data/proposal_element/question.mjs";
import * as $translate from "../../../../../common/data/translate.mjs";
import * as $language from "../../../../../common/data/translate/language.mjs";
import * as $user from "../../../../../common/data/user.mjs";
import * as $list_ from "../../../../../common_common/gleam/list/extra.mjs";
import * as $colors from "../../../../../ds/ds/colors.mjs";
import * as $button from "../../../../../ds/ds/components/button.mjs";
import * as $checkbox from "../../../../../ds/ds/components/checkbox.mjs";
import * as $dropdown from "../../../../../ds/ds/components/dropdown.mjs";
import * as $input from "../../../../../ds/ds/components/input.mjs";
import * as $label from "../../../../../ds/ds/components/label.mjs";
import * as $layout from "../../../../../ds/ds/components/layout.mjs";
import * as $pp from "../../../../../ds/ds/components/profile_picture.mjs";
import * as $skeleton from "../../../../../ds/ds/components/skeleton.mjs";
import * as $tooltip from "../../../../../ds/ds/components/tooltip.mjs";
import * as $icons from "../../../../../ds/ds/icons.mjs";
import * as $outline from "../../../../../ds/ds/outline.mjs";
import * as $ds_utils from "../../../../../ds/ds/utils.mjs";
import * as $bool from "../../../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $float from "../../../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../../lustre/lustre/event.mjs";
import * as $mime_types from "../../../../../mime_types/mime_types.mjs";
import * as $css from "../../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../../sketch/sketch/css/length.mjs";
import * as $element from "../../../../../sketch_lustre_experimental/sketch/lustre/experimental/element.mjs";
import * as $model from "../../../../data/model.mjs";
import * as $computed from "../../../../data/model/computed.mjs";
import * as $data from "../../../../data/model/data.mjs";
import { Data } from "../../../../data/model/data.mjs";
import * as $msg from "../../../../data/msg.mjs";
import * as $route from "../../../../data/route.mjs";
import * as $common from "../../../../frontend/view/common.mjs";
import * as $pagination from "../../../../frontend/view/components/pagination.mjs";
import * as $translated_text from "../../../../frontend/view/components/translated_text.mjs";
import * as $translation from "../../../../frontend/view/components/translation.mjs";
import * as $projects_common from "../../../../frontend/view/projects/show/common.mjs";
import * as $states from "../../../../frontend/view/projects/show/states.mjs";
import * as $sc from "../../../../frontend/view/styles/content_library.mjs";
import * as $dropdown_styles from "../../../../frontend/view/styles/dropdowns.mjs";
import * as $s from "../../../../frontend/view/styles/proposals.mjs";
import {
  Ok,
  Error,
  toList,
  prepend as listPrepend,
  CustomType as $CustomType,
  isEqual,
} from "../../../../gleam.mjs";
import * as $utils from "../../../../utils.mjs";
import { stop_propagation_ as on_click } from "../../../../utils.mjs";
import {
  clickUploadAttachment as click_upload_attachment,
  blurTargetWithEscape as blur_target_with_escape,
} from "./questionnaire.ffi.ts";

export class Props extends $CustomType {
  constructor(sheet, page, tab) {
    super();
    this.sheet = sheet;
    this.page = page;
    this.tab = tab;
  }
}

function yes_no_button(model, question, language, content) {
  let data = $bright.data(model);
  let user_id = (() => {
    let _pipe = $data.find_current_user_id(data);
    return $option.from_result(_pipe);
  })();
  let yes_no = $translate.parse_yes_no(
    $option.unwrap(question.answer.yes_no, ""),
  );
  let node = (() => {
    let $ = isEqual(yes_no, new Ok(content));
    if ($) {
      return $button.primary;
    } else {
      return $button.secondary;
    }
  })();
  let disabled = $a.disabled(
    question.ai_processing || (!isEqual(question.owner, user_id)),
  );
  let on_click$1 = $e.on_click(
    new $msg.UserUpdatedProposalQuestionYesNo(question, content),
  );
  let title = $translate.yes_no_answer(content, language);
  return node(toList([disabled, on_click$1]), toList([$h.text(title)]));
}

function multiple_choice_button(model, question, content) {
  let data = $bright.data(model);
  let user_id = (() => {
    let _pipe = $data.find_current_user_id(data);
    return $option.from_result(_pipe);
  })();
  let node = (() => {
    let $ = isEqual(question.answer.choice, new Some(content));
    if ($) {
      return $button.primary;
    } else {
      return $button.secondary;
    }
  })();
  let disabled = $a.disabled(
    question.ai_processing || (!isEqual(question.owner, user_id)),
  );
  let on_click$1 = $e.on_click(
    new $msg.UserUpdatedProposalQuestionChoice(question, content),
  );
  return node(toList([disabled, on_click$1]), toList([$h.text(content)]));
}

function question_attachments(proposal, question) {
  let is_empty = $list.is_empty(question.metadata.attachments);
  return $bool.lazy_guard(
    is_empty,
    $el.none,
    () => {
      return $s.question_attachments(
        toList([]),
        toList([
          $layout.row(
            toList([$layout.gap(8), $layout.wrap("wrap")]),
            toList([]),
            $list.filter_map(
              question.metadata.attachments,
              (attachment) => {
                return $result.map(
                  $mime_types.from_string(attachment.mime_type),
                  (mime) => {
                    let on_delete = $e.on_click(
                      new $msg.UserDeletedAttachment(
                        proposal,
                        question,
                        attachment,
                      ),
                    );
                    return $s.attachment_pill(
                      toList([]),
                      toList([
                        $icons.tiny($ds_utils.select_icon(mime)),
                        $s.attachment_text(
                          toList([]),
                          toList([$h.text(attachment.name)]),
                        ),
                        $s.attachment_close(
                          toList([on_delete]),
                          toList([$icons.tiny($icons.cross())]),
                        ),
                      ]),
                    );
                  },
                );
              },
            ),
          ),
        ]),
      );
    },
  );
}

function ai_rewording_popup(opened, proposal, question, custom_rewording_input) {
  let proposal_id = proposal.id;
  let question_id = question.id;
  return $bool.guard(
    !opened,
    $el.none(),
    () => {
      let on_click_ai_rewrite = (asked) => {
        return $e.on_click(
          new $msg.UserClickedAiRewrite(proposal_id, question_id, asked),
        );
      };
      let on_click_add_question_bank = $e.on_click(
        new $msg.UserClickedAddToQuestionBank(proposal_id, question_id),
      );
      let on_click_remove_question = $e.on_click(
        new $msg.UserDisplayedDeleteQuestionInProposalModal(
          proposal_id,
          question_id,
        ),
      );
      let on_click_add_attachment = $e.on(
        "click",
        (_) => {
          click_upload_attachment();
          return new Error(toList([]));
        },
      );
      return $s.ai_rewording_popup(
        toList([$ds_utils.stop_propagation()]),
        toList([
          $s.add_attachment_input(
            toList([
              $a.id("projects-show-questionnaire-upload"),
              $a.type_("file"),
              $utils.on_files_change(
                (files) => {
                  return $bool.guard(
                    $list.is_empty(files),
                    new Error(undefined),
                    () => {
                      return new Ok(
                        new $msg.UserChoseAttachment(proposal, question, files),
                      );
                    },
                  );
                },
              ),
            ]),
          ),
          $s.ai_rewording_title(toList([]), toList([$h.text("Actions")])),
          $s.ai_rewording_option(
            toList([on_click_add_attachment]),
            toList([
              $s.details_collaborators_icon($icons.upload()),
              $h.text("Add attachment"),
            ]),
          ),
          $s.ai_rewording_option(
            toList([on_click_add_question_bank]),
            toList([
              $s.details_collaborators_icon($icons.add()),
              $h.text("Add to question bank"),
            ]),
          ),
          $s.ai_rewording_option(
            toList([on_click_remove_question]),
            toList([
              $s.details_collaborators_icon($outline.trash()),
              $h.text("Remove question"),
            ]),
          ),
          $s.ai_rewording_title(toList([]), toList([$h.text("AI Edits")])),
          $s.ai_rewording_option(
            toList([on_click_ai_rewrite("shorter")]),
            toList([
              $s.details_collaborators_icon($icons.shorter()),
              $h.text("Make shorter"),
            ]),
          ),
          $s.ai_rewording_option(
            toList([on_click_ai_rewrite("longer")]),
            toList([
              $s.details_collaborators_icon($icons.longer()),
              $h.text("Make longer"),
            ]),
          ),
          $s.ai_rewording_option(
            toList([on_click_ai_rewrite("proofread")]),
            toList([
              $s.details_collaborators_icon($icons.file_edit()),
              $h.text("Proofread"),
            ]),
          ),
          $s.ai_text_input(
            toList([]),
            toList([
              $h.form(
                toList([
                  $e.on_submit(
                    new $msg.UserSubmittedCustomRewording(
                      proposal_id,
                      question_id,
                    ),
                  ),
                ]),
                toList([
                  $input.input(
                    toList([
                      $input.placeholder("Custom rewording"),
                      $input.on_input(
                        (var0) => {
                          return new $msg.UserEdittedCustomRewording(var0);
                        },
                      ),
                      $input.value(custom_rewording_input),
                    ]),
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      );
    },
  );
}

function proposal_filters(model, project, proposal) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $bool.guard(
    !data.filter_proposal_opened,
    $el.none(),
    () => {
      let proposal_collaborators = (() => {
        let _pipe = project.collaborators;
        let _pipe$1 = $list.append(_pipe, proposal.collaborators);
        let _pipe$2 = $list.prepend(_pipe$1, proposal.owner);
        return $list.unique(_pipe$2);
      })();
      let white_choice = $css.class$(
        toList([
          $css.border("1px solid " + $colors.light.grey_200),
          $css.border_radius($length.px(10)),
          $css.hover(
            toList([$css.border("1px solid " + $colors.light.grey_400)]),
          ),
        ]),
      );
      let other_choices = $css.class$(
        toList([$css.padding_("10px 12px"), $css.border_radius($length.px(10))]),
      );
      return $layout.row(
        toList([$layout.gap(8)]),
        toList([]),
        toList([
          $dropdown.dropdown(
            toList([
              $dropdown.placeholder("No status selected"),
              $dropdown.selected(new Some(data.proposal_filters.status)),
              $dropdown.on_selected(
                (var0) => {
                  return new $msg.UserUpdatedProposalFiltersStatus(var0);
                },
              ),
              $dropdown.panel_class($dropdown_styles.spacing()),
              $dropdown.show_arrow(),
              $dropdown.input_class(
                $css.class$(
                  toList([
                    $css.compose(
                      $css.class$(
                        toList([
                          (() => {
                            let $1 = data.proposal_filters.status;
                            if ($1 instanceof $proposal.InReview) {
                              return $css.compose($dropdown_styles.current());
                            } else if ($1 instanceof $proposal.Validated) {
                              return $css.compose($dropdown_styles.success());
                            } else {
                              return $css.none();
                            }
                          })(),
                          $css.compose(other_choices),
                          $css.min_width($length.px(125)),
                        ]),
                      ),
                    ),
                  ]),
                ),
              ),
              $dropdown.disabled($list.is_empty(proposal_collaborators)),
            ]),
            toList([
              (() => {
                let _pipe = $dropdown.choice(
                  "All status",
                  new $proposal.AllStatus(),
                );
                return $dropdown.with_class(_pipe, white_choice);
              })(),
              (() => {
                let _pipe = $dropdown.choice(
                  "In review",
                  new $proposal.InReview(),
                );
                return $dropdown.with_class(
                  _pipe,
                  $css.class$(
                    toList([
                      $css.compose($dropdown_styles.current()),
                      $css.compose(other_choices),
                    ]),
                  ),
                );
              })(),
              (() => {
                let _pipe = $dropdown.choice(
                  "Validated",
                  new $proposal.Validated(),
                );
                return $dropdown.with_class(
                  _pipe,
                  $css.class$(
                    toList([
                      $css.compose($dropdown_styles.success()),
                      $css.compose(other_choices),
                    ]),
                  ),
                );
              })(),
            ]),
          ),
          $dropdown.dropdown(
            toList([
              $dropdown.placeholder("No collaborator selected"),
              $dropdown.selected(new Some(data.proposal_filters.owner)),
              $dropdown.on_selected(
                (var0) => {
                  return new $msg.UserUpdatedProposalFiltersOwner(var0);
                },
              ),
              $dropdown.panel_class($dropdown_styles.spacing()),
              $dropdown.show_arrow(),
              $dropdown.input_class(
                $css.class$(toList([$css.min_width($length.px(200))])),
              ),
            ]),
            listPrepend(
              (() => {
                let _pipe = $dropdown.choice("All collaborators", new None());
                return $dropdown.with_class(_pipe, white_choice);
              })(),
              $list.filter_map(
                data.organization.users,
                (user) => {
                  let user_in_proposal = $list.contains(
                    proposal_collaborators,
                    user.id,
                  );
                  return $bool.guard(
                    !user_in_proposal,
                    new Error(undefined),
                    () => {
                      let id = user.id;
                      let name = user.name;
                      let picture = user.picture;
                      let initials = user.initials;
                      let icon = $pp.from_user(
                        picture,
                        initials,
                        new $pp.Medium(),
                      );
                      let _pipe = $dropdown.choice(name, new Some(id));
                      let _pipe$1 = $dropdown.with_icon(_pipe, icon);
                      let _pipe$2 = $dropdown.with_class(_pipe$1, white_choice);
                      return new Ok(_pipe$2);
                    },
                  );
                },
              ),
            ),
          ),
        ]),
      );
    },
  );
}

function can_contribute(role) {
  return (role.name === "Contributor") || (role.name === "Proposal Manager");
}

function collaborator_button(model, project, proposal, question) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let disabled = !$list.contains(data.permissions, "rfp:users:add") || question.assign_processing;
  let skeleton = () => { return $skeleton.skeleton(150, 35); };
  return $bool.lazy_guard(
    question.assign_processing,
    skeleton,
    () => {
      return $el.fragment(
        toList([
          $dropdown.dropdown(
            toList([
              $dropdown.placeholder("Add collaborator"),
              $dropdown.input_class($s.details_collaborators_class()),
              $dropdown.panel_right(),
              $dropdown.disabled(disabled),
              $dropdown.selected(question.owner),
              $dropdown.on_selected(
                ((_capture) => {
                  return new $msg.UserSelectedElementOwner(
                    question.id,
                    question.proposal_id,
                    _capture,
                  );
                }),
              ),
            ]),
            listPrepend(
              $dropdown.section("Select collaborator"),
              $list.filter_map(
                data.organization.users,
                (user) => {
                  let is_contributable = $list.any(user.roles, can_contribute);
                  let is_proposal_member = ($list.contains(
                    proposal.collaborators,
                    user.id,
                  ) || $list.contains(project.collaborators, user.id)) || (proposal.owner === user.id);
                  let is_selectable = is_contributable && is_proposal_member;
                  return $bool.guard(
                    !is_selectable,
                    new Error(undefined),
                    () => {
                      let id = user.id;
                      let name = user.name;
                      let picture = user.picture;
                      let initials = user.initials;
                      let icon = $pp.from_user(
                        picture,
                        initials,
                        new $pp.Medium(),
                      );
                      return new Ok(
                        (() => {
                          let _pipe = $dropdown.choice(name, id);
                          return $dropdown.with_icon(_pipe, icon);
                        })(),
                      );
                    },
                  );
                },
              ),
            ),
          ),
        ]),
      );
    },
  );
}

function view_question_status(question) {
  let $ = (() => {
    let $1 = question.validated;
    if ($1) {
      return [$label.success(), "Validated"];
    } else {
      return [$label.current(), "In review"];
    }
  })();
  let status = $[0];
  let content = $[1];
  return $s.question_status(
    toList([]),
    toList([$label.label(toList([status, $label.content(content)]))]),
  );
}

function question_answer_or_loader(project, question, answer, next) {
  let $ = question.ai_processing;
  let $1 = project.metadata.languages;
  if ($) {
    return $skeleton.full_width(60);
  } else if (!$ && $1.hasLength(0)) {
    return next($question.get_answer_text(question.answer));
  } else if (!$ && $1.hasLength(1)) {
    return next($question.get_answer_text(question.answer));
  } else {
    if (answer.isOk()) {
      let answer$1 = answer[0];
      return next(answer$1);
    } else {
      return $skeleton.full_width(60);
    }
  }
}

function is_question_editable(is_hint, is_owner) {
  return $a.attribute(
    "contenteditable",
    (() => {
      let $ = is_hint || !is_owner;
      if ($) {
        return "false";
      } else {
        return "true";
      }
    })(),
  );
}

function on_question_blur(question, language) {
  return $e.on(
    "blur",
    (event) => {
      let _pipe = $decode.run(
        event,
        $decode.at(toList(["target", "innerText"]), $decode.string),
      );
      let _pipe$1 = $result.replace_error(_pipe, toList([]));
      return $result.map(
        _pipe$1,
        (content) => {
          return new $msg.UserUpdatedProposalQuestion(
            question,
            content,
            language,
          );
        },
      );
    },
  );
}

function on_question_escape() {
  return $e.on(
    "keyup",
    (event) => {
      blur_target_with_escape(event);
      return new Error(toList([]));
    },
  );
}

function get_language(language, project) {
  let languages = project.metadata.languages;
  let first = $list.first(languages);
  let is_contained = $list.contains(languages, language);
  if (!is_contained && first.isOk()) {
    let language$1 = first[0];
    return language$1;
  } else {
    return language;
  }
}

function view_question_answer(model, question, project) {
  let data = $bright.data(model);
  let user_id = $data.find_current_user_id(data);
  let language = get_language(data.language, project);
  let is_hint = $question.is_hint(question);
  let is_owner = isEqual($option.to_result(question.owner, undefined), user_id);
  let editable = is_question_editable(is_hint, is_owner);
  let on_blur = on_question_blur(question, language);
  let on_escape = on_question_escape();
  let answer = $translated_text.translated_answer(question, data, language);
  return question_answer_or_loader(
    project,
    question,
    answer,
    (answer) => {
      return $s.question_response(
        is_hint,
        toList([editable, on_blur, on_escape]),
        answer,
      );
    },
  );
}

function view_question_card(
  model,
  current_user,
  project,
  proposal,
  question,
  index,
  total,
  data_sources,
  data_points
) {
  let data = $bright.data(model);
  let style = $a.style(toList([["z-index", $int.to_string(9000 - index)]]));
  let index$1 = $int.to_string(index + 1);
  let total$1 = $int.to_string(total);
  let points = data_points;
  let actions_disabled = (() => {
    let _pipe = question.validated;
    let _pipe$1 = $bool.or(
      _pipe,
      !isEqual(current_user, $option.to_result(question.owner, undefined)),
    );
    let _pipe$2 = $bool.or(_pipe$1, question.ai_processing);
    return $a.disabled(_pipe$2);
  })();
  let more_proposal_opened = isEqual(
    data.more_proposal_opened,
    new Some(question.id)
  );
  let on_validate = $e.on_click(new $msg.UserValidatedQuestion(question));
  let on_toggle_more = $e.on_click(
    new $msg.UserToggledQuestionMoreButton(question.id),
  );
  let question_content = (() => {
    let _pipe = get_language(data.language, project);
    return ((_capture) => {
      return $translated_text.translated_question(question, data, _capture);
    })(_pipe);
  })();
  return $s.question_card(
    toList([$a.id("question-" + question.id), style]),
    toList([
      $s.question_card_title(
        toList([]),
        toList([
          $checkbox.checkbox(
            $checkbox.checked(
              $data.proposal_question_is_selected(data, question.id),
            ),
            (_capture) => {
              return new $msg.UserSelectedQuestion(question.id, _capture);
            },
          ),
          $s.question_number(
            toList([]),
            toList([$h.text((("Question " + index$1) + "/") + total$1)]),
          ),
          view_question_status(question),
          $s.question_question(
            toList([
              $a.attribute(
                "dangerous-unescaped-html",
                (() => {
                  let _pipe = question_content;
                  return $utils.escape_html(_pipe);
                })(),
              ),
            ]),
            toList([]),
          ),
          $s.question_owner(
            toList([]),
            toList([collaborator_button(model, project, proposal, question)]),
          ),
        ]),
      ),
      $layout.column(
        toList([$layout.gap(10)]),
        toList([$a.style(toList([["grid-column", "1"], ["grid-row", "2"]]))]),
        toList([
          (() => {
            let $ = $list.contains(question.kind, new $question.Long());
            if (!$) {
              return $el.none();
            } else {
              return view_question_answer(model, question, project);
            }
          })(),
          (() => {
            let $ = $list.contains(question.kind, new $question.YesNo());
            if (!$) {
              return $el.none();
            } else {
              let l = get_language(data.language, project);
              return $layout.column(
                toList([$layout.gap(10), $layout.align("start")]),
                toList([]),
                toList([
                  $layout.row(
                    toList([$layout.gap(10)]),
                    toList([]),
                    toList([
                      yes_no_button(model, question, l, new $translate.Yes()),
                      yes_no_button(model, question, l, new $translate.No()),
                      yes_no_button(model, question, l, new $translate.NA()),
                    ]),
                  ),
                ]),
              );
            }
          })(),
          (() => {
            let $ = $list.contains(
              question.kind,
              new $question.MultipleChoice(),
            );
            if (!$) {
              return $el.none();
            } else {
              return $layout.column(
                toList([$layout.gap(10), $layout.align("start")]),
                toList([]),
                toList([
                  $layout.row(
                    toList([
                      $layout.gap(10),
                      $layout.wrap("wrap"),
                      $layout.justify("start"),
                    ]),
                    toList([]),
                    $list.map(
                      question.choices,
                      (choice) => {
                        return multiple_choice_button(model, question, choice);
                      },
                    ),
                  ),
                ]),
              );
            }
          })(),
        ]),
      ),
      $s.question_actions(
        toList([]),
        toList([
          $s.button_icon(
            false,
            toList([on_validate, actions_disabled]),
            toList([$s.large_icon($icons.checkmark())]),
          ),
          $s.button_icon(
            more_proposal_opened,
            toList([on_toggle_more, actions_disabled]),
            toList([$s.large_icon($icons.more())]),
          ),
          ai_rewording_popup(
            more_proposal_opened,
            proposal,
            question,
            data.custom_rewording_input,
          ),
        ]),
      ),
      question_attachments(proposal, question),
      $s.question_sources(
        toList([]),
        toList([
          $layout.row(
            toList([$layout.align("center"), $layout.gap(8)]),
            toList([]),
            toList([
              $s.question_sources_title(
                toList([]),
                toList([$h.text("Sources")]),
              ),
              (() => {
                let $ = $list.is_empty(points);
                if ($) {
                  return $h.text("∅");
                } else {
                  return $el.fragment(
                    $list.index_map(
                      points,
                      (point, index) => {
                        let _pipe = $list.find(
                          data_sources,
                          (s) => { return s.id === point.source_id; },
                        );
                        let _pipe$1 = $result.try_recover(
                          _pipe,
                          (_) => { return new Error($el.none()); },
                        );
                        let _pipe$2 = $result.map(
                          _pipe$1,
                          (source) => {
                            let index$1 = $h.text(
                              ("[" + $int.to_string(index + 1)) + "]",
                            );
                            return $tooltip.tooltip(
                              toList([]),
                              $s.question_source_index(
                                toList([]),
                                toList([index$1]),
                              ),
                              (() => {
                                let icon = $ds_utils.select_icon(
                                  source.display.mime_type,
                                );
                                return $s.question_source_tooltip(
                                  toList([]),
                                  toList([
                                    $layout.row(
                                      toList([
                                        $layout.gap(12),
                                        $layout.align("center"),
                                      ]),
                                      toList([]),
                                      toList([
                                        $common.small_icon(icon),
                                        $s.question_source_tooltip_title(
                                          toList([]),
                                          toList([
                                            (() => {
                                              let $1 = source.display.mime_type;
                                              if ($1 instanceof $mime_types.Qna) {
                                                return $h.text("Question bank");
                                              } else {
                                                return $h.text(
                                                  source.display.name,
                                                );
                                              }
                                            })(),
                                          ]),
                                        ),
                                      ]),
                                    ),
                                    $h.div(
                                      toList([
                                        $a.attribute(
                                          "dangerous-unescaped-html",
                                          $translated_text.translated_data_point(
                                            point,
                                            data,
                                            get_language(data.language, project),
                                          ),
                                        ),
                                      ]),
                                      toList([]),
                                    ),
                                  ]),
                                );
                              })(),
                            );
                          },
                        );
                        return $result.unwrap_both(_pipe$2);
                      },
                    ),
                  );
                }
              })(),
            ]),
          ),
          (() => {
            let $ = question.display.score;
            if ($ instanceof None) {
              return $el.none();
            } else {
              let score = $[0];
              return $s.confidence_score(
                toList([]),
                toList([
                  $h.text("Confidence Score"),
                  $s.confidence_score_highlight(
                    score,
                    toList([]),
                    (() => {
                      let value = $int.to_string($float.round(score * 100.0));
                      let value$1 = $string.slice(value, 0, 5);
                      return toList([$h.text(value$1 + " %")]);
                    })(),
                  ),
                ]),
              );
            }
          })(),
        ]),
      ),
    ]),
  );
}

function view_questions_list(
  model,
  project,
  proposal,
  questions,
  current_user,
  active_sheet,
  page
) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let computed = $[1];
  let page_size = data.proposal_block_page_size;
  let total_sheet = computed.sheet_questions_count;
  let total = computed.displayed_questions_count;
  let questions$1 = (() => {
    let _pipe = questions;
    let _pipe$1 = $list.filter(
      _pipe,
      (q) => { return isEqual(q[1].sheet, active_sheet); },
    );
    return $list.sort(
      _pipe$1,
      (a, b) => { return $int.compare(a[1].qa_order, b[1].qa_order); },
    );
  })();
  let start = page * page_size;
  let splitted_questions = (() => {
    let _pipe = questions$1;
    return $list_.slice(_pipe, start, start + page_size);
  })();
  return $el.fragment(
    toList([
      $s.question_questions(
        toList([]),
        toList([
          $s.question_list(
            toList([]),
            toList([
              $el.fragment(
                $list.map(
                  splitted_questions,
                  (question) => {
                    let data_sources = (() => {
                      let _pipe = data.elements.data_sources;
                      let _pipe$1 = $dict.get(_pipe, question[1].id);
                      return $result.unwrap(_pipe$1, toList([]));
                    })();
                    let tmp_data_points = (() => {
                      let _pipe = data.elements.data_points;
                      let _pipe$1 = $dict.get(_pipe, question[1].id);
                      return $result.unwrap(_pipe$1, toList([]));
                    })();
                    let list_of_elements = question[1].data_points_id;
                    let data_points = $list.filter_map(
                      list_of_elements,
                      (element) => {
                        return $list.find(
                          tmp_data_points,
                          (dp) => { return dp.id === element[0]; },
                        );
                      },
                    );
                    return view_question_card(
                      model,
                      current_user,
                      project,
                      proposal,
                      question[1],
                      question[1].qa_order,
                      total_sheet,
                      data_sources,
                      data_points,
                    );
                  },
                ),
              ),
            ]),
          ),
        ]),
      ),
      $pagination.page_selector(
        page,
        $model.compute_page_max(total, page_size),
        (var0) => { return new $msg.UserSelectedProposalPage(var0); },
        true,
      ),
    ]),
  );
}

function change_owner_popup(users, proposal_id) {
  return $sc.change_owner_popup(
    toList([]),
    listPrepend(
      $sc.change_popup_title(toList([]), toList([$el.text("Select one owner")])),
      $list.map(
        users,
        (user) => {
          let name = user.name;
          let picture = user.picture;
          let initials = user.initials;
          return $sc.change_popup_owner_select(
            toList([
              $e.on_click(
                new $msg.UserToggledAssignUserPopupInProposal(
                  user.id,
                  proposal_id,
                ),
              ),
            ]),
            toList([
              $layout.row(
                toList([$layout.gap(8), $layout.align("center")]),
                toList([]),
                toList([
                  $pp.from_user(picture, initials, new $pp.Medium()),
                  $el.text(name),
                ]),
              ),
            ]),
          );
        },
      ),
    ),
  );
}

function change_owner_button(data, proposal_id, users) {
  return $sc.change_button(
    toList([on_click(new $msg.UserToggledChangeOwnerPopupInProposal())]),
    toList([
      $sc.change_icon(false, $icons.user()),
      $h.text("Change owner"),
      $sc.change_icon(
        !data.proposal_toggles.change_owner_popup_opened,
        $icons.arrow_dropdown(),
      ),
      (() => {
        let $ = data.proposal_toggles.change_owner_popup_opened;
        if ($) {
          return change_owner_popup(users, proposal_id);
        } else {
          return $el.none();
        }
      })(),
    ]),
  );
}

function status_label(label, effect, content) {
  return $label.label(
    toList([label, $label.on_click(effect), $label.content(content)]),
  );
}

function change_status_button(data, proposal_id) {
  return $sc.change_button(
    toList([on_click(new $msg.UserToggledChangeStatusPopupInProposal())]),
    toList([
      $sc.change_icon(false, $icons.checkmark()),
      $h.text("Change status"),
      $sc.change_icon(
        !data.proposal_toggles.change_status_popup_opened,
        $icons.arrow_dropdown(),
      ),
      (() => {
        let $ = data.proposal_toggles.change_status_popup_opened;
        if ($) {
          return $sc.change_status_popup(
            toList([]),
            toList([
              status_label(
                $label.success(),
                new $msg.UserAssignedStatusInProposal(true, proposal_id),
                "Validated",
              ),
              status_label(
                $label.current(),
                new $msg.UserAssignedStatusInProposal(false, proposal_id),
                "In review",
              ),
            ]),
          );
        } else {
          return $el.none();
        }
      })(),
    ]),
  );
}

function questions_by_sheet(computed) {
  let _pipe = $list.group(
    computed.displayed_questions,
    (q) => { return q[1].sheet; },
  );
  return $dict.map_values(_pipe, (_, value) => { return $list.reverse(value); });
}

function find_active_sheet(proposal, props, by_sheet) {
  let first_non_empty_sheet = (() => {
    let $ = proposal.metadata;
    if ($ instanceof $proposal.Document) {
      return new Ok(new None());
    } else if ($ instanceof $proposal.Narrative) {
      return new Ok(new None());
    } else if ($ instanceof $proposal.Portal) {
      return new Ok(new None());
    } else {
      let worksheets = $.worksheets;
      return $list.find_map(
        worksheets,
        (worksheet) => {
          let is_empty = (() => {
            let _pipe = $dict.get(by_sheet, new Some(worksheet.name));
            let _pipe$1 = $result.unwrap(_pipe, toList([]));
            return $list.is_empty(_pipe$1);
          })();
          return $bool.guard(
            is_empty,
            new Error(""),
            () => { return new Ok(new Some(worksheet.name)); },
          );
        },
      );
    }
  })();
  let $ = proposal.metadata;
  if ($ instanceof $proposal.Document) {
    return new None();
  } else if ($ instanceof $proposal.Narrative) {
    return new None();
  } else if ($ instanceof $proposal.Portal) {
    return new None();
  } else {
    let sheets = $.sheets;
    let _pipe = $list.find(
      sheets,
      (s) => { return isEqual(new Some(s), props.sheet); },
    );
    let _pipe$1 = $result.map(
      _pipe,
      (var0) => { return new $option.Some(var0); },
    );
    let _pipe$2 = $result.try_recover(
      _pipe$1,
      (_) => { return first_non_empty_sheet; },
    );
    let _pipe$3 = $option.from_result(_pipe$2);
    return $option.flatten(_pipe$3);
  }
}

function view_questions(model, project, proposal, props) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let computed = $[1];
  let current_user = $data.find_current_user_id(data);
  let by_sheet = questions_by_sheet(computed);
  let active_sheet = find_active_sheet(proposal, props, by_sheet);
  return $s.questions_wrapper(
    toList([]),
    toList([
      (() => {
        let $1 = $dict.get(by_sheet, active_sheet);
        if (!$1.isOk()) {
          return $states.no_questions_placeholder();
        } else {
          let questions = $1[0];
          return view_questions_list(
            model,
            project,
            proposal,
            questions,
            current_user,
            active_sheet,
            props.page,
          );
        }
      })(),
      (() => {
        let $1 = proposal.metadata;
        if ($1 instanceof $proposal.Document) {
          return $el.none();
        } else if ($1 instanceof $proposal.Narrative) {
          return $el.none();
        } else if ($1 instanceof $proposal.Portal) {
          return $el.none();
        } else {
          let sheets = $1.sheets;
          return $s.question_spreadsheet_sheets(
            toList([]),
            $list.map(
              sheets,
              (sheet) => {
                let s = new Some(sheet);
                let on_click$1 = $e.on_click(
                  new $msg.UserClickedProposal(proposal, s),
                );
                let $2 = isEqual(s, active_sheet);
                if ($2) {
                  return $s.active_spreadsheet_sheet(
                    toList([on_click$1]),
                    toList([$el.text(sheet)]),
                  );
                } else {
                  return $s.spreadsheet_sheet(
                    toList([on_click$1]),
                    toList([$el.text(sheet)]),
                  );
                }
              },
            ),
          );
        }
      })(),
    ]),
  );
}

function proposal_menu(model, project, proposal, props) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let computed = $[1];
  let proposal_id = proposal.id;
  let users = (() => {
    let _pipe = data.organization.users;
    return $list.filter(
      _pipe,
      (u) => {
        return $list.contains(
          listPrepend(project.owner, project.collaborators),
          u.id,
        );
      },
    );
  })();
  let selected = data.proposal_selected_questions;
  let size = $dict.size(selected);
  let hidden = size === 0;
  let by_sheet = questions_by_sheet(computed);
  let active_sheet = find_active_sheet(proposal, props, by_sheet);
  let all_selected = $computed.all_questions_selected(
    data,
    computed,
    props.page,
  );
  let check_state = (() => {
    if (all_selected) {
      return new $checkbox.Checked();
    } else if (!hidden) {
      return new $checkbox.Indeterminate();
    } else {
      return new $checkbox.Unchecked();
    }
  })();
  return $sc.menu_wrapper(
    hidden,
    toList([]),
    toList([
      $sc.menu(
        toList([]),
        toList([
          $layout.row(
            toList([$layout.gap(16), $layout.align("center")]),
            toList([]),
            toList([
              $checkbox.checkbox(
                check_state,
                (_) => {
                  return new $msg.UserClickedGlobalProposalCheckbox(
                    all_selected,
                    $computed.filtered_questions_in_page(
                      data,
                      computed,
                      props.page,
                      active_sheet,
                    ),
                  );
                },
              ),
              $sc.number_selected(
                toList([]),
                toList([$h.text($int.to_string(size) + " selected")]),
              ),
              change_owner_button(data, proposal_id, users),
              change_status_button(data, proposal_id),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function view(model, project, proposal, props) {
  let $ = $bright.data(model);
  let filter_proposal_opened = $.filter_proposal_opened;
  let language = $.language;
  let center = $layout.align("center");
  let space_between = $layout.justify("space-between");
  let on_filters_click = $e.on_click(new $msg.UserToggledFilterProposal());
  return $el.fragment(
    toList([
      $projects_common.header(project, proposal),
      $projects_common.tab_actions_bar(model, project, proposal),
      (() => {
        let _pipe = $layout.row(
          toList([$layout.gap(8), center, space_between]),
          toList([]),
          toList([
            $layout.row(
              toList([$layout.gap(32), center, space_between]),
              toList([]),
              toList([
                $s.filters_button(
                  filter_proposal_opened,
                  toList([on_filters_click]),
                  toList([$button.icon($icons.filters()), $h.text("Filters")]),
                ),
                proposal_filters(model, project, proposal),
              ]),
            ),
            $translation.selector(
              project.metadata.languages,
              get_language(language, project),
              project.metadata.default_language,
              (var0) => { return new $msg.UserSelectedProposalLanguage(var0); },
            ),
          ]),
        );
        return $element.map(
          _pipe,
          (var0) => { return new $msg.Proposal(var0); },
        );
      })(),
      (() => {
        let _pipe = view_questions(model, project, proposal, props);
        return $element.map(
          _pipe,
          (var0) => { return new $msg.Proposal(var0); },
        );
      })(),
      (() => {
        let _pipe = proposal_menu(model, project, proposal, props);
        return $element.map(
          _pipe,
          (var0) => { return new $msg.Proposal(var0); },
        );
      })(),
    ]),
  );
}
