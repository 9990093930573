/// <reference types="./content_library.d.mts" />
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $shadows from "../../../../ds/ds/shadows.mjs";
import * as $display from "../../../../ds/ds/typography/display.mjs";
import * as $text from "../../../../ds/ds/typography/text.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $angle from "../../../../sketch/sketch/css/angle.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { percent, px, vw } from "../../../../sketch/sketch/css/length.mjs";
import * as $transform from "../../../../sketch/sketch/css/transform.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../../gleam.mjs";

export function filter_card(settings, attrs, children) {
  let color = settings[0];
  let background = settings[1];
  let border = settings[2];
  let active = settings[3];
  let border_color = (() => {
    if (!active) {
      return "transparent";
    } else {
      return border;
    }
  })();
  let _pipe = $css.class$(
    toList([
      $css.color(color),
      $css.background(background),
      $css.border("1px solid " + border_color),
      $css.padding(px(34)),
      $css.flex("1"),
      $css.text_align("center"),
      $css.border_radius(px(8)),
      $css.cursor("pointer"),
      $css.hover(toList([$css.border("1px solid " + border)])),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function filter_card_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.md())]));
  return $h.div(_pipe, attrs, children);
}

export function filter_card_subtitle(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($text.md($text.medium))]));
  return $h.div(_pipe, attrs, children);
}

export function empty_cell(child) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.padding_("30px 0"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

export function empty_text_content(child) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.max_width(px(230)),
      $css.white_space("wrap"),
      $css.text_align("center"),
      $css.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

export function menu_wrapper(hidden, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.position("fixed"),
      $css.display("flex"),
      $css.justify_content("center"),
      $css.width(vw(100)),
      $css.transition("bottom .3s"),
      $css.left(px(0)),
      $css.z_index(90_020),
      $css.bottom(
        (() => {
          if (hidden) {
            return px(-200);
          } else {
            return px(40);
          }
        })(),
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function menu(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.color($colors.light.onyx),
      $css.display("flex"),
      $css.gap(px(32)),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius(px(8)),
      $css.background($colors.light.white),
      $css.padding_("14px 16px"),
      $css.box_shadow($shadows.m()),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function number_selected(attrs, children) {
  return $h.div_(attrs, children);
}

export function change_button(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.gap(px(8)),
      $css.align_items("center"),
      $css.cursor("pointer"),
      $css.hover(toList([$css.background($colors.light.grey_50)])),
      $css.padding(px(6)),
      $css.border_radius(px(4)),
      $css.position("relative"),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function change_status(attrs, children) {
  return $h.div_(attrs, children);
}

export function change_icon(reversed, icon) {
  let _pipe = $css.class$(
    toList([
      $css.width(px(20)),
      $css.height(px(20)),
      $css.transition("transform .3s"),
      $css.transform(
        (() => {
          if (reversed) {
            return toList([$transform.rotate($angle.deg(180.0))]);
          } else {
            return toList([$transform.rotate($angle.deg(0.0))]);
          }
        })(),
      ),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([icon]));
}

export function change_popup() {
  return $css.class$(
    toList([
      $css.position("absolute"),
      $css.bottom(px(55)),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius(px(8)),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.background($colors.light.white),
      $css.cursor("default"),
      $css.overflow("auto"),
      $css.max_height(px(500)),
      $css.box_shadow($shadows.m()),
    ]),
  );
}

export function change_owner_popup(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(change_popup()),
      $css.min_width(px(250)),
      $css.right($length.percent(-50)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function change_status_popup(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(change_popup()),
      $css.right(px(0)),
      $css.gap(px(10)),
      $css.padding(px(10)),
      $css.width(percent(100)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function change_tags_popup(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(change_popup()),
      $css.right(px(0)),
      $css.gap(px(10)),
      $css.padding(px(10)),
      $css.min_width(px(250)),
      $css.right($length.percent(0)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function change_expiration_date_popup(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(change_popup()),
      $css.right(px(0)),
      $css.gap(px(10)),
      $css.padding(px(10)),
      $css.min_width(px(300)),
      $css.right($length.percent(0)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function change_popup_title(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.color($colors.light.grey_800),
      $css.padding(px(10)),
      $css.position("sticky"),
      $css.top(px(0)),
      $css.background($colors.light.white),
      $css.border_bottom("1px solid " + $colors.light.grey_400),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function change_popup_owner_select(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("space-between"),
      $css.gap(px(8)),
      $css.padding(px(10)),
      $css.cursor("pointer"),
      $css.hover(toList([$css.background($colors.light.grey_100)])),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function new_question_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(24)),
      $css.max_width(px(500)),
      $css.width(percent(100)),
      $css.margin_("0 auto"),
      $css.padding_top(px(48)),
    ]),
  );
  return $h.form(_pipe, attrs, children);
}

export function question_title(attrs, children) {
  return $h.div(
    $css.class$(toList([$css.compose($display.xs())])),
    attrs,
    children,
  );
}
