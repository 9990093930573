import { BitArray } from '../../gleam.mjs'
import * as $gleam from '../../gleam.mjs'

export function new_(fileBits, fileName) {
  return new File([fileBits.buffer], fileName)
}

export async function bytes(file) {
  return new BitArray(new Uint8Array(await file.arrayBuffer()))
}

export function text(file) {
  return file.text()
}

export function name(file) {
  return file.name
}

export function mime(file) {
  return file.type
}

export function size(file) {
  return file.size
}

export function createObjectURL(file) {
  return URL.createObjectURL(file)
}

export function readFromEvent(event) {
  if (!event.target.files) return new $gleam.Error()
  const files = [...event.target.files]
  return new $gleam.Ok($gleam.List.fromArray(files))
}

export async function download(name, body) {
  return new Promise(resolve => {
    const blob = new Blob([body.buffer])
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    resolve()
  })
}
