import * as config from '#frontend/config'
import { main } from './frontend.gleam'

// Setup the environment.
config.elements.register()
config.vercel.setup()
Promise.all([
  config.serviceWorker.setup(),
  new Promise(r => document.addEventListener('DOMContentLoaded', r)),
]).then(() => {
  // Start the Lustre app.
  main()
})
