import { Node } from '@tiptap/core'
import * as h from '#editor/html'
import * as a from '#editor/html/attribute'

export const AnswerBlock = Node.create({
  name: 'steerlabAnswer',
  group: 'block',
  content: 'block*',
  addAttributes() {
    return {
      id: {
        parseHTML: element => element.getAttribute('data-id'),
        renderHTML: attributes => ({ ['data-id']: attributes.id }),
      },
    }
  },
  parseHTML() {
    return [{ tag: 'steerlab-answer' }]
  },
  renderHTML({ HTMLAttributes }) {
    return ['steerlab-answer', HTMLAttributes, 0]
  },
  addNodeView() {
    return props => {
      const contentDOM = h.div([], [])
      const dom = h.element(
        'steerlab-answer',
        [a.attribute('data-id', props.node.attrs.id)],
        [contentDOM]
      )
      return { dom, contentDOM }
    }
  },
})
