/// <reference types="./insights.d.mts" />
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $display from "../../../../ds/ds/typography/display.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../../gleam.mjs";

export function source_card(attrs, children) {
  let _pipe = $css.class$(toList([$css.width(percent(100))]));
  return $h.div(_pipe, attrs, children);
}

export function duplicate_or_conflict_card(attrs, children, active) {
  let border_color = (() => {
    if (!active) {
      return "transparent";
    } else {
      return $colors.light.success_400;
    }
  })();
  let _pipe = $css.class$(
    toList([
      $css.width(percent(100)),
      $css.display("flex"),
      $css.flex_direction("row"),
      $css.align_content("center"),
      $css.justify_content("center"),
      $css.border("4px solid " + border_color),
      $css.background($colors.light.grey_100),
      $css.border_radius(px(16)),
      $css.padding(px(32)),
      $css.cursor("pointer"),
      $css.hover(
        toList([$css.border("4px solid " + $colors.light.success_400)]),
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function irrelevant_card(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.border_radius(px(16)),
      $css.padding(px(32)),
      $css.background($colors.light.grey_100),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function irrelevant_details(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.color($colors.light.grey_800),
      $css.flex("1"),
      $css.display("flex"),
      $css.flex_direction("row"),
      $css.align_items("center"),
      $css.justify_content("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function card_with_source_name(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.flex("1"),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(16)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function source_name(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.color($colors.light.grey_800),
      $css.flex("1"),
      $css.display("flex"),
      $css.flex_direction("row"),
      $css.align_items("top"),
      $css.gap(px(16)),
      $css.margin_left(px(16)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function tags(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function duplicate_source_name(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.color($colors.light.grey_800),
      $css.flex("1"),
      $css.display("flex"),
      $css.flex_direction("row"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.gap(px(16)),
      $css.margin_left(px(16)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

export function title_and_tool_tip(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.flex("1"),
      $css.display("flex"),
      $css.flex_direction("row"),
      $css.align_items("center"),
      $css.gap(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function tooltip_icon(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.color($colors.light.grey_800),
      $css.background($colors.light.grey_100),
      $css.border_radius(px(128)),
      $css.padding(px(4)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function tooltip_content(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.color($colors.light.grey_800),
      $css.background($colors.light.white),
      $css.border("1px solid"),
      $css.border_radius(px(8)),
      $css.padding(px(16)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
