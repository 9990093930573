import * as a from '#editor/html/attribute'

export type Element = HTMLElement | Text | SVGElement | null | false

function el(tagName: string) {
  return function (attributes: a.Attribute[], children: Element[]) {
    const node = document.createElement(tagName)
    attributes.forEach(prop => {
      switch (prop.kind) {
        case 'none':
          return
        case 'handler':
          return node.addEventListener(prop.name, event => {
            prop.value(event)
            return null
          })
        case 'attr': {
          const { name, value } = prop
          if (name === 'class') {
            const previous = node.getAttribute(name)
            const classes = previous ? [previous, value].join(' ') : value
            node.setAttribute(name, classes)
          } else if (name === 'style') {
            const previous = node.getAttribute(name)
            const classes = previous ? [previous, value].join(';') : value
            node.setAttribute(name, classes)
          } else {
            node.setAttribute(name, value)
          }
        }
      }
    })
    node.append(...children.filter(c => !!c))
    return node
  }
}

export function element(
  tagName: string,
  attributes: a.Attribute[],
  children: Element[]
): HTMLElement {
  return el(tagName)(attributes, children)
}

export const div = el('div')
export const p = el('p')
export const button = el('button')
export const span = el('span')
export const table = el('table')
export const tbody = el('tbody')
export const thead = el('thead')
export const tr = el('tr')
export const td = el('td')
export const th = el('th')
export const img = (attributes: a.Attribute[]) => el('img')(attributes, [])

export function text(content: string): Text {
  return document.createTextNode(content)
}
