/// <reference types="./pagination.d.mts" />
import * as $icons from "../../../../ds/ds/icons.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $s from "../../../frontend/view/styles/pagination.mjs";
import { toList } from "../../../gleam.mjs";

function compute_start_end_bounds(current_page, page_max) {
  let half_block = 2;
  let start_block_bound = $int.max(1, current_page - half_block);
  let end_block_bound = $int.min(page_max - 1, current_page + half_block);
  let end_block_bound$1 = $int.max(start_block_bound, end_block_bound);
  return [start_block_bound, end_block_bound$1];
}

export function page_selector(page, page_max, msg, borders) {
  return $bool.lazy_guard(
    page_max === 0,
    $el.none,
    () => {
      let on_page_click = (page) => { return $e.on_click(msg(page)); };
      let $ = compute_start_end_bounds(page, page_max);
      let start_block_bound = $[0];
      let end_block_bound = $[1];
      return $s.page_selector(
        borders,
        toList([]),
        toList([
          $s.page_selector_prev(
            toList([on_page_click(page - 1), $a.disabled(page === 0)]),
            toList([$icons.medium($icons.arrow_left_line())]),
          ),
          $s.page_selector_page(
            page === 0,
            toList([on_page_click(0)]),
            toList([$h.text($int.to_string(1))]),
          ),
          (() => {
            let $1 = start_block_bound > 1;
            if ($1) {
              return $s.page_selector_page(
                false,
                toList([on_page_click(page - 3)]),
                toList([$h.text("...")]),
              );
            } else {
              return $el.none();
            }
          })(),
          (() => {
            let $1 = start_block_bound !== (end_block_bound + 1);
            if ($1) {
              return $el.fragment(
                $list.map(
                  $list.range(start_block_bound, end_block_bound),
                  (n) => {
                    return $s.page_selector_page(
                      n === page,
                      toList([on_page_click(n)]),
                      toList([$h.text($int.to_string(n + 1))]),
                    );
                  },
                ),
              );
            } else {
              return $el.none();
            }
          })(),
          (() => {
            let $1 = end_block_bound < (page_max - 1);
            if ($1) {
              return $s.page_selector_page(
                false,
                toList([on_page_click(page + 3)]),
                toList([$h.text("...")]),
              );
            } else {
              return $el.none();
            }
          })(),
          (() => {
            let $1 = page_max > 1;
            if ($1) {
              return $s.page_selector_page(
                page === page_max,
                toList([on_page_click(page_max)]),
                toList([$h.text($int.to_string(page_max + 1))]),
              );
            } else {
              return $el.none();
            }
          })(),
          $s.page_selector_next(
            toList([on_page_click(page + 1), $a.disabled(page === page_max)]),
            toList([$icons.medium($icons.arrow_right_line())]),
          ),
        ]),
      );
    },
  );
}
