/// <reference types="./warn.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $toast from "../../../grille_pain/grille_pain/lustre/toast.mjs";
import * as $level from "../../../grille_pain/grille_pain/toast/level.mjs";
import * as $msg from "../../data/msg.mjs";

function warn() {
  let _pipe = $toast.options();
  let _pipe$1 = $toast.timeout(_pipe, 8000);
  return $toast.level(_pipe$1, new $level.Warning());
}

function error() {
  let _pipe = $toast.options();
  let _pipe$1 = $toast.timeout(_pipe, 8000);
  return $toast.level(_pipe$1, new $level.Error());
}

export function unsupported_files(dispatch, count) {
  return $bool.guard(
    count === 0,
    undefined,
    () => {
      let msg = (() => {
        if (count === 1) {
          return "File type not supported. Retry with a .docx, .xlsx or .pdf.";
        } else {
          return "Some files type are not supported. Please, make sure to only send .docx, .xlsx or .pdf";
        }
      })();
      return dispatch(
        new $msg.ApplicationRequestedToDisplayCustomToast(warn(), msg),
      );
    },
  );
}

export function max_file_size(dispatch, count) {
  return $bool.guard(
    count === 0,
    undefined,
    () => {
      let msg = (() => {
        if (count === 1) {
          return "Max file size exceeded. Retry with a smaller file, less than 25 MB.";
        } else {
          return "Some files are exceeding max file size. Please, make sure to not send files heavier than 25 MB.";
        }
      })();
      return dispatch(
        new $msg.ApplicationRequestedToDisplayCustomToast(warn(), msg),
      );
    },
  );
}

export function upload_error(dispatch, count) {
  let count$1 = $int.to_string(count);
  let msg = ("Something went wrong during upload. " + count$1) + " files did not upload. Please, retry later.";
  return dispatch(
    new $msg.ApplicationRequestedToDisplayCustomToast(error(), msg),
  );
}

export function copilot_error(dispatch) {
  let msg = "Copilot is unresponsive at the moment, please refresh and try again.";
  return dispatch(
    new $msg.ApplicationRequestedToDisplayCustomToast(error(), msg),
  );
}
