/// <reference types="./questions.d.mts" />
import * as $proposal from "../../../../common/data/proposal.mjs";
import * as $question from "../../../../common/data/proposal_element/question.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $data from "../../../data/model/data.mjs";
import * as $route from "../../../data/route.mjs";
import { Ok, Error, toList, makeError, isEqual } from "../../../gleam.mjs";

function filter_questions_on_status(questions, status) {
  return $bool.guard(
    isEqual(status, new $proposal.AllStatus()),
    questions,
    () => {
      return $list.filter(
        questions,
        (_use0) => {
          let question_obj = _use0[1];
          if (status instanceof $proposal.Validated) {
            return question_obj.validated;
          } else if (status instanceof $proposal.InReview) {
            return !question_obj.validated;
          } else {
            throw makeError(
              "panic",
              "data/model/computed/questions",
              44,
              "",
              "Should be handled earlier",
              {}
            )
          }
        },
      );
    },
  );
}

function filter_questions_on_owner(questions, owner) {
  return $bool.guard(
    $option.is_none(owner),
    questions,
    () => {
      return $list.filter(
        questions,
        (_use0) => {
          let question = _use0[1];
          return isEqual(question.owner, owner);
        },
      );
    },
  );
}

function find_active_sheet(data, questions, id, sheet) {
  let by_sheet = (() => {
    let _pipe = $list.group(questions, (q) => { return q[1].sheet; });
    return $dict.map_values(
      _pipe,
      (_, value) => { return $list.reverse(value); },
    );
  })();
  return $result.try$(
    $list.key_find(data.proposals, id),
    (proposal) => {
      let first_non_empty_sheet = (() => {
        let $ = proposal.metadata;
        if ($ instanceof $proposal.Document) {
          return new Ok(new $option.None());
        } else if ($ instanceof $proposal.Narrative) {
          return new Ok(new $option.None());
        } else if ($ instanceof $proposal.Portal) {
          return new Ok(new $option.None());
        } else {
          let worksheets = $.worksheets;
          return $list.find_map(
            worksheets,
            (worksheet) => {
              let is_empty = (() => {
                let _pipe = $dict.get(
                  by_sheet,
                  new $option.Some(worksheet.name),
                );
                let _pipe$1 = $result.unwrap(_pipe, toList([]));
                return $list.is_empty(_pipe$1);
              })();
              return $bool.guard(
                is_empty,
                new Error(""),
                () => { return new Ok(new $option.Some(worksheet.name)); },
              );
            },
          );
        }
      })();
      let $ = proposal.metadata;
      if ($ instanceof $proposal.Document) {
        return new Error(undefined);
      } else if ($ instanceof $proposal.Narrative) {
        return new Error(undefined);
      } else if ($ instanceof $proposal.Portal) {
        return new Error(undefined);
      } else {
        let sheets = $.sheets;
        let _pipe = $list.find(
          sheets,
          (s) => { return isEqual(new $option.Some(s), sheet); },
        );
        let _pipe$1 = $result.map(
          _pipe,
          (var0) => { return new $option.Some(var0); },
        );
        return $result.try_recover(
          _pipe$1,
          (_) => { return first_non_empty_sheet; },
        );
      }
    },
  );
}

function filter_questions_on_sheet(data, questions, id, sheet) {
  let sheet$1 = (() => {
    let _pipe = find_active_sheet(data, questions, id, sheet);
    return $result.unwrap(_pipe, new $option.None());
  })();
  return $bool.guard(
    $option.is_none(sheet$1),
    questions,
    () => {
      return $list.filter(
        questions,
        (_use0) => {
          let question = _use0[1];
          return isEqual(question.sheet, sheet$1);
        },
      );
    },
  );
}

export function displayed(data) {
  let $ = data.route;
  if ($ instanceof $route.Projects &&
  $[0] instanceof $route.Proposals &&
  $[0].proposal instanceof $route.ShowProposal) {
    let id = $[0].proposal.id;
    let sheet = $[0].proposal.sheet;
    let _pipe = $data.get_proposal_questions(data, id);
    let _pipe$1 = filter_questions_on_status(
      _pipe,
      data.proposal_filters.status,
    );
    let _pipe$2 = filter_questions_on_owner(
      _pipe$1,
      data.proposal_filters.owner,
    );
    let _pipe$3 = filter_questions_on_sheet(data, _pipe$2, id, sheet);
    return new Ok(_pipe$3);
  } else {
    return new Error(undefined);
  }
}

export function sheet_question(data) {
  let $ = data.route;
  if ($ instanceof $route.Projects &&
  $[0] instanceof $route.Proposals &&
  $[0].proposal instanceof $route.ShowProposal) {
    let id = $[0].proposal.id;
    let sheet = $[0].proposal.sheet;
    let _pipe = $data.get_proposal_questions(data, id);
    let _pipe$1 = filter_questions_on_sheet(data, _pipe, id, sheet);
    return new Ok(_pipe$1);
  } else {
    return new Error(undefined);
  }
}
