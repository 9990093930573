/// <reference types="./transaction.d.mts" />
import * as $unsafe from "../../../../common_common/common/unsafe.mjs";
import * as $dict from "../../../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $decode from "../../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import { toList } from "../../../gleam.mjs";

export function decoder() {
  let transaction = $unsafe.coerce(undefined);
  return $decode.new_primitive_decoder(
    "Transaction",
    (dynamic) => {
      let _pipe = $decode.at(toList(["detail", "transaction"]), $decode.dynamic);
      let _pipe$1 = $decode.map(_pipe, $unsafe.coerce);
      let _pipe$2 = ((_capture) => { return $decode.run(dynamic, _capture); })(
        _pipe$1,
      );
      return $result.replace_error(_pipe$2, transaction);
    },
  );
}

export function meta(transaction) {
  let _pipe = $decode.at(
    toList(["meta"]),
    $decode.dict($decode.string, $decode.string),
  );
  let _pipe$1 = ((_capture) => {
    return $decode.run($dynamic.from(transaction), _capture);
  })(_pipe);
  return $result.lazy_unwrap(_pipe$1, $dict.new$);
}
