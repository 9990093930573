/// <reference types="./connector_settings.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $connector from "../data/connector.mjs";
import * as $decrypt from "../decrypt.mjs";
import { Ok, toList, CustomType as $CustomType } from "../gleam.mjs";

export class GoogleDrive extends $CustomType {
  constructor(folder) {
    super();
    this.folder = folder;
  }
}

export function encode_data(connector_settings) {
  {
    let folder = connector_settings.folder;
    return $json.object(toList([["folder", $json.string(folder)]]));
  }
}

export function encode(connector_settings) {
  {
    return $json.object(
      toList([
        ["kind", $connector.encode(new $connector.GoogleDrive())],
        ["data", encode_data(connector_settings)],
      ]),
    );
  }
}

export function decoder() {
  return $decode.then$(
    $decode.at(toList(["kind"]), $connector.decoder()),
    (type_) => {
      if (type_ instanceof $connector.GoogleDrive) {
        let folder = $decode.at(toList(["folder"]), $decode.string);
        return $decode.field(
          "data",
          $decrypt.json(folder),
          (data) => { return $decode.success(new GoogleDrive(data)); },
        );
      } else {
        let type_$1 = $connector.to_string(type_);
        let expected = ("ConnectorSettings(" + type_$1) + ")";
        return $decode.failure(new GoogleDrive(""), expected);
      }
    },
  );
}

export function is_google_drive(settings) {
  {
    return true;
  }
}

export function google_folder_id(settings) {
  {
    let folder = settings.folder;
    return new Ok(folder);
  }
}

export function kind(settings) {
  {
    return new $connector.GoogleDrive();
  }
}
