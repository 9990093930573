/// <reference types="./update.d.mts" />
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $model from "../../elements/editor/model.mjs";
import { Model } from "../../elements/editor/model.mjs";
import * as $editor from "../../elements/editor/tiptap/editor.mjs";
import * as $style from "../../elements/editor/tiptap/editor/style.mjs";
import * as $events from "../../elements/editor/tiptap/events.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { toList, CustomType as $CustomType, makeError } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";

export class EditorEmitted extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserClickedStyle extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserSelectedRatio extends $CustomType {
  constructor(ratio) {
    super();
    this.ratio = ratio;
  }
}

export class UserUpdatedContent extends $CustomType {
  constructor(content) {
    super();
    this.content = content;
  }
}

export class UserUpdatedEditable extends $CustomType {
  constructor(editable) {
    super();
    this.editable = editable;
  }
}

export class UserSelectedDropdown extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserEdittedCustomPrompt extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserSubmittedModal extends $CustomType {}

export class UserCancelledModal extends $CustomType {}

export class AiRewrite extends $CustomType {}

export class AiRespond extends $CustomType {}

function update_text_style(model, editor, style) {
  let _pipe = (() => {
    if (style instanceof $style.Bold) {
      let _record = model;
      return new Model(
        _record.editor,
        !model.bold,
        _record.italic,
        _record.underline,
        _record.ratio,
        _record.content,
        _record.editable,
        _record.modal,
        _record.custom_prompt,
        _record.id,
      );
    } else if (style instanceof $style.Italic) {
      let _record = model;
      return new Model(
        _record.editor,
        _record.bold,
        !model.italic,
        _record.underline,
        _record.ratio,
        _record.content,
        _record.editable,
        _record.modal,
        _record.custom_prompt,
        _record.id,
      );
    } else {
      let _record = model;
      return new Model(
        _record.editor,
        _record.bold,
        _record.italic,
        !model.underline,
        _record.ratio,
        _record.content,
        _record.editable,
        _record.modal,
        _record.custom_prompt,
        _record.id,
      );
    }
  })();
  return $pair.new$(_pipe, $style.toggle(editor, style));
}

function update_ai_respond(model, editor) {
  let _pipe = editor;
  let _pipe$1 = $editor.find_steerlab_block_position(_pipe);
  let _pipe$2 = $decode.run(
    _pipe$1,
    $decode.subfield(
      toList(["block", "attrs", "id"]),
      $decode.string,
      (block_id) => {
        return $decode.subfield(
          toList(["block", "attrs", "kind"]),
          $decode.string,
          (kind) => {
            let is_content = kind !== "outline_content";
            let def = new $model.ModalAiRespond("", "");
            return $bool.guard(
              is_content,
              $decode.failure(def, ""),
              () => {
                return $decode.subfield(
                  toList(["block", "textContent"]),
                  $decode.string,
                  (content) => {
                    return $decode.success(
                      new $model.ModalAiRespond(content, block_id),
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$3 = $result.map(
    _pipe$2,
    (var0) => { return new $option.Some(var0); },
  );
  let _pipe$4 = $result.map(
    _pipe$3,
    (modal) => {
      return [
        (() => {
          let _record = model;
          return new Model(
            _record.editor,
            _record.bold,
            _record.italic,
            _record.underline,
            _record.ratio,
            _record.content,
            _record.editable,
            modal,
            _record.custom_prompt,
            _record.id,
          );
        })(),
        $effect.none(),
      ];
    },
  );
  return $result.unwrap(_pipe$4, [model, $effect.none()]);
}

function submit_ai_respond(model, modal) {
  let $ = $utils.get_proposal_id();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "elements/editor/update",
      99,
      "submit_ai_respond",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let pid = $[0];
  return [
    (() => {
      let _record = model;
      return new Model(
        _record.editor,
        _record.bold,
        _record.italic,
        _record.underline,
        _record.ratio,
        _record.content,
        _record.editable,
        new $option.None(),
        "",
        _record.id,
      );
    })(),
    $middleware.require_access_token(
      (access_token) => {
        return $effect.from(
          (_) => {
            return $function.tap(
              undefined,
              (_) => {
                let _pipe = $ask.to(
                  new $ask.Heimdall(),
                  toList(["write-narrative-generation"]),
                );
                let _pipe$1 = $ask.via(_pipe, new $http.Post());
                let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                let _pipe$3 = $ask.with$(
                  _pipe$2,
                  $json.object(
                    toList([
                      ["id", $json.string(model.id)],
                      ["proposal", $json.string(pid)],
                      ["ask", $json.string(modal.content)],
                      ["block_id", $json.string(modal.block_id)],
                      ["custom_prompt", $json.string(model.custom_prompt)],
                    ]),
                  ),
                );
                return $ask.run(_pipe$3);
              },
            );
          },
        );
      },
    ),
  ];
}

function submit_ai_rewrite(model, modal) {
  let $ = $utils.get_proposal_id();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "elements/editor/update",
      121,
      "submit_ai_rewrite",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let pid = $[0];
  return [
    (() => {
      let _record = model;
      return new Model(
        _record.editor,
        _record.bold,
        _record.italic,
        _record.underline,
        _record.ratio,
        _record.content,
        _record.editable,
        new $option.None(),
        "",
        _record.id,
      );
    })(),
    $middleware.require_access_token(
      (access_token) => {
        return $effect.from(
          (_) => {
            return $function.tap(
              undefined,
              (_) => {
                let _pipe = $ask.to(
                  new $ask.Heimdall(),
                  toList(["rewrite-paragraph"]),
                );
                let _pipe$1 = $ask.via(_pipe, new $http.Post());
                let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                let _pipe$3 = $ask.with$(
                  _pipe$2,
                  $json.object(
                    toList([
                      ["id", $json.string(model.id)],
                      ["proposal", $json.string(pid)],
                      ["ask", $json.string(modal.content)],
                      ["block_id", $json.string(modal.block_id)],
                      ["custom_prompt", $json.string(model.custom_prompt)],
                    ]),
                  ),
                );
                return $ask.run(_pipe$3);
              },
            );
          },
        );
      },
    ),
  ];
}

function submit_modal(model) {
  let $ = model.modal;
  if ($ instanceof $option.None) {
    return [model, $effect.none()];
  } else if ($ instanceof $option.Some && $[0] instanceof $model.ModalAiRespond) {
    let m = $[0];
    return submit_ai_respond(model, m);
  } else {
    let m = $[0];
    return submit_ai_rewrite(model, m);
  }
}

function update_editor_required(model, msg) {
  return $model.require_editor(
    model,
    (editor) => {
      if (msg instanceof UserClickedStyle) {
        let style = msg[0];
        return update_text_style(model, editor, style);
      } else if (msg instanceof EditorEmitted) {
        let msg$1 = msg[0];
        return [$model.update_style(model), $events.bubble(msg$1)];
      } else if (msg instanceof UserSelectedDropdown &&
      msg.value instanceof AiRespond) {
        return update_ai_respond(model, editor);
      } else if (msg instanceof UserSelectedDropdown &&
      msg.value instanceof AiRewrite) {
        return update_ai_respond(model, editor);
      } else if (msg instanceof UserSubmittedModal) {
        return submit_modal(model);
      } else if (msg instanceof UserCancelledModal) {
        return [$model.close_modal(model), $effect.none()];
      } else {
        throw makeError(
          "panic",
          "elements/editor/update",
          60,
          "",
          "[editor/view] Should be handled outside",
          {}
        )
      }
    },
  );
}

export function update(model, msg) {
  if (msg instanceof UserSelectedRatio) {
    let ratio = msg.ratio;
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.editor,
          _record.bold,
          _record.italic,
          _record.underline,
          ratio,
          _record.content,
          _record.editable,
          _record.modal,
          _record.custom_prompt,
          _record.id,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof UserUpdatedContent) {
    let content = msg.content;
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.editor,
          _record.bold,
          _record.italic,
          _record.underline,
          _record.ratio,
          content,
          _record.editable,
          _record.modal,
          _record.custom_prompt,
          _record.id,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof UserUpdatedEditable) {
    let editable = msg.editable;
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.editor,
          _record.bold,
          _record.italic,
          _record.underline,
          _record.ratio,
          _record.content,
          editable,
          _record.modal,
          _record.custom_prompt,
          _record.id,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof UserEdittedCustomPrompt) {
    let custom_prompt = msg.value;
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.editor,
          _record.bold,
          _record.italic,
          _record.underline,
          _record.ratio,
          _record.content,
          _record.editable,
          _record.modal,
          custom_prompt,
          _record.id,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof EditorEmitted && msg[0] instanceof $events.Connected) {
    let editor = msg[0][0];
    let _pipe = model;
    let _pipe$1 = $model.set_editor(_pipe, editor);
    return $pair.new$(_pipe$1, $effect.none());
  } else {
    return update_editor_required(model, msg);
  }
}
