/// <reference types="./user.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Role extends $CustomType {
  constructor(id, name) {
    super();
    this.id = id;
    this.name = name;
  }
}

export class Engineering extends $CustomType {}

export class Product extends $CustomType {}

export class Security extends $CustomType {}

export class Sales extends $CustomType {}

export class Legal extends $CustomType {}

export class Marketing extends $CustomType {}

export class Other extends $CustomType {}

export class User extends $CustomType {
  constructor(id, email, name, picture, initials, roles, function$) {
    super();
    this.id = id;
    this.email = email;
    this.name = name;
    this.picture = picture;
    this.initials = initials;
    this.roles = roles;
    this.function = function$;
  }
}

export function encode_roles(role) {
  return $json.object(
    toList([["id", $json.string(role.id)], ["name", $json.string(role.name)]]),
  );
}

export function function_to_string(function$) {
  if (function$ instanceof Engineering) {
    return "engineering";
  } else if (function$ instanceof Product) {
    return "product";
  } else if (function$ instanceof Security) {
    return "security";
  } else if (function$ instanceof Sales) {
    return "sales";
  } else if (function$ instanceof Legal) {
    return "legal";
  } else if (function$ instanceof Marketing) {
    return "marketing";
  } else {
    return "other";
  }
}

export function encode_function(function$) {
  let _pipe = function_to_string(function$);
  return $json.string(_pipe);
}

export function encode(user) {
  return $json.object(
    toList([
      ["id", $json.string(user.id)],
      ["email", $json.string(user.email)],
      ["name", $json.string(user.name)],
      ["picture", $json.nullable(user.picture, $json.string)],
      ["roles", $json.array(user.roles, encode_roles)],
      ["function", $json.nullable(user.function, encode_function)],
    ]),
  );
}

export function roles_decoder() {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "name",
        $decode.string,
        (name) => { return $decode.success(new Role(id, name)); },
      );
    },
  );
}

export function function_decoder() {
  return $decode.then$(
    $decode.string,
    (function$) => {
      if (function$ === "engineering") {
        return $decode.success(new Engineering());
      } else if (function$ === "product") {
        return $decode.success(new Product());
      } else if (function$ === "security") {
        return $decode.success(new Security());
      } else if (function$ === "sales") {
        return $decode.success(new Sales());
      } else if (function$ === "legal") {
        return $decode.success(new Legal());
      } else if (function$ === "marketing") {
        return $decode.success(new Marketing());
      } else if (function$ === "other") {
        return $decode.success(new Other());
      } else {
        return $decode.failure(new Other(), "Function");
      }
    },
  );
}

function initials(name) {
  let _pipe = name;
  let _pipe$1 = $string.split(_pipe, " ");
  let _pipe$2 = $list.map(
    _pipe$1,
    (_capture) => { return $string.slice(_capture, 0, 1); },
  );
  let _pipe$3 = $list.map(_pipe$2, $string.capitalise);
  return $string.join(_pipe$3, "");
}

export function decoder() {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "email",
        $decode.string,
        (email) => {
          return $decode.field(
            "name",
            $decode.string,
            (name) => {
              return $decode.field(
                "picture",
                $decode.optional($decode.string),
                (picture) => {
                  return $decode.field(
                    "roles",
                    $decode.list(roles_decoder()),
                    (roles) => {
                      return $decode.field(
                        "function",
                        $decode.optional(function_decoder()),
                        (function$) => {
                          let initials$1 = initials(name);
                          let _pipe = new User(
                            id,
                            email,
                            name,
                            picture,
                            initials$1,
                            roles,
                            function$,
                          );
                          return $decode.success(_pipe);
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}
