/// <reference types="./one_trust_selector.d.mts" />
import * as $one_trust from "../../data/proposal_element/portal/one_trust.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class OneTrustSelector extends $CustomType {
  constructor(sections) {
    super();
    this.sections = sections;
  }
}

export function new$() {
  return new OneTrustSelector(toList([]));
}

export function add_sections(_, sections) {
  return new OneTrustSelector(sections);
}
