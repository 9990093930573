import * as prelude from '#gleam/prelude'

export function read(key: string) {
  try {
    const content = window.localStorage.getItem(key) ?? ''
    const dyn = JSON.parse(content)
    return new prelude.Ok(dyn)
  } catch (error) {
    return new prelude.Error(undefined)
  }
}

export function write(key: string, content: string) {
  try {
    window.localStorage.setItem(key, content)
    return new prelude.Ok(undefined)
  } catch (error) {
    return new prelude.Error(undefined)
  }
}
