/// <reference types="./styles.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $shadows from "../../../ds/ds/shadows.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { percent, px, px_ } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function container(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.position("relative"),
      $css.overflow("hidden"),
      $css.height(percent(100)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function page_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.width($length.percent(100)),
      $css.overflow("auto"),
      $css.padding_right(px(12)),
      $css.padding_bottom(px(12)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function format_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.width($length.percent(100)),
      $css.overflow("auto"),
      $css.border_bottom("1px solid " + $colors.light.grey_400),
      $css.padding_right(px(12)),
      $css.flex_shrink(0.0),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function format_wrapper_spacer(attrs, children) {
  let padding = $h.div(
    $css.class$(toList([$css.width(px(12)), $css.flex_shrink(0.0)])),
    toList([]),
    toList([]),
  );
  let _pipe = $css.class$(
    toList([
      $css.width($length.percent(100)),
      $css.padding_("12px 0px"),
      $css.display("flex"),
    ]),
  );
  return $h.div(
    _pipe,
    attrs,
    toList([padding, $el.fragment(children), padding]),
  );
}

function button_base() {
  return $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.padding(px(8)),
      $css.border_radius(px(8)),
      $css.border("1px solid transparent"),
      $css.background("transparent"),
      $css.transition("border-color .2s, background .2s, color .2s"),
      $css.color($colors.light.grey_800),
      $css.hover(
        toList([
          $css.background($colors.light.white),
          $css.border_color($colors.light.grey_400),
        ]),
      ),
    ]),
  );
}

export function action_text_button(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($button.button_secondary()),
      $css.compose($text.sm($text.medium)),
      $css.border_color($colors.light.grey_400),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function action_button(attrs, icon) {
  let children = toList([$icons.medium(icon)]);
  let _pipe = $css.class$(
    toList([$css.compose(button_base()), $css.color($colors.light.onyx)]),
  );
  return $h.button(_pipe, attrs, children);
}

function active_button(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose(button_base()), $css.color($colors.light.blue_800)]),
  );
  return $h.button(_pipe, attrs, children);
}

function inactive_button(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(button_base())]));
  return $h.button(_pipe, attrs, children);
}

export function format_button(active, attrs, icon) {
  let children = toList([$icons.medium(icon)]);
  if (active) {
    return active_button(attrs, children);
  } else {
    return inactive_button(attrs, children);
  }
}

export function rewrite_popup(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.position("absolute"),
      $css.top(px(0)),
      $css.left(px(0)),
      $css.right(px(0)),
      $css.bottom(px(0)),
      $css.z_index(100_000),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.align_items("center"),
      $css.background("rgba(0, 0, 0, 0.1)"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function rewrite_content(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.background($colors.light.white),
      $css.margin(px(24)),
      $css.padding(px(24)),
      $css.border_radius(px(8)),
      $css.box_shadow($shadows.s()),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(24)),
      $css.max_width(px(700)),
      $css.width(percent(100)),
    ]),
  );
  return $h.form(_pipe, attrs, children);
}

export function outline_content(attrs, children) {
  let _pipe = $css.class$(toList([]));
  return $h.div(_pipe, attrs, children);
}

export function outline_title(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.font_weight("600"), $css.font_size($length.rem(1.3))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function outline_custom(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.font_weight("600"), $css.font_size($length.rem(1.3))]),
  );
  return $h.div(_pipe, attrs, children);
}

const px_per_centimeter = 37.7952755906;

export function page(ratio, attrs, children) {
  let width = ((21.0 * px_per_centimeter) * ratio) + 80.0;
  let min_height = ((29.7 * px_per_centimeter) * ratio) + 80.0;
  let _pipe = $css.class$(
    toList([
      $css.width(px_(width)),
      $css.min_height(px_(min_height)),
      $css.background($colors.light.white),
      $css.border_radius(px(4)),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.margin_("12px auto 0 auto"),
      $css.transition("all .2s"),
      $css.position("relative"),
      $css.flex_shrink(0.0),
      $css.padding_top(px(48)),
      $css.padding_bottom(px(48)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function format_row(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.gap(px(5)),
      $css.align_items("center"),
      $css.width(px_((21.0 * px_per_centimeter) + 40.0)),
      $css.margin_("auto"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
