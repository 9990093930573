/// <reference types="./project.d.mts" />
import * as $project from "../../../common/data/project.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import { Data } from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $route from "../../data/route.mjs";
import * as $effects from "../../frontend/effects.mjs";
import * as $project_effects from "../../frontend/effects/project.mjs";
import { toList } from "../../gleam.mjs";

export function update(data, msg) {
  if (msg instanceof $msg.ApiDeletedProject) {
    let project_id = msg.project_id;
    return [$data.delete_project(data, project_id), $effect.none()];
  } else if (msg instanceof $msg.ApiReturnedProject) {
    let project = msg.project;
    return [$data.upsert_projects(data, toList([project])), $effect.none()];
  } else if (msg instanceof $msg.ApiReturnedProjects) {
    let projects = msg.projects;
    return [$data.upsert_projects(data, projects), $effect.none()];
  } else if (msg instanceof $msg.UserUpdatedProjectTitle) {
    let id = msg.id;
    let name = msg.title;
    let $ = $list.key_find(data.projects, id);
    if (!$.isOk()) {
      return [data, $effect.none()];
    } else {
      let proposal = $[0];
      let _pipe = (() => {
        let _record = proposal;
        return new $project.Project(
          _record.id,
          name,
          _record.owner,
          _record.org_id,
          _record.deadline,
          _record.client,
          _record.collaborators,
          _record.metadata,
          _record.created_at,
          _record.status,
        );
      })();
      let _pipe$1 = ((_capture) => {
        return $list.key_set(data.projects, id, _capture);
      })(_pipe);
      let _pipe$2 = ((projects) => {
        let _record = data;
        return new Data(
          _record.access_token,
          _record.client,
          _record.collaborators_proposal_opened,
          _record.connectors,
          _record.organization,
          _record.content_library,
          _record.copilot_input,
          _record.proposal_filters,
          _record.proposal_selected_questions,
          _record.copilot_threads,
          _record.custom_rewording_input,
          _record.modal,
          _record.modal_unsubscriber,
          _record.feed_opened,
          _record.loading,
          _record.more_proposal_opened,
          _record.more_proposal_unsubscriber,
          _record.proposal_builder,
          _record.filter_proposal_opened,
          _record.notifications,
          _record.notifications_unread,
          _record.permissions,
          projects,
          _record.proposals,
          _record.proposal_block_page_size,
          _record.elements,
          _record.route,
          _record.language,
          _record.running_requests,
          _record.translations,
          _record.user,
          _record.qualification_matrix,
          _record.collapsed_navbar,
          _record.opened_projects,
          _record.proposal_toggles,
          _record.dropdown_unsubscriber,
          _record.analytics,
        );
      })(_pipe$1);
      return $pair.new$(_pipe$2, $effect.none());
    }
  } else if (msg instanceof $msg.UserSavedProjectTitle) {
    let id = msg.id;
    let blur = $effects.blur_active_element();
    let update$1 = $project_effects.update_project(data, id);
    return [data, $effect.batch(toList([blur, update$1]))];
  } else if (msg instanceof $msg.UserToggledProjectCollaborator) {
    let project_id = msg.project_id;
    let user_id = msg.user_id;
    let _pipe = data.projects;
    let _pipe$1 = $list.key_find(_pipe, project_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (_capture) => { return $project.toggle_collaborator(_capture, user_id); },
    );
    let _pipe$3 = $result.map(_pipe$2, $list.wrap);
    let _pipe$4 = $result.map(
      _pipe$3,
      (_capture) => { return $data.upsert_projects(data, _capture); },
    );
    let _pipe$5 = $result.unwrap(_pipe$4, data);
    return $pair.new$(
      _pipe$5,
      $project_effects.toggle_project_collaborator(data, project_id, user_id),
    );
  } else if (msg instanceof $msg.UserToggledCollapsedProject) {
    let project = msg.project;
    let _pipe = $dict.get(data.opened_projects, project.id);
    let _pipe$1 = $result.unwrap(_pipe, true);
    let _pipe$2 = $bool.negate(_pipe$1);
    let _pipe$3 = ((_capture) => {
      return $dict.insert(data.opened_projects, project.id, _capture);
    })(_pipe$2);
    let _pipe$4 = ((opened_projects) => {
      let _record = data;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.modal,
        _record.modal_unsubscriber,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.elements,
        _record.route,
        _record.language,
        _record.running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    })(_pipe$3);
    return $pair.new$(_pipe$4, $effect.none());
  } else {
    let project = msg.project;
    let project_id = new $option.Some(project.id);
    let _pipe = new $route.AddProposal(project_id, false);
    let _pipe$1 = new $route.Projects(_pipe);
    let _pipe$2 = $route.push(_pipe$1);
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe$2);
  }
}
