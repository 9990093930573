/// <reference types="./output.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class OutlineOutput extends $CustomType {
  constructor(org_id, proposal_outline, session_id, user_id) {
    super();
    this.org_id = org_id;
    this.proposal_outline = proposal_outline;
    this.session_id = session_id;
    this.user_id = user_id;
  }
}

export class ProposalOutline extends $CustomType {
  constructor(sections, title) {
    super();
    this.sections = sections;
    this.title = title;
  }
}

export class Section extends $CustomType {
  constructor(content, section_title, subsections) {
    super();
    this.content = content;
    this.section_title = section_title;
    this.subsections = subsections;
  }
}

export class Subsection extends $CustomType {
  constructor(content, subsection_title) {
    super();
    this.content = content;
    this.subsection_title = subsection_title;
  }
}

export function subsection_decoder() {
  return $decode.field(
    "content",
    $decode.string,
    (content) => {
      return $decode.field(
        "subsection_title",
        $decode.string,
        (subsection_title) => {
          return $decode.success(new Subsection(content, subsection_title));
        },
      );
    },
  );
}

export function section_decoder() {
  return $decode.field(
    "content",
    $decode.string,
    (content) => {
      return $decode.field(
        "section_title",
        $decode.string,
        (section_title) => {
          return $decode.field(
            "subsections",
            $decode.optional($decode.list(subsection_decoder())),
            (subsections) => {
              return $decode.success(
                new Section(content, section_title, subsections),
              );
            },
          );
        },
      );
    },
  );
}

export function proposal_outline_decoder() {
  return $decode.field(
    "sections",
    $decode.list(section_decoder()),
    (sections) => {
      return $decode.field(
        "title",
        $decode.string,
        (title) => {
          return $decode.success(new ProposalOutline(sections, title));
        },
      );
    },
  );
}

export function outline_output_decoder() {
  return $decode.field(
    "org_id",
    $decode.string,
    (org_id) => {
      return $decode.field(
        "proposal_outline",
        proposal_outline_decoder(),
        (proposal_outline) => {
          return $decode.field(
            "session_id",
            $decode.string,
            (session_id) => {
              return $decode.field(
                "user_id",
                $decode.string,
                (user_id) => {
                  return $decode.success(
                    new OutlineOutput(
                      org_id,
                      proposal_outline,
                      session_id,
                      user_id,
                    ),
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function subsection_to_json(data) {
  return $json.object(
    toList([
      ["content", $json.string(data.content)],
      ["subsection_title", $json.string(data.subsection_title)],
    ]),
  );
}

export function section_to_json(data) {
  return $json.object(
    toList([
      ["content", $json.string(data.content)],
      ["section_title", $json.string(data.section_title)],
      [
        "subsections",
        $json.nullable(
          data.subsections,
          (_capture) => { return $json.array(_capture, subsection_to_json); },
        ),
      ],
    ]),
  );
}

export function proposal_outline_to_json(data) {
  return $json.object(
    toList([
      ["sections", $json.array(data.sections, section_to_json)],
      ["title", $json.string(data.title)],
    ]),
  );
}

export function outline_output_to_json(data) {
  return $json.object(
    toList([
      ["org_id", $json.string(data.org_id)],
      ["proposal_outline", proposal_outline_to_json(data.proposal_outline)],
      ["session_id", $json.string(data.session_id)],
      ["user_id", $json.string(data.user_id)],
    ]),
  );
}
