/// <reference types="./narrative_selector.d.mts" />
import * as $pdf_selector from "../../../../common/data/ephemeral/pdf_selector.mjs";
import * as $button from "../../../../ds/ds/components/button.mjs";
import * as $layout from "../../../../ds/ds/components/layout.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import * as $output from "../../../../interfaces/schemas/outline/output.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $editor from "../../../elements/editor.mjs";
import { toList } from "../../../gleam.mjs";

function to_content(outline) {
  let block = (c) => { return ("<steerlab-block>" + c) + "</steerlab-block>"; };
  let h1 = (c) => { return ("<h1>" + c) + "</h1>"; };
  let h2 = (c) => { return ("<h2>" + c) + "</h2>"; };
  let h3 = (c) => { return ("<h3>" + c) + "</h3>"; };
  let _pipe = toList([
    toList([block(h1(outline.proposal_outline.title))]),
    (() => {
      let _pipe = outline.proposal_outline.sections;
      return $list.flat_map(
        _pipe,
        (section) => {
          let _pipe$1 = toList([
            block(h2(section.section_title)),
            block(section.content),
          ]);
          return $list.append(
            _pipe$1,
            $list.flat_map(
              $option.unwrap(section.subsections, toList([])),
              (subsection) => {
                return toList([
                  block(h3(subsection.subsection_title)),
                  block(subsection.content),
                ]);
              },
            ),
          );
        },
      );
    })(),
  ]);
  let _pipe$1 = $list.flatten(_pipe);
  return $string.join(_pipe$1, "\n");
}

export function select_narrative(selector) {
  let $ = selector.outline;
  if ($ instanceof $option.None) {
    return $el.none();
  } else {
    let outline = $[0];
    let _pipe = toList([
      $layout.gap(20),
      $layout.overflow("hidden"),
      $layout.flex("1"),
    ]);
    return $layout.column(
      _pipe,
      toList([]),
      toList([
        $editor.view(
          toList([$editor.content(to_content(outline)), $editor.editable(false)]),
        ),
        $layout.row(
          toList([$layout.justify("end")]),
          toList([]),
          toList([
            $button.primary(
              toList([$e.on_click(new $msg.UserSubmittedPdf())]),
              toList([$el.text("Generate your responses")]),
            ),
          ]),
        ),
      ]),
    );
  }
}
