/// <reference types="./feed.d.mts" />
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $display from "../../../../ds/ds/typography/display.mjs";
import * as $text from "../../../../ds/ds/typography/text.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { px, vh } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../../gleam.mjs";

export function feed_title(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.justify_content("space-between"),
      $css.align_items("center"),
      $css.padding_("20px 16px"),
      $css.position("sticky"),
      $css.top(px(0)),
      $css.border_bottom("1px solid transparent"),
      $css.transition("all .3s"),
      $css.background($colors.light.white),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

export function mark_all_as_read(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.gap(px(8)),
      $css.align_items("center"),
      $css.background($colors.light.white),
      $css.transition("background .3s"),
      $css.padding_("6px 12px"),
      $css.border_radius(px(8)),
      $css.hover(toList([$css.background($colors.light.grey_50)])),
      $css.disabled(
        toList([$css.background("transparent"), $css.cursor("default")]),
      ),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function icon_wrapper(child) {
  let _pipe = $css.class$(toList([$css.width(px(20)), $css.height(px(20))]));
  return $h.div(_pipe, toList([]), toList([child]));
}

export function notification_wrapper(attrs, children) {
  let _pipe = $css.class$(toList([$css.padding_("16px 0px")]));
  return $h.div(_pipe, attrs, children);
}

function dot() {
  return $css.class$(
    toList([
      $css.width(px(12)),
      $css.height(px(12)),
      $css.border_radius(px(6)),
      $css.property("flex-shrink", "0"),
    ]),
  );
}

export function active_dot() {
  let _pipe = $css.class$(
    toList([$css.compose(dot()), $css.background($colors.light.error_800)]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function inactive_dot() {
  let _pipe = $css.class$(toList([$css.compose(dot())]));
  return $h.div(_pipe, toList([]), toList([]));
}

export function dim_text(child) {
  let _pipe = $css.class$(toList([$css.color($colors.light.grey_600)]));
  return $h.div(_pipe, toList([]), toList([child]));
}

export function text(child) {
  return $h.div_(toList([]), toList([child]));
}

export function wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.regular)),
      $css.position("absolute"),
      $css.height(vh(100)),
      $css.width(px(400)),
      $css.background("white"),
      $css.z_index(90_000),
      $css.overflow("auto"),
      $css.transition("left .6s"),
      $css.padding_("0px 24px"),
      $css.border_right("1px solid " + $colors.light.grey_400),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function date(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
