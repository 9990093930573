/// <reference types="./keyboard.d.mts" />
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px, rem } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../ds/colors.mjs";
import * as $icons from "../../ds/icons.mjs";
import * as $utils from "../../ds/utils.mjs";
import { toList } from "../../gleam.mjs";

function styles() {
  return $css.class$(
    toList([
      $css.font_family("monospace"),
      $css.background($colors.light.grey_50),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius(px(4)),
      $css.padding_("1px 3px"),
      $css.font_size(rem(0.7)),
      $css.font_weight("500"),
      $css.text_transform("little-caps"),
      $css.font_variant_caps("small-caps"),
      $css.letter_spacing("1px"),
    ]),
  );
}

export function shortcut(shortcut) {
  return $h.kbd(styles(), toList([]), toList([$h.text(shortcut)]));
}

export function cmd_shortcut(shortcut) {
  let modifier = (() => {
    let $ = $utils.is_mac();
    if ($) {
      return $icons.tiny($icons.cmd());
    } else {
      return $h.text("Ctrl");
    }
  })();
  let _pipe = $css.class$(
    toList([
      $css.display("inline-flex"),
      $css.align_items("center"),
      $css.gap(px(3)),
      $css.compose(styles()),
    ]),
  );
  return $h.kbd(
    _pipe,
    toList([]),
    toList([modifier, $icons.tiny($icons.add()), $h.text(shortcut)]),
  );
}
