/// <reference types="./metrics.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $order from "../../gleam_stdlib/gleam/order.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $calendar from "../../gleam_time/gleam/time/calendar.mjs";
import * as $duration from "../../gleam_time/gleam/time/duration.mjs";
import * as $timestamp from "../../gleam_time/gleam/time/timestamp.mjs";
import * as $uuid from "../../youid/youid/uuid.mjs";
import * as $decrypt from "../decrypt.mjs";
import { toList, CustomType as $CustomType, makeError, remainderInt, isEqual } from "../gleam.mjs";
import * as $time from "../time/utils/seconds.mjs";

export class MetricsCurve extends $CustomType {
  constructor(curve, metrics) {
    super();
    this.curve = curve;
    this.metrics = metrics;
  }
}

export class Metric extends $CustomType {
  constructor(id, value, curve_id, created_at) {
    super();
    this.id = id;
    this.value = value;
    this.curve_id = curve_id;
    this.created_at = created_at;
  }
}

export class Hour extends $CustomType {}

export class Day extends $CustomType {}

export class Week extends $CustomType {}

export class Month extends $CustomType {}

export class Year extends $CustomType {}

export class QuestionCreated extends $CustomType {}

export class AnswerCreated extends $CustomType {}

export class ProposalCreated extends $CustomType {}

export class ConfidenceScore extends $CustomType {}

export class Unknown extends $CustomType {}

export class CurveData extends $CustomType {
  constructor(name, period, kind) {
    super();
    this.name = name;
    this.period = period;
    this.kind = kind;
  }
}

export class Curve extends $CustomType {
  constructor(id, org_id, created_at, data) {
    super();
    this.id = id;
    this.org_id = org_id;
    this.created_at = created_at;
    this.data = data;
  }
}

export function decoder() {
  return $decode.field(
    "id",
    $decrypt.uuid(),
    (id) => {
      return $decode.field(
        "value",
        $decode.float,
        (value) => {
          return $decode.field(
            "curve_id",
            $decrypt.uuid(),
            (curve_id) => {
              return $decode.field(
                "created_at",
                $decrypt.timestamp(),
                (created_at) => {
                  return $decode.success(
                    new Metric(id, value, curve_id, created_at),
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function encode_period(p) {
  if (p instanceof Day) {
    return "day";
  } else if (p instanceof Hour) {
    return "hour";
  } else if (p instanceof Month) {
    return "month";
  } else if (p instanceof Week) {
    return "week";
  } else {
    return "year";
  }
}

export function encode_kind(kind) {
  if (kind instanceof QuestionCreated) {
    return "question_created";
  } else if (kind instanceof ProposalCreated) {
    return "proposal_created";
  } else if (kind instanceof AnswerCreated) {
    return "answer_created";
  } else if (kind instanceof ConfidenceScore) {
    return "confidence_score";
  } else {
    return "unknown";
  }
}

function period_decoder() {
  return $decode.then$(
    $decode.string,
    (period) => {
      if (period === "day") {
        return $decode.success(new Day());
      } else if (period === "hour") {
        return $decode.success(new Hour());
      } else if (period === "month") {
        return $decode.success(new Month());
      } else if (period === "week") {
        return $decode.success(new Week());
      } else if (period === "year") {
        return $decode.success(new Year());
      } else {
        return $decode.failure(new Year(), "period_decoder");
      }
    },
  );
}

function curve_kind_decoder() {
  return $decode.then$(
    $decode.string,
    (kind) => {
      if (kind === "question_created") {
        return $decode.success(new QuestionCreated());
      } else if (kind === "proposal_created") {
        return $decode.success(new ProposalCreated());
      } else if (kind === "answer_created") {
        return $decode.success(new AnswerCreated());
      } else if (kind === "confidence_score") {
        return $decode.success(new ConfidenceScore());
      } else {
        return $decode.failure(new Unknown(), "curve_kind_decoder");
      }
    },
  );
}

function data_decoder() {
  return $decode.field(
    "name",
    $decode.string,
    (name) => {
      return $decode.field(
        "period",
        period_decoder(),
        (period) => {
          return $decode.field(
            "kind",
            curve_kind_decoder(),
            (kind) => {
              return $decode.success(new CurveData(name, period, kind));
            },
          );
        },
      );
    },
  );
}

export function curve_decoder() {
  return $decode.field(
    "id",
    $decrypt.uuid(),
    (id) => {
      return $decode.field(
        "created_at",
        $decrypt.timestamp(),
        (created_at) => {
          return $decode.field(
            "org_id",
            $decode.string,
            (org_id) => {
              return $decode.field(
                "data",
                $decrypt.json(data_decoder()),
                (data) => {
                  return $decode.success(
                    new Curve(id, org_id, created_at, data),
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function metrics_curve_decoder() {
  return $decode.field(
    "curve",
    curve_decoder(),
    (curve) => {
      return $decode.field(
        "metrics",
        $decode.list(decoder()),
        (metrics) => {
          return $decode.success(new MetricsCurve(curve, metrics));
        },
      );
    },
  );
}

export function encode(metric) {
  return $json.object(
    toList([
      ["id", $json.string(metric.id)],
      ["value", $json.float(metric.value)],
      ["curve_id", $json.string(metric.curve_id)],
      [
        "created_at",
        $json.string(
          (() => {
            let _pipe = metric.created_at;
            return $timestamp.to_rfc3339(_pipe, $calendar.utc_offset);
          })(),
        ),
      ],
    ]),
  );
}

export function encode_curve_data(data) {
  return $json.object(
    toList([
      [
        "name",
        (() => {
          let _pipe = data.name;
          return $json.string(_pipe);
        })(),
      ],
      [
        "kind",
        (() => {
          let _pipe = data.kind;
          let _pipe$1 = encode_kind(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
      [
        "period",
        (() => {
          let _pipe = data.period;
          let _pipe$1 = encode_period(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
    ]),
  );
}

export function encode_curve(curve) {
  return $json.object(
    toList([
      ["id", $json.string(curve.id)],
      [
        "data",
        (() => {
          let _pipe = curve.data;
          return encode_curve_data(_pipe);
        })(),
      ],
      ["org_id", $json.string(curve.org_id)],
      [
        "created_at",
        $json.string(
          (() => {
            let _pipe = curve.created_at;
            return $timestamp.to_rfc3339(_pipe, $calendar.utc_offset);
          })(),
        ),
      ],
    ]),
  );
}

export function encode_metrics_curve(metric_curve) {
  return $json.object(
    toList([
      ["curve", encode_curve(metric_curve.curve)],
      ["metrics", $json.array(metric_curve.metrics, encode)],
    ]),
  );
}

function period_to_seconds(period) {
  if (period instanceof Day) {
    return $time.days(1);
  } else if (period instanceof Hour) {
    return $time.hours(1);
  } else if (period instanceof Week) {
    return $time.days(7);
  } else if (period instanceof Month) {
    return $time.days(30);
  } else {
    return $time.days(365);
  }
}

function get_previous_bound(timestamp, period) {
  let bound_gap = period_to_seconds(period);
  let $ = (() => {
    let _pipe = timestamp;
    return $timestamp.to_unix_seconds_and_nanoseconds(_pipe);
  })();
  let seconds = $[0];
  let start_int_timestamps = seconds - (remainderInt(seconds, bound_gap));
  return $timestamp.from_unix_seconds(start_int_timestamps);
}

function contain(start, end, check) {
  let start_in = $timestamp.compare(start, check);
  return ((isEqual(start_in, new $order.Eq())) || (isEqual(
    start_in,
    new $order.Lt()
  ))) && (isEqual($timestamp.compare(check, end), new $order.Lt()));
}

function to_seconds(t) {
  let $ = (() => {
    let _pipe = t;
    return $timestamp.to_unix_seconds_and_nanoseconds(_pipe);
  })();
  let s = $[0];
  return s;
}

export function transform_curve(curve, metrics) {
  return $bool.guard(
    (() => {
      let _pipe = metrics;
      return $list.length(_pipe);
    })() <= 1,
    metrics,
    () => {
      let sorted = (() => {
        let _pipe = metrics;
        return $list.sort(
          _pipe,
          (e1, e2) => {
            return $timestamp.compare(e1.created_at, e2.created_at);
          },
        );
      })();
      let first = (() => {
        let _pipe = sorted;
        return $list.first(_pipe);
      })();
      let last = (() => {
        let _pipe = sorted;
        return $list.last(_pipe);
      })();
      let bound_gap = period_to_seconds(curve.data.period);
      if (first.isOk() && last.isOk()) {
        let first$1 = first[0];
        let last$1 = last[0];
        let gap = $int.subtract(
          (() => {
            let _pipe = last$1.created_at;
            return to_seconds(_pipe);
          })(),
          (() => {
            let _pipe = first$1.created_at;
            return to_seconds(_pipe);
          })(),
        );
        let $ = (() => {
          let _pipe = $int.divide(gap, period_to_seconds(curve.data.period));
          return $result.map(
            _pipe,
            (_capture) => { return $int.add(_capture, 1); },
          );
        })();
        if (!$.isOk()) {
          throw makeError(
            "let_assert",
            "data/metrics",
            198,
            "",
            "Pattern match failed, no pattern matched the value.",
            { value: $ }
          )
        }
        let steps = $[0];
        let _pipe = $list.range(0, steps);
        return $list.map(
          _pipe,
          (index) => {
            let start_bound = get_previous_bound(
              (() => {
                let _pipe$1 = first$1.created_at;
                return $timestamp.add(
                  _pipe$1,
                  $duration.seconds(bound_gap * index),
                );
              })(),
              curve.data.period,
            );
            let end_bound = get_previous_bound(
              (() => {
                let _pipe$1 = first$1.created_at;
                return $timestamp.add(
                  _pipe$1,
                  $duration.seconds(bound_gap * (index + 1)),
                );
              })(),
              curve.data.period,
            );
            let _pipe$1 = metrics;
            let _pipe$2 = $list.filter(
              _pipe$1,
              (m) => { return contain(start_bound, end_bound, m.created_at); },
            );
            let _pipe$3 = $list.last(_pipe$2);
            return $result.unwrap(
              _pipe$3,
              new Metric(
                (() => {
                  let _pipe$4 = $uuid.v4_string();
                  return $string.lowercase(_pipe$4);
                })(),
                0.0,
                curve.id,
                start_bound,
              ),
            );
          },
        );
      } else {
        return metrics;
      }
    },
  );
}
