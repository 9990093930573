/// <reference types="./projects_table.d.mts" />
import * as $project from "../../../../common/data/project.mjs";
import * as $proposal from "../../../../common/data/proposal.mjs";
import * as $user from "../../../../common/data/user.mjs";
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $pp from "../../../../ds/ds/components/profile_picture.mjs";
import * as $progress_bar from "../../../../ds/ds/components/progress/bar.mjs";
import * as $skeleton from "../../../../ds/ds/components/skeleton.mjs";
import * as $table from "../../../../ds/ds/components/table.mjs";
import * as $icons from "../../../../ds/ds/icons.mjs";
import * as $text from "../../../../ds/ds/typography/text.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $calendar from "../../../../gleam_time/gleam/time/calendar.mjs";
import * as $timestamp from "../../../../gleam_time/gleam/time/timestamp.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { px } from "../../../../sketch/sketch/css/length.mjs";
import * as $html from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $common from "../../../frontend/view/common.mjs";
import * as $label from "../../../frontend/view/components/label.mjs";
import * as $sp from "../../../frontend/view/styles/proposals.mjs";
import { toList, CustomType as $CustomType } from "../../../gleam.mjs";
import { formatDate as format_date } from "./projects_table.ffi.ts";

export class ShowTrashButton extends $CustomType {}

export class HideTrashButton extends $CustomType {}

function to_due_date(time) {
  let _pipe = time;
  let _pipe$1 = $timestamp.to_rfc3339(_pipe, $calendar.utc_offset);
  return format_date(_pipe$1);
}

function view_owner(owner, users) {
  let $ = $list.find(users, (u) => { return u.id === owner; });
  if (!$.isOk()) {
    return $el.none();
  } else {
    let name = $[0].name;
    let picture = $[0].picture;
    let initials = $[0].initials;
    return $common.name_small(
      toList([]),
      toList([
        $pp.from_user(picture, initials, new $pp.Medium()),
        $el.text(name),
      ]),
    );
  }
}

function view_collaborators(collaborators, users) {
  if (collaborators.hasLength(0)) {
    return $el.none();
  } else if (collaborators.hasLength(1)) {
    let user_id = collaborators.head;
    return view_owner(user_id, users);
  } else {
    let users_id = collaborators;
    return $common.stacked_avatars(users_id, users);
  }
}

function empty_cell(child) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.padding_("30px 0"),
    ]),
  );
  return $html.div(_pipe, toList([]), toList([child]));
}

function empty_text_content(child) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.max_width(px(300)),
      $css.white_space("wrap"),
      $css.text_align("center"),
      $css.color($colors.light.grey_800),
    ]),
  );
  return $html.div(_pipe, toList([]), toList([child]));
}

const empty_text = "After you upload a proposal, it will appear here.";

function loading_table() {
  return $table.body(
    (() => {
      let _pipe = $table.row(
        (() => {
          let _pipe = $table.cell($skeleton.skeleton(100, 25));
          return $list.repeat(_pipe, 8);
        })(),
      );
      let _pipe$1 = $table.hover(_pipe, false);
      return $list.repeat(_pipe$1, 5);
    })(),
  );
}

export function table(loading, projects, opened_state, users, trash_button) {
  return $table.table(
    toList([
      $table.headers(
        toList([
          (() => {
            let _pipe = $table.header("");
            return $table.fit(_pipe);
          })(),
          $table.header("Name"),
          $table.header("Client"),
          (() => {
            let _pipe = $table.header("Creation Date");
            return $table.fit(_pipe);
          })(),
          (() => {
            let _pipe = $table.header("Due Date");
            return $table.fit(_pipe);
          })(),
          $table.header("Owner"),
          $table.header("Collaborators"),
          $table.header("Progress"),
          (() => {
            let _pipe = $table.header("Status");
            return $table.fit(_pipe);
          })(),
          (() => {
            let _pipe = $table.header("");
            return $table.fit(_pipe);
          })(),
        ]),
      ),
      (() => {
        if (loading) {
          return loading_table();
        } else {
          return body(projects, opened_state, users, trash_button);
        }
      })(),
    ]),
  );
}

function project_row(project, opened, completion, users) {
  let on_toggle_collapse = (() => {
    let _pipe = new $msg.UserToggledCollapsedProject(project);
    let _pipe$1 = new $msg.Project(_pipe);
    return $e.on_click(_pipe$1);
  })();
  let _pipe = $table.row(
    toList([
      (() => {
        let _pipe = toList([$icons.medium($icons.arrow_up_line())]);
        let _pipe$1 = ((_capture) => {
          return $sp.collapse_icon_button(
            opened,
            toList([on_toggle_collapse]),
            _capture,
          );
        })(_pipe);
        let _pipe$2 = $table.cell(_pipe$1);
        return $table.stop_cell_propagation(_pipe$2);
      })(),
      $table.cell($el.text(project.name)),
      $table.cell($el.text(project.client)),
      $table.cell($el.text(to_due_date(project.created_at))),
      $table.cell($el.text(to_due_date(project.deadline))),
      $table.cell(view_owner(project.owner, users)),
      $table.cell(view_collaborators(project.collaborators, users)),
      $table.cell($progress_bar.progress(completion)),
      (() => {
        let _pipe = $label.from_status(project.status);
        let _pipe$1 = $table.cell(_pipe);
        return $table.justify(_pipe$1, "start");
      })(),
    ]),
  );
  return $table.hover(_pipe, false);
}

function view_delete_button(trash_button, msg) {
  if (trash_button instanceof HideTrashButton) {
    return $table.cell($el.none());
  } else {
    let _pipe = $sp.trash_icon_button(
      toList([$e.on_click(msg)]),
      toList([$icons.tiny($icons.trash())]),
    );
    let _pipe$1 = $table.cell(_pipe);
    let _pipe$2 = $table.justify(_pipe$1, "end");
    return $table.stop_cell_propagation(_pipe$2);
  }
}

function single_project_proposal(
  project,
  proposal,
  completion,
  users,
  trash_button
) {
  let _pipe = $table.row(
    toList([
      $table.cell($el.none()),
      $table.cell($el.text(project.name)),
      $table.cell($el.text(project.client)),
      $table.cell($el.text(to_due_date(project.created_at))),
      $table.cell($el.text(to_due_date(project.deadline))),
      $table.cell(view_owner(project.owner, users)),
      $table.cell(view_collaborators(project.collaborators, users)),
      $table.cell($progress_bar.progress(completion)),
      (() => {
        let _pipe = $label.from_status(project.status);
        let _pipe$1 = $table.cell(_pipe);
        return $table.justify(_pipe$1, "start");
      })(),
      (() => {
        let _pipe = new $msg.UserDisplayedDeleteProposalModal(proposal.id);
        let _pipe$1 = new $msg.Proposal(_pipe);
        return ((_capture) => {
          return view_delete_button(trash_button, _capture);
        })(_pipe$1);
      })(),
    ]),
  );
  return $table.on_click(
    _pipe,
    new $msg.Proposal(
      new $msg.UserClickedProposal(proposal, new $option.None()),
    ),
  );
}

function proposal_row(proposal, users, trash_button) {
  return $table.row(
    toList([
      $table.cell($el.none()),
      (() => {
        let _pipe = $table.cell($sp.padding_left($el.text(proposal.name)));
        return $table.span(_pipe, 4);
      })(),
      $table.cell(view_owner(proposal.owner, users)),
      $table.cell(view_collaborators(proposal.collaborators, users)),
      $table.cell($progress_bar.progress(proposal.progress)),
      $table.cell($label.from_status(proposal.status)),
      (() => {
        let _pipe = new $msg.UserDisplayedDeleteProposalModal(proposal.id);
        let _pipe$1 = new $msg.Proposal(_pipe);
        let _pipe$2 = ((_capture) => {
          return view_delete_button(trash_button, _capture);
        })(_pipe$1);
        return $table.padding(_pipe$2, 0);
      })(),
    ]),
  );
}

function project_proposals(proposals, opened, users, trash_button) {
  return $bool.guard(
    !opened,
    toList([]),
    () => {
      return $list.map(
        proposals,
        (proposal) => {
          let _pipe = proposal_row(proposal, users, trash_button);
          return $table.on_click(
            _pipe,
            new $msg.Proposal(
              new $msg.UserClickedProposal(proposal, new $option.None()),
            ),
          );
        },
      );
    },
  );
}

function empty_table() {
  let empty_cell$1 = empty_cell(empty_text_content($el.text(empty_text)));
  return $table.body(
    toList([
      (() => {
        let _pipe = $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(empty_cell$1);
              return $table.span(_pipe, 8);
            })(),
          ]),
        );
        return $table.hover(_pipe, false);
      })(),
    ]),
  );
}

function body(projects, opened_state, users, trash_button) {
  return $bool.lazy_guard(
    $list.is_empty(projects),
    empty_table,
    () => {
      return $table.body(
        $list.flat_map(
          projects,
          (_use0) => {
            let project = _use0[0];
            let completion = _use0[1];
            let proposals = _use0[2];
            if (proposals.hasLength(0)) {
              return toList([$table.none()]);
            } else if (proposals.hasLength(1)) {
              let proposal = proposals.head;
              return toList([
                single_project_proposal(
                  project,
                  proposal,
                  completion,
                  users,
                  trash_button,
                ),
              ]);
            } else {
              let opened = (() => {
                let _pipe = $dict.get(opened_state, project.id);
                return $result.unwrap(_pipe, true);
              })();
              let _pipe = project_proposals(
                proposals,
                opened,
                users,
                trash_button,
              );
              return $list.prepend(
                _pipe,
                project_row(project, opened, completion, users),
              );
            }
          },
        ),
      );
    },
  );
}
