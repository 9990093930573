/// <reference types="./dropdowns.d.mts" />
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { px, rem } from "../../../../sketch/sketch/css/length.mjs";
import { toList } from "../../../gleam.mjs";

function label() {
  return $css.class$(
    toList([
      $css.padding_("4px 12px"),
      $css.border_radius(px(4)),
      $css.text_align("center"),
      $css.white_space("nowrap"),
      $css.flex("1"),
      $css.cursor("pointer"),
      $css.border("1px solid transparent"),
      $css.color("var(--text-color)"),
      (() => {
        let _pipe = $css.background("var(--background-color)");
        return $css.important(_pipe);
      })(),
      $css.transition("border_color 0.3s"),
      $css.font_weight("500"),
      $css.font_size(rem(0.875)),
      $css.line_height("1.25rem"),
      $css.min_width(px(120)),
      $css.hover(
        toList([
          (() => {
            let _pipe = $css.border_color("var(--text-color)");
            return $css.important(_pipe);
          })(),
          $css.background("var(--background-color)"),
        ]),
      ),
    ]),
  );
}

export function success() {
  return $css.class$(
    toList([
      $css.compose(label()),
      $css.property("--background-color", "#f6ffdf"),
      $css.property("--text-color", "#b1db4b"),
    ]),
  );
}

export function bordered_success() {
  return $css.class$(
    toList([
      $css.compose(success()),
      (() => {
        let _pipe = $css.border_color("var(--text-color)");
        return $css.important(_pipe);
      })(),
    ]),
  );
}

export function error() {
  return $css.class$(
    toList([
      $css.property("--background-color", "#ffeeee"),
      $css.property("--text-color", "#f35555"),
      $css.compose(label()),
    ]),
  );
}

export function bordered_error() {
  return $css.class$(
    toList([
      $css.compose(error()),
      (() => {
        let _pipe = $css.border_color("var(--text-color)");
        return $css.important(_pipe);
      })(),
    ]),
  );
}

export function neutral() {
  return $css.class$(
    toList([
      $css.compose(label()),
      $css.property("--background-color", "#eaebec"),
      $css.property("--text-color", "#7e7f88"),
    ]),
  );
}

export function bordered_neutral() {
  return $css.class$(
    toList([
      $css.compose(neutral()),
      (() => {
        let _pipe = $css.border_color("var(--text-color)");
        return $css.important(_pipe);
      })(),
    ]),
  );
}

export function current() {
  return $css.class$(
    toList([
      $css.compose(label()),
      $css.property("--background-color", "#eef4fe"),
      $css.property("--text-color", "#3b75ca"),
    ]),
  );
}

export function bordered_current() {
  return $css.class$(
    toList([
      $css.compose(current()),
      (() => {
        let _pipe = $css.border_color("var(--text-color)");
        return $css.important(_pipe);
      })(),
    ]),
  );
}

export function spacing() {
  return $css.class$(toList([$css.gap(px(10)), $css.padding(px(10))]));
}
