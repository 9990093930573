/// <reference types="./question.d.mts" />
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $document_updated from "../../../common/data/ephemeral/document_updated.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $proposal_element from "../../../common/data/proposal_element.mjs";
import * as $question from "../../../common/data/proposal_element/question.mjs";
import { Question } from "../../../common/data/proposal_element/question.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $language from "../../../common/data/translate/language.mjs";
import * as $iso639 from "../../../common/data/translate/language/iso639.mjs";
import * as $file_ from "../../../ds/plinth/browser/file.mjs";
import * as $form_data from "../../../gleam_fetch/gleam/fetch/form_data.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $promise from "../../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import { Data } from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { Ok, toList, prepend as listPrepend, isEqual } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";

export function ask_ai_rewording(_, proposal_id, question_id, asked, custom) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList([
                "proposals",
                proposal_id,
                "elements",
                question_id,
                "ai",
              ]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Patch());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(
                  toList([
                    ["kind", $json.string(asked)],
                    ["custom", $json.string(custom)],
                  ]),
                ),
              );
              return $ask.run(_pipe$3);
            },
          );
        },
      );
    },
  );
}

export function fetch_data_point_translation(
  data_point_id,
  model,
  dispatch,
  access_token
) {
  let exist = (() => {
    let _pipe = model.translations;
    let _pipe$1 = $dict.values(_pipe);
    return $list.find(
      _pipe$1,
      (translation) => { return translation.target_id === data_point_id; },
    );
  })();
  if (exist.isOk()) {
    return undefined;
  } else {
    let _pipe = $ask.to(
      new $ask.Heimdall(),
      toList(["translations", "point", data_point_id]),
    );
    let _pipe$1 = $ask.bearing(_pipe, access_token);
    let _pipe$2 = $ask.expect(
      _pipe$1,
      $decode.list($translate.translation_decoder()),
    );
    let _pipe$3 = $ask.notify(
      _pipe$2,
      (q) => {
        return dispatch(new $msg.Proposal(new $msg.ApiReturnedTranslations(q)));
      },
    );
    $ask.run(_pipe$3)
    return undefined;
  }
}

export function query_proposal_questions(data, timeout, proposal_id) {
  let $ = data.access_token;
  if ($ instanceof $option.None) {
    return $effect.from(
      (dispatch) => {
        return $utils.set_nil_timeout(
          timeout,
          () => {
            let _pipe = new $msg.ApplicationQueriedProposalElements(
              1000,
              proposal_id,
            );
            return dispatch(_pipe);
          },
        );
      },
    );
  } else {
    let access_token = $[0];
    return $effect.from(
      (dispatch) => {
        return $function.tap(
          undefined,
          (_) => {
            let at = toList(["proposals", proposal_id, "elements"]);
            let _pipe = $ask.to(new $ask.Heimdall(), at);
            let _pipe$1 = $ask.bearing(_pipe, access_token);
            let _pipe$2 = $ask.expect(
              _pipe$1,
              (() => {
                let elements_decoder = $decode.list($proposal_element.decoder());
                return $decode.field(
                  "elements",
                  elements_decoder,
                  (elements) => {
                    let ds_decoder = $decode.list($data_source.decoder());
                    let ds_decoder$1 = $decode.dict($decode.string, ds_decoder);
                    return $decode.field(
                      "data_sources",
                      ds_decoder$1,
                      (data_sources) => {
                        let dp_decoder = $decode.list($data_point.decoder());
                        let dp_decoder$1 = $decode.dict(
                          $decode.string,
                          dp_decoder,
                        );
                        return $decode.field(
                          "data_points",
                          dp_decoder$1,
                          (data_points) => {
                            return $decode.success(
                              [elements, data_sources, data_points],
                            );
                          },
                        );
                      },
                    );
                  },
                );
              })(),
            );
            let _pipe$3 = $ask.notify(
              _pipe$2,
              (q) => {
                let elements = q[0];
                let data_sources = q[1];
                let data_points = q[2];
                let _pipe$3 = data_points;
                let _pipe$4 = $dict.values(_pipe$3);
                let _pipe$5 = $list.flatten(_pipe$4);
                let _pipe$6 = $list.map(_pipe$5, (dp) => { return dp.id; });
                let _pipe$7 = $list.unique(_pipe$6);
                $list.map(
                  _pipe$7,
                  ((_capture) => {
                    return fetch_data_point_translation(
                      _capture,
                      data,
                      dispatch,
                      access_token,
                    );
                  }),
                )
                let _pipe$8 = new $msg.ApiReturnedProposalsElements(
                  elements,
                  data_sources,
                  data_points,
                );
                let _pipe$9 = new $msg.Proposal(_pipe$8);
                return dispatch(_pipe$9);
              },
            );
            let _pipe$4 = $ask.error(
              _pipe$3,
              (_) => {
                return dispatch(
                  new $msg.Proposal(new $msg.ApiTimeoutedProposalsQuestions()),
                );
              },
            );
            return $ask.run(_pipe$4);
          },
        );
      },
    );
  }
}

export function update_element(_, element) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let proposal_id = $proposal_element.proposal_id(element);
              let id = $proposal_element.id(element);
              let _pipe = $ask.to(
                new $ask.Heimdall(),
                toList(["proposals", proposal_id, "elements", id]),
              );
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $proposal_element.encode(element),
              );
              let _pipe$4 = $ask.expect(_pipe$3, $proposal_element.decoder());
              let _pipe$5 = $ask.notify(
                _pipe$4,
                (q) => {
                  return dispatch(new $msg.ApiReturnedProposalElement(q));
                },
              );
              let _pipe$6 = $ask.error(
                _pipe$5,
                (_) => {
                  return dispatch(new $msg.ApiRejectedProposalElement(element));
                },
              );
              return $ask.run(_pipe$6);
            },
          );
        },
      );
    },
  );
}

function should_update_question(question, content) {
  let content$1 = $string.trim(content);
  let answer = (() => {
    let _pipe = question.answer.custom;
    return $option.or(_pipe, question.answer.long);
  })();
  return !isEqual(answer, new $option.Some(content$1));
}

export function prepare_updating_question(data, question, content) {
  let should_update = should_update_question(question, content);
  let is_access_token = $option.is_some(data.access_token);
  let will_update = should_update && is_access_token;
  return $bool.guard(
    !will_update,
    data,
    () => {
      let id = "question-" + question.id;
      let running_requests = listPrepend(id, data.running_requests);
      let _record = data;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.modal,
        _record.modal_unsubscriber,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.elements,
        _record.route,
        _record.language,
        running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    },
  );
}

export function update_question(_, question, content, language) {
  let should_update = should_update_question(question, content);
  return $bool.guard(
    !should_update,
    $effect.none(),
    () => {
      return $middleware.require_access_token(
        (access_token) => {
          return $effect.from(
            (dispatch) => {
              return $function.tap(
                undefined,
                (_) => {
                  let at = (() => {
                    let _pipe = toList([
                      "proposals",
                      question.proposal_id,
                      "elements",
                      question.id,
                    ]);
                    return $list.append(
                      _pipe,
                      (() => {
                        if (language instanceof $option.None) {
                          return toList([]);
                        } else {
                          let language$1 = language[0];
                          return toList([
                            (() => {
                              let _pipe$1 = language$1;
                              return $iso639.to_string(_pipe$1);
                            })(),
                          ]);
                        }
                      })(),
                    );
                  })();
                  let element = new $proposal_element.Question(
                    $question.update_custom_answer(question, content),
                  );
                  let _pipe = $ask.to(new $ask.Heimdall(), at);
                  let _pipe$1 = $ask.bearing(_pipe, access_token);
                  let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
                  let _pipe$3 = $ask.with$(
                    _pipe$2,
                    $proposal_element.encode(element),
                  );
                  let _pipe$4 = $ask.expect(
                    _pipe$3,
                    $proposal_element.decoder(),
                  );
                  let _pipe$5 = $ask.notify(
                    _pipe$4,
                    (q) => {
                      return dispatch(
                        new $msg.Proposal(
                          new $msg.ApiReturnedProposalElement(q),
                        ),
                      );
                    },
                  );
                  let _pipe$6 = $ask.error(
                    _pipe$5,
                    (_) => {
                      return dispatch(
                        new $msg.Proposal(
                          new $msg.ApiRejectedProposalElement(element),
                        ),
                      );
                    },
                  );
                  let _pipe$7 = $ask.finally$(
                    _pipe$6,
                    () => {
                      return dispatch(
                        new $msg.ApplicationCompletedRequest(
                          "question-" + question.id,
                        ),
                      );
                    },
                  );
                  return $ask.run(_pipe$7);
                },
              );
            },
          );
        },
      );
    },
  );
}

function should_update_question_yes_no(question, yes_no) {
  let answer = $translate.parse_yes_no(
    $option.unwrap(question.answer.yes_no, ""),
  );
  return !isEqual(answer, new Ok(yes_no));
}

export function prepare_updating_question_yes_no(data, question, yes_no) {
  let should_update = should_update_question_yes_no(question, yes_no);
  let is_access_token = $option.is_some(data.access_token);
  let will_update = should_update && is_access_token;
  return $bool.guard(
    !will_update,
    data,
    () => {
      let id = "question-yes-no-" + question.id;
      let running_requests = listPrepend(id, data.running_requests);
      let _record = data;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.modal,
        _record.modal_unsubscriber,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.elements,
        _record.route,
        _record.language,
        running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    },
  );
}

export function update_question_yes_no(_, question, yes_no) {
  let should_update = should_update_question_yes_no(question, yes_no);
  return $bool.guard(
    !should_update,
    $effect.none(),
    () => {
      return $middleware.require_access_token(
        (access_token) => {
          return $effect.from(
            (dispatch) => {
              return $function.tap(
                undefined,
                (_) => {
                  let at = toList([
                    "proposals",
                    question.proposal_id,
                    "elements",
                    question.id,
                  ]);
                  let yes_no$1 = new $option.Some(
                    $translate.yes_no_to_string(yes_no),
                  );
                  let answer = (() => {
                    let _record = question.answer;
                    return new $question.Answer(
                      _record.short,
                      _record.long,
                      yes_no$1,
                      _record.choice,
                      _record.custom,
                    );
                  })();
                  let question$1 = (() => {
                    let _record = question;
                    return new Question(
                      _record.id,
                      _record.proposal_id,
                      _record.data_points_id,
                      _record.display,
                      _record.owner,
                      _record.kind,
                      _record.choices,
                      _record.sheet,
                      _record.content,
                      answer,
                      _record.validated,
                      _record.metadata,
                      _record.ai_processing,
                      _record.assign_processing,
                      _record.qa_order,
                      _record.doc_id_order,
                    );
                  })();
                  let element = new $proposal_element.Question(question$1);
                  let _pipe = $ask.to(new $ask.Heimdall(), at);
                  let _pipe$1 = $ask.bearing(_pipe, access_token);
                  let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
                  let _pipe$3 = $ask.with$(
                    _pipe$2,
                    $proposal_element.encode(element),
                  );
                  let _pipe$4 = $ask.expect(
                    _pipe$3,
                    $proposal_element.decoder(),
                  );
                  let _pipe$5 = $ask.notify(
                    _pipe$4,
                    (q) => {
                      return dispatch(
                        new $msg.Proposal(
                          new $msg.ApiReturnedProposalElement(q),
                        ),
                      );
                    },
                  );
                  let _pipe$6 = $ask.finally$(
                    _pipe$5,
                    () => {
                      return dispatch(
                        new $msg.ApplicationCompletedRequest(
                          "question-yes-no-" + question$1.id,
                        ),
                      );
                    },
                  );
                  return $ask.run(_pipe$6);
                },
              );
            },
          );
        },
      );
    },
  );
}

function should_update_question_choice(question, choice) {
  let answer = question.answer.choice;
  return !isEqual(answer, new $option.Some(choice));
}

export function prepare_updating_question_choice(data, question, choice) {
  let should_update = should_update_question_choice(question, choice);
  let is_access_token = $option.is_some(data.access_token);
  let will_update = should_update && is_access_token;
  return $bool.guard(
    !will_update,
    data,
    () => {
      let id = "question-choice-" + question.id;
      let running_requests = listPrepend(id, data.running_requests);
      let _record = data;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.modal,
        _record.modal_unsubscriber,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.elements,
        _record.route,
        _record.language,
        running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    },
  );
}

export function update_question_choice(_, question, choice) {
  let should_update = should_update_question_choice(question, choice);
  return $bool.guard(
    !should_update,
    $effect.none(),
    () => {
      return $middleware.require_access_token(
        (access_token) => {
          return $effect.from(
            (dispatch) => {
              return $function.tap(
                undefined,
                (_) => {
                  let at = toList([
                    "proposals",
                    question.proposal_id,
                    "elements",
                    question.id,
                  ]);
                  let choice$1 = new $option.Some(choice);
                  let answer = (() => {
                    let _record = question.answer;
                    return new $question.Answer(
                      _record.short,
                      _record.long,
                      _record.yes_no,
                      choice$1,
                      _record.custom,
                    );
                  })();
                  let question$1 = (() => {
                    let _record = question;
                    return new Question(
                      _record.id,
                      _record.proposal_id,
                      _record.data_points_id,
                      _record.display,
                      _record.owner,
                      _record.kind,
                      _record.choices,
                      _record.sheet,
                      _record.content,
                      answer,
                      _record.validated,
                      _record.metadata,
                      _record.ai_processing,
                      _record.assign_processing,
                      _record.qa_order,
                      _record.doc_id_order,
                    );
                  })();
                  let element = new $proposal_element.Question(question$1);
                  let _pipe = $ask.to(new $ask.Heimdall(), at);
                  let _pipe$1 = $ask.bearing(_pipe, access_token);
                  let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
                  let _pipe$3 = $ask.with$(
                    _pipe$2,
                    $proposal_element.encode(element),
                  );
                  let _pipe$4 = $ask.expect(
                    _pipe$3,
                    $proposal_element.decoder(),
                  );
                  let _pipe$5 = $ask.notify(
                    _pipe$4,
                    (q) => {
                      return dispatch(
                        new $msg.Proposal(
                          new $msg.ApiReturnedProposalElement(q),
                        ),
                      );
                    },
                  );
                  let _pipe$6 = $ask.finally$(
                    _pipe$5,
                    () => {
                      return dispatch(
                        new $msg.ApplicationCompletedRequest(
                          "question-choice-" + question$1.id,
                        ),
                      );
                    },
                  );
                  return $ask.run(_pipe$6);
                },
              );
            },
          );
        },
      );
    },
  );
}

export function update_elements_owner(_, elements_ids, proposal_id, user_id) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["proposals", proposal_id, "elements", "owner"]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(
                  toList([
                    ["owner_id", $json.string(user_id)],
                    ["elements_ids", $json.array(elements_ids, $json.string)],
                  ]),
                ),
              );
              return $ask.run(_pipe$3);
            },
          );
        },
      );
    },
  );
}

export function update_elements_status(_, elements_ids, proposal_id, validated) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["proposals", proposal_id, "elements", "status"]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Patch());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(
                  toList([
                    ["validated", $json.bool(validated)],
                    ["elements_ids", $json.array(elements_ids, $json.string)],
                  ]),
                ),
              );
              let _pipe$4 = $ask.expect(
                _pipe$3,
                $decode.list($proposal_element.decoder()),
              );
              let _pipe$5 = $ask.notify(
                _pipe$4,
                (elements) => {
                  return $list.each(
                    elements,
                    (element) => {
                      return dispatch(
                        new $msg.Proposal(
                          new $msg.ApiReturnedProposalElement(element),
                        ),
                      );
                    },
                  );
                },
              );
              return $ask.run(_pipe$5);
            },
          );
        },
      );
    },
  );
}

export function update_document_batch(_, proposal_id, content) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["proposals", proposal_id, "document"]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Patch());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $document_updated.encode(content),
              );
              return $ask.run(_pipe$3);
            },
          );
        },
      );
    },
  );
}

export function add_attachment(_, proposal, question, file) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              return $promise.await$(
                $file_.bytes(file),
                (body) => {
                  let at = toList([
                    "proposals",
                    proposal.id,
                    "elements",
                    question.id,
                    "attachments",
                  ]);
                  let _pipe = $ask.to(new $ask.Heimdall(), at);
                  let _pipe$1 = $ask.via(_pipe, new $http.Patch());
                  let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                  let _pipe$3 = $ask.data(
                    _pipe$2,
                    (() => {
                      let _pipe$3 = $form_data.new$();
                      let _pipe$4 = $form_data.append(
                        _pipe$3,
                        "name",
                        $file_.name(file),
                      );
                      let _pipe$5 = $form_data.append(
                        _pipe$4,
                        "mime",
                        $file_.mime(file),
                      );
                      return $form_data.append_bits(_pipe$5, "file", body);
                    })(),
                  );
                  return $ask.run(_pipe$3);
                },
              );
            },
          );
        },
      );
    },
  );
}

export function remove_attachment(_, proposal, question, attachment) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList([
                "proposals",
                proposal.id,
                "elements",
                question.id,
                "attachments",
              ]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Delete());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(
                  toList([["attachment", $json.string(attachment.id)]]),
                ),
              );
              return $ask.run(_pipe$3);
            },
          );
        },
      );
    },
  );
}
