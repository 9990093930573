/// <reference types="./profile_picture.d.mts" />
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../ds/colors.mjs";
import * as $text from "../../ds/typography/text.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Small extends $CustomType {}

export class Medium extends $CustomType {}

export class Large extends $CustomType {}

export class Humongous extends $CustomType {}

function size_to_px(size) {
  if (size instanceof Small) {
    return [px(16), px(4)];
  } else if (size instanceof Medium) {
    return [px(24), px(8)];
  } else if (size instanceof Large) {
    return [px(36), px(12)];
  } else {
    return [px(128), px(36)];
  }
}

export function initials(size, attrs, children) {
  let $ = size_to_px(size);
  let size$1 = $[0];
  let font = $[1];
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.display("flex"),
      $css.width(size$1),
      $css.height(size$1),
      $css.property("flex-shrink", "0"),
      $css.background($colors.light.foam),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.border_radius(px(500)),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.color($colors.light.paragraph),
      $css.font_size(font),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function picture(size, attrs) {
  let $ = size_to_px(size);
  let size$1 = $[0];
  let font = $[1];
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.display("flex"),
      $css.width(size$1),
      $css.height(size$1),
      $css.property("flex-shrink", "0"),
      $css.background($colors.light.foam),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.border_radius(px(500)),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.color($colors.light.paragraph),
      $css.font_size(font),
    ]),
  );
  return $h.img(_pipe, attrs);
}

export function from_user(pic, init, size) {
  if (pic instanceof $option.None) {
    return initials(size, toList([]), toList([$element.text(init)]));
  } else {
    let pic$1 = pic[0];
    return picture(size, toList([$attribute.src(pic$1)]));
  }
}
