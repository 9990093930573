/// <reference types="./style.d.mts" />
import * as $effect from "../../../../../lustre/lustre/effect.mjs";
import * as $editor from "../../../../elements/editor/tiptap/editor.mjs";
import { CustomType as $CustomType } from "../../../../gleam.mjs";
import { toggle as do_toggle, isActive as do_is_active } from "./style.ffi.ts";

export class Bold extends $CustomType {}

export class Underline extends $CustomType {}

export class Italic extends $CustomType {}

export function toggle(editor, option) {
  return $effect.from(
    (_) => {
      if (option instanceof Bold) {
        do_toggle(editor, "bold")
      } else if (option instanceof Italic) {
        do_toggle(editor, "italic")
      } else {
        do_toggle(editor, "underline")
      }
      return undefined;
    },
  );
}

export function is_active(editor, selector) {
  if (selector instanceof Bold) {
    return do_is_active(editor, "bold");
  } else if (selector instanceof Italic) {
    return do_is_active(editor, "italic");
  } else {
    return do_is_active(editor, "underline");
  }
}
