/// <reference types="./service_worker.d.mts" />
import * as $promise from "../../../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $function from "../../../../gleam_stdlib/gleam/function.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../../../lustre/lustre/effect.mjs";
import { sendAccessToken as do_send_access_token } from "./service_worker.ffi.ts";

export function send_access_token(access_token) {
  return $effect.from(
    (_) => {
      return $function.tap(
        undefined,
        (_) => { return do_send_access_token(access_token); },
      );
    },
  );
}
