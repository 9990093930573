/// <reference types="./menu.d.mts" />
import * as $tg from "../../../ds/ds/components/transition_group.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $lustre from "../../../lustre/lustre.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $experimental from "../../../sketch_lustre_experimental/sketch/lustre/experimental.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../../gleam.mjs";

class Model extends $CustomType {
  constructor(destroy) {
    super();
    this.destroy = destroy;
  }
}

class UserUpdatedDestroy extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

function decode_destroy(dyn) {
  let $ = $decode.run(dyn, $decode.string);
  if ($.isOk() && $[0] === "true") {
    return new Ok(new UserUpdatedDestroy(true));
  } else if ($.isOk() && $[0] === "True") {
    return new Ok(new UserUpdatedDestroy(true));
  } else if ($.isOk() && $[0] === "false") {
    return new Ok(new UserUpdatedDestroy(false));
  } else if ($.isOk() && $[0] === "False") {
    return new Ok(new UserUpdatedDestroy(false));
  } else {
    return new Error(toList([]));
  }
}

function update(_, msg) {
  {
    let destroy = msg[0];
    return [new Model(destroy), $effect.none()];
  }
}

function view_menu() {
  let menu = $icons.medium($icons.editor_menu());
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.width_("12px"),
      $css.height_("12px"),
      $css.color("#666"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([menu]));
}

function view(model) {
  return $experimental.render(
    toList([$experimental.node()]),
    () => {
      let transition_group = (_capture) => {
        return $tg.transition_group(_capture, toList([view_menu()]));
      };
      return transition_group(
        toList([
          $tg.destroy(model.destroy),
          $tg.duration(200),
          $tg.entering($css.class$(toList([$css.opacity(0.0)]))),
          $tg.enter(
            $css.class$(
              toList([$css.transition("opacity 200ms"), $css.opacity(1.0)]),
            ),
          ),
          $tg.entered($css.class$(toList([$css.opacity(1.0)]))),
          $tg.exiting($css.class$(toList([$css.opacity(1.0)]))),
          $tg.exit(
            $css.class$(
              toList([$css.transition("opacity 200ms"), $css.opacity(0.0)]),
            ),
          ),
          $tg.exited($css.class$(toList([$css.opacity(0.0)]))),
        ]),
      );
    },
  );
}

export function register() {
  let init = (_) => { return [new Model(false), $effect.none()]; };
  let _pipe = $dict.from_list(toList([["destroy", decode_destroy]]));
  let _pipe$1 = ((_capture) => {
    return $lustre.component(init, update, view, _capture);
  })(_pipe);
  return $lustre.register(_pipe$1, "editor-menu");
}
