/// <reference types="./bar.d.mts" />
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $element from "../../../../lustre/lustre/element.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../../ds/colors.mjs";
import * as $text from "../../../ds/typography/text.mjs";
import { toList } from "../../../gleam.mjs";

function progress_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.medium)),
      $css.display("flex"),
      $css.gap(px(24)),
      $css.align_items("center"),
      $css.color($colors.light.success_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function progress_inside(completion) {
  let _pipe = $css.class$(
    toList([
      $css.width(percent(completion)),
      $css.background($colors.light.success_800),
      $css.border_radius(px(8)),
      $css.transition("width .3s"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

function progress_bar(completion) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.height(px(8)),
      $css.background($colors.light.grey_200),
      $css.border_radius(px(8)),
      $css.overflow("hidden"),
      $css.flex("1"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([progress_inside(completion)]));
}

export function progress(completion) {
  return progress_wrapper(
    toList([]),
    toList([
      $h.text($int.to_string(completion) + " %"),
      progress_bar(completion),
    ]),
  );
}
