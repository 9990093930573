import * as tiptap from '@tiptap/core'

/** Define the possible observed attributes used to pass informations to
 * children editor elements. */
export type Attribute = (typeof attributes)[keyof typeof attributes]

/** Attributes is used to easily define observed attributes, and document
 * the different attributes. */
export const attributes = {
  /** `ratio` is a float, between 1.5 and 0.75. This represents the current
   * font-size as `rem`. */
  ratio: 'ratio',
  /** `headerColor` defines the header cells background color for tables. */
  headerColor: 'header-color',
  /** `borderColor` defines the border color for tables. */
  borderColor: 'border-color',
  /** `selectedCellColor` defines selected cells background for tables. */
  selectedCellColor: 'selected-cell-color',
  /** `content` defines the TipTap HTML content. */
  content: 'content',
} as const

// Formatting breaks the nice alignment here. Let's enjoy the improved
// readability by disabling prettier only for that function.
// prettier-ignore
export function styles(ratio: string) {
  const base = { '--font-size': `${ratio}rem` }
  switch (ratio) {
    case '0.75': return { ...base, '--line-height': '1.125rem' }
    case '1.25': return { ...base, '--line-height': '1.875rem' }
    case '1.5':  return { ...base, '--line-height': '2.25rem' }

    case '1.0':
    default:
      return { ...base, '--line-height': '1.5rem' }
  }
}

// prettier-ignore
export function getProperty(name: string) {
  switch (name) {
    case attributes.borderColor: return '--border-color'
    case attributes.headerColor: return '--header-background'
    case attributes.selectedCellColor: return '--selected-cell-background'
    default: throw new Error(`No property for name: ${name}`)
  }
}

/** Find a block with its position. Starting from the selection, the idea
 * consists in bubbling up in the tree to find the first `<steerlab-block>`,
 * and computes its index in the same way.
 * Because of the nature of the document, there will probably be at most two
 * or three jumps from the selected node to its nearest parent. The position
 * computation finally runs in a linear time. */
export function findSteerlabBlockPosition(editor: tiptap.Editor) {
  if (editor.state.selection.to !== editor.state.selection.from) return
  let depth = editor.state.selection.$from.depth
  while (depth >= 0) {
    const block = editor.state.selection.$from.node(depth)
    if (['steerlabBlock', 'steerlabAnswer'].includes(block.type.name)) {
      const pos = editor.state.selection.$from.posAtIndex(0, depth)
      return { block, pos, depth }
    }
    depth--
  }
}

export const getJSON = (editor: tiptap.Editor) => editor.getJSON()
