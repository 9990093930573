/// <reference types="./styles.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px, vh } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function layout(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("grid"),
      $css.grid_template_columns("auto 1fr"),
      $css.grid_template_areas(toList(["navbar main"])),
      $css.height(vh(100)),
      $css.color($colors.light.onyx),
      $css.overflow_y("hidden"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function main(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.padding(px(40)),
      $css.gap(px(32)),
      $css.overflow_y("auto"),
      $css.height(vh(100)),
    ]),
  );
  return $h.main(_pipe, attrs, children);
}

export function page_header(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(24)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function page_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.sm())]));
  return $h.div(_pipe, attrs, children);
}

export function page_catchphrase(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function catch_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.md())]));
  return $h.div(_pipe, attrs, children);
}

export function catch_subtitle(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.color($colors.light.paragraph),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
