/// <reference types="./proposal.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $calendar from "../../gleam_time/gleam/time/calendar.mjs";
import * as $timestamp from "../../gleam_time/gleam/time/timestamp.mjs";
import * as $question from "../data/proposal_element/question.mjs";
import * as $qualification_matrix from "../data/qualification_matrix.mjs";
import * as $spreadsheet from "../data/spreadsheet.mjs";
import * as $status from "../data/status.mjs";
import * as $tag from "../data/tag.mjs";
import * as $language from "../data/translate/language.mjs";
import * as $iso639 from "../data/translate/language/iso639.mjs";
import * as $decrypt from "../decrypt.mjs";
import {
  Ok,
  Error,
  toList,
  prepend as listPrepend,
  CustomType as $CustomType,
  isEqual,
} from "../gleam.mjs";

export class QuestionnaireResponse extends $CustomType {
  constructor(name, column, kind) {
    super();
    this.name = name;
    this.column = column;
    this.kind = kind;
  }
}

export class OneTrust extends $CustomType {}

export class Portal extends $CustomType {
  constructor(tags, languages, qualification_matrix, default_language, notified, portal) {
    super();
    this.tags = tags;
    this.languages = languages;
    this.qualification_matrix = qualification_matrix;
    this.default_language = default_language;
    this.notified = notified;
    this.portal = portal;
  }
}

export class Document extends $CustomType {
  constructor(tags, languages, qualification_matrix, default_language, notified) {
    super();
    this.tags = tags;
    this.languages = languages;
    this.qualification_matrix = qualification_matrix;
    this.default_language = default_language;
    this.notified = notified;
  }
}

export class Questionnaire extends $CustomType {
  constructor(tags, languages, qualification_matrix, default_language, notified, sheets, responses, worksheets) {
    super();
    this.tags = tags;
    this.languages = languages;
    this.qualification_matrix = qualification_matrix;
    this.default_language = default_language;
    this.notified = notified;
    this.sheets = sheets;
    this.responses = responses;
    this.worksheets = worksheets;
  }
}

export class Narrative extends $CustomType {
  constructor(tags, languages, qualification_matrix, default_language, notified, requirements) {
    super();
    this.tags = tags;
    this.languages = languages;
    this.qualification_matrix = qualification_matrix;
    this.default_language = default_language;
    this.notified = notified;
    this.requirements = requirements;
  }
}

export class Display extends $CustomType {
  constructor(type_) {
    super();
    this.type_ = type_;
  }
}

export class Sheets extends $CustomType {}

export class Doc extends $CustomType {}

export class Proposal extends $CustomType {
  constructor(id, display, org_id, name, deadline, owner, client, collaborators, progress, status, metadata, created_at, kind, project_id, google_sheet_id, link_id) {
    super();
    this.id = id;
    this.display = display;
    this.org_id = org_id;
    this.name = name;
    this.deadline = deadline;
    this.owner = owner;
    this.client = client;
    this.collaborators = collaborators;
    this.progress = progress;
    this.status = status;
    this.metadata = metadata;
    this.created_at = created_at;
    this.kind = kind;
    this.project_id = project_id;
    this.google_sheet_id = google_sheet_id;
    this.link_id = link_id;
  }
}

export class Validated extends $CustomType {}

export class InReview extends $CustomType {}

export class AllStatus extends $CustomType {}

export class ProposalFilters extends $CustomType {
  constructor(status, owner) {
    super();
    this.status = status;
    this.owner = owner;
  }
}

export function encode_kind(kind) {
  if (kind instanceof Doc) {
    return "doc";
  } else {
    return "sheets";
  }
}

export function kind_decoder() {
  return $decode.then$(
    $decode.string,
    (kind) => {
      if (kind === "doc") {
        return $decode.success(new Doc());
      } else if (kind === "sheets") {
        return $decode.success(new Sheets());
      } else {
        return $decode.failure(new Doc(), "Kind");
      }
    },
  );
}

function encode_portal_type(portal_type) {
  {
    return $json.string("one_trust");
  }
}

export function encode_metadata(metadata) {
  if (metadata instanceof Portal) {
    return $json.object(
      toList([
        ["type", $json.string("portal")],
        ["portal", encode_portal_type(metadata.portal)],
        [
          "languages",
          $json.array(
            metadata.languages,
            (language) => { return $iso639.encode(language); },
          ),
        ],
        [
          "default_language",
          $json.nullable(
            metadata.default_language,
            (language) => { return $iso639.encode(language); },
          ),
        ],
        ["tags", $json.array(metadata.tags, $tag.encode)],
        [
          "qualification_matrix",
          $json.nullable(
            metadata.qualification_matrix,
            (q) => {
              return $json.array(
                q,
                (_use0) => {
                  let matrix = _use0[0];
                  let answer = _use0[1];
                  return $json.object(
                    toList([
                      ["item", $qualification_matrix.encode(matrix)],
                      ["state", $qualification_matrix.encode_answer(answer)],
                    ]),
                  );
                },
              );
            },
          ),
        ],
        ["notified", $json.bool(metadata.notified)],
      ]),
    );
  } else if (metadata instanceof Document) {
    return $json.object(
      toList([
        ["type", $json.string("document")],
        [
          "languages",
          $json.array(
            metadata.languages,
            (language) => { return $json.string($iso639.to_string(language)); },
          ),
        ],
        [
          "default_language",
          $json.nullable(
            metadata.default_language,
            (language) => { return $json.string($iso639.to_string(language)); },
          ),
        ],
        ["tags", $json.array(metadata.tags, $tag.encode)],
        [
          "qualification_matrix",
          $json.nullable(
            metadata.qualification_matrix,
            (q) => {
              return $json.array(
                q,
                (_use0) => {
                  let matrix = _use0[0];
                  let answer = _use0[1];
                  return $json.object(
                    toList([
                      ["item", $qualification_matrix.encode(matrix)],
                      ["state", $qualification_matrix.encode_answer(answer)],
                    ]),
                  );
                },
              );
            },
          ),
        ],
        ["notified", $json.bool(metadata.notified)],
      ]),
    );
  } else if (metadata instanceof Narrative) {
    return $json.object(
      toList([
        ["type", $json.string("narrative")],
        [
          "languages",
          $json.array(
            metadata.languages,
            (language) => { return $json.string($iso639.to_string(language)); },
          ),
        ],
        [
          "default_language",
          $json.nullable(
            metadata.default_language,
            (language) => { return $json.string($iso639.to_string(language)); },
          ),
        ],
        ["tags", $json.array(metadata.tags, $tag.encode)],
        [
          "qualification_matrix",
          $json.nullable(
            metadata.qualification_matrix,
            (q) => {
              return $json.array(
                q,
                (_use0) => {
                  let matrix = _use0[0];
                  let answer = _use0[1];
                  return $json.object(
                    toList([
                      ["item", $qualification_matrix.encode(matrix)],
                      ["state", $qualification_matrix.encode_answer(answer)],
                    ]),
                  );
                },
              );
            },
          ),
        ],
        ["notified", $json.bool(metadata.notified)],
        [
          "requirements",
          $json.nullable(
            metadata.requirements,
            (_capture) => { return $json.array(_capture, $json.string); },
          ),
        ],
      ]),
    );
  } else {
    return $json.object(
      toList([
        ["type", $json.string("questionnaire")],
        ["languages", $json.array(metadata.languages, $iso639.encode)],
        [
          "default_language",
          $json.nullable(metadata.default_language, $iso639.encode),
        ],
        ["tags", $json.array(metadata.tags, $tag.encode)],
        ["sheets", $json.array(metadata.sheets, $json.string)],
        [
          "responses",
          $json.array(
            metadata.responses,
            (response) => {
              return $json.object(
                toList([
                  ["name", $json.string(response.name)],
                  ["column", $json.int(response.column)],
                  ["kind", $question.encode_question_type(response.kind)],
                ]),
              );
            },
          ),
        ],
        [
          "worksheets",
          $json.array(
            metadata.worksheets,
            (w) => {
              return $json.object(
                toList([
                  ["name", $json.string(w.name)],
                  ["start_index", $json.int(w.start_index)],
                  [
                    "content",
                    $json.array(
                      w.content,
                      (r) => {
                        return $json.array(
                          r,
                          (c) => { return $spreadsheet.encode_cell(c); },
                        );
                      },
                    ),
                  ],
                  [
                    "columns_type",
                    $spreadsheet.encode_columns_type(w.columns_type),
                  ],
                  ["rows_type", $spreadsheet.encode_rows_type(w.rows_type)],
                ]),
              );
            },
          ),
        ],
        [
          "qualification_matrix",
          $json.nullable(
            metadata.qualification_matrix,
            (q) => {
              return $json.array(
                q,
                (_use0) => {
                  let matrix = _use0[0];
                  let answer = _use0[1];
                  return $json.object(
                    toList([
                      ["item", $qualification_matrix.encode(matrix)],
                      ["state", $qualification_matrix.encode_answer(answer)],
                    ]),
                  );
                },
              );
            },
          ),
        ],
        ["notified", $json.bool(metadata.notified)],
      ]),
    );
  }
}

function decode_questionnaire_response() {
  return $decode.field(
    "name",
    $decode.string,
    (name) => {
      return $decode.field(
        "column",
        $decode.int,
        (column) => {
          let question_type = $question.question_type_decoder();
          return $decode.optional_field(
            "kind",
            new $question.Long(),
            question_type,
            (kind) => {
              return $decode.success(
                new QuestionnaireResponse(name, column, kind),
              );
            },
          );
        },
      );
    },
  );
}

function decode_qualification_matrix() {
  return $decode.field(
    "item",
    $qualification_matrix.decoder(),
    (item) => {
      return $decode.field(
        "state",
        $qualification_matrix.answer_decoder(),
        (state) => { return $decode.success([item, state]); },
      );
    },
  );
}

function languages_decoder() {
  let _pipe = $decode.optional($decode.list($iso639.decoder()));
  return $decode.map(
    _pipe,
    (_capture) => { return $option.unwrap(_capture, toList([])); },
  );
}

function portal_type_decoder() {
  return $decode.then$(
    $decode.string,
    (portal) => {
      if (portal === "one_trust") {
        return $decode.success(new OneTrust());
      } else {
        return $decode.failure(new OneTrust(), "Type");
      }
    },
  );
}

function portal_decoder() {
  return $decode.field(
    "type",
    $decrypt.type_("portal"),
    (_) => {
      return $decode.field(
        "tags",
        $decode.list($tag.decoder()),
        (tags) => {
          return $decode.optional_field(
            "languages",
            toList([]),
            languages_decoder(),
            (languages) => {
              let qm = $decode.list(decode_qualification_matrix());
              return $decrypt.optional_field(
                "qualification_matrix",
                qm,
                (qualification_matrix) => {
                  return $decrypt.optional_field(
                    "default_language",
                    $iso639.decoder(),
                    (default$) => {
                      return $decrypt.optional_field(
                        "notified",
                        $decode.bool,
                        (notified) => {
                          let notified$1 = $option.unwrap(notified, false);
                          return $decode.field(
                            "portal",
                            portal_type_decoder(),
                            (portal) => {
                              return $decode.success(
                                new Portal(
                                  tags,
                                  languages,
                                  qualification_matrix,
                                  default$,
                                  notified$1,
                                  portal,
                                ),
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function document_decoder() {
  return $decode.field(
    "type",
    $decrypt.type_("document"),
    (_) => {
      return $decode.field(
        "tags",
        $decode.list($tag.decoder()),
        (tags) => {
          return $decode.optional_field(
            "languages",
            toList([]),
            languages_decoder(),
            (languages) => {
              let qm = $decode.list(decode_qualification_matrix());
              return $decrypt.optional_field(
                "qualification_matrix",
                qm,
                (qualification_matrix) => {
                  return $decrypt.optional_field(
                    "default_language",
                    $iso639.decoder(),
                    (default$) => {
                      return $decrypt.optional_field(
                        "notified",
                        $decode.bool,
                        (notified) => {
                          let notified$1 = $option.unwrap(notified, false);
                          return $decode.success(
                            new Document(
                              tags,
                              languages,
                              qualification_matrix,
                              default$,
                              notified$1,
                            ),
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function narrative_decoder() {
  return $decode.field(
    "type",
    $decrypt.type_("narrative"),
    (_) => {
      return $decode.field(
        "tags",
        $decode.list($tag.decoder()),
        (tags) => {
          return $decode.optional_field(
            "languages",
            toList([]),
            languages_decoder(),
            (languages) => {
              let qm = $decode.list(decode_qualification_matrix());
              return $decrypt.optional_field(
                "qualification_matrix",
                qm,
                (qualification_matrix) => {
                  return $decrypt.optional_field(
                    "default_language",
                    $iso639.decoder(),
                    (default$) => {
                      return $decrypt.optional_field(
                        "notified",
                        $decode.bool,
                        (notified) => {
                          let notified$1 = $option.unwrap(notified, false);
                          return $decrypt.optional_field(
                            "requirements",
                            $decode.list($decode.string),
                            (requirements) => {
                              return $decode.success(
                                new Narrative(
                                  tags,
                                  languages,
                                  qualification_matrix,
                                  default$,
                                  notified$1,
                                  requirements,
                                ),
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function cell_decoder() {
  return $decode.field(
    "value",
    $decode.optional($decode.string),
    (value) => {
      return $decode.field(
        "dropdown",
        $decode.list($decode.string),
        (dropdown) => {
          return $decode.success(new $spreadsheet.Cell(value, dropdown));
        },
      );
    },
  );
}

function string_to_cell_decoder() {
  let _pipe = $decode.optional($decode.string);
  return $decode.map(
    _pipe,
    (_capture) => { return new $spreadsheet.Cell(_capture, toList([])); },
  );
}

function decode_worksheet() {
  return $decode.field(
    "name",
    $decode.string,
    (name) => {
      return $decrypt.optional_field(
        "start_index",
        $decode.int,
        (start_index) => {
          let start_index$1 = $option.unwrap(start_index, 0);
          return $decode.field(
            "content",
            (() => {
              let cell = $decrypt.json(cell_decoder());
              let string_to_cell = string_to_cell_decoder();
              return $decode.list(
                $decode.list($decode.one_of(cell, toList([string_to_cell]))),
              );
            })(),
            (content) => {
              let columns_type = $spreadsheet.type_decoder(
                $spreadsheet.parse_column_type,
              );
              return $decrypt.optional_field(
                "columns_type",
                columns_type,
                (columns_type) => {
                  let columns_type$1 = $option.unwrap(
                    columns_type,
                    $dict.new$(),
                  );
                  let rows_type = $spreadsheet.type_decoder(
                    $spreadsheet.parse_row_type,
                  );
                  return $decrypt.optional_field(
                    "rows_type",
                    rows_type,
                    (rows_type) => {
                      let rows_type$1 = $option.unwrap(rows_type, $dict.new$());
                      return $decode.success(
                        new $spreadsheet.Worksheet(
                          name,
                          start_index$1,
                          content,
                          columns_type$1,
                          rows_type$1,
                        ),
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function questionnaire_decoder() {
  return $decode.field(
    "type",
    $decrypt.type_("questionnaire"),
    (_) => {
      return $decode.field(
        "tags",
        $decode.list($tag.decoder()),
        (tags) => {
          return $decode.optional_field(
            "languages",
            toList([]),
            languages_decoder(),
            (languages) => {
              let qm = $decode.list(decode_qualification_matrix());
              return $decrypt.optional_field(
                "qualification_matrix",
                qm,
                (qualification_matrix) => {
                  return $decrypt.optional_field(
                    "default_language",
                    $iso639.decoder(),
                    (default$) => {
                      return $decode.field(
                        "sheets",
                        $decode.list($decode.string),
                        (sheets) => {
                          let responses = $decode.list(
                            decode_questionnaire_response(),
                          );
                          return $decode.field(
                            "responses",
                            responses,
                            (responses) => {
                              return $decode.field(
                                "worksheets",
                                $decode.list(decode_worksheet()),
                                (worksheets) => {
                                  return $decrypt.optional_field(
                                    "notified",
                                    $decode.bool,
                                    (notified) => {
                                      let notified$1 = $option.unwrap(
                                        notified,
                                        false,
                                      );
                                      return $decode.success(
                                        new Questionnaire(
                                          tags,
                                          languages,
                                          qualification_matrix,
                                          default$,
                                          notified$1,
                                          sheets,
                                          responses,
                                          worksheets,
                                        ),
                                      );
                                    },
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function metadata_decoder() {
  return $decode.one_of(
    document_decoder(),
    toList([narrative_decoder(), questionnaire_decoder(), portal_decoder()]),
  );
}

export function display_decoder() {
  return $decode.field(
    "type",
    $decode.string,
    (type_) => { return $decode.success(new Display(type_)); },
  );
}

export function decoder() {
  return $decode.field(
    "id",
    $decrypt.uuid(),
    (id) => {
      return $decode.field(
        "display",
        $decrypt.json(display_decoder()),
        (display) => {
          return $decode.field(
            "org_id",
            $decode.string,
            (org_id) => {
              return $decode.field(
                "name",
                $decode.string,
                (name) => {
                  return $decode.field(
                    "deadline",
                    $decrypt.timestamp(),
                    (deadline) => {
                      return $decode.field(
                        "owner",
                        $decode.string,
                        (owner) => {
                          return $decode.field(
                            "client",
                            $decode.string,
                            (client) => {
                              return $decode.field(
                                "collaborators",
                                $decode.list($decode.string),
                                (collaborators) => {
                                  return $decode.field(
                                    "progress",
                                    $decode.int,
                                    (progress) => {
                                      return $decode.field(
                                        "status",
                                        $status.decoder(),
                                        (status) => {
                                          return $decode.field(
                                            "metadata",
                                            $decrypt.json(metadata_decoder()),
                                            (metadata) => {
                                              return $decode.field(
                                                "created_at",
                                                $decrypt.timestamp(),
                                                (created_at) => {
                                                  return $decode.field(
                                                    "kind",
                                                    kind_decoder(),
                                                    (kind) => {
                                                      return $decode.field(
                                                        "project_id",
                                                        (() => {
                                                          let _pipe = $decode.string;
                                                          let _pipe$1 = $decode.optional(
                                                            _pipe,
                                                          );
                                                          return $decode.map(
                                                            _pipe$1,
                                                            (_capture) => {
                                                              return $option.unwrap(
                                                                _capture,
                                                                "unknown",
                                                              );
                                                            },
                                                          );
                                                        })(),
                                                        (project_id) => {
                                                          let optional_id = $decode.optional(
                                                            $decode.string,
                                                          );
                                                          return $decode.field(
                                                            "google_sheet_id",
                                                            optional_id,
                                                            (google_sheet_id) => {
                                                              return $decode.field(
                                                                "link_id",
                                                                optional_id,
                                                                (link_id) => {
                                                                  return $decode.success(
                                                                    new Proposal(
                                                                      id,
                                                                      display,
                                                                      org_id,
                                                                      name,
                                                                      deadline,
                                                                      owner,
                                                                      client,
                                                                      collaborators,
                                                                      progress,
                                                                      status,
                                                                      metadata,
                                                                      created_at,
                                                                      kind,
                                                                      project_id,
                                                                      google_sheet_id,
                                                                      link_id,
                                                                    ),
                                                                  );
                                                                },
                                                              );
                                                            },
                                                          );
                                                        },
                                                      );
                                                    },
                                                  );
                                                },
                                              );
                                            },
                                          );
                                        },
                                      );
                                    },
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function encode_display(display) {
  return $json.object(toList([["type", $json.string(display.type_)]]));
}

export function encode(proposal) {
  return $json.object(
    toList([
      ["id", $json.string(proposal.id)],
      ["display", encode_display(proposal.display)],
      ["org_id", $json.string(proposal.org_id)],
      ["name", $json.string(proposal.name)],
      [
        "deadline",
        (() => {
          let _pipe = proposal.deadline;
          let _pipe$1 = $timestamp.to_rfc3339(_pipe, $calendar.utc_offset);
          return $json.string(_pipe$1);
        })(),
      ],
      ["owner", $json.string(proposal.owner)],
      ["client", $json.string(proposal.client)],
      ["collaborators", $json.array(proposal.collaborators, $json.string)],
      ["progress", $json.int(proposal.progress)],
      ["status", $status.encode(proposal.status)],
      ["metadata", encode_metadata(proposal.metadata)],
      [
        "created_at",
        (() => {
          let _pipe = proposal.created_at;
          let _pipe$1 = $timestamp.to_rfc3339(_pipe, $calendar.utc_offset);
          return $json.string(_pipe$1);
        })(),
      ],
      [
        "kind",
        (() => {
          let _pipe = proposal.kind;
          let _pipe$1 = encode_kind(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
      ["project_id", $json.string(proposal.project_id)],
      [
        "google_sheet_id",
        $json.nullable(proposal.google_sheet_id, $json.string),
      ],
      ["link_id", $json.nullable(proposal.link_id, $json.string)],
    ]),
  );
}

export function is_collaborator(proposal, user_id) {
  let all_collaborators = listPrepend(proposal.owner, proposal.collaborators);
  return $list.contains(all_collaborators, user_id);
}

export function count_qualification_matrix_answers(qualification_matrix) {
  return $list.fold(
    qualification_matrix,
    [0.0, 0.0, 0.0],
    (_use0, val) => {
      let yes = _use0[0];
      let no = _use0[1];
      let total = _use0[2];
      let total$1 = total + 1.0;
      let $ = val[1];
      if ($ instanceof $qualification_matrix.Yes) {
        return [yes + 1.0, no, total$1];
      } else if ($ instanceof $qualification_matrix.No) {
        return [yes, no + 1.0, total$1];
      } else {
        return [yes, no, total$1];
      }
    },
  );
}

export function initial_question_kinds(proposal, sheet) {
  let $ = proposal.metadata;
  if ($ instanceof Portal) {
    return $question.default_kinds(toList([]));
  } else if ($ instanceof Document) {
    return $question.default_kinds(toList([]));
  } else if ($ instanceof Narrative) {
    return $question.default_kinds(toList([]));
  } else {
    let responses = $.responses;
    let _pipe = $list.filter_map(
      responses,
      (response) => {
        let $1 = isEqual(new Some(response.name), sheet);
        if ($1) {
          return new Ok(response.kind);
        } else {
          return new Error(undefined);
        }
      },
    );
    let _pipe$1 = $list.unique(_pipe);
    return $question.default_kinds(_pipe$1);
  }
}

export function is_narrative(proposal) {
  let $ = proposal.metadata;
  if ($ instanceof Narrative) {
    return true;
  } else {
    return false;
  }
}
