/// <reference types="./pagination.d.mts" />
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../../gleam.mjs";

function page_selector_border() {
  return $css.class$(
    toList([
      $css.border_left("1px solid " + $colors.light.grey_400),
      $css.border_right("1px solid " + $colors.light.grey_400),
      $css.border_bottom("1px solid " + $colors.light.grey_400),
      $css.border_bottom_right_radius(px(8)),
    ]),
  );
}

export function page_selector(border, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.width(percent(100)),
      $css.align_items("center"),
      $css.justify_content("end"),
      $css.display("flex"),
      $css.flex_direction("row"),
      $css.padding(px(8)),
      $css.cursor("pointer"),
      (() => {
        if (!border) {
          return $css.none();
        } else {
          return $css.compose(page_selector_border());
        }
      })(),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function page_selector_button() {
  return $css.class$(
    toList([
      $css.font_family("inherit"),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.padding(px(6)),
      $css.border_right("1px solid " + $colors.light.grey_400),
      $css.border_top("1px solid " + $colors.light.grey_400),
      $css.border_bottom("1px solid " + $colors.light.grey_400),
      $css.height(percent(100)),
      $css.width(px(40)),
      $css.transition("color .3s, background .3s"),
      $css.hover(toList([$css.background($colors.light.grey_200)])),
      $css.disabled(
        toList([
          $css.color($colors.light.grey_200),
          $css.background($colors.light.white),
          $css.cursor("default"),
        ]),
      ),
    ]),
  );
}

export function page_selector_prev(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(page_selector_button()),
      $css.border_left("1px solid " + $colors.light.grey_400),
      $css.border_radius_("8px 0px 0px 8px"),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function page_selector_next(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(page_selector_button()),
      $css.border_radius_("0px 8px 8px 0px"),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function page_selector_page(selected, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(page_selector_button()),
      (() => {
        if (selected) {
          return $css.background($colors.light.grey_200);
        } else {
          return $css.background($colors.light.white);
        }
      })(),
      (() => {
        if (selected) {
          return $css.cursor("default");
        } else {
          return $css.cursor("pointer");
        }
      })(),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function page_selector_counter(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.font_family("inherit"),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.height(percent(100)),
      $css.padding(px(6)),
      $css.width(px(100)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
