import * as txt from '#editor/utils/text'

export type Attribute =
  | { kind: 'none' }
  | { kind: 'attr'; name: string; value: string }
  | { kind: 'handler'; name: string; value: (event: Event) => void }

export function attribute(name: string, value: string): Attribute {
  return { name, value, kind: 'attr' }
}

export const class_ = (value: string) => attribute('class', value)
export const id = (value: string) => attribute('id', value)
export const src = (value: string) => attribute('src', value)
export const contentEditable = (value: boolean) =>
  attribute('contentEditable', `${value}`)

export function style(styles: { [key: string]: string | number }): Attribute {
  const value = Object.entries(styles).map(convertToCss).join(';')
  return { name: 'style', value, kind: 'attr' }
}

function convertToCss([key, value]: [string, string | number]) {
  const key_ = txt.toKebabCase(key)
  if (typeof value === 'number') value = `${value}px`
  return `${key_}: ${value}`
}

export function none(): Attribute {
  return { kind: 'none' }
}
