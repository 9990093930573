/// <reference types="./project.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $calendar from "../../gleam_time/gleam/time/calendar.mjs";
import * as $timestamp from "../../gleam_time/gleam/time/timestamp.mjs";
import * as $proposal from "../data/proposal.mjs";
import * as $proposal_element from "../data/proposal_element.mjs";
import * as $status from "../data/status.mjs";
import * as $language from "../data/translate/language.mjs";
import * as $iso639 from "../data/translate/language/iso639.mjs";
import * as $decrypt from "../decrypt.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType } from "../gleam.mjs";

export class Metadata extends $CustomType {
  constructor(languages, default_language) {
    super();
    this.languages = languages;
    this.default_language = default_language;
  }
}

export class Project extends $CustomType {
  constructor(id, name, owner, org_id, deadline, client, collaborators, metadata, created_at, status) {
    super();
    this.id = id;
    this.name = name;
    this.owner = owner;
    this.org_id = org_id;
    this.deadline = deadline;
    this.client = client;
    this.collaborators = collaborators;
    this.metadata = metadata;
    this.created_at = created_at;
    this.status = status;
  }
}

export function toggle_collaborator(project, user_id) {
  let all_collaborators$1 = listPrepend(project.owner, project.collaborators);
  let is_collaborator = $list.contains(all_collaborators$1, user_id);
  let _record = project;
  return new Project(
    _record.id,
    _record.name,
    _record.owner,
    _record.org_id,
    _record.deadline,
    _record.client,
    (() => {
      if (is_collaborator) {
        return $list.filter(
          project.collaborators,
          (u) => { return u !== user_id; },
        );
      } else {
        return listPrepend(user_id, project.collaborators);
      }
    })(),
    _record.metadata,
    _record.created_at,
    _record.status,
  );
}

export function from_proposal(proposal) {
  return new Project(
    "",
    proposal.name,
    proposal.owner,
    proposal.org_id,
    proposal.deadline,
    proposal.client,
    proposal.collaborators,
    new Metadata(
      proposal.metadata.languages,
      proposal.metadata.default_language,
    ),
    proposal.created_at,
    proposal.status,
  );
}

export function get_languages(p, q) {
  return $bool.guard(
    $list.is_empty(p.metadata.languages),
    toList([]),
    () => {
      if (q instanceof $proposal_element.Block) {
        return p.metadata.languages;
      } else {
        let q$1 = q.question;
        let $ = q$1.metadata.language;
        if ($ instanceof $option.None) {
          return p.metadata.languages;
        } else {
          let l = $[0];
          let _pipe = p.metadata.languages;
          let _pipe$1 = $list.append(_pipe, toList([l]));
          return $list.unique(_pipe$1);
        }
      }
    },
  );
}

function languages_decoder() {
  let _pipe = $decode.optional($decode.list($iso639.decoder()));
  return $decode.map(
    _pipe,
    (_capture) => { return $option.unwrap(_capture, toList([])); },
  );
}

function metadata_decoder() {
  return $decode.optional_field(
    "languages",
    toList([]),
    languages_decoder(),
    (languages) => {
      return $decrypt.optional_field(
        "default_language",
        $iso639.decoder(),
        (default$) => {
          return $decode.success(new Metadata(languages, default$));
        },
      );
    },
  );
}

export function encode_metadata(metadata) {
  return $json.object(
    toList([
      ["languages", $json.array(metadata.languages, $iso639.encode)],
      [
        "default_language",
        $json.nullable(metadata.default_language, $iso639.encode),
      ],
    ]),
  );
}

export function decoder() {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "name",
        $decode.string,
        (name) => {
          return $decode.field(
            "owner",
            $decode.string,
            (owner) => {
              return $decode.field(
                "org_id",
                $decode.string,
                (org_id) => {
                  return $decode.field(
                    "deadline",
                    $decrypt.timestamp(),
                    (deadline) => {
                      return $decode.field(
                        "client",
                        $decode.string,
                        (client) => {
                          return $decode.field(
                            "collaborators",
                            $decode.list($decode.string),
                            (collaborators) => {
                              return $decode.field(
                                "metadata",
                                $decrypt.json(metadata_decoder()),
                                (metadata) => {
                                  return $decode.field(
                                    "created_at",
                                    $decrypt.timestamp(),
                                    (created_at) => {
                                      return $decode.field(
                                        "status",
                                        $status.decoder(),
                                        (status) => {
                                          return $decode.success(
                                            new Project(
                                              id,
                                              name,
                                              owner,
                                              org_id,
                                              deadline,
                                              client,
                                              collaborators,
                                              metadata,
                                              created_at,
                                              status,
                                            ),
                                          );
                                        },
                                      );
                                    },
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function encode(project) {
  return $json.object(
    toList([
      ["id", $json.string(project.id)],
      ["name", $json.string(project.name)],
      ["owner", $json.string(project.owner)],
      ["org_id", $json.string(project.org_id)],
      [
        "deadline",
        $json.string(
          (() => {
            let _pipe = project.deadline;
            return $timestamp.to_rfc3339(_pipe, $calendar.utc_offset);
          })(),
        ),
      ],
      ["client", $json.string(project.client)],
      ["collaborators", $json.array(project.collaborators, $json.string)],
      ["metadata", encode_metadata(project.metadata)],
      [
        "created_at",
        $json.string(
          (() => {
            let _pipe = project.created_at;
            return $timestamp.to_rfc3339(_pipe, $calendar.utc_offset);
          })(),
        ),
      ],
      ["status", $status.encode(project.status)],
    ]),
  );
}

export function all_collaborators(project) {
  return listPrepend(project.owner, project.collaborators);
}
