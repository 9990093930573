/// <reference types="./proposals.d.mts" />
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $button from "../../../../ds/ds/components/button.mjs";
import * as $icons from "../../../../ds/ds/icons.mjs";
import * as $shadows from "../../../../ds/ds/shadows.mjs";
import * as $display from "../../../../ds/ds/typography/display.mjs";
import * as $text from "../../../../ds/ds/typography/text.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $angle from "../../../../sketch/sketch/css/angle.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { percent, percent_, px } from "../../../../sketch/sketch/css/length.mjs";
import * as $transform from "../../../../sketch/sketch/css/transform.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList, prepend as listPrepend, divideFloat, divideInt } from "../../../gleam.mjs";

export function drag_and_drop_zone(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.padding(px(64)),
      $css.border_radius(px(8)),
      $css.border("1px dashed " + $colors.light.grey_400),
      $css.flex_direction("column"),
      $css.align_items("center"),
      $css.margin_("0 auto"),
      $css.color($colors.light.onyx),
      $css.gap(px(32)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.width(px(48)),
      $css.height(px(48)),
      $css.padding(px(12)),
      $css.background($colors.light.foam),
      $css.border_radius(px(24)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function drop_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

function layout_wrapper_() {
  return $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("row"),
      $css.margin_("0 auto"),
      $css.width(percent(100)),
      $css.max_width(px(600)),
      $css.height(percent(100)),
    ]),
  );
}

export function details_wrapper(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(layout_wrapper_())]));
  return $h.form(_pipe, attrs, children);
}

export function layout_wrapper(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(layout_wrapper_())]));
  return $h.div(_pipe, attrs, children);
}

export function drop_subtitle(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($text.md($text.regular))]));
  return $h.div(_pipe, attrs, children);
}

export function details_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

export function details_subtitle(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($text.md($text.regular))]));
  return $h.div(_pipe, attrs, children);
}

export function details_owner_label(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.color($colors.light.onyx),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function details_owner_picture(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.regular)),
      $css.color($colors.light.paragraph),
      $css.display("flex"),
      $css.gap(px(8)),
      $css.align_items("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function file_input(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($button.button_secondary())]));
  return $h.div(_pipe, attrs, children);
}

export function main_file_input(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($button.button_primary())]));
  return $h.div(_pipe, attrs, children);
}

export function explanatory_text(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($text.lg($text.medium))]));
  return $h.div(_pipe, attrs, children);
}

export function spreadsheet_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.overflow_y("hidden"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function spreadsheet(size, attrs, children) {
  let internal_size = size - 1;
  let _pipe = $css.class$(
    toList([
      $css.display("grid"),
      $css.grid_template_columns(
        ("min-content repeat(" + $int.to_string(internal_size)) + ", minmax(auto,max-content))",
      ),
      $css.border_right("1px solid " + $colors.light.grey_200),
      $css.overflow("auto"),
      $css.flex("1"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function spreadsheet_sheets(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.gap(px(4)),
      $css.border_top("1px solid " + $colors.light.grey_200),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function spreadsheet_sheet_cl() {
  return $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.border_bottom_left_radius(px(8)),
      $css.border_bottom_right_radius(px(8)),
      $css.border("0.5px solid " + $colors.light.grey_200),
      $css.border_top("none"),
      $css.padding_("4px 24px"),
      $css.cursor("pointer"),
      $css.hover(
        toList([
          $css.background($colors.light.grey_100),
          $css.color($colors.light.grey_800),
        ]),
      ),
    ]),
  );
}

export function spreadsheet_sheet(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(spreadsheet_sheet_cl()),
      $css.background($colors.light.white),
      $css.color($colors.light.grey_600),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function active_spreadsheet_sheet(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(spreadsheet_sheet_cl()),
      $css.background($colors.light.grey_50),
      $css.color($colors.light.onyx),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function cell_base() {
  return $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.border("0.5px solid " + $colors.light.grey_200),
      $css.padding(px(8)),
    ]),
  );
}

export function empty_cell(attrs) {
  let _pipe = $css.class$(toList([$css.compose(cell_base())]));
  return $h.div(_pipe, attrs, toList([]));
}

export function filled_cell(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(cell_base())]));
  return $h.div(_pipe, attrs, children);
}

function shared_header_style() {
  return $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.padding_("8px 12px"),
      $css.background($colors.light.grey_50),
      $css.border("1px solid " + $colors.light.grey_200),
      $css.text_align("center"),
      $css.color($colors.light.onyx),
    ]),
  );
}

export function spreadsheet_header(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(shared_header_style()),
      $css.position("sticky"),
      $css.border("0.5px solid " + $colors.light.grey_200),
      $css.border_top("1px solid " + $colors.light.grey_200),
      $css.border_bottom("1px solid " + $colors.light.grey_200),
      $css.top(px(0)),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(9)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function spreadsheet_header_line(index, max, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(shared_header_style()),
      $css.border("0.5px solid " + $colors.light.grey_200),
      $css.border_left("1px solid " + $colors.light.grey_200),
      $css.position("sticky"),
      $css.left(px(0)),
      $css.display("flex"),
      $css.gap(px(9)),
      $css.align_items("center"),
      $css.z_index(max - index),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function dropdown_wrapper(attrs, children, opacity) {
  let _pipe = $css.class$(toList([$css.opacity(opacity)]));
  return $h.div(_pipe, attrs, children);
}

export function show_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.sm())]));
  return $h.div(_pipe, attrs, children);
}

export function show_title_form(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.sm())]));
  return $h.form(_pipe, attrs, children);
}

export function back_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.sm())]));
  return $h.div(_pipe, attrs, children);
}

export function empty_sheet(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.color($colors.light.grey_600),
      $css.compose($text.xl($text.regular)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_card(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("grid"),
      $css.grid_template_columns("1fr auto"),
      $css.padding(px(24)),
      $css.gap(px(16)),
      $css.position("relative"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function checkbox(attrs, children) {
  let _pipe = $css.class$(toList([$css.grid_column("1"), $css.grid_row("1")]));
  return $h.div(_pipe, attrs, children);
}

export function question_number(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.grid_column("2"),
      $css.grid_row("1"),
      $css.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_list(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.flex_direction("column")]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_questions(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.flex("1"),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius_("8px 8px 0px 0px"),
      $css.overflow("auto"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_card_title(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("grid"),
      $css.align_items("center"),
      $css.gap(px(12)),
      $css.grid_template_columns("auto auto 1fr auto"),
      $css.grid_column("1"),
      $css.z_index(1),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_spreadsheet_sheets(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.gap(px(4)), $css.overflow_x("auto")]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_status(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.grid_row("1"), $css.grid_column("3"), $css.width(px(100))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_question(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.grid_column("1 / 4"),
      $css.grid_row("2"),
      $css.compose($text.md($text.medium)),
      $css.color($colors.light.onyx),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_response(is_hint, attrs, content) {
  let attrs$1 = listPrepend(
    $a.attribute("dangerous-unescaped-html", content),
    attrs,
  );
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.position("relative"),
      $css.color(
        (() => {
          if (!is_hint) {
            return $colors.light.paragraph;
          } else {
            return $colors.light.grey_600;
          }
        })(),
      ),
      $css.white_space("pre-wrap"),
      $css.border_radius(px(8)),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.background($colors.light.white),
      $css.padding(px(16)),
      $css.transition("border .3s, box-shadow .3s"),
      $css.focus(
        toList([
          $css.border("1px solid " + $colors.light.ai_400),
          $css.outline("none"),
          $css.box_shadow("0px 0px 0px 4px " + $colors.light.ai_100),
        ]),
      ),
    ]),
  );
  return $h.div(_pipe, attrs$1, toList([]));
}

export function question_owner(attrs, children) {
  let _pipe = $css.class$(toList([$css.grid_column("5"), $css.grid_row("2")]));
  return $h.div(_pipe, attrs, children);
}

export function question_actions(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.grid_column("2"),
      $css.grid_row("2"),
      $css.display("flex"),
      $css.align_items("center"),
      $css.position("relative"),
      $css.gap(px(16)),
      $css.align_self("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_attachments(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.grid_column("1"),
      $css.grid_row("3"),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("space-between"),
      $css.gap(px(8)),
      $css.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function attachment_close_base() {
  return $css.class$(
    toList([
      $css.opacity(0.0),
      $css.transition("all .3s"),
      $css.color($colors.light.grey_600),
    ]),
  );
}

export function attachment_pill(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(8)),
      $css.background($colors.light.grey_100),
      $css.padding(px(8)),
      $css.border_radius(px(8)),
      $css.max_width(px(200)),
      $css.overflow("hidden"),
      $css.white_space("nowrap"),
      $css.selector("> div", toList([$css.flex_shrink(0.0)])),
      $css.hover(
        toList([
          $css.child(attachment_close_base(), toList([$css.opacity(1.0)])),
        ]),
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function attachment_close(attrs, children) {
  let _pipe = attachment_close_base();
  return $h.button(_pipe, attrs, children);
}

export function attachment_text(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.overflow("hidden"),
      $css.text_overflow("ellipsis"),
      $css.flex("1"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_sources(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.grid_column("1"),
      $css.grid_row("4"),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("space-between"),
      $css.gap(px(8)),
      $css.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_sources_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.text_transform("uppercase")]));
  return $h.div(_pipe, attrs, children);
}

export function question_source_index(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.hover(
        toList([$css.color($colors.light.onyx), $css.cursor("default")]),
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_source_tooltip_title(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.color($colors.light.onyx),
      $css.compose($text.sm($text.medium)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_sources_infos(attrs, children) {
  return $h.div_(attrs, children);
}

export function confidence_score(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.text_transform("uppercase"),
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(12)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function confidence_score_highlight(value, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.color(
        (() => {
          if (value > 0.8) {
            return $colors.light.success_800;
          } else if (value > 0.35) {
            return $colors.light.orange_800;
          } else {
            return $colors.light.error_800;
          }
        })(),
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_source_tooltip(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.margin_top(px(12)),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.padding(px(12)),
      $css.gap(px(12)),
      $css.border_radius(px(8)),
      $css.background($colors.light.grey_50),
      $css.box_shadow($shadows.m()),
      $css.color($colors.light.grey_800),
      $css.max_height(px(350)),
      $css.overflow("auto"),
      $css.white_space("pre-wrap"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function details_collaborators_class() {
  return $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.display("flex"),
      $css.gap(px(8)),
      (() => {
        let _pipe = $css.padding(px(8));
        return $css.important(_pipe);
      })(),
      (() => {
        let _pipe = $css.background($colors.light.grey_100);
        return $css.important(_pipe);
      })(),
      (() => {
        let _pipe = $css.color($colors.light.paragraph);
        return $css.important(_pipe);
      })(),
      $css.border_radius(px(8)),
      $css.position("relative"),
      $css.align_items("center"),
      $css.hover(toList([$css.background($colors.light.grey_200)])),
      $css.disabled(
        toList([
          $css.background("transparent"),
          $css.cursor("default"),
          $css.padding(px(0)),
        ]),
      ),
    ]),
  );
}

export function details_collaborators_button(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(details_collaborators_class())]));
  return $h.button(_pipe, attrs, children);
}

export function details_collaborators_loading_button(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.display("flex"),
      $css.gap(px(8)),
      $css.padding(px(8)),
      $css.background($colors.light.grey_100),
      $css.color($colors.light.paragraph),
      $css.border_radius(px(8)),
      $css.position("relative"),
      $css.align_items("center"),
      $css.pointer_events("none"),
      $css.hover(toList([$css.background($colors.light.grey_200)])),
      $css.disabled(toList([$css.cursor("default"), $css.padding(px(0))])),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function details_collaborators_icon(child) {
  let _pipe = $css.class$(toList([$css.width(px(20)), $css.height(px(20))]));
  return $h.div(_pipe, toList([]), toList([child]));
}

export function add_attachment_input(attrs) {
  let _pipe = $css.class$(toList([$css.display("none")]));
  return $h.input(_pipe, attrs);
}

export function large_icon(child) {
  let _pipe = $css.class$(toList([$css.width(px(24)), $css.height(px(24))]));
  return $h.div(_pipe, toList([]), toList([child]));
}

export function button_icon(dark, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.position("relative"),
      $css.width(px(40)),
      $css.height(px(40)),
      $css.border_radius(px(20)),
      $css.background(
        (() => {
          if (!dark) {
            return $colors.light.grey_200;
          } else {
            return $colors.light.onyx;
          }
        })(),
      ),
      $css.color(
        (() => {
          if (!dark) {
            return $colors.light.grey_800;
          } else {
            return $colors.light.white;
          }
        })(),
      ),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.transition("all .2s"),
      $css.hover(
        toList([
          $css.background($colors.light.onyx),
          $css.color($colors.light.white),
        ]),
      ),
      $css.disabled(
        toList([
          $css.background($colors.light.grey_50),
          $css.color($colors.light.grey_200),
          $css.cursor("default"),
          $css.pointer_events("none"),
        ]),
      ),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function details_collaborators_icon_delete(attrs, child) {
  let _pipe = $css.class$(
    toList([$css.width(px(16)), $css.height(px(16)), $css.cursor("pointer")]),
  );
  return $h.button(_pipe, attrs, toList([child]));
}

export function change_popup() {
  return $css.class$(
    toList([
      $css.position("absolute"),
      $css.bottom(px(50)),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius(px(8)),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.background($colors.light.white),
      $css.cursor("default"),
      $css.overflow("auto"),
      $css.max_height(px(500)),
      $css.box_shadow($shadows.m()),
    ]),
  );
}

export function add_collaborators_popup_left(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose(change_popup()), $css.width(px(250)), $css.left(px(0))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function add_collaborators_popup_right(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(change_popup()),
      $css.width(px(250)),
      $css.right(px(0)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function add_collaborators_popup_title(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.color($colors.light.grey_800),
      $css.padding(px(10)),
      $css.position("sticky"),
      $css.top(px(0)),
      $css.background($colors.light.white),
      $css.border_bottom("1px solid " + $colors.light.grey_400),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function add_collaborator_button(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("space-between"),
      $css.gap(px(8)),
      $css.padding(px(10)),
      $css.cursor("pointer"),
      $css.hover(toList([$css.background($colors.light.grey_100)])),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function collaborators_list(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(8)),
      $css.property("flex-wrap", "wrap"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function details_owner_picture_deletion(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(6)),
      $css.padding(px(10)),
      $css.background($colors.light.grey_100),
      $css.border_radius(px(8)),
      $css.white_space("nowrap"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function base_ai_rewording_popup() {
  return $css.class$(
    toList([
      $css.compose($text.md($text.medium)),
      $css.position("absolute"),
      $css.display("flex"),
      $css.z_index(9000),
      $css.flex_direction("column"),
      $css.right(px(0)),
      $css.background($colors.light.white),
      $css.border_radius(px(8)),
      $css.box_shadow($shadows.m()),
      $css.border("1px solid " + $colors.light.grey_100),
      $css.overflow("hidden"),
    ]),
  );
}

export function ai_rewording_popup(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose(base_ai_rewording_popup()), $css.top(px(50))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function ai_rewording_title(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.color($colors.light.grey_600),
      $css.padding_("10px 14px"),
      $css.border_bottom("1px solid " + $colors.light.grey_200),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function ai_rewording_option(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.white_space("nowrap"),
      $css.display("flex"),
      $css.align_items("center"),
      $css.padding_("10px 14px"),
      $css.gap(px(8)),
      $css.padding_right(px(30)),
      $css.color($colors.light.paragraph),
      $css.hover(toList([$css.background($colors.light.grey_100)])),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function ai_text_input(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.flex("1"),
      $css.overflow("hidden"),
      $css.padding_("8px 12px"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function questions_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.flex("1"),
      $css.overflow("hidden"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function typing_dot(size) {
  return $css.class$(
    toList([
      $css.background($colors.light.grey_800),
      $css.width(px(size)),
      $css.height(px(size)),
      $css.border_radius(px(20)),
      $css.animation("pulse 2s infinite"),
    ]),
  );
}

function typing_dot_1(size) {
  let _pipe = $css.class$(toList([$css.compose(typing_dot(size))]));
  return $h.div(_pipe, toList([]), toList([]));
}

function typing_dot_2(size) {
  let _pipe = $css.class$(
    toList([$css.compose(typing_dot(size)), $css.animation_delay("600ms")]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

function typing_dot_3(size) {
  let _pipe = $css.class$(
    toList([$css.compose(typing_dot(size)), $css.animation_delay("1200ms")]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function typing_indicator(size) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.gap(px(divideInt(size, 2)))]),
  );
  return $h.div(
    _pipe,
    toList([]),
    toList([typing_dot_1(size), typing_dot_2(size), typing_dot_3(size)]),
  );
}

export function loading_answer_indicator(children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.medium)),
      $css.position("absolute"),
      $css.top(px(0)),
      $css.right(px(0)),
      $css.left(px(0)),
      $css.bottom(px(0)),
      $css.background("#fffb"),
      $css.border_radius(px(8)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.gap(px(16)),
      $css.property("backdrop-filter", "blur(6px)"),
    ]),
  );
  return $h.div(_pipe, toList([]), children);
}

function base_icon() {
  return $css.class$(
    toList([
      $css.width(px(48)),
      $css.height(px(48)),
      $css.border_radius(px(24)),
      $css.color($colors.light.grey_800),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.transition("all .3s"),
      $css.border("none"),
      $css.cursor("pointer"),
      $css.outline("none"),
      $css.hover(
        toList([
          $css.background($colors.light.grey_100),
          $css.color($colors.light.onyx),
        ]),
      ),
      $css.disabled(
        toList([
          $css.background($colors.light.grey_100),
          $css.color($colors.light.grey_400),
          $css.cursor("default"),
        ]),
      ),
    ]),
  );
}

export function trash_icon_button(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(base_icon())]));
  return $h.button(_pipe, attrs, children);
}

export function collapse_icon_button(opened, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(base_icon()),
      (() => {
        if (!opened) {
          return $css.transform(toList([$transform.rotate($angle.deg(180.0))]));
        } else {
          return $css.none();
        }
      })(),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function filters_button(active, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.compose(
        (() => {
          if (active) {
            return $button.button_primary();
          } else {
            return $button.button_secondary();
          }
        })(),
      ),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

function success_bar(success) {
  let _pipe = $css.class$(
    toList([
      $css.width(percent_(success)),
      $css.height(px(10)),
      $css.background($colors.light.success_800),
      $css.transition("width .3s"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

function failure_bar(failure) {
  let _pipe = $css.class$(
    toList([
      $css.width(percent_(failure)),
      $css.height(px(10)),
      $css.background($colors.light.error_800),
      $css.transition("width .3s"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function progress_bar(success, failure, total) {
  let success$1 = divideFloat((success * 100.0), total);
  let failure$1 = divideFloat((failure * 100.0), total);
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex("1"),
      $css.height(px(10)),
      $css.border_radius(px(10)),
      $css.overflow("hidden"),
      $css.align_items("center"),
      $css.justify_content("space-between"),
      $css.background($colors.light.grey_200),
    ]),
  );
  return $h.div(
    _pipe,
    toList([]),
    toList([success_bar(success$1), failure_bar(failure$1)]),
  );
}

export function success_text(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.color($colors.light.success_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function error_text(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.color($colors.light.error_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function padding_left(children) {
  let _pipe = $css.class$(toList([$css.padding_left(px(40))]));
  return $h.div(_pipe, toList([]), toList([children]));
}

export function file_card(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.width(px(130)),
      $css.height(px(130)),
      $css.background($colors.light.grey_100),
      $css.border_radius(px(15)),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.justify_content("space-between"),
      $css.text_align("right"),
      $css.padding(px(15)),
      $css.overflow("hidden"),
      $css.cursor(""),
      $css.word_break("break-all"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function close_button(attrs) {
  let _pipe = $css.class$(toList([$css.cursor("pointer")]));
  return $h.div(_pipe, attrs, toList([$icons.tiny($icons.cross())]));
}

export function no_questions_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex("1"),
      $css.justify_content("center"),
      $css.align_items("center"),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius_("8px 8px 8px 0px"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
