/// <reference types="./utils.d.mts" />
import * as $month from "../common_common/gleam/time/calendar/month.mjs";
import * as $file from "../ds/plinth/browser/file.mjs";
import * as $global from "../ds/plinth/javascript/global.mjs";
import * as $promise from "../gleam_javascript/gleam/javascript/promise.mjs";
import * as $bool from "../gleam_stdlib/gleam/bool.mjs";
import * as $decode from "../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $int from "../gleam_stdlib/gleam/int.mjs";
import * as $list from "../gleam_stdlib/gleam/list.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $string from "../gleam_stdlib/gleam/string.mjs";
import * as $uri from "../gleam_stdlib/gleam/uri.mjs";
import * as $calendar from "../gleam_time/gleam/time/calendar.mjs";
import * as $timestamp from "../gleam_time/gleam/time/timestamp.mjs";
import * as $a from "../lustre/lustre/attribute.mjs";
import * as $event from "../lustre/lustre/event.mjs";
import * as $mime_types from "../mime_types/mime_types.mjs";
import { Ok, toList } from "./gleam.mjs";
import {
  getDataFromEvent as get_data_from_event,
  allSettled as all_settled,
  sendBlockId as send_block_id,
  getProposalId as get_proposal_id,
  escapeHTML as escape_html,
} from "./utils.ffi.ts";

export {
  all_settled,
  escape_html,
  get_data_from_event,
  get_proposal_id,
  send_block_id,
};

export function to_path(segments) {
  let _pipe = segments;
  let _pipe$1 = $list.map(
    _pipe,
    (_capture) => { return $string.replace(_capture, "/", ""); },
  );
  let _pipe$2 = $list.map(_pipe$1, $uri.percent_encode);
  let _pipe$3 = $string.join(_pipe$2, "/");
  return ((_capture) => { return $string.append("/", _capture); })(_pipe$3);
}

export function time_to_normalized_date(time, separator) {
  let time$1 = $timestamp.to_calendar(time, $calendar.utc_offset);
  let month = $month.to_int(time$1[0].month);
  let year = $string.pad_start($int.to_string(time$1[0].year), 4, "0");
  let month$1 = $string.pad_start($int.to_string(month), 2, "0");
  let date = $string.pad_start($int.to_string(time$1[0].day), 2, "0");
  return $string.join(toList([year, month$1, date]), separator);
}

function append_format(formats, types, is_hidden) {
  return $bool.guard(
    is_hidden,
    formats,
    () => { return $list.append(formats, types); },
  );
}

export function restrict_proposal(word_disallowed, narrative_disallowed) {
  let excel = toList([
    ".xlsx",
    ".xls",
    $mime_types.to_string(new $mime_types.Xlsx()),
  ]);
  let docx = toList([
    ".doc",
    ".docx",
    $mime_types.to_string(new $mime_types.Docx()),
  ]);
  let pdf = toList([".pdf", $mime_types.to_string(new $mime_types.Pdf())]);
  let _pipe = excel;
  let _pipe$1 = append_format(_pipe, docx, word_disallowed);
  let _pipe$2 = append_format(_pipe$1, pdf, narrative_disallowed);
  let _pipe$3 = $string.join(_pipe$2, ",");
  return ((_capture) => { return $a.attribute("accept", _capture); })(_pipe$3);
}

export function on_files_change(msg) {
  return $event.on(
    "change",
    (event) => {
      let event$1 = (() => {
        let _pipe = $file.from_event(event);
        return $result.replace_error(_pipe, toList([]));
      })();
      return $result.then$(
        event$1,
        (files) => {
          let _pipe = msg(files);
          return $result.replace_error(_pipe, toList([]));
        },
      );
    },
  );
}

export function set_nil_timeout(timeout, fun) {
  $global.set_timeout(timeout, fun);
  return undefined;
}

export function stop_propagation_(msg) {
  return $event.on(
    "click",
    (event) => {
      $event.stop_propagation(event);
      return new Ok(msg);
    },
  );
}
