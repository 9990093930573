// Temporary way of handling feature flags, saving hard-coded org_ids
// In this future this will be implemented correctly with a DB
// Limit the use of feature flags to hide/show pages in general and avoid
// usage of feature flags within complex UX flows.

type Settings = { [key: string]: boolean | undefined }

const analytics = {
  // [Production]
  // Steerlab
  org_ebzcDfujZPCil4rK: true,

  // Innovasoft
  org_fyRnv8sLvOhfZfq2: true,

  // Other orgs without names
  org_QeXvSyXfeG3y1Yn7: true,
  org_q52dBNInozyMRc1v: true,
  org_DClOjkhFduUjkzVA: true,
  org_zuZsGhrTtK6N3yVe: true,
  org_aY4u9JpdP9I7bliK: true,

  // [Staging]
  // Steerlab
  org_egPFsl1S2zuF29yU: true,

  // [Development]
  // Steerlab
  org_fTzbIdVCeegZoUTi: true,

  // Additional temporary environments
  org_HvfPabJ9UzUvR3Yc: true,
} as Settings

const qualificationMatrix = {
  // [Production]
  // Steerlab
  org_ebzcDfujZPCil4rK: true,

  // Innovasoft
  org_fyRnv8sLvOhfZfq2: true,

  // Other orgs without names in prod
  org_QeXvSyXfeG3y1Yn7: true,
  org_q52dBNInozyMRc1v: true,
  org_DClOjkhFduUjkzVA: true,

  // [Staging]
  // Steerlab
  org_egPFsl1S2zuF29yU: true,

  // [Development]
  // Steerlab
  org_fTzbIdVCeegZoUTi: true,

  // Other orgs without names in dev
  org_HvfPabJ9UzUvR3Yc: true,
} as Settings

const translations = {
  // [Production]
  // Steerlab
  org_ebzcDfujZPCil4rK: true,

  // Innovasoft
  org_fyRnv8sLvOhfZfq2: true,

  // Other orgs without names in prod
  org_QeXvSyXfeG3y1Yn7: true,
  org_q52dBNInozyMRc1v: true,
  org_DClOjkhFduUjkzVA: true,
  org_zuZsGhrTtK6N3yVe: true,
  org_aY4u9JpdP9I7bliK: true,

  // [Staging]
  // Steerlab
  org_egPFsl1S2zuF29yU: true,

  // [Development]
  // Steerlab
  org_fTzbIdVCeegZoUTi: true,

  // Other orgs without names in dev
  org_HvfPabJ9UzUvR3Yc: true,
} as Settings

const insights = {
  // [Production]
  // Steerlab
  org_ebzcDfujZPCil4rK: true,

  // Innovasoft
  org_fyRnv8sLvOhfZfq2: true,

  // Other orgs without names in prod
  org_LLSlSIvrOHYlwGH4: true,

  // [Staging]
  // Steerlab
  org_egPFsl1S2zuF29yU: true,

  // [Development]
  // Steerlab
  org_fTzbIdVCeegZoUTi: true,
} as Settings

const wordSupport = {
  // [Production]
  // Steerlab
  org_ebzcDfujZPCil4rK: true,

  // Innovasoft
  org_fyRnv8sLvOhfZfq2: true,

  // Other orgs without names in prod
  org_DClOjkhFduUjkzVA: true,
  org_zuZsGhrTtK6N3yVe: true,
  org_aY4u9JpdP9I7bliK: true,

  // [Staging]
  // Steerlab
  org_egPFsl1S2zuF29yU: true,

  // [Development]
  // Steerlab
  org_fTzbIdVCeegZoUTi: true,
} as Settings

const narrativeSupport = {
  // [Production]
  // Steerlab
  org_ebzcDfujZPCil4rK: true,

  // Innovasoft
  org_fyRnv8sLvOhfZfq2: true,

  // [Staging]
  // Steerlab
  org_egPFsl1S2zuF29yU: true,

  // [Development]
  // Steerlab
  org_fTzbIdVCeegZoUTi: true,
} as Settings

export function authorized(flag: string, orgId: string) {
  switch (flag) {
    case 'analytics':
      return analytics[orgId] ?? false
    case 'qualification-matrix':
      return qualificationMatrix[orgId] ?? false
    case 'translations':
      return translations[orgId] ?? false
    case 'insights':
      return insights[orgId] ?? false
    case 'word-support':
      return wordSupport[orgId] ?? false
    case 'narrative-support':
      return narrativeSupport[orgId] ?? false
  }
}
