/// <reference types="./settings.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $tab_bar from "../../../ds/ds/components/tab_bar.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $uri from "../../../gleam_stdlib/gleam/uri.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $model from "../../data/model.mjs";
import * as $route from "../../data/route.mjs";
import * as $account from "../../frontend/view/settings/account.mjs";
import { toList, makeError, isEqual } from "../../gleam.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = data.route;
  if (!($1 instanceof $route.Settings)) {
    throw makeError(
      "let_assert",
      "frontend/view/settings",
      14,
      "view",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let route = $1[0];
  return $el.fragment(
    toList([
      $tab_bar.tab_bar(
        toList([]),
        toList([
          $tab_bar.tab(
            isEqual(route, new $route.Account()),
            (() => {
              let _pipe = $route.to_uri(
                new $route.Settings(new $route.Account()),
              );
              let _pipe$1 = $uri.to_string(_pipe);
              let _pipe$2 = $a.href(_pipe$1);
              return $list.wrap(_pipe$2);
            })(),
            toList([$h.text("Account")]),
          ),
        ]),
      ),
      (() => {
        {
          return $account.view(model);
        }
      })(),
    ]),
  );
}
