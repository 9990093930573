/// <reference types="./editor.d.mts" />
import * as $colors from "../../ds/ds/colors.mjs";
import * as $shadows from "../../ds/ds/shadows.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $lustre from "../../lustre/lustre.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $eff from "../../lustre/lustre/effect.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $css from "../../sketch/sketch/css.mjs";
import * as $length from "../../sketch/sketch/css/length.mjs";
import * as $element from "../../sketch_lustre_experimental/sketch/lustre/experimental/element.mjs";
import * as $menu from "../elements/editor/menu.mjs";
import * as $model from "../elements/editor/model.mjs";
import * as $events from "../elements/editor/tiptap/events.mjs";
import * as $update from "../elements/editor/update.mjs";
import * as $view from "../elements/editor/view.mjs";
import { Ok, Error, toList } from "../gleam.mjs";

export function content(content) {
  return $a.property("content", content);
}

export function editable(editable) {
  let editable$1 = $bool.to_string(editable);
  return $a.attribute("editable", editable$1);
}

export function on_event(msg) {
  let _pipe = $events.listen();
  return $a.map(_pipe, msg);
}

function decode_content(dyn) {
  let _pipe = $decode.run(dyn, $decode.string);
  let _pipe$1 = $result.map(
    _pipe,
    (var0) => { return new $update.UserUpdatedContent(var0); },
  );
  return $result.replace_error(_pipe$1, toList([]));
}

function decode_editable(dyn) {
  let $ = $decode.run(dyn, $decode.string);
  if ($.isOk() && $[0] === "True") {
    return new Ok(new $update.UserUpdatedEditable(true));
  } else if ($.isOk() && $[0] === "true") {
    return new Ok(new $update.UserUpdatedEditable(true));
  } else if ($.isOk() && $[0] === "False") {
    return new Ok(new $update.UserUpdatedEditable(false));
  } else if ($.isOk() && $[0] === "false") {
    return new Ok(new $update.UserUpdatedEditable(false));
  } else {
    return new Error(toList([]));
  }
}

const tag_name = "steerlab-editor";

export function view(attributes) {
  let _pipe = $css.class$(
    toList([
      $css.flex("1"),
      $css.overflow("auto"),
      $css.background($colors.light.grey_100),
      $css.border_radius($length.px(8)),
      $css.box_shadow($shadows.m()),
    ]),
  );
  return ((_capture) => {
    return $element.element(tag_name, _capture, attributes, toList([]));
  })(_pipe);
}

export function register() {
  return $result.try$(
    $menu.register(),
    (_) => {
      let init = (_) => { return [$model.init(), $eff.none()]; };
      let content$1 = ["content", decode_content];
      let editable$1 = ["editable", decode_editable];
      let _pipe = $dict.from_list(toList([content$1, editable$1]));
      let _pipe$1 = ((_capture) => {
        return $lustre.component(init, $update.update, $view.view, _capture);
      })(_pipe);
      return $lustre.register(_pipe$1, tag_name);
    },
  );
}
