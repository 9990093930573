/// <reference types="./content_library.d.mts" />
import { find_list_element_index, find_nth } from "../../../common/common/utils.mjs";
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $insight from "../../../common/data/insight.mjs";
import * as $spreadsheet from "../../../common/data/spreadsheet.mjs";
import {
  AnswerColumn,
  QuestionColumn,
  SectionRow,
  TagColumn,
  UndefinedRow,
  Worksheet,
} from "../../../common/data/spreadsheet.mjs";
import * as $tag from "../../../common/data/tag.mjs";
import * as $decrypt from "../../../common/decrypt.mjs";
import * as $file from "../../../ds/plinth/browser/file.mjs";
import * as $window from "../../../ds/plinth/browser/window.mjs";
import * as $form_data from "../../../gleam_fetch/gleam/fetch/form_data.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $promise from "../../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bit_array from "../../../gleam_stdlib/gleam/bit_array.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $uri from "../../../gleam_stdlib/gleam/uri.mjs";
import * as $calendar from "../../../gleam_time/gleam/time/calendar.mjs";
import * as $timestamp from "../../../gleam_time/gleam/time/timestamp.mjs";
import * as $bread from "../../../grille_pain/grille_pain/toast.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $mime_types from "../../../mime_types/mime_types.mjs";
import * as $content_library from "../../data/content_library.mjs";
import { ContentLibrary } from "../../data/content_library.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $dom from "../../frontend/effects/dom.mjs";
import * as $info from "../../frontend/error/info.mjs";
import * as $warn from "../../frontend/error/warn.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import {
  Ok,
  Error,
  toList,
  prepend as listPrepend,
  CustomType as $CustomType,
  isEqual,
} from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";

export class Unprocessable extends $CustomType {}

export class TooLarge extends $CustomType {}

export class Correct extends $CustomType {}

export function open_link(_, filename, link) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let prod = "https://api.steerlab.ai";
              let staging = "https://api.steerlab.dev";
              let dev = "http://localhost";
              let is_uploaded = $list.any(
                toList([prod, staging, dev]),
                (_capture) => { return $string.starts_with(link, _capture); },
              );
              let link$1 = (() => {
                if (!is_uploaded) {
                  return link;
                } else {
                  let _pipe = toList([
                    ["access_token", access_token],
                    ["filename", filename],
                  ]);
                  let _pipe$1 = $list.map(
                    _pipe,
                    (_capture) => {
                      return $pair.map_second(_capture, $uri.percent_encode);
                    },
                  );
                  let _pipe$2 = $uri.query_to_string(_pipe$1);
                  let _pipe$3 = $list.wrap(_pipe$2);
                  let _pipe$4 = ((_capture) => {
                    return $list.append(toList([link]), _capture);
                  })(_pipe$3);
                  return $string.join(_pipe$4, "?");
                }
              })();
              let $ = $window.open(link$1, "_blank", "");
              
              return $;
            },
          );
        },
      );
    },
  );
}

export function update_data_sources_owner(data, user_id) {
  return $middleware.require_access_token(
    (access_token) => {
      let data_sources = $dict.fold(
        data.content_library.selected,
        toList([]),
        (ids, key, value) => {
          return $bool.guard(
            !value,
            ids,
            () => { return listPrepend(key, ids); },
          );
        },
      );
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["content-library"]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(
                  toList([
                    ["owner_id", $json.string(user_id)],
                    ["data_source_ids", $json.array(data_sources, $json.string)],
                  ]),
                ),
              );
              return $ask.run(_pipe$3);
            },
          );
        },
      );
    },
  );
}

export function update_data_sources_expiration_date(data, expiration_date) {
  return $middleware.require_access_token(
    (access_token) => {
      let data_sources = $dict.fold(
        data.content_library.selected,
        toList([]),
        (ids, key, value) => {
          return $bool.guard(
            !value,
            ids,
            () => { return listPrepend(key, ids); },
          );
        },
      );
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["content-library"]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(
                  toList([
                    [
                      "expiration_date",
                      $json.string(
                        $timestamp.to_rfc3339(
                          expiration_date,
                          $calendar.utc_offset,
                        ),
                      ),
                    ],
                    ["data_source_ids", $json.array(data_sources, $json.string)],
                  ]),
                ),
              );
              return $ask.run(_pipe$3);
            },
          );
        },
      );
    },
  );
}

export function add_remove_data_sources_tag(data, tag, method) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let selected = (() => {
                let _pipe = $dict.to_list(data.content_library.selected);
                let _pipe$1 = $list.filter(_pipe, (a) => { return a[1]; });
                return $list.map(_pipe$1, (a) => { return a[0]; });
              })();
              let tags = $list.map(
                selected,
                (source_id) => {
                  return $json.object(
                    toList([
                      ["tag_id", $json.string(tag.id)],
                      ["source_id", $json.string(source_id)],
                    ]),
                  );
                },
              );
              let method$1 = (() => {
                if (method instanceof $msg.Add) {
                  return new $http.Post();
                } else {
                  return new $http.Delete();
                }
              })();
              let at = toList(["content-library", "tags"]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, method$1);
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.with$(_pipe$2, $json.preprocessed_array(tags));
              return $ask.run(_pipe$3);
            },
          );
        },
      );
    },
  );
}

export function update_data_sources_status(status, data_sources) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["content-library"]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(
                  toList([
                    [
                      "status",
                      $json.string($data_source.status_to_string(status)),
                    ],
                    ["data_source_ids", $json.array(data_sources, $json.string)],
                  ]),
                ),
              );
              return $ask.run(_pipe$3);
            },
          );
        },
      );
    },
  );
}

export function fetch_content_library(_) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let _pipe = $ask.to(
                new $ask.Heimdall(),
                toList(["content-library"]),
              );
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.expect(
                _pipe$1,
                $decode.list($data_source.decoder()),
              );
              let _pipe$3 = $ask.notify(
                _pipe$2,
                (d) => {
                  return dispatch(
                    new $msg.ContentLibrary(new $msg.ApiReturnedSources(d)),
                  );
                },
              );
              return $ask.run(_pipe$3);
            },
          );
        },
      );
    },
  );
}

export function upload_question_bank_question(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["question-bank"]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Post());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.preprocessed_array(
                  toList([
                    $json.object(
                      toList([
                        [
                          "question",
                          $json.string(data.content_library.new_qna.question),
                        ],
                        [
                          "answer",
                          $json.string(data.content_library.new_qna.answer),
                        ],
                        ["tag", $json.null$()],
                        [
                          "document_id",
                          (() => {
                            let _pipe$3 = data.content_library.new_qna.existing_document;
                            let _pipe$4 = $option.map(
                              _pipe$3,
                              (d) => { return d.document_id; },
                            );
                            return $json.nullable(_pipe$4, $json.string);
                          })(),
                        ],
                      ]),
                    ),
                  ]),
                ),
              );
              let _pipe$4 = $ask.notify(
                _pipe$3,
                (_) => {
                  return dispatch(
                    new $msg.ContentLibrary(
                      new $msg.ApiReturnedUploadingQuestion(false),
                    ),
                  );
                },
              );
              let _pipe$5 = $ask.error(
                _pipe$4,
                (_) => {
                  return dispatch(
                    new $msg.ContentLibrary(
                      new $msg.ApiRejectedUploadingQuestion(false),
                    ),
                  );
                },
              );
              return $ask.run(_pipe$5);
            },
          );
        },
      );
    },
  );
}

function get_cell_value(row, column_index) {
  let _pipe = $list.map(row, (x) => { return x.value; });
  let _pipe$1 = find_nth(_pipe, column_index, 0);
  return $result.map(
    _pipe$1,
    (_capture) => { return $option.unwrap(_capture, ""); },
  );
}

function extract_questions(spreadsheet, invalid_lines, columns_type) {
  return $list.flatten(
    $list.filter_map(
      spreadsheet.data,
      (_use0) => {
        let sheet = _use0.name;
        let cells = _use0.content;
        let types = (() => {
          let _pipe = $list.key_find(columns_type, sheet);
          let _pipe$1 = $result.map(_pipe, $dict.to_list);
          return $result.unwrap(_pipe$1, toList([]));
        })();
        let invalid = (() => {
          let _pipe = $list.key_find(invalid_lines, sheet);
          return $result.unwrap(_pipe, $dict.new$());
        })();
        let tag_index = find_list_element_index(types, new TagColumn());
        return $result.try$(
          find_list_element_index(types, new QuestionColumn()),
          (question_index) => {
            return $result.map(
              find_list_element_index(types, new AnswerColumn()),
              (answer_index) => {
                return $list.flatten(
                  $list.index_map(
                    cells,
                    (row, index) => {
                      let _pipe = (() => {
                        let $ = $dict.get(invalid, index);
                        if ($.isOk() && $[0] instanceof SectionRow) {
                          return new Error(undefined);
                        } else if ($.isOk() && $[0] instanceof UndefinedRow) {
                          return new Error(undefined);
                        } else {
                          let tag = (() => {
                            let _pipe = $result.try$(
                              tag_index,
                              (_capture) => {
                                return get_cell_value(row, _capture);
                              },
                            );
                            return $option.from_result(_pipe);
                          })();
                          return $result.try$(
                            get_cell_value(row, question_index),
                            (question) => {
                              return $result.map(
                                get_cell_value(row, answer_index),
                                (answer) => {
                                  return toList([[question, answer, tag]]);
                                },
                              );
                            },
                          );
                        }
                      })();
                      return $result.unwrap(_pipe, toList([]));
                    },
                  ),
                );
              },
            );
          },
        );
      },
    ),
  );
}

function do_ingest_question_bank(
  access_token,
  invalid_lines,
  columns_type,
  spreadsheet
) {
  return $effect.from(
    (dispatch) => {
      return $function.tap(
        undefined,
        (_) => {
          let q = extract_questions(spreadsheet, invalid_lines, columns_type);
          let at = toList(["question-bank"]);
          let _pipe = $ask.to(new $ask.Heimdall(), at);
          let _pipe$1 = $ask.via(_pipe, new $http.Post());
          let _pipe$2 = $ask.bearing(_pipe$1, access_token);
          let _pipe$3 = $ask.with$(
            _pipe$2,
            $json.array(
              q,
              (question) => {
                return $json.object(
                  toList([
                    ["question", $json.string(question[0])],
                    ["answer", $json.string(question[1])],
                    ["tag", $json.nullable(question[2], $json.string)],
                    ["document_id", $json.null$()],
                  ]),
                );
              },
            ),
          );
          let _pipe$4 = $ask.notify(
            _pipe$3,
            (_) => {
              return dispatch(
                new $msg.ContentLibrary(
                  new $msg.ApiReturnedUploadingQuestion(true),
                ),
              );
            },
          );
          let _pipe$5 = $ask.error(
            _pipe$4,
            (_) => {
              return dispatch(
                new $msg.ContentLibrary(
                  new $msg.ApiRejectedUploadingQuestion(true),
                ),
              );
            },
          );
          return $ask.run(_pipe$5);
        },
      );
    },
  );
}

export function ingest_question_bank(data) {
  let $ = data.content_library;
  let ingestion_spreadsheet = $.ingestion_spreadsheet;
  return $middleware.require_access_token(
    (access_token) => {
      let $1 = ingestion_spreadsheet.xlsx;
      if ($1 instanceof $option.None) {
        return $effect.none();
      } else {
        let spreadsheet = $1[0];
        return do_ingest_question_bank(
          access_token,
          ingestion_spreadsheet.invalid_lines,
          ingestion_spreadsheet.columns_type,
          spreadsheet,
        );
      }
    },
  );
}

export function upload_content_library_sources(_, sources) {
  return $middleware.require_access_token(
    (access_token) => {
      let sources$1 = (() => {
        let _pipe = $list.map(sources, $file.bytes);
        let _pipe$1 = $promise.await_list(_pipe);
        return $promise.map(
          _pipe$1,
          (_capture) => { return $list.zip(sources, _capture); },
        );
      })();
      return $middleware.from_promise(
        sources$1,
        (dispatch, files) => {
          return $dict.each(
            $list.group(
              files,
              (file) => {
                let source = file[0];
                let blob = file[1];
                let is_processable = (() => {
                  let _pipe = $file.mime(source);
                  let _pipe$1 = $mime_types.from_string(_pipe);
                  let _pipe$2 = $result.map(_pipe$1, $mime_types.is_processable);
                  return ((b) => { return isEqual(b, new Ok(true)); })(_pipe$2);
                })();
                return $bool.guard(
                  !is_processable,
                  new Unprocessable(),
                  () => {
                    let size_50_mb = 50 * 1024 * 1024;
                    let is_too_huge = $bit_array.byte_size(blob) >= size_50_mb;
                    return $bool.guard(
                      is_too_huge,
                      new TooLarge(),
                      () => { return new Correct(); },
                    );
                  },
                );
              },
            ),
            (status, sources) => {
              return $bool.guard(
                $list.is_empty(sources),
                undefined,
                () => {
                  if (status instanceof Unprocessable) {
                    return $warn.unsupported_files(
                      dispatch,
                      $list.length(sources),
                    );
                  } else if (status instanceof TooLarge) {
                    return $warn.max_file_size(dispatch, $list.length(sources));
                  } else {
                    let toast_id = (() => {
                      let _pipe = $bread.options();
                      let _pipe$1 = $bread.sticky(_pipe);
                      return $bread.custom(
                        _pipe$1,
                        "Uploading files. This may take a while…",
                      );
                    })();
                    let _pipe = $list.map(
                      sources,
                      (source) => {
                        let source$1 = source[0];
                        let blob = source[1];
                        let file_name = $file.name(source$1);
                        let mime_type = $file.mime(source$1);
                        let _pipe = $ask.to(
                          new $ask.Heimdall(),
                          toList(["content-library"]),
                        );
                        let _pipe$1 = $ask.via(_pipe, new $http.Post());
                        let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                        let _pipe$3 = $ask.data(
                          _pipe$2,
                          (() => {
                            let _pipe$3 = $form_data.new$();
                            let _pipe$4 = $form_data.append(
                              _pipe$3,
                              "name",
                              file_name,
                            );
                            let _pipe$5 = $form_data.append(
                              _pipe$4,
                              "mime-type",
                              mime_type,
                            );
                            return $form_data.append_bits(_pipe$5, "blob", blob);
                          })(),
                        );
                        return $ask.run(_pipe$3);
                      },
                    );
                    let _pipe$1 = $utils.all_settled(_pipe);
                    $promise.tap(
                      _pipe$1,
                      (results) => {
                        $bread.hide(toast_id);
                        let total = $list.length(results);
                        let are_errors = (() => {
                          let _pipe$2 = results;
                          let _pipe$3 = $list.filter_map(
                            _pipe$2,
                            (_capture) => {
                              return $decode.run(
                                _capture,
                                $decode.at(toList(["status"]), $decode.string),
                              );
                            },
                          );
                          let _pipe$4 = $list.filter(
                            _pipe$3,
                            (s) => { return s === "rejected"; },
                          );
                          return $list.length(_pipe$4);
                        })();
                        dispatch(
                          new $msg.ContentLibrary(
                            new $msg.ApiSuccessfullyProcessedFiles(
                              total - are_errors,
                            ),
                          ),
                        );
                        if (are_errors === 0) {
                          return $info.success_uploading(dispatch, total);
                        } else if (are_errors !== total) {
                          let count = are_errors;
                          $info.success_uploading(dispatch, total - count);
                          return $warn.upload_error(dispatch, count);
                        } else {
                          let count = are_errors;
                          return $warn.upload_error(dispatch, count);
                        }
                      },
                    )
                    return undefined;
                  }
                },
              );
            },
          );
        },
      );
    },
  );
}

export function fetch_status_stats(_) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let _pipe = $ask.to(
                new $ask.Heimdall(),
                toList(["content-library", "stats"]),
              );
              let _pipe$1 = $ask.expect(
                _pipe,
                (() => {
                  let stats_decoder = $data_source.content_library_stats_decoder();
                  return $decode.field(
                    "non_qna_stats",
                    stats_decoder,
                    (non_qna_stats) => {
                      return $decode.field(
                        "qna_stats",
                        stats_decoder,
                        (qna_stats) => {
                          return $decode.success([non_qna_stats, qna_stats]);
                        },
                      );
                    },
                  );
                })(),
              );
              let _pipe$2 = $ask.via(_pipe$1, new $http.Get());
              let _pipe$3 = $ask.bearing(_pipe$2, access_token);
              let _pipe$4 = $ask.notify(
                _pipe$3,
                (statuses) => {
                  return dispatch(
                    new $msg.ContentLibrary(
                      new $msg.ApiReturnedStatusStats(statuses),
                    ),
                  );
                },
              );
              return $ask.run(_pipe$4);
            },
          );
        },
      );
    },
  );
}

export function fetch_insights(_) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let _pipe = $ask.to(new $ask.Heimdall(), toList(["insights"]));
              let _pipe$1 = $ask.expect(
                _pipe,
                (() => {
                  let insights = $decode.list($decrypt.json($insight.decoder()));
                  return $decode.field(
                    "insights",
                    insights,
                    (insights) => {
                      let data_points = $decode.list(
                        $decrypt.json($data_point.decoder()),
                      );
                      return $decode.field(
                        "data_points",
                        data_points,
                        (data_points) => {
                          return $decode.success([insights, data_points]);
                        },
                      );
                    },
                  );
                })(),
              );
              let _pipe$2 = $ask.via(_pipe$1, new $http.Get());
              let _pipe$3 = $ask.bearing(_pipe$2, access_token);
              let _pipe$4 = $ask.notify(
                _pipe$3,
                (result) => {
                  let insights = result[0];
                  let data_points = result[1];
                  let data_points$1 = (() => {
                    let _pipe$4 = $list.map(
                      data_points,
                      (data_point) => { return [data_point.id, data_point]; },
                    );
                    return $dict.from_list(_pipe$4);
                  })();
                  return dispatch(
                    new $msg.ContentLibrary(
                      new $msg.ApiReturnedInsights(insights, data_points$1),
                    ),
                  );
                },
              );
              return $ask.run(_pipe$4);
            },
          );
        },
      );
    },
  );
}

export function resolve_insight(_, insight) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let _pipe = $ask.to(new $ask.Heimdall(), toList(["insight"]));
              let _pipe$1 = $ask.via(_pipe, new $http.Post());
              let _pipe$2 = $ask.with$(_pipe$1, $insight.encode(insight));
              let _pipe$3 = $ask.bearing(_pipe$2, access_token);
              let _pipe$4 = $ask.expect(_pipe$3, $insight.decoder());
              let _pipe$5 = $ask.notify(
                _pipe$4,
                (insight) => {
                  return dispatch(
                    new $msg.ContentLibrary(
                      new $msg.ApiResolvedInsight(insight),
                    ),
                  );
                },
              );
              return $ask.run(_pipe$5);
            },
          );
        },
      );
    },
  );
}

export function delete_data_point(_, data_point_id) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["content-library", "data_point", data_point_id]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Delete());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              return $ask.run(_pipe$2);
            },
          );
        },
      );
    },
  );
}

export function delete_data_source(_, data_source_id) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["content-library", "data_source", data_source_id]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Delete());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              return $ask.run(_pipe$2);
            },
          );
        },
      );
    },
  );
}

export function unsubscribe_previous_dom_click(data) {
  let _pipe = data.content_library.dropdown_unsubscriber;
  let _pipe$1 = $option.unwrap(_pipe, $effect.none());
  return $effect.map(
    _pipe$1,
    (var0) => { return new $msg.ContentLibrary(var0); },
  );
}

export function subscribe_dom_click(data) {
  return $effect.batch(
    toList([
      unsubscribe_previous_dom_click(data),
      (() => {
        let $ = $content_library.should_subscribe_dom_click(
          data.content_library,
        );
        if (!$) {
          return $effect.none();
        } else {
          let _pipe = $dom.on_click(
            new $msg.UserClickedOutsideInContentLibrary(),
          );
          let _pipe$1 = $dom.to_cancel(
            _pipe,
            (var0) => {
              return new $msg.ContentLibraryReturnedUnsubscriber(var0);
            },
          );
          let _pipe$2 = $dom.once(_pipe$1);
          let _pipe$3 = $dom.subscribe(_pipe$2);
          return $effect.map(
            _pipe$3,
            (var0) => { return new $msg.ContentLibrary(var0); },
          );
        }
      })(),
    ]),
  );
}

export function submit_question_bank_edit() {
  return $effect.from(
    (dispatch) => {
      let _pipe = new $msg.UserSubmittedQuestionBankEdit();
      let _pipe$1 = new $msg.ContentLibrary(_pipe);
      return dispatch(_pipe$1);
    },
  );
}
