/// <reference types="./scaffold.d.mts" />
import {
  activeElementId as active_element_id,
  blurActiveElement as blur_active_element,
  subscribeFeedScroll as subscribe_feed_scroll,
  scrollTo as scroll_to,
} from "./scaffold.ffi.ts";

export { active_element_id, blur_active_element, subscribe_feed_scroll };

export function scroll_to_question(question_id) {
  let node_id = "question-" + question_id;
  return scroll_to(node_id);
}

export function scroll_to_source(source_id) {
  let node_id = "row-id-" + source_id;
  return scroll_to(node_id);
}
