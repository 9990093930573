/// <reference types="./kind.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import { CustomType as $CustomType } from "../../gleam.mjs";

export class Question extends $CustomType {}

export class Section extends $CustomType {}

export class Subsection extends $CustomType {}

export class Other extends $CustomType {}

export class OutlineSection extends $CustomType {}

export class OutlineSubsection extends $CustomType {}

export class OutlineTitle extends $CustomType {}

export class OutlineContent extends $CustomType {}

export function decoder() {
  return $decode.then$(
    $decode.string,
    (content) => {
      if (content === "question") {
        return $decode.success(new Question());
      } else if (content === "question-answer") {
        return $decode.success(new Question());
      } else if (content === "question_answer") {
        return $decode.success(new Question());
      } else if (content === "section") {
        return $decode.success(new Section());
      } else if (content === "subsection") {
        return $decode.success(new Subsection());
      } else if (content === "outline_section") {
        return $decode.success(new OutlineSection());
      } else if (content === "outline_subsection") {
        return $decode.success(new OutlineSubsection());
      } else if (content === "outline_title") {
        return $decode.success(new OutlineTitle());
      } else if (content === "outline_content") {
        return $decode.success(new OutlineContent());
      } else if (content === "other") {
        return $decode.success(new Other());
      } else {
        return $decode.failure(new Question(), "Kind");
      }
    },
  );
}

export function to_string(kind) {
  if (kind instanceof Other) {
    return "other";
  } else if (kind instanceof Question) {
    return "question";
  } else if (kind instanceof Section) {
    return "section";
  } else if (kind instanceof Subsection) {
    return "subsection";
  } else if (kind instanceof OutlineSection) {
    return "outline_section";
  } else if (kind instanceof OutlineSubsection) {
    return "outline_subsection";
  } else if (kind instanceof OutlineTitle) {
    return "outline_title";
  } else {
    return "outline_content";
  }
}

export function encode(kind) {
  let _pipe = to_string(kind);
  return $json.string(_pipe);
}

export function question_decoder() {
  return $decode.then$(
    decoder(),
    (content) => {
      if (content instanceof Question) {
        return $decode.success(new Question());
      } else {
        return $decode.failure(new Question(), "Question");
      }
    },
  );
}
