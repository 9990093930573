/// <reference types="./computed.d.mts" />
import * as $source from "../../../common/data/data_source.mjs";
import * as $insight from "../../../common/data/insight.mjs";
import * as $project from "../../../common/data/project.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $question from "../../../common/data/proposal_element/question.mjs";
import * as $list_ from "../../../common_common/gleam/list/extra.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $insights from "../../data/model/computed/insights.mjs";
import { Insights } from "../../data/model/computed/insights.mjs";
import * as $projects from "../../data/model/computed/projects.mjs";
import { Projects } from "../../data/model/computed/projects.mjs";
import * as $questions from "../../data/model/computed/questions.mjs";
import * as $sources from "../../data/model/computed/sources.mjs";
import { Sources } from "../../data/model/computed/sources.mjs";
import * as $data from "../../data/model/data.mjs";
import { toList, CustomType as $CustomType, isEqual } from "../../gleam.mjs";

export class Computed extends $CustomType {
  constructor(displayed_questions, displayed_questions_count, sheet_questions, sheet_questions_count, selected_questions, selected_questions_count, non_qna_sources, non_qna_sources_count, projects, projects_count, qna_sources, qna_sources_count, insights, insights_count) {
    super();
    this.displayed_questions = displayed_questions;
    this.displayed_questions_count = displayed_questions_count;
    this.sheet_questions = sheet_questions;
    this.sheet_questions_count = sheet_questions_count;
    this.selected_questions = selected_questions;
    this.selected_questions_count = selected_questions_count;
    this.non_qna_sources = non_qna_sources;
    this.non_qna_sources_count = non_qna_sources_count;
    this.projects = projects;
    this.projects_count = projects_count;
    this.qna_sources = qna_sources;
    this.qna_sources_count = qna_sources_count;
    this.insights = insights;
    this.insights_count = insights_count;
  }
}

export function new$() {
  return new Computed(
    toList([]),
    0,
    toList([]),
    0,
    toList([]),
    0,
    new Sources(toList([]), toList([]), toList([]), toList([]), toList([])),
    new Sources(0, 0, 0, 0, 0),
    new Projects(toList([]), toList([]), toList([])),
    new Projects(0, 0, 0),
    new Sources(toList([]), toList([]), toList([]), toList([]), toList([])),
    new Sources(0, 0, 0, 0, 0),
    new Insights(toList([]), toList([]), toList([]), toList([])),
    new Insights(0, 0, 0, 0),
  );
}

export function projects(data, computed) {
  let projects$1 = $projects.compute(data);
  let projects_count = $projects.count(projects$1);
  let _record = computed;
  return new Computed(
    _record.displayed_questions,
    _record.displayed_questions_count,
    _record.sheet_questions,
    _record.sheet_questions_count,
    _record.selected_questions,
    _record.selected_questions_count,
    _record.non_qna_sources,
    _record.non_qna_sources_count,
    projects$1,
    projects_count,
    _record.qna_sources,
    _record.qna_sources_count,
    _record.insights,
    _record.insights_count,
  );
}

export function sources(_, computed, sources) {
  let qna_sources = sources[0];
  let non_qna_sources = sources[1];
  let qna_sources$1 = $sources.compute(qna_sources);
  let qna_sources_count = $sources.count(qna_sources$1);
  let non_qna_sources$1 = $sources.compute(non_qna_sources);
  let non_qna_sources_count = $sources.count(non_qna_sources$1);
  let _record = computed;
  return new Computed(
    _record.displayed_questions,
    _record.displayed_questions_count,
    _record.sheet_questions,
    _record.sheet_questions_count,
    _record.selected_questions,
    _record.selected_questions_count,
    non_qna_sources$1,
    non_qna_sources_count,
    _record.projects,
    _record.projects_count,
    qna_sources$1,
    qna_sources_count,
    _record.insights,
    _record.insights_count,
  );
}

export function insights(_, computed, insights) {
  let insights$1 = $insights.compute(insights);
  let insights_count = $insights.count(insights$1);
  let _record = computed;
  return new Computed(
    _record.displayed_questions,
    _record.displayed_questions_count,
    _record.sheet_questions,
    _record.sheet_questions_count,
    _record.selected_questions,
    _record.selected_questions_count,
    _record.non_qna_sources,
    _record.non_qna_sources_count,
    _record.projects,
    _record.projects_count,
    _record.qna_sources,
    _record.qna_sources_count,
    insights$1,
    insights_count,
  );
}

export function sources_selector(data) {
  let qna_sources = data.content_library.qna_sources;
  let non_qna_sources = data.content_library.non_qna_sources;
  return [qna_sources, non_qna_sources];
}

export function insights_selector(data) {
  return data.content_library.insights;
}

export function content_library_loading_selector(data) {
  return data.loading.content_library;
}

export function displayed_questions(data, computed) {
  let _pipe = $questions.displayed(data);
  let _pipe$1 = $result.map(
    _pipe,
    (q) => {
      let _record = computed;
      return new Computed(
        q,
        (() => {
          let _pipe$1 = q;
          return $list.length(_pipe$1);
        })(),
        _record.sheet_questions,
        _record.sheet_questions_count,
        _record.selected_questions,
        _record.selected_questions_count,
        _record.non_qna_sources,
        _record.non_qna_sources_count,
        _record.projects,
        _record.projects_count,
        _record.qna_sources,
        _record.qna_sources_count,
        _record.insights,
        _record.insights_count,
      );
    },
  );
  return $result.unwrap(_pipe$1, computed);
}

export function sheet_questions(data, computed) {
  let _pipe = $questions.sheet_question(data);
  let _pipe$1 = $result.map(
    _pipe,
    (q) => {
      let _record = computed;
      return new Computed(
        _record.displayed_questions,
        _record.displayed_questions_count,
        q,
        (() => {
          let _pipe$1 = q;
          return $list.length(_pipe$1);
        })(),
        _record.selected_questions,
        _record.selected_questions_count,
        _record.non_qna_sources,
        _record.non_qna_sources_count,
        _record.projects,
        _record.projects_count,
        _record.qna_sources,
        _record.qna_sources_count,
        _record.insights,
        _record.insights_count,
      );
    },
  );
  return $result.unwrap(_pipe$1, computed);
}

export function filtered_non_qna_sources(data, computed) {
  let sources$1 = computed.non_qna_sources;
  let $ = data.content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $source.NotVerified) {
    return sources$1.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Outdated) {
    return sources$1.outdated;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Pending) {
    return sources$1.pending;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Verified) {
    return sources$1.verified;
  } else {
    return data.content_library.non_qna_sources;
  }
}

export function filtered_qna_sources(data, computed) {
  let sources$1 = computed.qna_sources;
  let $ = data.content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $source.NotVerified) {
    return sources$1.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Outdated) {
    return sources$1.outdated;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Pending) {
    return sources$1.pending;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Verified) {
    return sources$1.verified;
  } else {
    return sources$1.all;
  }
}

export function filtered_qna_sources_in_page(data, computed, page) {
  let page_size = data.content_library.content_library_block_page_size;
  let _pipe = filtered_qna_sources(data, computed);
  return $list_.slice(_pipe, page_size * page, page_size * (page + 1));
}

export function filtered_non_qna_sources_page(data, computed, page) {
  let page_size = data.content_library.content_library_block_page_size;
  let _pipe = filtered_non_qna_sources(data, computed);
  return $list_.slice(_pipe, page_size * page, page_size * (page + 1));
}

export function filtered_non_qna_count(data, computed) {
  let sources$1 = computed.non_qna_sources_count;
  let $ = data.content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $source.NotVerified) {
    return sources$1.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Outdated) {
    return sources$1.outdated;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Pending) {
    return sources$1.pending;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Verified) {
    return sources$1.verified;
  } else {
    return sources$1.all;
  }
}

export function filtered_qna_count(data, computed) {
  let sources$1 = computed.qna_sources_count;
  let $ = data.content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $source.NotVerified) {
    return sources$1.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Outdated) {
    return sources$1.outdated;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Pending) {
    return sources$1.pending;
  } else if ($ instanceof $option.Some && $[0] instanceof $source.Verified) {
    return sources$1.verified;
  } else {
    return sources$1.all;
  }
}

export function filtered_insights(data, computed) {
  let insights$1 = computed.insights;
  let $ = data.content_library.insight_filter_selected;
  if ($ instanceof $insight.Conflict) {
    return insights$1.conflict;
  } else if ($ instanceof $insight.Duplicate) {
    return insights$1.duplicate;
  } else {
    return insights$1.irrelevant;
  }
}

function compute_display_count(data, page, filtered_count) {
  let page_size = data.content_library.content_library_block_page_size;
  let displayed_so_far = page_size * page;
  return $int.min(page_size, filtered_count - displayed_so_far);
}

export function all_non_qna_selected(data, computed, page) {
  let selected_count = $dict.size(data.content_library.selected);
  let filtered_non_qna_count$1 = filtered_non_qna_count(data, computed);
  let displayed_count = compute_display_count(
    data,
    page,
    filtered_non_qna_count$1,
  );
  return selected_count === displayed_count;
}

export function all_qna_selected(data, computed, page) {
  let selected_count = $dict.size(data.content_library.selected);
  let filtered_qna_count$1 = filtered_qna_count(data, computed);
  let displayed_count = compute_display_count(data, page, filtered_qna_count$1);
  return selected_count === displayed_count;
}

function compute_proposal_display_count(data, computed, page) {
  let page_size = data.proposal_block_page_size;
  let displayed_so_far = page_size * page;
  return $int.min(
    page_size,
    computed.displayed_questions_count - displayed_so_far,
  );
}

export function all_questions_selected(data, computed, page) {
  let selected_count = $dict.size(data.proposal_selected_questions);
  let displayed_count = compute_proposal_display_count(data, computed, page);
  return selected_count === displayed_count;
}

function filter_questions_by_sheet(computed, sheet) {
  let _pipe = computed.displayed_questions;
  return $list.filter(_pipe, (q) => { return isEqual(q[1].sheet, sheet); });
}

export function filtered_questions_in_page(data, computed, page, sheet) {
  let page_size = data.proposal_block_page_size;
  let _pipe = filter_questions_by_sheet(computed, sheet);
  let _pipe$1 = $list.map(_pipe, (t) => { return t[1]; });
  return $list_.slice(_pipe$1, page_size * page, page_size * (page + 1));
}
