/// <reference types="./navbar.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $pp from "../../../ds/ds/components/profile_picture.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $logo from "../../../ds/ds/logo.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $uri from "../../../gleam_stdlib/gleam/uri.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $route from "../../data/route.mjs";
import * as $feature_flags from "../../feature_flags.mjs";
import * as $common from "../../frontend/view/common.mjs";
import * as $s from "../../frontend/view/styles/navbar.mjs";
import { Ok, toList, CustomType as $CustomType } from "../../gleam.mjs";

class NavLink extends $CustomType {
  constructor(icon, title, link, permission, feature_flag) {
    super();
    this.icon = icon;
    this.title = title;
    this.link = link;
    this.permission = permission;
    this.feature_flag = feature_flag;
  }
}

function is_partly_permitted(item, mapper) {
  let _pipe = item;
  let _pipe$1 = $option.map(_pipe, mapper);
  return $option.unwrap(_pipe$1, true);
}

function is_permitted(item, model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $option.then$(
    data.user,
    (user) => {
      return $option.map(
        user.org_id,
        (org_id) => {
          return is_partly_permitted(
            item.permission,
            (_capture) => { return $list.contains(data.permissions, _capture); },
          ) && is_partly_permitted(
            item.feature_flag,
            ((_capture) => {
              return $feature_flags.authorized(org_id, _capture);
            }),
          );
        },
      );
    },
  );
}

function hide_collapsed(model, content) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = data.collapsed_navbar;
  if ($1) {
    return $element.none();
  } else {
    return content;
  }
}

function view_navlinks(model, colour, navlinks) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let route = data.route;
  return $list.map(
    navlinks,
    (item) => {
      let permitted = (() => {
        let _pipe = is_permitted(item, model);
        return $option.unwrap(_pipe, false);
      })();
      return $bool.guard(
        !permitted,
        $element.none(),
        () => {
          let href = (() => {
            let _pipe = item.link;
            let _pipe$1 = $list.first(_pipe);
            let _pipe$2 = $result.map(_pipe$1, $route.to_uri);
            let _pipe$3 = $result.map(_pipe$2, $uri.to_string);
            return $result.unwrap(_pipe$3, "#");
          })();
          let active = $list.contains(item.link, route);
          return $s.nav_link(
            colour,
            active,
            toList([$a.href(href)]),
            toList([
              $s.icon_wrapper(item.icon()),
              hide_collapsed(model, $html.text(item.title)),
            ]),
          );
        },
      );
    },
  );
}

const navlinks = /* @__PURE__ */ toList([
  /* @__PURE__ */ new NavLink(
    $icons.map,
    "Overview",
    /* @__PURE__ */ toList([/* @__PURE__ */ new $route.Overview()]),
    /* @__PURE__ */ new None(),
    /* @__PURE__ */ new None(),
  ),
  /* @__PURE__ */ new NavLink(
    $icons.book,
    "Content Library",
    /* @__PURE__ */ toList([
      /* @__PURE__ */ new $route.ContentLibrary(
        /* @__PURE__ */ new $route.DataSources(
          0,
          /* @__PURE__ */ new $option.None(),
        ),
      ),
      /* @__PURE__ */ new $route.ContentLibrary(
        /* @__PURE__ */ new $route.QuestionBank(
          0,
          /* @__PURE__ */ new $option.None(),
        ),
      ),
      /* @__PURE__ */ new $route.ContentLibrary(
        /* @__PURE__ */ new $route.NewQuestion(),
      ),
    ]),
    /* @__PURE__ */ new None(),
    /* @__PURE__ */ new None(),
  ),
  /* @__PURE__ */ new NavLink(
    $icons.form,
    "Proposals",
    /* @__PURE__ */ toList([
      /* @__PURE__ */ new $route.Projects(/* @__PURE__ */ new $route.OnGoing()),
    ]),
    /* @__PURE__ */ new None(),
    /* @__PURE__ */ new None(),
  ),
  /* @__PURE__ */ new NavLink(
    $icons.lightbulb_flash,
    "Co-pilot",
    /* @__PURE__ */ toList([
      /* @__PURE__ */ new $route.CoPilot(/* @__PURE__ */ new $route.AiSearch()),
    ]),
    /* @__PURE__ */ new None(),
    /* @__PURE__ */ new None(),
  ),
  /* @__PURE__ */ new NavLink(
    $icons.barchart,
    "Analytics",
    /* @__PURE__ */ toList([/* @__PURE__ */ new $route.Analytics()]),
    /* @__PURE__ */ new Some("admin:organization"),
    /* @__PURE__ */ new Some(/* @__PURE__ */ new $feature_flags.Analytics()),
  ),
]);

const supportlinks = /* @__PURE__ */ toList([
  /* @__PURE__ */ new NavLink(
    $icons.building,
    "Organization",
    /* @__PURE__ */ toList([
      /* @__PURE__ */ new $route.Organization(
        /* @__PURE__ */ new $route.Collaborators(),
      ),
    ]),
    /* @__PURE__ */ new Some("admin:organization"),
    /* @__PURE__ */ new None(),
  ),
  /* @__PURE__ */ new NavLink(
    $icons.wrench,
    "Settings",
    /* @__PURE__ */ toList([
      /* @__PURE__ */ new $route.Settings(/* @__PURE__ */ new $route.Account()),
    ]),
    /* @__PURE__ */ new None(),
    /* @__PURE__ */ new None(),
  ),
]);

export function navbar(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let user_id = (() => {
    let _pipe = $option.map(data.user, (u) => { return u.sub; });
    return $option.unwrap(_pipe, "");
  })();
  let user = $list.find(
    data.organization.users,
    (u) => { return u.id === user_id; },
  );
  return $s.navbar(
    data.collapsed_navbar,
    toList([]),
    toList([
      $s.clickable_logo(
        data.collapsed_navbar,
        "/",
        toList([
          $logo.light(),
          hide_collapsed(
            model,
            $s.logo_text(toList([]), toList([$logo.text()])),
          ),
        ]),
      ),
      $s.navbar_main(
        toList([]),
        toList([
          $s.name_notifications(
            data.collapsed_navbar,
            toList([]),
            toList([
              (() => {
                if (!user.isOk()) {
                  return $s.name(toList([]), toList([]));
                } else {
                  let name = user[0].name;
                  let picture = user[0].picture;
                  let initials = user[0].initials;
                  return $s.name(
                    toList([]),
                    toList([
                      $pp.from_user(picture, initials, new $pp.Large()),
                      hide_collapsed(model, $html.text(name)),
                    ]),
                  );
                }
              })(),
              $s.bell_button(
                data.feed_opened,
                toList([
                  $e.on_click(
                    new $msg.Notifications(
                      new $msg.UserToggledNotificationFeed(),
                    ),
                  ),
                ]),
                toList([
                  $s.icon_wrapper($icons.bell()),
                  (() => {
                    let $1 = data.notifications_unread;
                    if (!$1) {
                      return $element.none();
                    } else {
                      return $s.bell_active_dot();
                    }
                  })(),
                ]),
              ),
            ]),
          ),
          $common.separator(),
          $s.nav_links(
            toList([]),
            view_navlinks(model, $colors.light.onyx, navlinks),
          ),
        ]),
      ),
      $s.spacer(),
      $s.navbar_settings(
        toList([]),
        toList([
          $common.separator(),
          $s.nav_links(
            toList([]),
            view_navlinks(model, $colors.light.grey_800, supportlinks),
          ),
          $common.separator(),
          $s.nav_link(
            $colors.light.grey_800,
            false,
            toList([
              $e.on(
                "click",
                (event) => {
                  $e.stop_propagation(event);
                  return new Ok(new $msg.UserToggledCollapsedNavbar());
                },
              ),
            ]),
            (() => {
              let $1 = data.collapsed_navbar;
              if ($1) {
                return toList([$s.icon_wrapper($icons.arrow_right_line())]);
              } else {
                return toList([
                  $s.icon_wrapper($icons.arrow_left_line()),
                  $html.text("Collapse"),
                ]);
              }
            })(),
          ),
        ]),
      ),
    ]),
  );
}
