/// <reference types="./one_trust.d.mts" />
import * as $json from "../../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $order from "../../../../gleam_stdlib/gleam/order.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import { toList, CustomType as $CustomType, isEqual } from "../../../gleam.mjs";

export class Composed extends $CustomType {}

export class InputText extends $CustomType {}

export class Button extends $CustomType {}

export class MultiChoice extends $CustomType {}

export class RichText extends $CustomType {}

export class Unknown extends $CustomType {}

export class Section extends $CustomType {
  constructor(section_name, section_id, questions) {
    super();
    this.section_name = section_name;
    this.section_id = section_id;
    this.questions = questions;
  }
}

export class Question extends $CustomType {
  constructor(question_id, question_text, question_display_number, answer_type, section_id, section_index, answer) {
    super();
    this.question_id = question_id;
    this.question_text = question_text;
    this.question_display_number = question_display_number;
    this.answer_type = answer_type;
    this.section_id = section_id;
    this.section_index = section_index;
    this.answer = answer;
  }
}

export class ComposedAnswer extends $CustomType {
  constructor(sub_answers) {
    super();
    this.sub_answers = sub_answers;
  }
}

export class AnswerButton extends $CustomType {
  constructor(answer, button_options) {
    super();
    this.answer = answer;
    this.button_options = button_options;
  }
}

export class AnswerRichText extends $CustomType {
  constructor(answer) {
    super();
    this.answer = answer;
  }
}

export class AnswerInputText extends $CustomType {
  constructor(answer) {
    super();
    this.answer = answer;
  }
}

export class AnswerMultiChoices extends $CustomType {
  constructor(answer, multi_choice_options) {
    super();
    this.answer = answer;
    this.multi_choice_options = multi_choice_options;
  }
}

export class AnswerUnknown extends $CustomType {}

export function map_answer_to_type(a) {
  if (a instanceof AnswerButton) {
    return new Button();
  } else if (a instanceof AnswerInputText) {
    return new InputText();
  } else if (a instanceof AnswerMultiChoices) {
    return new MultiChoice();
  } else if (a instanceof AnswerRichText) {
    return new RichText();
  } else if (a instanceof AnswerUnknown) {
    return new Unknown();
  } else {
    return new Composed();
  }
}

export function answer_type_decoder() {
  return $decode.then$(
    $decode.string,
    (content) => {
      if (content === "input_text") {
        return $decode.success(new InputText());
      } else if (content === "button") {
        return $decode.success(new Button());
      } else if (content === "composed") {
        return $decode.success(new Composed());
      } else if (content === "multichoice") {
        return $decode.success(new MultiChoice());
      } else if (content === "rich_text") {
        return $decode.success(new RichText());
      } else if (content === "unknown") {
        return $decode.success(new Unknown());
      } else {
        return $decode.success(new Unknown());
      }
    },
  );
}

function maybe_read_type(a, next) {
  return $decode.optional_field(
    "type",
    new None(),
    $decode.then$(
      $decode.optional(answer_type_decoder()),
      (t) => {
        if (t instanceof Some && (isEqual(a, t[0]))) {
          let b = t[0];
          return $decode.success(t);
        } else if (t instanceof None) {
          return $decode.success(t);
        } else {
          return $decode.failure(new None(), "type");
        }
      },
    ),
    (content) => { return next(content); },
  );
}

function answer_button_decoder() {
  return maybe_read_type(
    new Button(),
    (_) => {
      return $decode.field(
        "answer",
        $decode.string,
        (answer) => {
          return $decode.field(
            "button_options",
            $decode.list($decode.string),
            (button_options) => {
              return $decode.success(new AnswerButton(answer, button_options));
            },
          );
        },
      );
    },
  );
}

function answer_multichoices_decoder() {
  return maybe_read_type(
    new MultiChoice(),
    (_) => {
      return $decode.field(
        "answer",
        $decode.list($decode.string),
        (answer) => {
          return $decode.field(
            "multi_choice_options",
            $decode.list($decode.string),
            (multi_choice_options) => {
              return $decode.success(
                new AnswerMultiChoices(answer, multi_choice_options),
              );
            },
          );
        },
      );
    },
  );
}

function answer_rich_text_decoder() {
  return maybe_read_type(
    new RichText(),
    (_) => {
      return $decode.field(
        "answer",
        $decode.string,
        (answer) => { return $decode.success(new AnswerRichText(answer)); },
      );
    },
  );
}

function answer_input_text_decoder() {
  return maybe_read_type(
    new InputText(),
    (_) => {
      return $decode.field(
        "answer",
        $decode.string,
        (answer) => { return $decode.success(new AnswerInputText(answer)); },
      );
    },
  );
}

function answer_composed_decoder() {
  return $decode.field(
    "sub_answers",
    $decode.list(
      $decode.one_of(
        answer_button_decoder(),
        toList([
          answer_input_text_decoder(),
          answer_rich_text_decoder(),
          answer_multichoices_decoder(),
        ]),
      ),
    ),
    (sub_answers) => { return $decode.success(new ComposedAnswer(sub_answers)); },
  );
}

function answer_decoder(answer_type) {
  if (answer_type instanceof Composed) {
    return answer_composed_decoder();
  } else if (answer_type instanceof InputText) {
    return answer_input_text_decoder();
  } else if (answer_type instanceof Button) {
    return answer_button_decoder();
  } else if (answer_type instanceof MultiChoice) {
    return answer_multichoices_decoder();
  } else if (answer_type instanceof RichText) {
    return answer_rich_text_decoder();
  } else {
    return $decode.success(new AnswerUnknown());
  }
}

export function question_decoder() {
  return $decode.then$(
    $decode.at(toList(["answer_type"]), answer_type_decoder()),
    (answer_type) => {
      return $decode.field(
        "question_id",
        $decode.string,
        (question_id) => {
          return $decode.field(
            "question_text",
            $decode.string,
            (question_text) => {
              return $decode.field(
                "question_display_number",
                $decode.string,
                (question_display_number) => {
                  return $decode.field(
                    "section_id",
                    $decode.string,
                    (section_id) => {
                      return $decode.field(
                        "section_index",
                        $decode.int,
                        (section_index) => {
                          return $decode.field(
                            "answer",
                            answer_decoder(answer_type),
                            (answer) => {
                              return $decode.success(
                                new Question(
                                  question_id,
                                  question_text,
                                  question_display_number,
                                  answer_type,
                                  section_id,
                                  section_index,
                                  answer,
                                ),
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function section_decoder() {
  return $decode.field(
    "section_name",
    $decode.string,
    (section_name) => {
      return $decode.field(
        "section_id",
        $decode.string,
        (section_id) => {
          return $decode.field(
            "questions",
            $decode.list(question_decoder()),
            (questions) => {
              return $decode.success(
                new Section(section_name, section_id, questions),
              );
            },
          );
        },
      );
    },
  );
}

export function answer_type_to_string(answer_type) {
  if (answer_type instanceof InputText) {
    return "input_text";
  } else if (answer_type instanceof Button) {
    return "button";
  } else if (answer_type instanceof Composed) {
    return "composed";
  } else if (answer_type instanceof MultiChoice) {
    return "multichoice";
  } else if (answer_type instanceof RichText) {
    return "rich_text";
  } else {
    return "unknown";
  }
}

export function encode_answer(answer) {
  if (answer instanceof AnswerUnknown) {
    return $json.object(toList([]));
  } else if (answer instanceof ComposedAnswer) {
    let sub_answers = answer.sub_answers;
    return $json.object(
      toList([["sub_answers", $json.array(sub_answers, encode_answer)]]),
    );
  } else if (answer instanceof AnswerButton) {
    return $json.object(
      toList([
        ["type", $json.string(answer_type_to_string(new Button()))],
        ["answer", $json.string(answer.answer)],
        ["button_options", $json.array(answer.button_options, $json.string)],
      ]),
    );
  } else if (answer instanceof AnswerRichText) {
    return $json.object(
      toList([
        ["type", $json.string(answer_type_to_string(new RichText()))],
        ["answer", $json.string(answer.answer)],
      ]),
    );
  } else if (answer instanceof AnswerInputText) {
    return $json.object(
      toList([
        ["type", $json.string(answer_type_to_string(new InputText()))],
        ["answer", $json.string(answer.answer)],
      ]),
    );
  } else {
    return $json.object(
      toList([
        ["type", $json.string(answer_type_to_string(new MultiChoice()))],
        ["answer", $json.array(answer.answer, $json.string)],
        [
          "multi_choice_options",
          $json.array(answer.multi_choice_options, $json.string),
        ],
      ]),
    );
  }
}

export function encode_question(question) {
  return $json.object(
    toList([
      ["question_id", $json.string(question.question_id)],
      ["question_text", $json.string(question.question_text)],
      [
        "question_display_number",
        $json.string(question.question_display_number),
      ],
      [
        "answer_type",
        $json.string(
          (() => {
            let _pipe = question.answer_type;
            return answer_type_to_string(_pipe);
          })(),
        ),
      ],
      ["section_id", $json.string(question.section_id)],
      ["section_index", $json.int(question.section_index)],
      ["answer", encode_answer(question.answer)],
    ]),
  );
}

export function encode_section(section) {
  return $json.object(
    toList([
      ["section_name", $json.string(section.section_name)],
      ["section_id", $json.string(section.section_id)],
      ["questions", $json.array(section.questions, encode_question)],
    ]),
  );
}

function get_indexes_from_display_number(dn) {
  let _pipe = dn;
  let _pipe$1 = $string.split(_pipe, ".");
  return $list.map(
    _pipe$1,
    (s) => {
      let _pipe$2 = $int.parse(s);
      return $result.unwrap(_pipe$2, 0);
    },
  );
}

export function sort_by_question_display_number(q1, q2) {
  let qdn1 = (() => {
    let _pipe = q1.question_display_number;
    return get_indexes_from_display_number(_pipe);
  })();
  let qdn2 = (() => {
    let _pipe = q2.question_display_number;
    return get_indexes_from_display_number(_pipe);
  })();
  if (qdn1.hasLength(2) && qdn2.hasLength(2) && (qdn1.head === qdn2.head)) {
    let s1 = qdn1.head;
    let q1$1 = qdn1.tail.head;
    let s2 = qdn2.head;
    let q2$1 = qdn2.tail.head;
    return $int.compare(q1$1, q2$1);
  } else if (qdn1.hasLength(2) && qdn2.hasLength(2)) {
    let s1 = qdn1.head;
    let s2 = qdn2.head;
    return $int.compare(s1, s2);
  } else {
    return new $order.Lt();
  }
}
