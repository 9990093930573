/// <reference types="./init.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $bright from "../../bright/bright.mjs";
import * as $sentry from "../../common/sentry.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $io from "../../gleam_stdlib/gleam/io.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $modem from "../../modem/modem.mjs";
import * as $vitools from "../../vitools/vitools.mjs";
import * as $model from "../data/model.mjs";
import * as $computed from "../data/model/computed.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $auth from "../frontend/auth.mjs";
import * as $scaffold from "../frontend/browser/scaffold.mjs";
import * as $effects from "../frontend/effects.mjs";
import * as $window from "../frontend/effects/window.mjs";
import * as $local_storage from "../frontend/window/local_storage.mjs";
import { toList } from "../gleam.mjs";

export function sentry() {
  let _pipe = $vitools.get_env("SENTRY_DSN");
  let _pipe$1 = $result.map_error(
    _pipe,
    (_) => { return $io.println("No VITE_SENTRY_DSN found"); },
  );
  return $result.map(_pipe$1, $sentry.init);
}

function route() {
  return $result.map(
    $modem.initial_uri(),
    (uri) => { return $route.from_uri(uri); },
  );
}

function initial_navbar_state() {
  let _pipe = $local_storage.read("collapsed-navbar");
  let _pipe$1 = $result.replace_error(_pipe, toList([]));
  let _pipe$2 = $result.try$(
    _pipe$1,
    (_capture) => { return $decode.run(_capture, $decode.bool); },
  );
  return $result.unwrap(_pipe$2, false);
}

export function flags() {
  return $result.map(
    route(),
    (initial_route) => {
      let auth0_client = $auth.client(new $auth.Authentication());
      let collapsed_navbar = initial_navbar_state();
      let project_id = $route.get_project_id(initial_route);
      return [initial_route, auth0_client, collapsed_navbar, project_id];
    },
  );
}

export function init(flags) {
  let route$1 = flags[0];
  let client = flags[1];
  let collapsed_navbar = flags[2];
  let project_id = flags[3];
  let data = $data.new$(client, route$1, collapsed_navbar, project_id);
  return $bright.start(
    $bright.init(data, $computed.new$()),
    (model) => {
      let _pipe = model;
      let _pipe$1 = $bright.schedule(
        _pipe,
        (_, _1) => {
          return $auth0.init(
            client,
            (var0) => { return new $msg.ApplicationChangedAuthState(var0); },
          );
        },
      );
      let _pipe$2 = $bright.schedule(
        _pipe$1,
        (_, _1) => {
          return $modem.init(
            (var0) => { return new $msg.ApplicationChangedRoute(var0); },
          );
        },
      );
      let _pipe$3 = $bright.schedule(
        _pipe$2,
        (d, _) => { return $model.save_access_token(d.access_token); },
      );
      let _pipe$4 = $bright.schedule(
        _pipe$3,
        (_, _1) => { return $window.update_page_title(route$1); },
      );
      let _pipe$5 = $bright.schedule(
        _pipe$4,
        (d, _) => { return $effects.on_route(d, new $route.NotFound()); },
      );
      let _pipe$6 = $bright.schedule(
        _pipe$5,
        (_, _1) => { return $effects.listen_popup_messages(); },
      );
      return $bright.schedule(
        _pipe$6,
        (_, _1) => {
          return $effect.from(
            (_) => { return $scaffold.subscribe_feed_scroll(); },
          );
        },
      );
    },
  );
}
