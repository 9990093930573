/// <reference types="./extra.d.mts" />
import * as $float from "../../../../gleam_stdlib/gleam/float.mjs";
import * as $duration from "../../../../gleam_time/gleam/time/duration.mjs";
import { CustomType as $CustomType, divideFloat } from "../../../gleam.mjs";

export class Minute extends $CustomType {}

export class Hour extends $CustomType {}

export class Day extends $CustomType {}

export class Month extends $CustomType {}

export class Year extends $CustomType {}

export function blur_to(duration, unit) {
  let duration$1 = $duration.to_seconds(duration);
  return $float.round(
    (() => {
      if (unit instanceof Day) {
        return divideFloat(duration$1, 86_400.0);
      } else if (unit instanceof Hour) {
        return divideFloat(duration$1, 3600.0);
      } else if (unit instanceof Minute) {
        return divideFloat(duration$1, 60.0);
      } else if (unit instanceof Month) {
        return divideFloat(duration$1, 2_629_800.0);
      } else {
        return divideFloat(duration$1, 31_557_600.0);
      }
    })(),
  );
}
