/// <reference types="./projects.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $route from "../../data/route.mjs";
import * as $add from "../../frontend/view/projects/add.mjs";
import * as $overview from "../../frontend/view/projects/overview.mjs";
import * as $show from "../../frontend/view/projects/show.mjs";
import * as $questionnaire from "../../frontend/view/projects/show/questionnaire.mjs";
import { makeError } from "../../gleam.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = data.route;
  if (!($1 instanceof $route.Projects)) {
    throw makeError(
      "let_assert",
      "frontend/view/projects",
      14,
      "view",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let route = $1[0];
  if (route instanceof $route.OnGoing) {
    return $overview.view(model);
  } else if (route instanceof $route.Previous) {
    return $overview.view(model);
  } else if (route instanceof $route.Proposals &&
  route.proposal instanceof $route.ShowProposal) {
    let id = route.proposal.id;
    let sheet = route.proposal.sheet;
    let tab = route.proposal.tab;
    let page = route.proposal.page;
    let _pipe = $option.unwrap(tab, new $route.Blocks());
    let _pipe$1 = ((_capture) => {
      return new $questionnaire.Props(sheet, page, _capture);
    })(_pipe);
    return ((_capture) => { return $show.view(model, id, _capture); })(_pipe$1);
  } else if (route instanceof $route.AddProposal) {
    let project_id = route.project_id;
    let loading = route.loading;
    let _pipe = $add.view(model, project_id, loading);
    return $el.map(_pipe, (var0) => { return new $msg.ProposalBuilder(var0); });
  } else {
    return $el.none();
  }
}
