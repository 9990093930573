/// <reference types="./collaborators.d.mts" />
import * as $bright from "../../../../bright/bright.mjs";
import * as $user from "../../../../common/data/user.mjs";
import * as $dropdown from "../../../../ds/ds/components/dropdown.mjs";
import * as $profile_picture from "../../../../ds/ds/components/profile_picture.mjs";
import * as $table from "../../../../ds/ds/components/table.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $model from "../../../data/model.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $common from "../../../frontend/view/common.mjs";
import * as $s from "../../../frontend/view/styles/organization.mjs";
import { toList } from "../../../gleam.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $el.fragment(
    toList([
      $s.members(
        toList([]),
        toList([
          $common.underlined_title(
            toList([]),
            toList([$el.text("Collaborators"), $common.separator()]),
          ),
          $table.table(
            toList([
              $table.headers(
                toList([
                  $table.header("User"),
                  $table.header("Email"),
                  $table.header("Role"),
                  $table.header("Function"),
                ]),
              ),
              $table.body(
                $list.map(
                  data.organization.users,
                  (user) => {
                    let displayed_user_roles = (() => {
                      let _pipe = user.roles;
                      let _pipe$1 = $list.map(_pipe, (r) => { return r.name; });
                      return $list.filter(
                        _pipe$1,
                        (role_name) => { return role_name !== "Super Admin"; },
                      );
                    })();
                    return $table.row(
                      toList([
                        $table.cell(
                          $common.name_small(
                            toList([]),
                            toList([
                              (() => {
                                let $1 = user.picture;
                                if ($1 instanceof $option.Some) {
                                  let picture = $1[0];
                                  return $profile_picture.picture(
                                    new $profile_picture.Medium(),
                                    toList([$a.src(picture)]),
                                  );
                                } else {
                                  return $profile_picture.initials(
                                    new $profile_picture.Medium(),
                                    toList([]),
                                    toList([$el.text(user.initials)]),
                                  );
                                }
                              })(),
                              $el.text(user.name),
                            ]),
                          ),
                        ),
                        $table.cell($el.text(user.email)),
                        $table.cell(
                          $el.text($string.join(displayed_user_roles, ", ")),
                        ),
                        $table.cell(
                          $dropdown.dropdown(
                            toList([
                              $dropdown.show_arrow(),
                              $dropdown.placeholder("No function selected"),
                              $dropdown.selected(user.function),
                              $dropdown.on_selected(
                                (_capture) => {
                                  return new $msg.UserSelectedFunction(
                                    user.id,
                                    _capture,
                                  );
                                },
                              ),
                            ]),
                            toList([
                              $dropdown.choice(
                                "Engineering",
                                new $user.Engineering(),
                              ),
                              $dropdown.choice("Product", new $user.Product()),
                              $dropdown.choice("Security", new $user.Security()),
                              $dropdown.choice("Sales", new $user.Sales()),
                              $dropdown.choice("Legal", new $user.Legal()),
                              $dropdown.choice(
                                "Marketing",
                                new $user.Marketing(),
                              ),
                              $dropdown.choice("Other", new $user.Other()),
                            ]),
                          ),
                        ),
                      ]),
                    );
                  },
                ),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
