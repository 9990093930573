import * as Sentry from '@sentry/browser'
import * as analytics from '@vercel/analytics'
import * as speed from '@vercel/speed-insights'
import * as barChart from '#frontend/elements/charts/elements/bar'
import * as pieChart from '#frontend/elements/charts/elements/pie'
import * as editor from '#frontend/elements/editor/tiptap/element/element'

export const elements = {
  /** Register custom elements in the application. */
  register() {
    pieChart.register()
    barChart.register()
    editor.register()
  },
}

export const vercel = {
  /** Inject Vercel Analytics & Speed Insights, iif production. */
  setup() {
    if (import.meta.env.PROD) {
      analytics.inject({ mode: 'production' })
      speed.injectSpeedInsights()
    }
  },
}

export const serviceWorker = {
  worker: null as ServiceWorkerRegistration | null,
  async setup() {
    try {
      const url = '/service-worker.js'
      const options = { type: 'module' } as const
      this.worker = await navigator.serviceWorker.register(url, options)
      console.log('Service worker registered')
      await this.worker.update()
      console.log('Service worker updated')
      this.onMessage()
    } catch (error) {
      console.error('Service worker not registered')
      if (Sentry.isInitialized()) {
        Sentry.captureException(error)
      }
    }
  },
  onMessage() {
    if (!this.worker) return
    this.worker.addEventListener('message', event => {
      console.log('Message received from Service Worker')
      console.log((event as any).data)
    })
  },
}
