/// <reference types="./local_storage.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import { read, write as write_local_storage } from "./local_storage.ffi.ts";

export { read };

export function write(content, key) {
  let _pipe = $json.to_string(content);
  return ((_capture) => { return write_local_storage(key, _capture); })(_pipe);
}
