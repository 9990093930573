/// <reference types="./proposal_element.d.mts" />
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $kind from "../data/docx/kind.mjs";
import * as $block from "../data/proposal_element/block.mjs";
import * as $one_trust from "../data/proposal_element/portal/one_trust.mjs";
import * as $question from "../data/proposal_element/question.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class Question extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class Block extends $CustomType {
  constructor(block) {
    super();
    this.block = block;
  }
}

export function encode(el) {
  if (el instanceof Question) {
    let question$1 = el.question;
    return $question.encode(question$1);
  } else {
    let block$1 = el.block;
    return $block.encode(block$1);
  }
}

export function question(el) {
  if (el instanceof Question) {
    let question$1 = el.question;
    return new Ok(question$1);
  } else {
    return new Error(undefined);
  }
}

export function block(el) {
  if (el instanceof Block) {
    let element = el.block;
    return new Ok(element);
  } else {
    return new Error(undefined);
  }
}

export function decoder() {
  let question$1 = (() => {
    let _pipe = $question.decoder();
    return $decode.map(_pipe, (var0) => { return new Question(var0); });
  })();
  let block$1 = (() => {
    let _pipe = $block.decoder();
    return $decode.map(_pipe, (var0) => { return new Block(var0); });
  })();
  return $decode.one_of(question$1, toList([block$1]));
}

export function id(el) {
  if (el instanceof Block) {
    let e = el.block;
    return e.id;
  } else {
    let q = el.question;
    return q.id;
  }
}

export function proposal_id(el) {
  if (el instanceof Block) {
    let e = el.block;
    return e.proposal_id;
  } else {
    let q = el.question;
    return q.proposal_id;
  }
}

export function owner(el) {
  if (el instanceof Block) {
    let e = el.block;
    return e.owner;
  } else {
    let q = el.question;
    return q.owner;
  }
}

export function doc_id_order(el) {
  if (el instanceof Block) {
    let e = el.block;
    return e.doc_id_order;
  } else {
    let q = el.question;
    return q.doc_id_order;
  }
}

export function qa_order(el) {
  if (el instanceof Block) {
    let e = el.block;
    return e.qa_order;
  } else {
    let q = el.question;
    return q.qa_order;
  }
}

function map_attribute(value, data_key) {
  let _pipe = value;
  let _pipe$1 = $option.map(
    _pipe,
    (_capture) => { return $a.attribute(data_key, _capture); },
  );
  return $option.lazy_unwrap(_pipe$1, $a.none);
}

export function to_editor(el, profile_picture, initials) {
  let order = $int.to_string(doc_id_order(el));
  let index = $a.attribute("data-index", order);
  let id$1 = $a.attribute("data-id", id(el));
  let _pipe = (() => {
    if (el instanceof Block) {
      let e = el.block;
      let content = $a.attribute("dangerous-unescaped-html", e.content);
      let kind = $a.attribute("data-kind", $kind.to_string(e.element_type));
      return $el.element(
        "steerlab-block",
        toList([index, id$1, content, kind]),
        toList([]),
      );
    } else {
      let q = el.question;
      let content = el.question.content;
      let answer = el.question.answer;
      let long = answer.long;
      let custom = answer.custom;
      let answer$1 = (() => {
        let $ = $option.or(custom, long);
        if ($ instanceof $option.Some && $[0] === "") {
          return "<p></p>";
        } else if ($ instanceof $option.Some) {
          let value = $[0];
          return value;
        } else {
          return "<p></p>";
        }
      })();
      let status = $a.attribute(
        "data-status",
        (() => {
          let $ = q.validated;
          if ($) {
            return "complete";
          } else {
            return "in-review";
          }
        })(),
      );
      let picture = map_attribute(profile_picture, "data-picture");
      let initials$1 = map_attribute(initials, "data-initials");
      let content$1 = $a.attribute("dangerous-unescaped-html", content);
      let answer$2 = $a.attribute("dangerous-unescaped-html", answer$1);
      return $el.element(
        "steerlab-question",
        toList([index, id$1]),
        toList([
          (() => {
            let _pipe = toList([id$1, content$1, status, picture, initials$1]);
            return ((_capture) => {
              return $el.element("steerlab-subject", _capture, toList([]));
            })(_pipe);
          })(),
          $el.element("steerlab-answer", toList([answer$2, id$1]), toList([])),
        ]),
      );
    }
  })();
  return $el.to_string(_pipe);
}

function get_multi_choices(answer) {
  let _pipe = answer.choice;
  let _pipe$1 = $option.or(_pipe, answer.yes_no);
  return $option.unwrap(_pipe$1, "");
}

function get_text(answer) {
  let _pipe = answer.long;
  let _pipe$1 = $option.or(_pipe, answer.custom);
  return $option.unwrap(_pipe$1, "");
}

function map_answer(question) {
  return (answer) => {
    if (answer instanceof $one_trust.AnswerButton) {
      let bo = answer.button_options;
      return new $one_trust.AnswerButton(get_multi_choices(question.answer), bo);
    } else if (answer instanceof $one_trust.AnswerMultiChoices) {
      let mc = answer.multi_choice_options;
      return new $one_trust.AnswerMultiChoices(
        toList([get_multi_choices(question.answer)]),
        mc,
      );
    } else if (answer instanceof $one_trust.AnswerInputText) {
      return new $one_trust.AnswerInputText(get_text(question.answer));
    } else if (answer instanceof $one_trust.AnswerRichText) {
      return new $one_trust.AnswerRichText(get_text(question.answer));
    } else if (answer instanceof $one_trust.AnswerUnknown) {
      return new $one_trust.AnswerUnknown();
    } else {
      let l = answer.sub_answers;
      return new $one_trust.ComposedAnswer(
        (() => {
          let _pipe = l;
          return $list.map(_pipe, map_answer(question));
        })(),
      );
    }
  };
}

function insert_answer(question, one_trust_question) {
  let $ = question.metadata;
  if ($ instanceof $question.SheetQuestion) {
    return new Error("Wrong metadata type: SheetQuestion");
  } else {
    let answer = map_answer(question)(one_trust_question.answer);
    return new Ok(
      (() => {
        let _record = one_trust_question;
        return new $one_trust.Question(
          _record.question_id,
          _record.question_text,
          _record.question_display_number,
          _record.answer_type,
          _record.section_id,
          _record.section_index,
          answer,
        );
      })(),
    );
  }
}

export function question_to_one_trust(question) {
  let $ = question.metadata;
  if ($ instanceof $question.PortalQuestion) {
    let one_trust_question = $.question;
    return insert_answer(question, one_trust_question);
  } else {
    return new Error("Wrong metadata type: SheetQuestion");
  }
}

export function element_to_one_trust(element) {
  if (element instanceof Question) {
    let question$1 = element.question;
    return question_to_one_trust(question$1);
  } else {
    return new Error("Wrong element type: Block");
  }
}
