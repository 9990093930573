/// <reference types="./user.d.mts" />
import * as $endpoints from "../../../app_config/app_config/endpoints.mjs";
import * as $websocket from "../../../app_config/app_config/websocket.mjs";
import * as $notification from "../../../common/data/notification.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $uri from "../../../gleam_stdlib/gleam/uri.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import { Data } from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { toList, makeError } from "../../gleam.mjs";

export function fetch_notifications(_) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["notifications"]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.expect(
                _pipe$1,
                $decode.list($notification.decoder()),
              );
              let _pipe$3 = $ask.notify(
                _pipe$2,
                (n) => {
                  return dispatch(
                    new $msg.Notifications(new $msg.ApiReturnedNotifications(n)),
                  );
                },
              );
              return $ask.run(_pipe$3);
            },
          );
        },
      );
    },
  );
}

export function mark_notification_as_read(_, notification_id) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["notifications", notification_id]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Patch());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.expect(_pipe$2, $notification.decoder());
              let _pipe$4 = $ask.notify(
                _pipe$3,
                (n) => {
                  return dispatch(
                    new $msg.Notifications(new $msg.ApiReturnedNotification(n)),
                  );
                },
              );
              return $ask.run(_pipe$4);
            },
          );
        },
      );
    },
  );
}

export function mark_all_notifications_as_read(_) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (_) => {
          return $function.tap(
            undefined,
            (_) => {
              let at = toList(["notifications"]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Patch());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              return $ask.run(_pipe$2);
            },
          );
        },
      );
    },
  );
}

export function register_slack_user(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_slack_login(
        data,
        (team_id, user_id) => {
          return $effect.from(
            (_) => {
              return $function.tap(
                undefined,
                (_) => {
                  let at = toList(["slack", "login"]);
                  let _pipe = $ask.to(new $ask.Loki(), at);
                  let _pipe$1 = $ask.via(_pipe, new $http.Post());
                  let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                  let _pipe$3 = $ask.query(_pipe$2, "slack_team_id", team_id);
                  let _pipe$4 = $ask.query(_pipe$3, "slack_user_id", user_id);
                  return $ask.run(_pipe$4);
                },
              );
            },
          );
        },
      );
    },
  );
}

export function connect_websocket() {
  return $middleware.require_access_token(
    (access_token) => {
      let $ = $uri.parse($endpoints.wss());
      if (!$.isOk()) {
        throw makeError(
          "let_assert",
          "frontend/effects/user",
          70,
          "",
          "Pattern match failed, no pattern matched the value.",
          { value: $ }
        )
      }
      let to = $[0];
      return $websocket.connect(to, access_token);
    },
  );
}
