/// <reference types="./model.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $utils from "../../common/common/utils.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import * as $local_storage from "../../ds/plinth/browser/local_storage.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $computed from "../data/model/computed.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $scaffold from "../frontend/browser/scaffold.mjs";
import * as $guards from "../frontend/guards.mjs";
import { makeError } from "../gleam.mjs";

export function replace_proposal_tab(data, _) {
  return $bool.guard(
    data.loading.questions,
    $effect.none(),
    () => {
      return $bool.guard(
        data.loading.proposals,
        $effect.none(),
        () => {
          let $ = data.route;
          if ($ instanceof $route.Projects &&
          $[0] instanceof $route.Proposals &&
          $[0].proposal instanceof $route.ShowProposal) {
            let pid = $[0].id;
            let route = $[0].proposal;
            let $1 = $list.key_find(data.proposals, route.id);
            if (!$1.isOk()) {
              return $effect.none();
            } else {
              let proposal = $1[0];
              let tabs = $route.proposal_tabs(proposal);
              let default_tab = $route.default_proposal_tab(proposal);
              let tab = $option.unwrap(route.tab, default_tab);
              let contains_tabs = $list.contains(tabs, tab);
              return $bool.guard(
                contains_tabs,
                $effect.none(),
                () => {
                  let _pipe = (() => {
                    let _record = route;
                    return new $route.ShowProposal(
                      _record.id,
                      _record.sheet,
                      new $option.None(),
                      _record.page,
                    );
                  })();
                  let _pipe$1 = ((_capture) => {
                    return new $route.Proposals(pid, _capture);
                  })(_pipe);
                  let _pipe$2 = new $route.Projects(_pipe$1);
                  return $route.replace(_pipe$2);
                },
              );
            }
          } else {
            return $effect.none();
          }
        },
      );
    },
  );
}

export function scroll_to_source_effect(data, computed, _) {
  return $bool.guard(
    data.loading.content_library,
    $effect.none(),
    () => {
      let $ = data.route;
      if ($ instanceof $route.ContentLibrary &&
      $[0] instanceof $route.DataSources &&
      $[0].source_id instanceof $option.Some) {
        let source_id = $[0].source_id[0];
        return $bool.guard(
          (() => {
            let _pipe = computed.non_qna_sources.all;
            return $list.is_empty(_pipe);
          })(),
          $effect.none(),
          () => {
            return $effect.from(
              (_) => { return $scaffold.scroll_to_source(source_id); },
            );
          },
        );
      } else if ($ instanceof $route.ContentLibrary &&
      $[0] instanceof $route.QuestionBank &&
      $[0].source_id instanceof $option.Some) {
        let source_id = $[0].source_id[0];
        return $bool.guard(
          (() => {
            let _pipe = computed.qna_sources.all;
            return $list.is_empty(_pipe);
          })(),
          $effect.none(),
          () => {
            return $effect.from(
              (_) => { return $scaffold.scroll_to_source(source_id); },
            );
          },
        );
      } else {
        return $effect.none();
      }
    },
  );
}

export function compute_page_max(length, block_size) {
  let $ = (() => {
    let _pipe = length;
    let _pipe$1 = $int.to_float(_pipe);
    return $float.divide(_pipe$1, $int.to_float(block_size));
  })();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "data/model",
      174,
      "compute_page_max",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let page_max = $[0];
  let _pipe = page_max;
  let _pipe$1 = $float.ceiling(_pipe);
  let _pipe$2 = $float.round(_pipe$1);
  let _pipe$3 = $int.subtract(_pipe$2, 1);
  return $int.max(_pipe$3, 0);
}

export function replace_proposal_question_page(data, computed) {
  return $bool.guard(
    data.loading.questions,
    $effect.none(),
    () => {
      let page_size = data.proposal_block_page_size;
      let $ = data.route;
      if ($ instanceof $route.Projects &&
      $[0] instanceof $route.Proposals &&
      $[0].proposal instanceof $route.ShowProposal) {
        let pid = $[0].id;
        let route = $[0].proposal;
        return $guards.require_page_more_than_0(
          route.page,
          () => {
            let _pipe = new $route.ShowProposal(
              route.id,
              route.sheet,
              new $option.None(),
              0,
            );
            let _pipe$1 = ((_capture) => {
              return new $route.Proposals(pid, _capture);
            })(_pipe);
            return new $route.Projects(_pipe$1);
          },
          () => {
            let page_max = (() => {
              let _pipe = computed.displayed_questions_count;
              return compute_page_max(_pipe, page_size);
            })();
            return $bool.guard(
              route.page <= page_max,
              $effect.none(),
              () => {
                let _pipe = new $route.ShowProposal(
                  route.id,
                  route.sheet,
                  route.tab,
                  page_max,
                );
                let _pipe$1 = ((_capture) => {
                  return new $route.Proposals(pid, _capture);
                })(_pipe);
                let _pipe$2 = new $route.Projects(_pipe$1);
                return $route.replace(_pipe$2);
              },
            );
          },
        );
      } else {
        return $effect.none();
      }
    },
  );
}

function compute_page_from_id(sources, block_size, source_id) {
  let source_position = (() => {
    let _pipe = sources;
    let _pipe$1 = $list.map(_pipe, (ds) => { return ds.id; });
    let _pipe$2 = $list.index_map(_pipe$1, (x, i) => { return [i, x]; });
    let _pipe$3 = $utils.find_list_element_index(_pipe$2, source_id);
    return $result.unwrap(_pipe$3, 0);
  })();
  let _pipe = source_position;
  let _pipe$1 = $int.add(_pipe, 1);
  let _pipe$2 = $int.to_float(_pipe$1);
  let _pipe$3 = $float.divide(_pipe$2, $int.to_float(block_size));
  let _pipe$4 = $result.unwrap(_pipe$3, 0.0);
  let _pipe$5 = $float.ceiling(_pipe$4);
  let _pipe$6 = $float.round(_pipe$5);
  let _pipe$7 = $int.subtract(_pipe$6, 1);
  return $int.max(_pipe$7, 0);
}

export function replace_content_library_page(
  data,
  computed,
  content_library_loading
) {
  return $bool.guard(
    content_library_loading,
    $effect.none(),
    () => {
      let page_size = data.content_library.content_library_block_page_size;
      let $ = data.route;
      if ($ instanceof $route.ContentLibrary &&
      $[0] instanceof $route.DataSources) {
        let page = $[0].page;
        let source_id = $[0].source_id;
        let data_sources = (p, source_id) => {
          return new $route.ContentLibrary(new $route.DataSources(p, source_id));
        };
        if (source_id instanceof $option.None) {
          return $guards.require_page_more_than_0(
            page,
            () => { return data_sources(0, source_id); },
            () => {
              let page_max = (() => {
                let _pipe = $computed.filtered_non_qna_count(data, computed);
                return compute_page_max(_pipe, page_size);
              })();
              return $bool.guard(
                (page <= page_max) || (page_max === 0),
                $effect.none(),
                () => {
                  return $route.replace(data_sources(page_max, source_id));
                },
              );
            },
          );
        } else {
          let source_id$1 = source_id[0];
          let block_size = data.content_library.content_library_block_page_size;
          let page_of_source_id = compute_page_from_id(
            computed.non_qna_sources.all,
            block_size,
            source_id$1,
          );
          return $route.replace(
            data_sources(page_of_source_id, new $option.Some(source_id$1)),
          );
        }
      } else if ($ instanceof $route.ContentLibrary &&
      $[0] instanceof $route.QuestionBank) {
        let page = $[0].page;
        let source_id = $[0].source_id;
        let question_bank = (p, source_id) => {
          return new $route.ContentLibrary(
            new $route.QuestionBank(p, source_id),
          );
        };
        if (source_id instanceof $option.None) {
          return $guards.require_page_more_than_0(
            page,
            () => { return question_bank(0, source_id); },
            () => {
              let page_max = (() => {
                let _pipe = $computed.filtered_qna_count(data, computed);
                return compute_page_max(_pipe, page_size);
              })();
              return $bool.guard(
                (page <= page_max) || (page_max === 0),
                $effect.none(),
                () => {
                  return $route.replace(question_bank(page_max, source_id));
                },
              );
            },
          );
        } else {
          let source_id$1 = source_id[0];
          let block_size = data.content_library.content_library_block_page_size;
          let page_of_source_id = compute_page_from_id(
            computed.qna_sources.all,
            block_size,
            source_id$1,
          );
          return $route.replace(
            question_bank(page_of_source_id, new $option.Some(source_id$1)),
          );
        }
      } else {
        return $effect.none();
      }
    },
  );
}

export function find_element(data, proposal_id, element_id) {
  let _pipe = data.elements.by_proposal;
  let _pipe$1 = $dict.get(_pipe, proposal_id);
  return $result.then$(
    _pipe$1,
    (_capture) => { return $list.key_find(_capture, element_id); },
  );
}

export function unsubscribe_more_proposal(data) {
  let _pipe = data.more_proposal_unsubscriber;
  return $option.unwrap(_pipe, $effect.none());
}

export function save_access_token(access_token) {
  return $effect.from(
    (dispatch) => {
      if (access_token instanceof $option.None) {
        return $local_storage.remove_item("access-token");
      } else {
        let access_token$1 = access_token[0];
        let $ = $local_storage.set_item("access-token", access_token$1);
        
        return dispatch(new $msg.ApplicationStoredAccessToken());
      }
    },
  );
}
