export function subscribeDOMClick(dispatch: any, once: any) {
  const run = () => {
    try {
      dispatch()
    } catch (e) {
      console.log(e)
    }
  }
  setTimeout(() => {
    document.addEventListener('click', run, { once })
  }, 100)
  return () => document.removeEventListener('click', run)
}
