/// <reference types="./iso639.d.mts" />
import * as $json from "../../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $language from "../../../data/translate/language.mjs";
import { Ok, Error } from "../../../gleam.mjs";

export function to_string(language) {
  if (language instanceof $language.English) {
    return "en";
  } else if (language instanceof $language.French) {
    return "fr";
  } else if (language instanceof $language.German) {
    return "de";
  } else if (language instanceof $language.Spanish) {
    return "es";
  } else if (language instanceof $language.Dutch) {
    return "nl";
  } else if (language instanceof $language.Portuguese) {
    return "pt";
  } else if (language instanceof $language.Italian) {
    return "it";
  } else if (language instanceof $language.Finnish) {
    return "fi";
  } else if (language instanceof $language.Swedish) {
    return "sv";
  } else if (language instanceof $language.Nynorsk) {
    return "nn";
  } else if (language instanceof $language.Bokmal) {
    return "nb";
  } else if (language instanceof $language.Danish) {
    return "da";
  } else if (language instanceof $language.Estonian) {
    return "et";
  } else if (language instanceof $language.Latvian) {
    return "lv";
  } else if (language instanceof $language.Lithuanian) {
    return "lt";
  } else {
    return "is";
  }
}

export function parse(digram) {
  if (digram === "fr") {
    return new Ok(new $language.French());
  } else if (digram === "en") {
    return new Ok(new $language.English());
  } else if (digram === "es") {
    return new Ok(new $language.Spanish());
  } else if (digram === "de") {
    return new Ok(new $language.German());
  } else if (digram === "nl") {
    return new Ok(new $language.Dutch());
  } else if (digram === "pt") {
    return new Ok(new $language.Portuguese());
  } else if (digram === "it") {
    return new Ok(new $language.Italian());
  } else if (digram === "fi") {
    return new Ok(new $language.Finnish());
  } else if (digram === "sv") {
    return new Ok(new $language.Swedish());
  } else if (digram === "nn") {
    return new Ok(new $language.Nynorsk());
  } else if (digram === "nb") {
    return new Ok(new $language.Bokmal());
  } else if (digram === "da") {
    return new Ok(new $language.Danish());
  } else if (digram === "et") {
    return new Ok(new $language.Estonian());
  } else if (digram === "lv") {
    return new Ok(new $language.Latvian());
  } else if (digram === "lt") {
    return new Ok(new $language.Lithuanian());
  } else if (digram === "is") {
    return new Ok(new $language.Icelandic());
  } else {
    return new Error(undefined);
  }
}

export function decoder() {
  return $decode.then$(
    $decode.string,
    (language) => {
      let $ = parse(language);
      if ($.isOk()) {
        let language$1 = $[0];
        return $decode.success(language$1);
      } else {
        return $decode.failure(new $language.English(), "Language");
      }
    },
  );
}

export function encode(language) {
  let language$1 = to_string(language);
  return $json.string(language$1);
}
