/// <reference types="./pdf_selector.d.mts" />
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $output from "../../../interfaces/schemas/outline/output.mjs";
import * as $file from "../../data/ephemeral/file.mjs";
import { CustomType as $CustomType } from "../../gleam.mjs";

export class PdfSelector extends $CustomType {
  constructor(file, session_id, loading, content, outline) {
    super();
    this.file = file;
    this.session_id = session_id;
    this.loading = loading;
    this.content = content;
    this.outline = outline;
  }
}

export function new$() {
  return new PdfSelector(new None(), new None(), false, new None(), new None());
}
