/// <reference types="./insights.d.mts" />
import * as $insight from "../../../../common/data/insight.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import { CustomType as $CustomType, isEqual } from "../../../gleam.mjs";

export class Insights extends $CustomType {
  constructor(all, conflict, duplicate, irrelevant) {
    super();
    this.all = all;
    this.conflict = conflict;
    this.duplicate = duplicate;
    this.irrelevant = irrelevant;
  }
}

export function compute(all) {
  let conflict = $list.filter(
    all,
    (s) => { return isEqual(s.kind, new $insight.Conflict()); },
  );
  let duplicate = $list.filter(
    all,
    (s) => { return isEqual(s.kind, new $insight.Duplicate()); },
  );
  let irrelevant = $list.filter(
    all,
    (s) => { return isEqual(s.kind, new $insight.Irrelevant()); },
  );
  return new Insights(all, conflict, duplicate, irrelevant);
}

export function count(insights) {
  let all = $list.length(insights.all);
  let conflict = $list.length(insights.conflict);
  let duplicate = $list.length(insights.duplicate);
  let irrelevant = $list.length(insights.irrelevant);
  return new Insights(all, conflict, duplicate, irrelevant);
}
