/// <reference types="./view.d.mts" />
import * as $unsafe from "../../../common_common/common/unsafe.mjs";
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $dropdown from "../../../ds/ds/components/dropdown.mjs";
import * as $input from "../../../ds/ds/components/input.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import * as $experimental from "../../../sketch_lustre_experimental/sketch/lustre/experimental.mjs";
import * as $model from "../../elements/editor/model.mjs";
import { Model } from "../../elements/editor/model.mjs";
import * as $s from "../../elements/editor/styles.mjs";
import * as $style from "../../elements/editor/tiptap/editor/style.mjs";
import * as $events from "../../elements/editor/tiptap/events.mjs";
import * as $transaction from "../../elements/editor/tiptap/transaction.mjs";
import * as $update from "../../elements/editor/update.mjs";
import { Ok, toList, prepend as listPrepend } from "../../gleam.mjs";

function insert_button() {
  let placeholder = $dropdown.placeholder("Insert");
  let icon = $dropdown.icon($icons.add());
  let on_selected = $dropdown.on_selected(
    (var0) => { return new $update.UserSelectedDropdown(var0); },
  );
  return $dropdown.dropdown(
    toList([placeholder, icon, on_selected]),
    toList([$dropdown.choice("Ask AI to answer", new $update.AiRespond())]),
  );
}

function on_popup_close() {
  return $e.on(
    "click",
    (event) => {
      $e.prevent_default(event);
      return new Ok(new $update.UserCancelledModal());
    },
  );
}

function ratio_selector(model) {
  let state = $dropdown.selected(new $option.Some(model.ratio));
  let on_select = $dropdown.on_selected(
    (var0) => { return new $update.UserSelectedRatio(var0); },
  );
  let style = $dropdown.input_class(
    $css.class$(toList([$css.width($length.ch(9.0))])),
  );
  return $dropdown.dropdown(
    toList([state, $dropdown.show_arrow(), on_select, style]),
    toList([
      $dropdown.choice("75 %", 0.75),
      $dropdown.choice("100 %", 1.0),
      $dropdown.choice("125 %", 1.25),
      $dropdown.choice("150 %", 1.5),
    ]),
  );
}

function formatting_ribbon(model) {
  return $bool.lazy_guard(
    !model.editable,
    $el.none,
    () => {
      let on_bold = $e.on_click(new $update.UserClickedStyle(new $style.Bold()));
      let on_italic = $e.on_click(
        new $update.UserClickedStyle(new $style.Italic()),
      );
      let on_underline = $e.on_click(
        new $update.UserClickedStyle(new $style.Underline()),
      );
      return $s.format_wrapper(
        toList([]),
        toList([
          $s.format_wrapper_spacer(
            toList([]),
            toList([
              $s.format_row(
                toList([]),
                toList([
                  insert_button(),
                  $s.action_button(toList([on_bold]), $icons.shining()),
                  ratio_selector(model),
                  $s.format_button(model.bold, toList([on_bold]), $icons.bold()),
                  $s.format_button(
                    model.italic,
                    toList([on_italic]),
                    $icons.italic(),
                  ),
                  $s.format_button(
                    model.underline,
                    toList([on_underline]),
                    $icons.underline(),
                  ),
                  (() => {
                    let $ = model.modal;
                    if ($ instanceof $option.None) {
                      return $el.none();
                    } else {
                      let modal = $[0];
                      return $s.rewrite_popup(
                        toList([]),
                        toList([
                          $s.rewrite_content(
                            toList([
                              $e.on_submit(new $update.UserSubmittedModal()),
                            ]),
                            toList([
                              $s.outline_title(
                                toList([]),
                                toList([$el.text("AI will respond to")]),
                              ),
                              $s.outline_content(
                                toList([]),
                                toList([$el.text(modal.content)]),
                              ),
                              $s.outline_custom(
                                toList([]),
                                toList([
                                  $el.text("Add any details you want to add"),
                                ]),
                              ),
                              $input.input(
                                toList([
                                  $input.value(model.custom_prompt),
                                  $input.on_input(
                                    (var0) => {
                                      return new $update.UserEdittedCustomPrompt(
                                        var0,
                                      );
                                    },
                                  ),
                                ]),
                              ),
                              $layout.row(
                                toList([$layout.gap(12), $layout.justify("end")]),
                                toList([]),
                                toList([
                                  $button.secondary(
                                    toList([
                                      $a.type_("button"),
                                      on_popup_close(),
                                    ]),
                                    toList([$el.text("Cancel")]),
                                  ),
                                  $button.primary(
                                    toList([]),
                                    toList([$el.text("Confirm")]),
                                  ),
                                ]),
                              ),
                            ]),
                          ),
                        ]),
                      );
                    }
                  })(),
                ]),
              ),
            ]),
          ),
        ]),
      );
    },
  );
}

function generate_empty_handlers() {
  return $list.map(
    $events.empty,
    (_use0) => {
      let name = _use0[0];
      let msg = _use0[1];
      return $e.on(
        name,
        (_) => { return new Ok(new $update.EditorEmitted(msg)); },
      );
    },
  );
}

function generate_transaction_handlers() {
  return $list.map(
    $events.transactions,
    (_use0) => {
      let name = _use0[0];
      let msg = _use0[1];
      return $e.on(
        name,
        (dynamic) => {
          let _pipe = $transaction.decoder();
          let _pipe$1 = $decode.map(_pipe, msg);
          let _pipe$2 = $decode.map(
            _pipe$1,
            (var0) => { return new $update.EditorEmitted(var0); },
          );
          let _pipe$3 = ((_capture) => { return $decode.run(dynamic, _capture); })(
            _pipe$2,
          );
          return $result.replace_error(_pipe$3, toList([]));
        },
      );
    },
  );
}

function on_connected() {
  return $e.on(
    "connected",
    (dynamic) => {
      let _pipe = $decode.at(toList(["detail", "editor"]), $decode.dynamic);
      let _pipe$1 = $decode.map(_pipe, $unsafe.coerce);
      let _pipe$2 = $decode.map(
        _pipe$1,
        (var0) => { return new $events.Connected(var0); },
      );
      let _pipe$3 = $decode.map(
        _pipe$2,
        (var0) => { return new $update.EditorEmitted(var0); },
      );
      let _pipe$4 = ((_capture) => { return $decode.run(dynamic, _capture); })(
        _pipe$3,
      );
      return $result.replace_error(_pipe$4, toList([]));
    },
  );
}

function generate_editor_handlers() {
  let empty = generate_empty_handlers();
  let transactions = generate_transaction_handlers();
  let _pipe = $list.append(empty, transactions);
  return $list.prepend(_pipe, on_connected());
}

export function view(model) {
  return $experimental.render(
    toList([$experimental.node()]),
    () => {
      let tiptap_editor = (_capture) => {
        return $el.element("tiptap-editor", _capture, toList([]));
      };
      return $s.container(
        toList([]),
        toList([
          formatting_ribbon(model),
          $s.page_wrapper(
            toList([]),
            toList([
              $s.format_wrapper_spacer(
                toList([]),
                toList([
                  $s.page(
                    model.ratio,
                    toList([]),
                    toList([
                      tiptap_editor(
                        listPrepend(
                          $a.id("tiptap-editor"),
                          listPrepend(
                            $a.attribute("ratio", $float.to_string(model.ratio)),
                            listPrepend(
                              $a.attribute("border-color", "#ccc"),
                              listPrepend(
                                $a.attribute("header-color", "#eee"),
                                listPrepend(
                                  $a.attribute("selected-cell-color", "#ccf2"),
                                  listPrepend(
                                    $a.property("content", model.content),
                                    listPrepend(
                                      $a.attribute(
                                        "editable",
                                        (() => {
                                          let $ = model.editable;
                                          if ($) {
                                            return "true";
                                          } else {
                                            return "false";
                                          }
                                        })(),
                                      ),
                                      listPrepend(
                                        $e.on(
                                          "updated",
                                          (event) => {
                                            let _pipe = $decode.at(
                                              toList(["detail"]),
                                              $decode.dynamic,
                                            );
                                            let _pipe$1 = $decode.map(
                                              _pipe,
                                              $unsafe.coerce,
                                            );
                                            let _pipe$2 = $decode.map(
                                              _pipe$1,
                                              (var0) => {
                                                return new $events.Updated(var0);
                                              },
                                            );
                                            let _pipe$3 = $decode.map(
                                              _pipe$2,
                                              (var0) => {
                                                return new $update.EditorEmitted(
                                                  var0,
                                                );
                                              },
                                            );
                                            let _pipe$4 = ((_capture) => {
                                              return $decode.run(
                                                event,
                                                _capture,
                                              );
                                            })(_pipe$3);
                                            return $result.replace_error(
                                              _pipe$4,
                                              toList([]),
                                            );
                                          },
                                        ),
                                        generate_editor_handlers(),
                                      ),
                                    ),
                                  ),
                                ),
                              ),
                            ),
                          ),
                        ),
                      ),
                    ]),
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      );
    },
  );
}
