import * as prelude from '#gleam/prelude'

export function activeElementId() {
  let isEditable = false
  let node = document.activeElement
  while (node && !node.id) {
    const isInput = node.tagName === 'input'
    const isContentEditable = node.getAttribute('contenteditable') === 'true'
    if (isInput || isContentEditable) isEditable = true
    node = node.parentElement
  }
  if (node?.id && isEditable) return new prelude.Ok(node.id)
  return new prelude.Error(undefined)
}

export function blurActiveElement() {
  const node = document.activeElement as HTMLElement | null
  node?.blur()
}

export function subscribeFeedScroll() {
  const id = document.getElementById('feed-wrapper')
  if (!id) {
    setTimeout(() => subscribeFeedScroll(), 100)
  } else {
    id.addEventListener('scroll', event => {
      const scroll = (event.target as HTMLElement).scrollTop
      const node = document.getElementById('feed-title')
      if (!node) return
      if (scroll > 0 && !node.style.borderBottomColor) {
        node.setAttribute('style', 'border-bottom-color: #DADAE2;')
      } else {
        node.removeAttribute('style')
      }
    })
  }
}

export function scrollTo(node_id: string, iteration = 0) {
  if (iteration === 10) return
  const node = document.getElementById(node_id)
  if (!node) {
    setTimeout(() => scrollTo(node_id, iteration + 1), 300)
  } else {
    node.scrollIntoView({ behavior: 'smooth' })
  }
}
