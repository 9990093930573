/// <reference types="./show.d.mts" />
import * as $bright from "../../../../bright/bright.mjs";
import * as $project from "../../../../common/data/project.mjs";
import { Project } from "../../../../common/data/project.mjs";
import * as $proposal from "../../../../common/data/proposal.mjs";
import { Proposal } from "../../../../common/data/proposal.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $model from "../../../data/model.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $route from "../../../data/route.mjs";
import * as $document from "../../../frontend/view/projects/show/document.mjs";
import * as $questionnaire from "../../../frontend/view/projects/show/questionnaire.mjs";
import { Props } from "../../../frontend/view/projects/show/questionnaire.mjs";
import * as $requirements from "../../../frontend/view/projects/show/requirements.mjs";
import * as $states from "../../../frontend/view/projects/show/states.mjs";
import { CustomType as $CustomType } from "../../../gleam.mjs";

class ProposalLoading extends $CustomType {}

class ProposalElementsLoading extends $CustomType {
  constructor(proposal, project) {
    super();
    this.proposal = proposal;
    this.project = project;
  }
}

class ProposalLoaded extends $CustomType {
  constructor(proposal, project) {
    super();
    this.proposal = proposal;
    this.project = project;
  }
}

class ProposalNotFound extends $CustomType {}

class ProjectLoading extends $CustomType {}

class ProjectLoaded extends $CustomType {
  constructor(project) {
    super();
    this.project = project;
  }
}

class ProjectNotFound extends $CustomType {}

function find_proposal(model, id) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $bool.guard(
    data.loading.proposals,
    new ProposalLoading(),
    () => {
      let $1 = $list.key_find(data.proposals, id);
      if (!$1.isOk()) {
        return new ProposalNotFound();
      } else {
        let proposal = $1[0];
        return $bool.guard(
          data.loading.projects,
          new ProposalLoading(),
          () => {
            let $2 = $list.key_find(data.projects, proposal.project_id);
            if (!$2.isOk()) {
              return new ProposalNotFound();
            } else {
              let project = $2[0];
              let loading_state = new ProposalElementsLoading(proposal, project);
              return $bool.guard(
                data.loading.questions,
                loading_state,
                () => { return new ProposalLoaded(proposal, project); },
              );
            }
          },
        );
      }
    },
  );
}

function find_project(model, id) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $bool.guard(
    data.loading.projects,
    new ProjectLoading(),
    () => {
      let $1 = $list.key_find(data.projects, id);
      if (!$1.isOk()) {
        return new ProjectNotFound();
      } else {
        let project = $1[0];
        return new ProjectLoaded(project);
      }
    },
  );
}

function view_proposal(model, project, proposal, props) {
  let $ = props.tab;
  let $1 = proposal.metadata;
  if ($ instanceof $route.Blocks) {
    return $questionnaire.view(model, project, proposal, props);
  } else if ($ instanceof $route.Document && $1 instanceof $proposal.Document) {
    return $document.view(model, project, proposal);
  } else if ($ instanceof $route.Document && $1 instanceof $proposal.Narrative) {
    return $document.view(model, project, proposal);
  } else if ($ instanceof $route.Requirements &&
  $1 instanceof $proposal.Narrative) {
    return $requirements.view(model, project, proposal);
  } else if ($ instanceof $route.Spreadsheet &&
  $1 instanceof $proposal.Questionnaire) {
    return $el.none();
  } else {
    return $el.none();
  }
}

export function view(model, id, props) {
  let $ = find_proposal(model, id);
  if ($ instanceof ProposalNotFound) {
    return $states.not_found(model);
  } else if ($ instanceof ProposalLoading) {
    return $states.proposal_loading(model);
  } else if ($ instanceof ProposalElementsLoading) {
    let project = $.project;
    return $states.proposal_questions_loading(model, project);
  } else {
    let proposal = $.proposal;
    let project = $.project;
    let $1 = find_project(model, proposal.project_id);
    if ($1 instanceof ProjectNotFound) {
      return $states.not_found(model);
    } else if ($1 instanceof ProjectLoading) {
      return $states.proposal_questions_loading(model, project);
    } else {
      let project$1 = $1.project;
      let props$1 = (() => {
        let $2 = props.tab;
        let $3 = proposal.metadata;
        if ($2 instanceof $route.Blocks && $3 instanceof $proposal.Narrative) {
          let _record = props;
          return new Props(_record.sheet, _record.page, new $route.Document());
        } else if ($2 instanceof $route.Spreadsheet &&
        $3 instanceof $proposal.Narrative) {
          let _record = props;
          return new Props(_record.sheet, _record.page, new $route.Document());
        } else {
          return props;
        }
      })();
      return view_proposal(model, project$1, proposal, props$1);
    }
  }
}
