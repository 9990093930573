/// <reference types="./msg.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $auth0_user from "../../auth0_client/auth0/client/user.mjs";
import * as $connector from "../../common/data/connector.mjs";
import * as $connector_settings from "../../common/data/connector_settings.mjs";
import * as $copilot_question from "../../common/data/copilot_question.mjs";
import * as $data_point from "../../common/data/data_point.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import * as $docx from "../../common/data/docx.mjs";
import * as $insight from "../../common/data/insight.mjs";
import * as $metrics from "../../common/data/metrics.mjs";
import * as $notification from "../../common/data/notification.mjs";
import * as $project from "../../common/data/project.mjs";
import * as $proposal from "../../common/data/proposal.mjs";
import * as $proposal_element from "../../common/data/proposal_element.mjs";
import * as $question from "../../common/data/proposal_element/question.mjs";
import * as $qm from "../../common/data/qualification_matrix.mjs";
import * as $sp from "../../common/data/spreadsheet.mjs";
import * as $tag from "../../common/data/tag.mjs";
import * as $translate from "../../common/data/translate.mjs";
import * as $language from "../../common/data/translate/language.mjs";
import * as $user from "../../common/data/user.mjs";
import * as $file from "../../ds/plinth/browser/file.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $timestamp from "../../gleam_time/gleam/time/timestamp.mjs";
import * as $toast from "../../grille_pain/grille_pain/lustre/toast.mjs";
import * as $level from "../../grille_pain/grille_pain/toast/level.mjs";
import * as $output from "../../interfaces/schemas/outline/output.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $route from "../data/route.mjs";
import * as $events from "../elements/editor/tiptap/events.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Add extends $CustomType {}

export class Remove extends $CustomType {}

export class ApiReturnedAnalytics extends $CustomType {
  constructor(analytics) {
    super();
    this.analytics = analytics;
  }
}

export class ApiSentOutline extends $CustomType {
  constructor(outline) {
    super();
    this.outline = outline;
  }
}

export class ApiSentOutlineGeneration extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

export class ApplicationStoredAccessToken extends $CustomType {}

export class ApplicationChangedRoute extends $CustomType {
  constructor(route) {
    super();
    this.route = route;
  }
}

export class ApplicationCompletedRequest extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationQueriedProposalElements extends $CustomType {
  constructor(timeout, id) {
    super();
    this.timeout = timeout;
    this.id = id;
  }
}

export class ApplicationRequestedFetchUserData extends $CustomType {}

export class ApplicationChangedAuthState extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApplicationRequestedToHideToast extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class ApplicationRequestedToDisplayToast extends $CustomType {
  constructor(level, message) {
    super();
    this.level = level;
    this.message = message;
  }
}

export class ApplicationRequestedToDisplayCustomToast extends $CustomType {
  constructor(options, content) {
    super();
    this.options = options;
    this.content = content;
  }
}

export class UserClosedModal extends $CustomType {}

export class UserClickedBackArrow extends $CustomType {}

export class UserClosedPopup extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserReplacedPage extends $CustomType {
  constructor(route) {
    super();
    this.route = route;
  }
}

export class UserToggledCollapsedNavbar extends $CustomType {}

export class UserClickedToOpenLink extends $CustomType {
  constructor(link) {
    super();
    this.link = link;
  }
}

export class Auth0 extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Connectors extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ContentLibrary extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Notifications extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ProposalBuilder extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Organization extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Project extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Proposal extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Xlsx extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Narrative extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Copilot extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ModalReturnedUnsubscriber extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class MoreButtonReturnedUnsubscriber extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ProposalReturnedUnsubscriber extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiReturnedCopilotQuestion extends $CustomType {
  constructor(question, data_sources, data_points) {
    super();
    this.question = question;
    this.data_sources = data_sources;
    this.data_points = data_points;
  }
}

export class ApiReturnedCopilotThreads extends $CustomType {
  constructor(questions, data_sources, data_points) {
    super();
    this.questions = questions;
    this.data_sources = data_sources;
    this.data_points = data_points;
  }
}

export class UserAddedCopilotToQuestionBank extends $CustomType {
  constructor(question, answer) {
    super();
    this.question = question;
    this.answer = answer;
  }
}

export class UserEdittedCopilotInput extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserSubmittedCopilotInput extends $CustomType {}

export class UserUpdatedDocument extends $CustomType {
  constructor(project, proposal, event) {
    super();
    this.project = project;
    this.proposal = proposal;
    this.event = event;
  }
}

export class UserClickedNotification extends $CustomType {
  constructor(notification) {
    super();
    this.notification = notification;
  }
}

export class UserMarkedAllNotificationsAsRead extends $CustomType {}

export class UserMarkedNotificationAsRead extends $CustomType {
  constructor(notification_id) {
    super();
    this.notification_id = notification_id;
  }
}

export class UserToggledNotificationFeed extends $CustomType {}

export class ApiReturnedNotification extends $CustomType {
  constructor(notification) {
    super();
    this.notification = notification;
  }
}

export class ApiReturnedNotifications extends $CustomType {
  constructor(notifications) {
    super();
    this.notifications = notifications;
  }
}

export class ApiSentUserFunctionUpdated extends $CustomType {
  constructor(x0, x1, x2) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
  }
}

export class ApiReturnedUsers extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiReturnedTags extends $CustomType {
  constructor(tags) {
    super();
    this.tags = tags;
  }
}

export class ApiReturnedQualificationMatrix extends $CustomType {
  constructor(qm) {
    super();
    this.qm = qm;
  }
}

export class UserSelectedFunction extends $CustomType {
  constructor(user_id, function$) {
    super();
    this.user_id = user_id;
    this.function = function$;
  }
}

export class ApiDeletedProject extends $CustomType {
  constructor(project_id) {
    super();
    this.project_id = project_id;
  }
}

export class ApiReturnedProject extends $CustomType {
  constructor(project) {
    super();
    this.project = project;
  }
}

export class ApiReturnedProjects extends $CustomType {
  constructor(projects) {
    super();
    this.projects = projects;
  }
}

export class UserClickedAddProposal extends $CustomType {
  constructor(project) {
    super();
    this.project = project;
  }
}

export class UserSavedProjectTitle extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserToggledCollapsedProject extends $CustomType {
  constructor(project) {
    super();
    this.project = project;
  }
}

export class UserToggledProjectCollaborator extends $CustomType {
  constructor(project_id, user_id) {
    super();
    this.project_id = project_id;
    this.user_id = user_id;
  }
}

export class UserUpdatedProjectTitle extends $CustomType {
  constructor(id, title) {
    super();
    this.id = id;
    this.title = title;
  }
}

export class ApiSentElementDeleted extends $CustomType {
  constructor(proposal_id, element_id) {
    super();
    this.proposal_id = proposal_id;
    this.element_id = element_id;
  }
}

export class ApiRejectedProposal extends $CustomType {
  constructor(proposal) {
    super();
    this.proposal = proposal;
  }
}

export class ApiRejectedProposalElement extends $CustomType {
  constructor(element) {
    super();
    this.element = element;
  }
}

export class ApiReturnedProposalElement extends $CustomType {
  constructor(element) {
    super();
    this.element = element;
  }
}

export class ApiReturnedProposals extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiReturnedProposalsElements extends $CustomType {
  constructor(elements, data_sources, data_points) {
    super();
    this.elements = elements;
    this.data_sources = data_sources;
    this.data_points = data_points;
  }
}

export class ApiSentElementUpdated extends $CustomType {
  constructor(x0, x1, x2) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
  }
}

export class ApiReturnedTranslations extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiTimeoutedProposalsQuestions extends $CustomType {}

export class UserSelectedProposalLanguage extends $CustomType {
  constructor(language) {
    super();
    this.language = language;
  }
}

export class UserToggledFilterProposal extends $CustomType {}

export class UserUpdatedProposalFiltersOwner extends $CustomType {
  constructor(owner) {
    super();
    this.owner = owner;
  }
}

export class UserUpdatedProposalFiltersStatus extends $CustomType {
  constructor(status) {
    super();
    this.status = status;
  }
}

export class UserAssignedStatusInProposal extends $CustomType {
  constructor(validated, proposal_id) {
    super();
    this.validated = validated;
    this.proposal_id = proposal_id;
  }
}

export class UserChoseAttachment extends $CustomType {
  constructor(proposal, question, files) {
    super();
    this.proposal = proposal;
    this.question = question;
    this.files = files;
  }
}

export class UserClickedAddToQuestionBank extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class UserClickedAiRewrite extends $CustomType {
  constructor(proposal_id, question_id, asked) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
    this.asked = asked;
  }
}

export class UserClickedGlobalProposalCheckbox extends $CustomType {
  constructor(all_selected, questions) {
    super();
    this.all_selected = all_selected;
    this.questions = questions;
  }
}

export class UserClickedOutsideInProposal extends $CustomType {}

export class UserClickedProposal extends $CustomType {
  constructor(proposal, sheet) {
    super();
    this.proposal = proposal;
    this.sheet = sheet;
  }
}

export class UserDeletedAttachment extends $CustomType {
  constructor(proposal, question, attachment) {
    super();
    this.proposal = proposal;
    this.question = question;
    this.attachment = attachment;
  }
}

export class UserDeletedQuestionInProposal extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class UserDeletedProposal extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserDisplayedDeleteProposalModal extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserDisplayedDeleteQuestionInProposalModal extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class UserEdittedCustomRewording extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserSelectedElementOwner extends $CustomType {
  constructor(element_id, proposal_id, user_id) {
    super();
    this.element_id = element_id;
    this.proposal_id = proposal_id;
    this.user_id = user_id;
  }
}

export class UserSelectedProposalPage extends $CustomType {
  constructor(page) {
    super();
    this.page = page;
  }
}

export class UserSelectedQuestion extends $CustomType {
  constructor(id, checked) {
    super();
    this.id = id;
    this.checked = checked;
  }
}

export class UserSubmittedCustomRewording extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class UserToggledAssignUserPopupInProposal extends $CustomType {
  constructor(user_id, proposal_id) {
    super();
    this.user_id = user_id;
    this.proposal_id = proposal_id;
  }
}

export class UserToggledChangeOwnerPopupInProposal extends $CustomType {}

export class UserToggledChangeStatusPopupInProposal extends $CustomType {}

export class UserToggledQuestionMoreButton extends $CustomType {
  constructor(question_id) {
    super();
    this.question_id = question_id;
  }
}

export class UserUpdatedProposalQuestion extends $CustomType {
  constructor(question, content, language) {
    super();
    this.question = question;
    this.content = content;
    this.language = language;
  }
}

export class UserUpdatedProposalQuestionChoice extends $CustomType {
  constructor(question, choice) {
    super();
    this.question = question;
    this.choice = choice;
  }
}

export class UserUpdatedProposalQuestionYesNo extends $CustomType {
  constructor(question, yes_no) {
    super();
    this.question = question;
    this.yes_no = yes_no;
  }
}

export class UserValidatedQuestion extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class GenerateXlsx extends $CustomType {
  constructor(proposal_id) {
    super();
    this.proposal_id = proposal_id;
  }
}

export class DownloadXlsx extends $CustomType {
  constructor(proposal_id, toast_id) {
    super();
    this.proposal_id = proposal_id;
    this.toast_id = toast_id;
  }
}

export class ApiReturnedConnectorData extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

export class ApiReturnedConnectorSettings extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserAskedConnectingTo extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserFetchedConnectorData extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserSubmitedGoogleFolderId extends $CustomType {}

export class UserToggledConfluencePanel extends $CustomType {}

export class UserUpdatedConfluenceApiKey extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserUpdatedConfluenceDomain extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserUpdatedConfluenceUser extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserToggledNotionPanel extends $CustomType {}

export class UserUpdatedNotionApiKey extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserUpdatedNotionPageId extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserUpdatedGoogleFolderId extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiUpsertedInsight extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

export class ApiRejectedUploadingQuestion extends $CustomType {
  constructor(ingestion) {
    super();
    this.ingestion = ingestion;
  }
}

export class ApiResolvedInsight extends $CustomType {
  constructor(insight) {
    super();
    this.insight = insight;
  }
}

export class ApiReturnedInsights extends $CustomType {
  constructor(insights, data_points) {
    super();
    this.insights = insights;
    this.data_points = data_points;
  }
}

export class ApiReturnedSource extends $CustomType {
  constructor(source) {
    super();
    this.source = source;
  }
}

export class ApiReturnedSources extends $CustomType {
  constructor(sources) {
    super();
    this.sources = sources;
  }
}

export class ApiUpsertedDataSources extends $CustomType {
  constructor(sources) {
    super();
    this.sources = sources;
  }
}

export class ApiReturnedStatusStats extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiReturnedUploadingQuestion extends $CustomType {
  constructor(ingestion) {
    super();
    this.ingestion = ingestion;
  }
}

export class ApiSuccessfullyProcessedFiles extends $CustomType {
  constructor(total) {
    super();
    this.total = total;
  }
}

export class ContentLibraryHandledSpreadsheetSelector extends $CustomType {
  constructor(msg) {
    super();
    this.msg = msg;
  }
}

export class ContentLibraryReturnedUnsubscriber extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserAssignedExpirationDate extends $CustomType {
  constructor(expiration_date) {
    super();
    this.expiration_date = expiration_date;
  }
}

export class UserAssignedStatusInContentLibrary extends $CustomType {
  constructor(status) {
    super();
    this.status = status;
  }
}

export class UserAssignedTag extends $CustomType {
  constructor(tag, add_remove) {
    super();
    this.tag = tag;
    this.add_remove = add_remove;
  }
}

export class UserClickedGlobalCheckbox extends $CustomType {
  constructor(all_selected, sources) {
    super();
    this.all_selected = all_selected;
    this.sources = sources;
  }
}

export class UserClickedOutsideInContentLibrary extends $CustomType {}

export class UserCreatedQuestion extends $CustomType {}

export class UserDeletedDataSource extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserDisplayedDeleteDataSourceModal extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserEdittedQuestion extends $CustomType {
  constructor(question_id) {
    super();
    this.question_id = question_id;
  }
}

export class UserFixedConflict extends $CustomType {
  constructor(insight, chosen_id, deleted_id) {
    super();
    this.insight = insight;
    this.chosen_id = chosen_id;
    this.deleted_id = deleted_id;
  }
}

export class UserFixedDuplicate extends $CustomType {
  constructor(insight, chosen_id, deleted_id) {
    super();
    this.insight = insight;
    this.chosen_id = chosen_id;
    this.deleted_id = deleted_id;
  }
}

export class UserFixedIrrelevant extends $CustomType {
  constructor(insight) {
    super();
    this.insight = insight;
  }
}

export class UserSelectedInsightCard extends $CustomType {
  constructor(id, insight_id) {
    super();
    this.id = id;
    this.insight_id = insight_id;
  }
}

export class UserSelectedContentLibraryPage extends $CustomType {
  constructor(page) {
    super();
    this.page = page;
  }
}

export class UserSelectedInsightFilter extends $CustomType {
  constructor(filter) {
    super();
    this.filter = filter;
  }
}

export class UserSelectedFilter extends $CustomType {
  constructor(filter) {
    super();
    this.filter = filter;
  }
}

export class UserSelectedSource extends $CustomType {
  constructor(id, checked) {
    super();
    this.id = id;
    this.checked = checked;
  }
}

export class UserSkippedInsight extends $CustomType {
  constructor(insight) {
    super();
    this.insight = insight;
  }
}

export class UserSubmittedQuestionBankEdit extends $CustomType {}

export class UserToggledAssignUserPopupInContentLibrary extends $CustomType {
  constructor(user_id) {
    super();
    this.user_id = user_id;
  }
}

export class UserToggledChangeExpirationDatePopup extends $CustomType {}

export class UserToggledChangeOwnerPopupInContentLibrary extends $CustomType {}

export class UserToggledChangeStatusPopupInContentLibrary extends $CustomType {}

export class UserToggledChangeTags extends $CustomType {}

export class UserUpdatedExpirationDateInput extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserUpdatedQuestionBankEditAnswer extends $CustomType {
  constructor(answer) {
    super();
    this.answer = answer;
  }
}

export class UserUpdatedQuestionBankEditQuestion extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class UserUpdatedSearch extends $CustomType {
  constructor(search) {
    super();
    this.search = search;
  }
}

export class UserUploadedSourceFiles extends $CustomType {
  constructor(sources) {
    super();
    this.sources = sources;
  }
}

export class ApiReturnedSavedProposal extends $CustomType {
  constructor(saved_proposal) {
    super();
    this.saved_proposal = saved_proposal;
  }
}

export class ProposalBuilderHandledSpreadsheetSelector extends $CustomType {
  constructor(msg) {
    super();
    this.msg = msg;
  }
}

export class ProposalBuilderHandledDocumentSelector extends $CustomType {
  constructor(msg) {
    super();
    this.msg = msg;
  }
}

export class ProposalBuilderHandledPdfSelector extends $CustomType {
  constructor(msg) {
    super();
    this.msg = msg;
  }
}

export class UserAddedProjectLanguage extends $CustomType {
  constructor(language) {
    super();
    this.language = language;
  }
}

export class UserAddedProjectTag extends $CustomType {
  constructor(tag) {
    super();
    this.tag = tag;
  }
}

export class UserAddedProposalLanguage extends $CustomType {
  constructor(language) {
    super();
    this.language = language;
  }
}

export class UserConfirmedModalProposalQualificationMatrix extends $CustomType {}

export class UserConfirmedProposalQualificationMatrix extends $CustomType {}

export class UserEditedProjectCollaborator extends $CustomType {
  constructor(action) {
    super();
    this.action = action;
  }
}

export class UserEditedProposalCollaborator extends $CustomType {
  constructor(action) {
    super();
    this.action = action;
  }
}

export class UserRemovedProjectLanguage extends $CustomType {
  constructor(language) {
    super();
    this.language = language;
  }
}

export class UserRemovedProjectTag extends $CustomType {
  constructor(tag) {
    super();
    this.tag = tag;
  }
}

export class UserRemovedProposalLanguage extends $CustomType {
  constructor(language) {
    super();
    this.language = language;
  }
}

export class UserSelectedProposalQualificationMatrix extends $CustomType {
  constructor(id, answer) {
    super();
    this.id = id;
    this.answer = answer;
  }
}

export class UserSkippedProposalQualificationMatrix extends $CustomType {}

export class UserSubmittedProjectDetails extends $CustomType {}

export class UserSubmittedProposalDetails extends $CustomType {}

export class UserUpdatedProjectClient extends $CustomType {
  constructor(name) {
    super();
    this.name = name;
  }
}

export class UserUpdatedProjectDeadline extends $CustomType {
  constructor(deadline) {
    super();
    this.deadline = deadline;
  }
}

export class UserUpdatedProjectName extends $CustomType {
  constructor(name) {
    super();
    this.name = name;
  }
}

export class UserUsedProposalQualificationMatrix extends $CustomType {}

export class UserAddedProjectAttachments extends $CustomType {
  constructor(attachments) {
    super();
    this.attachments = attachments;
  }
}

export class UserRemovedProjectAttachment extends $CustomType {
  constructor(index) {
    super();
    this.index = index;
  }
}

export class BrowserExtractedProjectAttachments extends $CustomType {
  constructor(attachments) {
    super();
    this.attachments = attachments;
  }
}

export class UserAddedCollaborator extends $CustomType {
  constructor(user_id) {
    super();
    this.user_id = user_id;
  }
}

export class UserRemovedCollaborator extends $CustomType {
  constructor(user_id) {
    super();
    this.user_id = user_id;
  }
}

export class ApiAutoDetectedXlsxColumns extends $CustomType {
  constructor(sheet, column_types) {
    super();
    this.sheet = sheet;
    this.column_types = column_types;
  }
}

export class ApiAutoDetectedXlsxRows extends $CustomType {
  constructor(sheet, rows_type) {
    super();
    this.sheet = sheet;
    this.rows_type = rows_type;
  }
}

export class ApiFetchedXlsx extends $CustomType {
  constructor(decoded_sheet) {
    super();
    this.decoded_sheet = decoded_sheet;
  }
}

export class BrowserDecodedXlsxFile extends $CustomType {
  constructor(file) {
    super();
    this.file = file;
  }
}

export class UserCancelledSpreadsheetSelection extends $CustomType {}

export class UserChangedColumnType extends $CustomType {
  constructor(sheet, index, type_) {
    super();
    this.sheet = sheet;
    this.index = index;
    this.type_ = type_;
  }
}

export class UserChangedInvalidLine extends $CustomType {
  constructor(sheet, index, validity) {
    super();
    this.sheet = sheet;
    this.index = index;
    this.validity = validity;
  }
}

export class UserSelectedXlsx extends $CustomType {
  constructor(file) {
    super();
    this.file = file;
  }
}

export class UserSkippedSelectedSheet extends $CustomType {
  constructor(current_sheet, next_sheet) {
    super();
    this.current_sheet = current_sheet;
    this.next_sheet = next_sheet;
  }
}

export class UserSubmittedXlsx extends $CustomType {}

export class UserUpdatedSelectedSheet extends $CustomType {
  constructor(sheet) {
    super();
    this.sheet = sheet;
  }
}

export class ApiFetchedDocx extends $CustomType {
  constructor(decoded_docx) {
    super();
    this.decoded_docx = decoded_docx;
  }
}

export class BrowserDecodedDocxFile extends $CustomType {
  constructor(file) {
    super();
    this.file = file;
  }
}

export class UserIssuedTransaction extends $CustomType {
  constructor(event) {
    super();
    this.event = event;
  }
}

export class UserSelectedDocx extends $CustomType {
  constructor(file) {
    super();
    this.file = file;
  }
}

export class UserSubmittedDocument extends $CustomType {}

export class BrowserDecodedPdfFile extends $CustomType {
  constructor(file) {
    super();
    this.file = file;
  }
}

export class ApiFetchedOutline extends $CustomType {
  constructor(outline) {
    super();
    this.outline = outline;
  }
}

export class UserSelectedPdf extends $CustomType {
  constructor(file) {
    super();
    this.file = file;
  }
}

export class UserSubmittedPdf extends $CustomType {}

function project_decoder() {
  return $decode.field(
    "project",
    $project.decoder(),
    (project) => {
      let _pipe = $decode.success(new ApiReturnedProject(project));
      return $decode.map(_pipe, (var0) => { return new Project(var0); });
    },
  );
}

function proposal_updated_decoder() {
  return $decode.field(
    "proposal",
    $proposal.decoder(),
    (proposal) => {
      let _pipe = $decode.success(new ApiReturnedProposals(toList([proposal])));
      return $decode.map(_pipe, (var0) => { return new Proposal(var0); });
    },
  );
}

function element_updated_decoder() {
  return $decode.field(
    "element",
    $proposal_element.decoder(),
    (element) => {
      let data_sources = $decode.list($data_source.decoder());
      return $decode.field(
        "data_sources",
        data_sources,
        (data_sources) => {
          let data_points = $decode.list($data_point.decoder());
          return $decode.field(
            "data_points",
            data_points,
            (data_points) => {
              let _pipe = $decode.success(
                new ApiSentElementUpdated(element, data_sources, data_points),
              );
              return $decode.map(
                _pipe,
                (var0) => { return new Proposal(var0); },
              );
            },
          );
        },
      );
    },
  );
}

function connector_registered_decoder() {
  return $decode.field(
    "connector",
    $connector.decoder(),
    (connector) => {
      return $decode.field(
        "status",
        $decode.bool,
        (status) => {
          let _pipe = $decode.success(
            new ApiReturnedConnectorData(connector, status),
          );
          return $decode.map(_pipe, (var0) => { return new Connectors(var0); });
        },
      );
    },
  );
}

function connector_settings_updated_decoder() {
  let settings = $connector_settings.decoder();
  return $decode.field(
    "connector_settings",
    settings,
    (settings) => {
      let _pipe = $decode.success(
        new ApiReturnedConnectorSettings(toList([settings])),
      );
      return $decode.map(_pipe, (var0) => { return new Connectors(var0); });
    },
  );
}

function notification_updated_decoder() {
  return $decode.field(
    "notification",
    $notification.decoder(),
    (notification) => {
      let _pipe = $decode.success(new ApiReturnedNotification(notification));
      return $decode.map(_pipe, (var0) => { return new Notifications(var0); });
    },
  );
}

function data_sources_updated_decoder() {
  let data_sources = $decode.list($data_source.decoder());
  return $decode.field(
    "data_sources",
    data_sources,
    (data_sources) => {
      let _pipe = $decode.success(new ApiUpsertedDataSources(data_sources));
      return $decode.map(_pipe, (var0) => { return new ContentLibrary(var0); });
    },
  );
}

function user_data_updated_decoder() {
  return $decode.field(
    "user_id",
    $decode.string,
    (user_id) => {
      return $decode.field(
        "org_id",
        $decode.string,
        (org_id) => {
          return $decode.field(
            "function",
            $user.function_decoder(),
            (function$) => {
              let _pipe = $decode.success(
                new ApiSentUserFunctionUpdated(user_id, org_id, function$),
              );
              return $decode.map(
                _pipe,
                (var0) => { return new Organization(var0); },
              );
            },
          );
        },
      );
    },
  );
}

function translation_decoder() {
  let translation = $translate.translation_decoder();
  return $decode.field(
    "translation",
    translation,
    (translation) => {
      let _pipe = $decode.success(
        new ApiReturnedTranslations(toList([translation])),
      );
      return $decode.map(_pipe, (var0) => { return new Proposal(var0); });
    },
  );
}

function deleted_element_decoder() {
  return $decode.field(
    "element_id",
    $decode.string,
    (element_id) => {
      return $decode.field(
        "proposal_id",
        $decode.string,
        (proposal_id) => {
          let _pipe = $decode.success(
            new ApiSentElementDeleted(element_id, proposal_id),
          );
          return $decode.map(_pipe, (var0) => { return new Proposal(var0); });
        },
      );
    },
  );
}

function insight_decoder() {
  return $decode.field(
    "insight",
    $insight.decoder(),
    (insight) => {
      return $decode.field(
        "conflict_data_points",
        $decode.list($data_point.decoder()),
        (conflict_data_points) => {
          let _pipe = $decode.success(
            new ApiUpsertedInsight(insight, conflict_data_points),
          );
          return $decode.map(
            _pipe,
            (var0) => { return new ContentLibrary(var0); },
          );
        },
      );
    },
  );
}

function outline_decoder() {
  let _pipe = $decode.at(toList(["outline"]), $output.outline_output_decoder());
  return $decode.map(_pipe, (var0) => { return new ApiSentOutline(var0); });
}

function outline_generation_decoder() {
  return $decode.field(
    "answer",
    $decode.string,
    (answer) => {
      return $decode.field(
        "block_id",
        $decode.string,
        (block_id) => {
          return $decode.success(new ApiSentOutlineGeneration(answer, block_id));
        },
      );
    },
  );
}

export function incoming_decoder() {
  return $decode.then$(
    $decode.at(toList(["type"]), $decode.string),
    (type_) => {
      if (type_ === "project") {
        return project_decoder();
      } else if (type_ === "proposal") {
        return proposal_updated_decoder();
      } else if (type_ === "proposal_element") {
        return element_updated_decoder();
      } else if (type_ === "connector_registered") {
        return connector_registered_decoder();
      } else if (type_ === "connector_settings") {
        return connector_settings_updated_decoder();
      } else if (type_ === "notification") {
        return notification_updated_decoder();
      } else if (type_ === "data_sources") {
        return data_sources_updated_decoder();
      } else if (type_ === "user_data") {
        return user_data_updated_decoder();
      } else if (type_ === "translation") {
        return translation_decoder();
      } else if (type_ === "insight") {
        return insight_decoder();
      } else if (type_ === "deleted_proposal_element") {
        return deleted_element_decoder();
      } else if (type_ === "outline_output") {
        return outline_decoder();
      } else if (type_ === "outline_generation") {
        return outline_generation_decoder();
      } else {
        return $decode.failure(
          new ApplicationCompletedRequest(""),
          "ApiSentMessage",
        );
      }
    },
  );
}
