/// <reference types="./common.d.mts" />
import * as $bright from "../../../../../bright/bright.mjs";
import * as $project from "../../../../../common/data/project.mjs";
import { Project } from "../../../../../common/data/project.mjs";
import * as $proposal from "../../../../../common/data/proposal.mjs";
import { Proposal } from "../../../../../common/data/proposal.mjs";
import * as $button from "../../../../../ds/ds/components/button.mjs";
import * as $dropdown from "../../../../../ds/ds/components/dropdown.mjs";
import * as $input from "../../../../../ds/ds/components/input.mjs";
import * as $layout from "../../../../../ds/ds/components/layout.mjs";
import * as $pp from "../../../../../ds/ds/components/profile_picture.mjs";
import * as $tab_bar from "../../../../../ds/ds/components/tab_bar.mjs";
import * as $icons from "../../../../../ds/ds/icons.mjs";
import * as $bool from "../../../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../../../gleam_stdlib/gleam/option.mjs";
import * as $a from "../../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../../lustre/lustre/event.mjs";
import * as $element from "../../../../../sketch_lustre_experimental/sketch/lustre/experimental/element.mjs";
import * as $model from "../../../../data/model.mjs";
import * as $msg from "../../../../data/msg.mjs";
import * as $route from "../../../../data/route.mjs";
import * as $common from "../../../../frontend/view/common.mjs";
import * as $s from "../../../../frontend/view/styles/proposals.mjs";
import { Ok, Error, toList, prepend as listPrepend, makeError, isEqual } from "../../../../gleam.mjs";

export function proposal_back() {
  return $layout.row(
    toList([]),
    toList([]),
    toList([
      $button.link(
        toList([$e.on_click(new $msg.UserClickedBackArrow())]),
        toList([$button.icon($icons.left_arrow()), $h.text("See all proposals")]),
      ),
    ]),
  );
}

export function proposal_title(project) {
  let id = project.id;
  let name = project.name;
  let _pipe = $s.show_title_form(
    toList([$e.on_submit(new $msg.UserSavedProjectTitle(id))]),
    toList([
      $input.input(
        toList([
          $input.value(name),
          $input.id("input-proposal-name"),
          $input.invisible(),
          $input.on_input(
            (_capture) => {
              return new $msg.UserUpdatedProjectTitle(id, _capture);
            },
          ),
          $input.on_blur(new $msg.UserSavedProjectTitle(id)),
        ]),
      ),
    ]),
  );
  return $element.map(_pipe, (var0) => { return new $msg.Project(var0); });
}

function on_export(id) {
  let _pipe = id;
  let _pipe$1 = $option.map(
    _pipe,
    (var0) => { return new $msg.GenerateXlsx(var0); },
  );
  let _pipe$2 = $option.map(_pipe$1, (var0) => { return new $msg.Xlsx(var0); });
  let _pipe$3 = $option.map(_pipe$2, $e.on_click);
  return $option.lazy_unwrap(_pipe$3, $a.none);
}

export function export_button(id) {
  return $button.primary(
    toList([$a.disabled($option.is_none(id)), on_export(id)]),
    toList([$button.icon($icons.export$()), $h.text("Export document")]),
  );
}

export function header(project, proposal) {
  return $layout.column(
    toList([$layout.gap(8)]),
    toList([]),
    toList([
      proposal_back(),
      $layout.row(
        toList([$layout.gap(8), $layout.justify("space-between")]),
        toList([]),
        toList([proposal_title(project), export_button(new Some(proposal.id))]),
      ),
    ]),
  );
}

function mode_tab(active, enabled, on_click, icon, title) {
  return $tab_bar.tab(
    active,
    toList([$a.disabled(!enabled), on_click]),
    toList([
      $layout.row(
        toList([$layout.align("center"), $layout.gap(8)]),
        toList([]),
        toList([$s.details_collaborators_icon(icon), $h.text(title)]),
      ),
    ]),
  );
}

function active_tab(proposal, tab, route, enabled) {
  let $ = (() => {
    if (tab instanceof $route.Blocks) {
      return [$icons.menu(), "Blocks"];
    } else if (tab instanceof $route.Document) {
      return [$icons.table_line(), "Document"];
    } else if (tab instanceof $route.Requirements) {
      return [$icons.book(), "Requirements"];
    } else {
      return [$icons.book(), "Spreadsheet"];
    }
  })();
  let icon = $[0];
  let title = $[1];
  let default_route = $route.default_proposal_tab(proposal);
  if (
    !(route instanceof $route.Projects) ||
    !(route[0] instanceof $route.Proposals)
  ) {
    throw makeError(
      "let_assert",
      "frontend/view/projects/show/common",
      110,
      "active_tab",
      "Pattern match failed, no pattern matched the value.",
      { value: route }
    )
  }
  let id = route[0].id;
  let proposal_ = route[0].proposal;
  let proposal$1 = (() => {
    let _record = proposal_;
    return new $route.ShowProposal(
      _record.id,
      _record.sheet,
      new Some(tab),
      _record.page,
    );
  })();
  let route$1 = new $route.Projects(new $route.Proposals(id, proposal$1));
  let on_click = $e.on_click(new $msg.UserReplacedPage(route$1));
  let active = enabled && (isEqual(
    $option.unwrap(proposal_.tab, default_route),
    tab
  ));
  return mode_tab(active, enabled, on_click, icon, title);
}

export function mode_bar(proposal, route, enabled) {
  return $layout.row(
    toList([]),
    toList([]),
    toList([
      $tab_bar.tab_bar(
        toList([]),
        (() => {
          if (proposal instanceof $option.None) {
            return toList([
              mode_tab(false, false, $a.none(), $icons.menu(), "Blocks"),
            ]);
          } else {
            let proposal$1 = proposal[0];
            return $list.map(
              $route.proposal_tabs(proposal$1),
              (tab) => { return active_tab(proposal$1, tab, route, enabled); },
            );
          }
        })(),
      ),
    ]),
  );
}

function proposal_collaborators_choice(model, project) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let collaborators = listPrepend(project.owner, project.collaborators);
  return $list.filter_map(
    data.organization.users,
    (user) => {
      let is_contributor = $list.any(
        user.roles,
        (u) => { return u.name === "Contributor"; },
      );
      let is_present = $list.contains(collaborators, user.id);
      let is_owner = project.owner === user.id;
      let is_yourself = isEqual(
        $option.map(data.user, (u) => { return u.sub; }),
        new Some(user.id)
      );
      let is_selectable = (is_contributor && !is_owner) && !is_yourself;
      return $bool.guard(
        !is_selectable,
        new Error(undefined),
        () => {
          let _pipe = $dropdown.choice(user.name, user.id);
          let _pipe$1 = $dropdown.with_icon(
            _pipe,
            $pp.from_user(user.picture, user.initials, new $pp.Small()),
          );
          let _pipe$2 = $dropdown.as_selected(_pipe$1, is_present);
          return new Ok(_pipe$2);
        },
      );
    },
  );
}

function proposal_collaborators(model, project, proposal) {
  let data = $bright.data(model);
  let is_owner = isEqual(
    new Some(proposal.owner),
    $option.map(data.user, (u) => { return u.sub; })
  );
  return $bool.lazy_guard(
    !is_owner,
    $el.none,
    () => {
      let collaborators = $project.all_collaborators(project);
      let choices = proposal_collaborators_choice(model, project);
      let on_selected = (_capture) => {
        return new $msg.UserToggledProjectCollaborator(
          proposal.project_id,
          _capture,
        );
      };
      return $layout.row(
        toList([$layout.gap(10), $layout.align("center")]),
        toList([]),
        toList([
          $common.stacked_avatars(collaborators, data.organization.users),
          $dropdown.dropdown(
            toList([
              $dropdown.panel_right(),
              $dropdown.placeholder("Add / Remove collaborator"),
              $dropdown.icon($icons.add_user()),
              $dropdown.disabled($list.is_empty(choices) || !is_owner),
              $dropdown.on_selected(on_selected),
            ]),
            choices,
          ),
        ]),
      );
    },
  );
}

export function tab_actions_bar(model, project, proposal) {
  let route = $bright.data(model).route;
  return $layout.row(
    toList([$layout.justify("space-between")]),
    toList([]),
    toList([
      mode_bar(new Some(proposal), route, true),
      (() => {
        let _pipe = proposal_collaborators(model, project, proposal);
        return $element.map(_pipe, (var0) => { return new $msg.Project(var0); });
      })(),
    ]),
  );
}
