/// <reference types="./document_selector.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $docx from "../../data/docx.mjs";
import * as $kind from "../../data/docx/kind.mjs";
import * as $paragraph from "../../data/docx/paragraph.mjs";
import * as $file from "../../data/ephemeral/file.mjs";
import { CustomType as $CustomType, isEqual } from "../../gleam.mjs";

export class DocumentSelector extends $CustomType {
  constructor(file, document, loading, questions_count, others_count, uncategorised_count) {
    super();
    this.file = file;
    this.document = document;
    this.loading = loading;
    this.questions_count = questions_count;
    this.others_count = others_count;
    this.uncategorised_count = uncategorised_count;
  }
}

export class Question extends $CustomType {}

export class Others extends $CustomType {}

export class Uncategorised extends $CustomType {}

export function new$() {
  return new DocumentSelector(new None(), new None(), false, 0, 0, 0);
}

export function set_document(selector, docx) {
  let _record = selector;
  return new DocumentSelector(
    _record.file,
    new Some(docx),
    _record.loading,
    $list.fold(
      docx.elements,
      0,
      (count, element) => {
        let question = new Some(new $kind.Question());
        if (element instanceof $docx.Table &&
        (isEqual(element[0].metadata.kind, question))) {
          let t = element[0];
          return count + 1;
        } else if (element instanceof $docx.Paragraph &&
        (isEqual(element[0].metadata.kind, question))) {
          let p = element[0];
          return count + 1;
        } else {
          return count;
        }
      },
    ),
    $list.fold(
      docx.elements,
      0,
      (count, element) => {
        let section = new Some(new $kind.Section());
        let subsection = new Some(new $kind.Subsection());
        if (element instanceof $docx.Table &&
        ((isEqual(element[0].metadata.kind, section)) || (isEqual(
          element[0].metadata.kind,
          subsection
        )))) {
          let t = element[0];
          return count + 1;
        } else if (element instanceof $docx.Paragraph &&
        ((isEqual(element[0].metadata.kind, section)) || (isEqual(
          element[0].metadata.kind,
          subsection
        )))) {
          let p = element[0];
          return count + 1;
        } else {
          return count;
        }
      },
    ),
    $list.fold(
      docx.elements,
      0,
      (count, element) => {
        let other = new Some(new $kind.Other());
        if (element instanceof $docx.Table &&
        (isEqual(element[0].metadata.kind, other))) {
          let t = element[0];
          return count + 1;
        } else if (element instanceof $docx.Paragraph) {
          let p = element[0];
          return $bool.guard(
            $paragraph.is_empty(p),
            count,
            () => {
              return $bool.guard(
                isEqual(p.metadata.kind, other),
                count + 1,
                () => { return count; },
              );
            },
          );
        } else {
          return count;
        }
      },
    ),
  );
}

export function update_element(selector, index, kind) {
  let $ = selector.document;
  if ($ instanceof None) {
    return selector;
  } else {
    let document = $[0];
    let _record = selector;
    return new DocumentSelector(
      _record.file,
      new Some(
        (() => {
          let _record$1 = document;
          return new $docx.Docx(
            _record$1.language,
            _record$1.styles,
            $list.map(
              document.elements,
              (element) => {
                let is_element = $docx.index(element) === index;
                return $bool.guard(
                  !is_element,
                  element,
                  () => { return $docx.update_kind(element, kind); },
                );
              },
            ),
          );
        })(),
      ),
      _record.loading,
      _record.questions_count,
      _record.others_count,
      _record.uncategorised_count,
    );
  }
}

export function modify_count(selector, previous_kind, kind) {
  let selector$1 = (() => {
    if (previous_kind instanceof $kind.Section) {
      let others_count = selector.others_count - 1;
      let _record = selector;
      return new DocumentSelector(
        _record.file,
        _record.document,
        _record.loading,
        _record.questions_count,
        others_count,
        _record.uncategorised_count,
      );
    } else if (previous_kind instanceof $kind.Subsection) {
      let others_count = selector.others_count - 1;
      let _record = selector;
      return new DocumentSelector(
        _record.file,
        _record.document,
        _record.loading,
        _record.questions_count,
        others_count,
        _record.uncategorised_count,
      );
    } else if (previous_kind instanceof $kind.Question) {
      let questions_count = selector.questions_count - 1;
      let _record = selector;
      return new DocumentSelector(
        _record.file,
        _record.document,
        _record.loading,
        questions_count,
        _record.others_count,
        _record.uncategorised_count,
      );
    } else if (previous_kind instanceof $kind.Other) {
      let uncategorised_count = selector.uncategorised_count - 1;
      let _record = selector;
      return new DocumentSelector(
        _record.file,
        _record.document,
        _record.loading,
        _record.questions_count,
        _record.others_count,
        uncategorised_count,
      );
    } else {
      return selector;
    }
  })();
  if (kind instanceof $kind.Section) {
    let others_count = selector$1.others_count + 1;
    let _record = selector$1;
    return new DocumentSelector(
      _record.file,
      _record.document,
      _record.loading,
      _record.questions_count,
      others_count,
      _record.uncategorised_count,
    );
  } else if (kind instanceof $kind.Subsection) {
    let others_count = selector$1.others_count + 1;
    let _record = selector$1;
    return new DocumentSelector(
      _record.file,
      _record.document,
      _record.loading,
      _record.questions_count,
      others_count,
      _record.uncategorised_count,
    );
  } else if (kind instanceof $kind.Question) {
    let questions_count = selector$1.questions_count + 1;
    let _record = selector$1;
    return new DocumentSelector(
      _record.file,
      _record.document,
      _record.loading,
      questions_count,
      _record.others_count,
      _record.uncategorised_count,
    );
  } else if (kind instanceof $kind.Other) {
    let uncategorised_count = selector$1.uncategorised_count + 1;
    let _record = selector$1;
    return new DocumentSelector(
      _record.file,
      _record.document,
      _record.loading,
      _record.questions_count,
      _record.others_count,
      uncategorised_count,
    );
  } else {
    return selector$1;
  }
}

export function get_language(selector) {
  return $option.map(
    selector.document,
    (document) => { return document.language; },
  );
}
