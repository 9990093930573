/// <reference types="./route.d.mts" />
import * as $proposal from "../../common/data/proposal.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $modem from "../../modem/modem.mjs";
import { Ok, Error, toList, CustomType as $CustomType, isEqual } from "../gleam.mjs";

export class Account extends $CustomType {}

export class Collaborators extends $CustomType {}

export class Integrations extends $CustomType {}

export class OnGoing extends $CustomType {}

export class Previous extends $CustomType {}

export class ShowProject extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class AddProposal extends $CustomType {
  constructor(project_id, loading) {
    super();
    this.project_id = project_id;
    this.loading = loading;
  }
}

export class Proposals extends $CustomType {
  constructor(id, proposal) {
    super();
    this.id = id;
    this.proposal = proposal;
  }
}

export class ShowProposal extends $CustomType {
  constructor(id, sheet, tab, page) {
    super();
    this.id = id;
    this.sheet = sheet;
    this.tab = tab;
    this.page = page;
  }
}

export class Blocks extends $CustomType {}

export class Document extends $CustomType {}

export class Requirements extends $CustomType {}

export class Spreadsheet extends $CustomType {}

export class AiSearch extends $CustomType {}

export class AiResponse extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class DataSources extends $CustomType {
  constructor(page, source_id) {
    super();
    this.page = page;
    this.source_id = source_id;
  }
}

export class QuestionBank extends $CustomType {
  constructor(page, source_id) {
    super();
    this.page = page;
    this.source_id = source_id;
  }
}

export class Insights extends $CustomType {}

export class NewQuestion extends $CustomType {}

export class Ingestion extends $CustomType {}

export class Overview extends $CustomType {}

export class ContentLibrary extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Projects extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class CoPilot extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Settings extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Organization extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Analytics extends $CustomType {}

export class Support extends $CustomType {}

export class Login extends $CustomType {
  constructor(invitation, organization) {
    super();
    this.invitation = invitation;
    this.organization = organization;
  }
}

export class SlackLogin extends $CustomType {
  constructor(team_id, user_id) {
    super();
    this.team_id = team_id;
    this.user_id = user_id;
  }
}

export class Editor extends $CustomType {}

export class NotFound extends $CustomType {}

function optional_query_find(query, a) {
  let _pipe = $list.key_find(query, a);
  return $option.from_result(_pipe);
}

export function extract_page(uri) {
  let _pipe = $option.to_result(uri.query, undefined);
  let _pipe$1 = $result.then$(_pipe, $uri.parse_query);
  let _pipe$2 = $result.then$(
    _pipe$1,
    (_capture) => { return $list.key_find(_capture, "page"); },
  );
  let _pipe$3 = $result.then$(_pipe$2, $int.parse);
  let _pipe$4 = $result.unwrap(_pipe$3, 1);
  return $int.subtract(_pipe$4, 1);
}

function extract_sheet(uri) {
  let _pipe = $option.to_result(uri.query, undefined);
  let _pipe$1 = $result.then$(_pipe, $uri.parse_query);
  let _pipe$2 = $result.map(
    _pipe$1,
    (_capture) => { return optional_query_find(_capture, "sheet"); },
  );
  return $result.unwrap(_pipe$2, new $option.None());
}

function extract_tab(uri) {
  let _pipe = $option.to_result(uri.query, undefined);
  let _pipe$1 = $result.then$(_pipe, $uri.parse_query);
  let _pipe$2 = $result.map(
    _pipe$1,
    (_capture) => { return optional_query_find(_capture, "tab"); },
  );
  let _pipe$3 = $result.then$(
    _pipe$2,
    (tab) => {
      if (tab instanceof Some && tab[0] === "document") {
        return new Ok(new Document());
      } else if (tab instanceof Some && tab[0] === "requirements") {
        return new Ok(new Requirements());
      } else if (tab instanceof Some && tab[0] === "blocks") {
        return new Ok(new Blocks());
      } else if (tab instanceof Some && tab[0] === "spreadsheet") {
        return new Ok(new Spreadsheet());
      } else {
        return new Error(undefined);
      }
    },
  );
  return $option.from_result(_pipe$3);
}

function extract_source_id(uri) {
  let _pipe = $option.to_result(uri.query, undefined);
  let _pipe$1 = $result.then$(_pipe, $uri.parse_query);
  let _pipe$2 = $result.map(
    _pipe$1,
    (_capture) => { return optional_query_find(_capture, "source_id"); },
  );
  return $result.unwrap(_pipe$2, new $option.None());
}

export function from_uri(uri) {
  let $ = $uri.path_segments(uri.path);
  if ($.hasLength(0)) {
    return new Overview();
  } else if ($.hasLength(1) && $.head === "content-library") {
    let page = extract_page(uri);
    let source_id = extract_source_id(uri);
    return new ContentLibrary(new DataSources(page, source_id));
  } else if ($.hasLength(1) && $.head === "question-bank") {
    let page = extract_page(uri);
    let source_id = extract_source_id(uri);
    return new ContentLibrary(new QuestionBank(page, source_id));
  } else if ($.hasLength(1) && $.head === "insights") {
    return new ContentLibrary(new Insights());
  } else if ($.hasLength(2) &&
  $.head === "question-bank" &&
  $.tail.head === "new") {
    return new ContentLibrary(new NewQuestion());
  } else if ($.hasLength(2) &&
  $.head === "question-bank" &&
  $.tail.head === "ingestion") {
    return new ContentLibrary(new Ingestion());
  } else if ($.hasLength(1) && $.head === "projects") {
    return new Projects(new OnGoing());
  } else if ($.hasLength(2) &&
  $.head === "projects" &&
  $.tail.head === "previous") {
    return new Projects(new Previous());
  } else if ($.hasLength(1) && $.head === "co-pilot") {
    return new CoPilot(new AiSearch());
  } else if ($.hasLength(2) && $.head === "co-pilot") {
    let id = $.tail.head;
    return new CoPilot(new AiResponse(id));
  } else if ($.hasLength(1) && $.head === "settings") {
    return new Settings(new Account());
  } else if ($.hasLength(1) && $.head === "editor") {
    return new Editor();
  } else if ($.hasLength(2) &&
  $.head === "organization" &&
  $.tail.head === "collaborators") {
    return new Organization(new Collaborators());
  } else if ($.hasLength(2) &&
  $.head === "organization" &&
  $.tail.head === "integrations") {
    return new Organization(new Integrations());
  } else if ($.hasLength(2) && $.head === "slack" && $.tail.head === "login") {
    let _pipe = $uri.parse_query($option.unwrap(uri.query, ""));
    let _pipe$1 = $result.then$(
      _pipe,
      (query) => {
        return $result.try$(
          $list.key_find(query, "slack_team_id"),
          (team_id) => {
            return $result.try$(
              $list.key_find(query, "slack_user_id"),
              (user_id) => { return new Ok(new SlackLogin(team_id, user_id)); },
            );
          },
        );
      },
    );
    return $result.unwrap(_pipe$1, new NotFound());
  } else if ($.hasLength(1) && $.head === "login") {
    let _pipe = $option.to_result(uri.query, undefined);
    let _pipe$1 = $result.then$(_pipe, $uri.parse_query);
    let _pipe$2 = $result.map(
      _pipe$1,
      (query) => {
        let invitation = optional_query_find(query, "invitation");
        let organization = optional_query_find(query, "organization");
        return new Login(invitation, organization);
      },
    );
    return $result.unwrap(_pipe$2, new Login(new None(), new None()));
  } else if ($.hasLength(1) && $.head === "analytics") {
    return new Analytics();
  } else if ($.hasLength(1) && $.head === "support") {
    return new Support();
  } else if ($.hasLength(2) && $.head === "projects") {
    let id = $.tail.head;
    return new Projects(new ShowProject(id));
  } else if ($.hasLength(3) &&
  $.head === "projects" &&
  $.tail.head === "proposals" &&
  $.tail.tail.head === "add") {
    let _pipe = $option.to_result(uri.query, undefined);
    let _pipe$1 = $result.then$(_pipe, $uri.parse_query);
    let _pipe$2 = $result.map(
      _pipe$1,
      (_capture) => { return optional_query_find(_capture, "project_id"); },
    );
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => { return new AddProposal(_capture, false); },
    );
    let _pipe$4 = $result.unwrap(_pipe$3, new AddProposal(new None(), false));
    return new Projects(_pipe$4);
  } else if ($.hasLength(4) &&
  $.head === "projects" &&
  $.tail.tail.head === "proposals") {
    let id = $.tail.head;
    let pid = $.tail.tail.tail.head;
    let page = extract_page(uri);
    let sheet = extract_sheet(uri);
    let tab = extract_tab(uri);
    let _pipe = new ShowProposal(pid, sheet, tab, page);
    let _pipe$1 = ((_capture) => { return new Proposals(id, _capture); })(_pipe);
    return new Projects(_pipe$1);
  } else {
    return new NotFound();
  }
}

function set_query_string(uri, query) {
  let query$1 = $uri.query_to_string(query);
  let _record = uri;
  return new $uri.Uri(
    _record.scheme,
    _record.userinfo,
    _record.host,
    _record.port,
    _record.path,
    new Some(query$1),
    _record.fragment,
  );
}

function not_found() {
  return new $uri.Uri(
    new None(),
    new None(),
    new None(),
    new None(),
    "#",
    new None(),
    new None(),
  );
}

export function to_uri(route) {
  let _pipe = (() => {
    if (route instanceof Overview) {
      return $uri.parse("/");
    } else if (route instanceof ContentLibrary &&
    route[0] instanceof DataSources) {
      let page = route[0].page;
      let source_id = route[0].source_id;
      return $result.map(
        $uri.parse("/content-library"),
        (uri) => {
          let source_id$1 = (() => {
            let _pipe = $option.map(
              source_id,
              (qid) => { return toList([["source_id", qid]]); },
            );
            return $option.unwrap(_pipe, toList([]));
          })();
          let _pipe = toList([["page", $int.to_string(page + 1)]]);
          let _pipe$1 = $list.append(_pipe, source_id$1);
          return ((_capture) => { return set_query_string(uri, _capture); })(
            _pipe$1,
          );
        },
      );
    } else if (route instanceof ContentLibrary &&
    route[0] instanceof QuestionBank) {
      let page = route[0].page;
      let source_id = route[0].source_id;
      return $result.map(
        $uri.parse("/question-bank"),
        (uri) => {
          let source_id$1 = (() => {
            let _pipe = $option.map(
              source_id,
              (qid) => { return toList([["source_id", qid]]); },
            );
            return $option.unwrap(_pipe, toList([]));
          })();
          let _pipe = toList([["page", $int.to_string(page + 1)]]);
          let _pipe$1 = $list.append(_pipe, source_id$1);
          return ((_capture) => { return set_query_string(uri, _capture); })(
            _pipe$1,
          );
        },
      );
    } else if (route instanceof ContentLibrary && route[0] instanceof Insights) {
      return $uri.parse("/insights");
    } else if (route instanceof ContentLibrary &&
    route[0] instanceof NewQuestion) {
      return $uri.parse("/question-bank/new");
    } else if (route instanceof ContentLibrary && route[0] instanceof Ingestion) {
      return $uri.parse("/question-bank/ingestion");
    } else if (route instanceof Projects && route[0] instanceof OnGoing) {
      return $uri.parse("/projects");
    } else if (route instanceof Projects && route[0] instanceof Previous) {
      return $uri.parse("/projects/previous");
    } else if (route instanceof Projects && route[0] instanceof AddProposal) {
      let project_id = route[0].project_id;
      return $result.map(
        $uri.parse("/projects/proposals/add"),
        (uri) => {
          let _pipe = project_id;
          let _pipe$1 = $option.map(
            _pipe,
            (id) => {
              let _record = uri;
              return new $uri.Uri(
                _record.scheme,
                _record.userinfo,
                _record.host,
                _record.port,
                _record.path,
                new Some($uri.query_to_string(toList([["project_id", id]]))),
                _record.fragment,
              );
            },
          );
          return $option.unwrap(_pipe$1, uri);
        },
      );
    } else if (route instanceof CoPilot && route[0] instanceof AiSearch) {
      return $uri.parse("/co-pilot");
    } else if (route instanceof CoPilot && route[0] instanceof AiResponse) {
      let id = route[0][0];
      return $uri.parse("/co-pilot/" + id);
    } else if (route instanceof Settings && route[0] instanceof Account) {
      return $uri.parse("/settings");
    } else if (route instanceof Organization &&
    route[0] instanceof Collaborators) {
      return $uri.parse("/organization/collaborators");
    } else if (route instanceof Organization && route[0] instanceof Integrations) {
      return $uri.parse("/organization/integrations");
    } else if (route instanceof Analytics) {
      return $uri.parse("/analytics");
    } else if (route instanceof Support) {
      return $uri.parse("/support");
    } else if (route instanceof SlackLogin) {
      let team_id = route.team_id;
      let user_id = route.user_id;
      let _pipe = $uri.parse("/slack/login");
      return $result.map(
        _pipe,
        (uri) => {
          let _pipe$1 = toList([
            ["slack_team_id", team_id],
            ["slack_user_id", user_id],
          ]);
          let _pipe$2 = $uri.query_to_string(_pipe$1);
          return ((query) => {
            let _record = uri;
            return new $uri.Uri(
              _record.scheme,
              _record.userinfo,
              _record.host,
              _record.port,
              _record.path,
              new Some(query),
              _record.fragment,
            );
          })(_pipe$2);
        },
      );
    } else if (route instanceof Editor) {
      return $uri.parse("/editor");
    } else if (route instanceof NotFound) {
      return new Ok(not_found());
    } else if (route instanceof Login) {
      let invitation = route.invitation;
      let organization = route.organization;
      return $result.map(
        $uri.parse("/login"),
        (uri) => {
          if (invitation instanceof Some && organization instanceof Some) {
            let i = invitation[0];
            let o = organization[0];
            let query = $uri.query_to_string(
              toList([["invitation", i], ["organization", o]]),
            );
            let _record = uri;
            return new $uri.Uri(
              _record.scheme,
              _record.userinfo,
              _record.host,
              _record.port,
              _record.path,
              new Some(query),
              _record.fragment,
            );
          } else {
            return uri;
          }
        },
      );
    } else if (route instanceof Projects && route[0] instanceof ShowProject) {
      let id = route[0].id;
      return $uri.parse("/projects/" + id);
    } else {
      let id = route[0].id;
      let pid = route[0].proposal.id;
      let sheet = route[0].proposal.sheet;
      let tab = route[0].proposal.tab;
      let page = route[0].proposal.page;
      let endpoint = (("/projects/" + id) + "/proposals/") + pid;
      return $result.map(
        $uri.parse(endpoint),
        (uri) => {
          let sheet$1 = (() => {
            let _pipe = $option.map(
              sheet,
              (s) => { return toList([["sheet", s]]); },
            );
            return $option.unwrap(_pipe, toList([]));
          })();
          let tab$1 = (() => {
            let _pipe = $option.map(
              tab,
              (t) => {
                return toList([
                  [
                    "tab",
                    (() => {
                      if (t instanceof Document) {
                        return "document";
                      } else if (t instanceof Requirements) {
                        return "requirements";
                      } else if (t instanceof Blocks) {
                        return "blocks";
                      } else {
                        return "spreadsheet";
                      }
                    })(),
                  ],
                ]);
              },
            );
            return $option.unwrap(_pipe, toList([]));
          })();
          let _pipe = toList([["page", $int.to_string(page + 1)]]);
          let _pipe$1 = $list.append(_pipe, sheet$1);
          let _pipe$2 = $list.append(_pipe$1, tab$1);
          let _pipe$3 = $uri.query_to_string(_pipe$2);
          let _pipe$4 = new Some(_pipe$3);
          return ((query) => {
            let _record = uri;
            return new $uri.Uri(
              _record.scheme,
              _record.userinfo,
              _record.host,
              _record.port,
              _record.path,
              query,
              _record.fragment,
            );
          })(_pipe$4);
        },
      );
    }
  })();
  return $result.lazy_unwrap(_pipe, not_found);
}

export function to_title(route) {
  if (route instanceof Overview) {
    return "Overview";
  } else if (route instanceof ContentLibrary) {
    return "Content Library";
  } else if (route instanceof Projects) {
    return "Proposals";
  } else if (route instanceof CoPilot) {
    return "Co-pilot";
  } else if (route instanceof Settings && route[0] instanceof Account) {
    return "Settings";
  } else if (route instanceof Organization && route[0] instanceof Collaborators) {
    return "Organization Members";
  } else if (route instanceof Organization && route[0] instanceof Integrations) {
    return "Organization Integrations";
  } else if (route instanceof Analytics) {
    return "Analytics";
  } else if (route instanceof Support) {
    return "Support";
  } else if (route instanceof Login) {
    return "Login";
  } else if (route instanceof SlackLogin) {
    return "Slack Login";
  } else if (route instanceof Editor) {
    return "Editor";
  } else {
    return "Not Found";
  }
}

export function push(route) {
  let uri = to_uri(route);
  return $modem.push(uri.path, uri.query, uri.fragment);
}

export function replace(route) {
  let uri = to_uri(route);
  return $modem.replace(uri.path, uri.query, uri.fragment);
}

export function current_proposal_id(route) {
  if (route instanceof Projects &&
  route[0] instanceof Proposals &&
  route[0].proposal instanceof ShowProposal) {
    let pid = route[0].proposal.id;
    return new Ok(pid);
  } else {
    return new Error(undefined);
  }
}

export function is_already_on_proposal(route, proposal_id) {
  return isEqual(current_proposal_id(route), new Ok(proposal_id));
}

export function is_question_bank(route) {
  if (route instanceof ContentLibrary && route[0] instanceof QuestionBank) {
    return true;
  } else {
    return false;
  }
}

export function get_project_id(route) {
  if (route instanceof Projects && route[0] instanceof AddProposal) {
    let project_id = route[0].project_id;
    return project_id;
  } else {
    return new $option.None();
  }
}

export function proposal_tabs(proposal) {
  let $ = proposal.metadata;
  if ($ instanceof $proposal.Document) {
    return toList([new Blocks(), new Document()]);
  } else if ($ instanceof $proposal.Narrative) {
    return toList([new Document(), new Requirements()]);
  } else if ($ instanceof $proposal.Portal) {
    return toList([new Blocks()]);
  } else {
    return toList([new Blocks()]);
  }
}

export function default_proposal_tab(proposal) {
  let $ = proposal.metadata;
  if ($ instanceof $proposal.Document) {
    return new Blocks();
  } else if ($ instanceof $proposal.Narrative) {
    return new Document();
  } else if ($ instanceof $proposal.Portal) {
    return new Blocks();
  } else {
    return new Blocks();
  }
}
