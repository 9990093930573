/// <reference types="./copilot.d.mts" />
import * as $copilot_question from "../../../common/data/copilot_question.mjs";
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $promise from "../../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $timestamp from "../../../gleam_time/gleam/time/timestamp.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $crypto from "../../frontend/browser/crypto.mjs";
import * as $warn from "../../frontend/error/warn.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { toList } from "../../gleam.mjs";

export function submit_copilot(data, thread_id) {
  let input = data.copilot_input;
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_org_id(
        data,
        (org_id) => {
          return $middleware.require_user_id(
            data,
            (user_id) => {
              return $effect.from(
                (dispatch) => {
                  return $function.tap(
                    undefined,
                    (_) => {
                      let question = new $copilot_question.CopilotQuestion(
                        $crypto.uuid(),
                        thread_id,
                        new $copilot_question.Display(new $option.None()),
                        input.value,
                        user_id,
                        new $copilot_question.Answer(
                          new $option.None(),
                          new $option.None(),
                          new $option.None(),
                          new $option.None(),
                        ),
                        org_id,
                        true,
                        toList([]),
                        $timestamp.system_time(),
                        true,
                      );
                      dispatch(
                        new $msg.Copilot(
                          new $msg.ApiReturnedCopilotQuestion(
                            question,
                            toList([]),
                            toList([]),
                          ),
                        ),
                      );
                      let at = toList(["copilot", thread_id]);
                      let _pipe = $ask.to(new $ask.Heimdall(), at);
                      let _pipe$1 = $ask.bearing(_pipe, access_token);
                      let _pipe$2 = $ask.via(_pipe$1, new $http.Post());
                      let _pipe$3 = $ask.with$(
                        _pipe$2,
                        $json.object(
                          toList([
                            ["question", $copilot_question.encode(question)],
                          ]),
                        ),
                      );
                      let _pipe$4 = $ask.expect(
                        _pipe$3,
                        $decode.field(
                          "question",
                          $copilot_question.decoder(),
                          (question) => {
                            let data_sources_decoder = $decode.list(
                              $data_source.decoder(),
                            );
                            return $decode.field(
                              "data_sources",
                              data_sources_decoder,
                              (data_sources) => {
                                let data_points_decoder = $decode.list(
                                  $data_point.decoder(),
                                );
                                return $decode.field(
                                  "data_points",
                                  data_points_decoder,
                                  (data_points) => {
                                    return $decode.success(
                                      [question, data_sources, data_points],
                                    );
                                  },
                                );
                              },
                            );
                          },
                        ),
                      );
                      let _pipe$5 = $ask.notify(
                        _pipe$4,
                        (q) => {
                          let question$1 = q[0];
                          let data_sources = q[1];
                          let data_points = q[2];
                          let _pipe$5 = new $msg.ApiReturnedCopilotQuestion(
                            question$1,
                            data_sources,
                            data_points,
                          );
                          let _pipe$6 = new $msg.Copilot(_pipe$5);
                          return dispatch(_pipe$6);
                        },
                      );
                      let _pipe$6 = $ask.run(_pipe$5);
                      $promise.tap(
                        _pipe$6,
                        (result) => {
                          if (!result.isOk()) {
                            return $warn.copilot_error(dispatch);
                          } else {
                            return undefined;
                          }
                        },
                      )
                      return undefined;
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function fetch_copilot_questions(_) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let data_sources = $decode.list($data_source.decoder());
              let data_sources_decoder = $decode.dict(
                $decode.string,
                data_sources,
              );
              let data_points = $decode.list($data_point.decoder());
              let data_points_decoder = $decode.dict(
                $decode.string,
                data_points,
              );
              let _pipe = $ask.to(new $ask.Heimdall(), toList(["copilot"]));
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.expect(
                _pipe$1,
                (() => {
                  let copilot_questions_decoder = $decode.list(
                    $copilot_question.decoder(),
                  );
                  return $decode.field(
                    "questions",
                    copilot_questions_decoder,
                    (questions) => {
                      return $decode.field(
                        "data_sources",
                        data_sources_decoder,
                        (data_sources) => {
                          return $decode.field(
                            "data_points",
                            data_points_decoder,
                            (data_points) => {
                              return $decode.success(
                                [questions, data_sources, data_points],
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                })(),
              );
              let _pipe$3 = $ask.notify(
                _pipe$2,
                (t) => {
                  return dispatch(
                    new $msg.Copilot(
                      new $msg.ApiReturnedCopilotThreads(t[0], t[1], t[2]),
                    ),
                  );
                },
              );
              $ask.run(_pipe$3)
              return undefined;
            },
          );
        },
      );
    },
  );
}
