/// <reference types="./organization.d.mts" />
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $text from "../../../../ds/ds/typography/text.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../../gleam.mjs";

export function members(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(16)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function invitation_block(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.align_items("start"),
      $css.gap(px(16)),
    ]),
  );
  return $h.form(_pipe, attrs, children);
}

export function connectors_block(attrs, children) {
  let _pipe = $css.class$(toList([$css.display("flex"), $css.gap(px(32))]));
  return $h.div(_pipe, attrs, children);
}

export function connectors_card(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.align_items("center"),
      $css.padding_("0px 48px 32px 48px"),
      $css.gap(px(16)),
      $css.flex("1"),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connectors_spacer(attrs, children) {
  let _pipe = $css.class$(toList([$css.flex("1")]));
  return $h.div(_pipe, attrs, children);
}

export function connector_titles(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connector_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($text.xl($text.medium))]));
  return $h.div(_pipe, attrs, children);
}

export function connector_content(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.regular)),
      $css.color($colors.light.paragraph),
      $css.text_align("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connectors_row(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.gap(px(32)),
      $css.justify_content("space-between"),
      $css.align_items("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connectors_description(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.gap(px(16)), $css.align_items("center")]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connectors_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($text.md($text.regular))]));
  return $h.div(_pipe, attrs, children);
}

export function connectors_main_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($text.md($text.medium))]));
  return $h.div(_pipe, attrs, children);
}

export function connector_row_actions(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.align_items("center"), $css.gap(px(12))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function connectors_icon(icon) {
  let _pipe = $css.class$(toList([$css.width(px(60)), $css.height(px(60))]));
  return $h.div(_pipe, toList([]), toList([icon]));
}

export function connectors_card_icon(icon) {
  let _pipe = $css.class$(toList([$css.width(px(128)), $css.height(px(128))]));
  return $h.div(_pipe, toList([]), toList([icon]));
}
