import * as prelude from '#gleam/prelude'

export async function toBitArray(file) {
  try {
    const content = await file.arrayBuffer()
    const bitArray = prelude.toBitArray(new Uint8Array(content))
    return new prelude.Ok(bitArray)
  } catch (error) {
    return new prelude.Error()
  }
}

export function toJSON(data) {
  try {
    return JSON.stringify(data)
  } catch (error) {
    return ''
  }
}
