/// <reference types="./copilot.d.mts" />
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $route from "../../data/route.mjs";
import * as $crypto from "../../frontend/browser/crypto.mjs";
import * as $copilot_effects from "../../frontend/effects/copilot.mjs";
import { toList } from "../../gleam.mjs";

export function update(data, msg) {
  if (msg instanceof $msg.ApiReturnedCopilotThreads) {
    let questions = msg.questions;
    let data_sources = msg.data_sources;
    let data_points = msg.data_points;
    let _pipe = data;
    let _pipe$1 = $data.add_copilot_questions(_pipe, questions);
    let _pipe$2 = $data.add_questions_data_sources(_pipe$1, data_sources);
    let _pipe$3 = $data.add_questions_data_points(_pipe$2, data_points);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserSubmittedCopilotInput) {
    let thread_id = $crypto.uuid();
    let _pipe = $route.push(
      new $route.CoPilot(new $route.AiResponse(thread_id)),
    );
    let _pipe$1 = ((_capture) => {
      return $list.prepend(
        toList([$copilot_effects.submit_copilot(data, thread_id)]),
        _capture,
      );
    })(_pipe);
    let _pipe$2 = $effect.batch(_pipe$1);
    return ((_capture) => {
      return $pair.new$($data.reset_copilot_input(data), _capture);
    })(_pipe$2);
  } else if (msg instanceof $msg.ApiReturnedCopilotQuestion) {
    let question = msg.question;
    let data_sources = msg.data_sources;
    let data_points = msg.data_points;
    let data_sources$1 = (() => {
      let _pipe = $dict.new$();
      return $dict.insert(_pipe, question.id, data_sources);
    })();
    let data_points$1 = (() => {
      let _pipe = $dict.new$();
      return $dict.insert(_pipe, question.id, data_points);
    })();
    let _pipe = data;
    let _pipe$1 = $data.add_copilot_questions(_pipe, toList([question]));
    let _pipe$2 = $data.add_questions_data_sources(_pipe$1, data_sources$1);
    let _pipe$3 = $data.add_questions_data_points(_pipe$2, data_points$1);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserEdittedCopilotInput) {
    let content = msg[0];
    let _pipe = data;
    let _pipe$1 = $data.update_copilot_input(_pipe, content);
    return $pair.new$(_pipe$1, $effect.none());
  } else {
    let question = msg.question;
    let answer = msg.answer;
    let _pipe = (() => {
      let _record = $content_library.new_qna();
      return new $content_library.NewQna(
        answer,
        _record.existing_document,
        _record.loading,
        question,
      );
    })();
    let _pipe$1 = ((_capture) => {
      return $content_library.set_new_qna(data.content_library, _capture);
    })(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $route.push(new $route.ContentLibrary(new $route.NewQuestion())),
    );
  }
}
