/// <reference types="./insights.d.mts" />
import * as $bright from "../../../../bright/bright.mjs";
import * as $data_point from "../../../../common/data/data_point.mjs";
import * as $data_source from "../../../../common/data/data_source.mjs";
import * as $insight from "../../../../common/data/insight.mjs";
import * as $tag from "../../../../common/data/tag.mjs";
import * as $button from "../../../../ds/ds/components/button.mjs";
import * as $label from "../../../../ds/ds/components/label.mjs";
import * as $layout from "../../../../ds/ds/components/layout.mjs";
import * as $tooltip from "../../../../ds/ds/components/tooltip.mjs";
import * as $icons from "../../../../ds/ds/icons.mjs";
import * as $ds_utils from "../../../../ds/ds/utils.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../../gleam_stdlib/gleam/dict.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../../lustre/lustre/event.mjs";
import * as $mime_types from "../../../../mime_types/mime_types.mjs";
import * as $model from "../../../data/model.mjs";
import * as $computed from "../../../data/model/computed.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $common from "../../../frontend/view/common.mjs";
import * as $cl_common from "../../../frontend/view/content_library/common.mjs";
import * as $s from "../../../frontend/view/styles/insights.mjs";
import { Ok, Error, toList, CustomType as $CustomType, isEqual } from "../../../gleam.mjs";

class InsightCard extends $CustomType {
  constructor(conflicts_data_points, insight, index, nb_insights, selected_id, selected_insight_id, all_sources, tags) {
    super();
    this.conflicts_data_points = conflicts_data_points;
    this.insight = insight;
    this.index = index;
    this.nb_insights = nb_insights;
    this.selected_id = selected_id;
    this.selected_insight_id = selected_insight_id;
    this.all_sources = all_sources;
    this.tags = tags;
  }
}

function title(index, nb_insights, kind) {
  let title$1 = (() => {
    if (kind instanceof $insight.Conflict) {
      return "Conflictual information";
    } else if (kind instanceof $insight.Duplicate) {
      return "Duplicate";
    } else {
      return "Irrelevant document";
    }
  })();
  let index$1 = $int.to_string(index + 1);
  let nb_insights$1 = $int.to_string(nb_insights);
  return $s.title(
    toList([]),
    toList([$h.text((((title$1 + " ") + index$1) + "/") + nb_insights$1)]),
  );
}

function display_qna(question, answer, text) {
  if (text instanceof $option.Some) {
    let text$1 = text[0];
    return $h.div(
      toList([
        $a.style(
          toList([["white-space", "pre-line"], ["line-height", "1.5em"]]),
        ),
      ]),
      toList([$h.text(text$1)]),
    );
  } else {
    return $layout.column(
      toList([$layout.gap(8)]),
      toList([]),
      toList([
        $h.div(toList([]), toList([$h.text("Question: " + question)])),
        $h.div(toList([]), toList([$h.text("Answer: " + answer)])),
      ]),
    );
  }
}

function source_name(data_source) {
  let mime_type = data_source.display.mime_type;
  let name = (() => {
    if (mime_type instanceof $mime_types.Qna) {
      return "Question bank";
    } else {
      return data_source.display.name;
    }
  })();
  return $s.source_name(
    toList([]),
    toList([
      $common.small_icon($ds_utils.select_icon(mime_type)),
      $h.div(toList([]), toList([$h.text(name)])),
    ]),
  );
}

function duplicate_source_name(data_source) {
  let mime_type = data_source.display.mime_type;
  let name = (() => {
    if (mime_type instanceof $mime_types.Qna) {
      return "Question bank";
    } else {
      return data_source.display.name;
    }
  })();
  return $s.duplicate_source_name(
    toList([]),
    toList([
      $common.small_icon($ds_utils.select_icon(mime_type)),
      $h.div(toList([]), toList([$h.text(name)])),
    ]),
  );
}

function fix_enabled(card, first_id, second_id) {
  let _pipe = (() => {
    let $ = card.selected_id;
    if ($ instanceof $option.Some) {
      let id = $[0];
      return (id === first_id) || (id === second_id);
    } else {
      return false;
    }
  })();
  return $bool.and(
    _pipe,
    (() => {
      let $ = card.selected_insight_id;
      if ($ instanceof $option.Some) {
        let selected_insight_id = $[0];
        return selected_insight_id === card.insight.id;
      } else {
        return false;
      }
    })(),
  );
}

function conflict_card(data_point, is_selected, data_source, insight_id, tags) {
  let on_click = $e.on_click(
    new $msg.ContentLibrary(
      new $msg.UserSelectedInsightCard(data_point.id, insight_id),
    ),
  );
  let tags$1 = $list.map(
    tags,
    (tag) => {
      return $label.label(toList([$label.neutral(), $label.content(tag.name)]));
    },
  );
  let card = (() => {
    let $ = data_point.display;
    if ($ instanceof $data_point.DisplayText) {
      let as_html = $.as_html;
      let text = (() => {
        let _pipe = as_html;
        return $option.unwrap(_pipe, data_point.text);
      })();
      return $s.duplicate_or_conflict_card(
        toList([$a.attribute("dangerous-unescaped-html", text), on_click]),
        toList([]),
        is_selected,
      );
    } else {
      let question = $.question;
      let answer = $.answer;
      let text = (() => {
        let $1 = data_source.display.mime_type;
        if ($1 instanceof $mime_types.Qna) {
          return new $option.None();
        } else {
          return new $option.Some(data_point.text);
        }
      })();
      return $s.duplicate_or_conflict_card(
        toList([on_click]),
        toList([display_qna(question, answer, text)]),
        is_selected,
      );
    }
  })();
  let source_name_and_tags = $layout.row(
    toList([]),
    toList([]),
    toList([source_name(data_source), $s.tags(toList([]), tags$1)]),
  );
  return $s.card_with_source_name(
    toList([]),
    toList([card, source_name_and_tags]),
  );
}

function duplicate_card(data_source, selected_id, insight_id, tags) {
  let on_click = $e.on_click(
    new $msg.ContentLibrary(
      new $msg.UserSelectedInsightCard(data_source.id, insight_id),
    ),
  );
  let tags$1 = $list.map(
    tags,
    (tag) => {
      return $label.label(toList([$label.neutral(), $label.content(tag.name)]));
    },
  );
  let text = (() => {
    let $ = data_source.display.qna;
    if ($ instanceof $option.Some) {
      let qna = $[0];
      return display_qna(qna.question, qna.answer, new $option.None());
    } else {
      return duplicate_source_name(data_source);
    }
  })();
  let card = $s.duplicate_or_conflict_card(
    toList([on_click]),
    toList([text]),
    $option.unwrap(selected_id, "") === data_source.id,
  );
  let source_name_and_tags = $layout.row(
    toList([]),
    toList([]),
    toList([source_name(data_source), $s.tags(toList([]), tags$1)]),
  );
  let $ = data_source.display.qna;
  if ($ instanceof $option.Some) {
    return $s.card_with_source_name(
      toList([]),
      toList([card, source_name_and_tags]),
    );
  } else {
    return $s.source_card(toList([]), toList([card]));
  }
}

function irrelevant_card(data_source, details, insight_id) {
  let on_click = $e.on_click(
    new $msg.ContentLibrary(
      new $msg.UserSelectedInsightCard(data_source.id, insight_id),
    ),
  );
  let card = (() => {
    let $ = data_source.display.qna;
    if ($ instanceof $option.Some) {
      let qna = $[0];
      return $s.irrelevant_card(
        toList([on_click]),
        toList([display_qna(qna.question, qna.answer, new $option.None())]),
      );
    } else {
      return $s.irrelevant_card(
        toList([on_click]),
        toList([$s.irrelevant_details(toList([]), toList([$h.text(details)]))]),
      );
    }
  })();
  return $s.card_with_source_name(
    toList([]),
    toList([card, source_name(data_source)]),
  );
}

function card_is_selected(card, data_point) {
  return ($option.unwrap(card.selected_id, "") === data_point.id) && ($option.unwrap(
    card.selected_insight_id,
    "",
  ) === card.insight.id);
}

function on_fix(insight, chosen_id, deleted_id) {
  let $ = insight.kind;
  if ($ instanceof $insight.Conflict) {
    return $e.on_click(
      new $msg.ContentLibrary(
        new $msg.UserFixedConflict(insight, chosen_id, deleted_id),
      ),
    );
  } else if ($ instanceof $insight.Duplicate) {
    return $e.on_click(
      new $msg.ContentLibrary(
        new $msg.UserFixedDuplicate(insight, chosen_id, deleted_id),
      ),
    );
  } else {
    return $e.on_click(
      new $msg.ContentLibrary(new $msg.UserFixedIrrelevant(insight)),
    );
  }
}

function find_source(sources, source_id) {
  let _pipe = sources;
  let _pipe$1 = $list.filter(_pipe, (s) => { return s.id === source_id; });
  return $list.first(_pipe$1);
}

function skip_fix_buttons(on_skip, on_fix, fix_enabled) {
  return $layout.row(
    toList([$layout.gap(16), $layout.padding(16), $layout.justify("right")]),
    toList([]),
    toList([
      $h.div(
        toList([]),
        toList([
          $button.secondary(
            toList([on_skip]),
            toList([$h.text("Skip"), $button.icon($icons.right_arrow())]),
          ),
        ]),
      ),
      $h.div(
        toList([]),
        toList([
          $button.primary(
            toList([$a.disabled(!fix_enabled), on_fix]),
            toList([$h.text("Fix")]),
          ),
        ]),
      ),
    ]),
  );
}

function view_conflicts(card) {
  return $result.try$(
    (() => {
      let $ = card.insight.content;
      if ($ instanceof $insight.ConflictContent) {
        let data_point_id = $.data_point_id;
        let candidate_data_point_id = $.candidate_data_point_id;
        let candidate_data_source_id = $.candidate_data_source_id;
        let details = $.details;
        return new Ok(
          [
            data_point_id,
            candidate_data_point_id,
            candidate_data_source_id,
            details,
          ],
        );
      } else {
        return new Error(undefined);
      }
    })(),
    (_use0) => {
      let data_point_id = _use0[0];
      let candidate_data_point_id = _use0[1];
      let candidate_data_source_id = _use0[2];
      let details = _use0[3];
      return $result.try$(
        $dict.get(card.conflicts_data_points, data_point_id),
        (first_data_point) => {
          return $result.try$(
            $dict.get(card.conflicts_data_points, candidate_data_point_id),
            (second_data_point) => {
              return $result.try$(
                $list.first(
                  (() => {
                    let _pipe = card.all_sources;
                    return $list.filter(
                      _pipe,
                      (s) => { return s.id === card.insight.source_id; },
                    );
                  })(),
                ),
                (first_source) => {
                  return $result.map(
                    $list.first(
                      (() => {
                        let _pipe = card.all_sources;
                        return $list.filter(
                          _pipe,
                          (s) => { return s.id === candidate_data_source_id; },
                        );
                      })(),
                    ),
                    (second_source) => {
                      let first_source_tags = $list.filter(
                        card.tags,
                        (t) => {
                          return $list.contains(first_source.tags, t.id);
                        },
                      );
                      let second_source_tags = $list.filter(
                        card.tags,
                        (t) => {
                          return $list.contains(second_source.tags, t.id);
                        },
                      );
                      let deleted_id = (() => {
                        let $ = isEqual(
                          card.selected_id,
                          new $option.Some(first_data_point.id)
                        );
                        if ($) {
                          return second_data_point.id;
                        } else {
                          return first_data_point.id;
                        }
                      })();
                      let chosen_id = (() => {
                        let _pipe = card.selected_id;
                        return $option.unwrap(_pipe, "");
                      })();
                      let on_skip = $e.on_click(
                        new $msg.ContentLibrary(
                          new $msg.UserSkippedInsight(card.insight),
                        ),
                      );
                      let on_fix$1 = on_fix(card.insight, chosen_id, deleted_id);
                      let insight_id = card.insight.id;
                      let first_is_selected = card_is_selected(
                        card,
                        first_data_point,
                      );
                      let second_is_selected = card_is_selected(
                        card,
                        second_data_point,
                      );
                      let tooltip = $tooltip.tooltip(
                        toList([
                          $a.style(toList([["z-index", $int.to_string(100)]])),
                        ]),
                        $common.small_icon($icons.shining()),
                        $s.tooltip_content(
                          toList([]),
                          toList([$h.text(details)]),
                        ),
                      );
                      return $layout.column(
                        toList([$layout.gap(16)]),
                        toList([]),
                        toList([
                          $s.title_and_tool_tip(
                            toList([]),
                            toList([
                              title(
                                card.index,
                                card.nb_insights,
                                card.insight.kind,
                              ),
                              $s.tooltip_icon(toList([]), toList([tooltip])),
                            ]),
                          ),
                          $layout.row(
                            toList([$layout.gap(32)]),
                            toList([]),
                            toList([
                              conflict_card(
                                first_data_point,
                                first_is_selected,
                                first_source,
                                insight_id,
                                first_source_tags,
                              ),
                              conflict_card(
                                second_data_point,
                                second_is_selected,
                                second_source,
                                insight_id,
                                second_source_tags,
                              ),
                            ]),
                          ),
                          skip_fix_buttons(
                            on_skip,
                            on_fix$1,
                            fix_enabled(
                              card,
                              first_data_point.id,
                              second_data_point.id,
                            ),
                          ),
                        ]),
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function view_duplicates(card) {
  return $result.try$(
    (() => {
      let $ = card.insight.content;
      if ($ instanceof $insight.DuplicateContent) {
        let candidate_source_id = $.candidate_source_id;
        return new Ok(candidate_source_id);
      } else {
        return new Error(undefined);
      }
    })(),
    (candidate_source_id) => {
      return $result.try$(
        find_source(card.all_sources, card.insight.source_id),
        (first_source) => {
          return $result.map(
            find_source(card.all_sources, candidate_source_id),
            (second_source) => {
              let first_source_tags = $list.filter(
                card.tags,
                (t) => { return $list.contains(first_source.tags, t.id); },
              );
              let second_source_tags = $list.filter(
                card.tags,
                (t) => { return $list.contains(second_source.tags, t.id); },
              );
              let deleted_id = (() => {
                let $ = isEqual(
                  card.selected_id,
                  new $option.Some(first_source.id)
                );
                if ($) {
                  return second_source.id;
                } else {
                  return first_source.id;
                }
              })();
              let chosen_id = (() => {
                let _pipe = card.selected_id;
                return $option.unwrap(_pipe, "");
              })();
              let on_skip = $e.on_click(
                new $msg.ContentLibrary(
                  new $msg.UserSkippedInsight(card.insight),
                ),
              );
              let on_fix$1 = on_fix(card.insight, chosen_id, deleted_id);
              let insight_id = card.insight.id;
              return $layout.column(
                toList([$layout.gap(16)]),
                toList([]),
                toList([
                  title(card.index, card.nb_insights, card.insight.kind),
                  $layout.row(
                    toList([$layout.gap(32)]),
                    toList([]),
                    toList([
                      duplicate_card(
                        first_source,
                        card.selected_id,
                        insight_id,
                        first_source_tags,
                      ),
                      duplicate_card(
                        second_source,
                        card.selected_id,
                        insight_id,
                        second_source_tags,
                      ),
                    ]),
                  ),
                  skip_fix_buttons(
                    on_skip,
                    on_fix$1,
                    fix_enabled(card, first_source.id, second_source.id),
                  ),
                ]),
              );
            },
          );
        },
      );
    },
  );
}

function view_irrelevant(card) {
  return $result.try$(
    (() => {
      let $ = card.insight.content;
      if ($ instanceof $insight.IrrelevantContent) {
        let details = $.details;
        return new Ok(details);
      } else {
        return new Error(undefined);
      }
    })(),
    (details) => {
      return $result.map(
        find_source(card.all_sources, card.insight.source_id),
        (source) => {
          let on_skip = $e.on_click(
            new $msg.ContentLibrary(new $msg.UserSkippedInsight(card.insight)),
          );
          let on_fix$1 = on_fix(card.insight, "", "");
          let insight_id = card.insight.id;
          return $layout.column(
            toList([$layout.gap(16)]),
            toList([]),
            toList([
              title(card.index, card.nb_insights, card.insight.kind),
              irrelevant_card(source, details, insight_id),
              skip_fix_buttons(on_skip, on_fix$1, true),
            ]),
          );
        },
      );
    },
  );
}

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let computed = $[1];
  let filter_selected = data.content_library.insight_filter_selected;
  let insights = $computed.filtered_insights(data, computed);
  let nb_insights = $list.length(insights);
  let conflicts_data_points = data.content_library.conflicts_data_points;
  let selected_id = data.content_library.selected_insight_data_point_id;
  let selected_insight_id = data.content_library.selected_insight_id;
  let all_sources = $list.append(
    data.content_library.qna_sources,
    data.content_library.non_qna_sources,
  );
  let tags = data.organization.tags;
  return $el.fragment(
    toList([
      $layout.row(
        toList([$layout.gap(32)]),
        toList([]),
        toList([
          $cl_common.insight_state_card(
            "red",
            computed.insights_count.duplicate,
            new $insight.Duplicate(),
            isEqual(new $insight.Duplicate(), filter_selected),
          ),
          $cl_common.insight_state_card(
            "yellow",
            computed.insights_count.conflict,
            new $insight.Conflict(),
            isEqual(new $insight.Conflict(), filter_selected),
          ),
          $cl_common.insight_state_card(
            "purple",
            computed.insights_count.irrelevant,
            new $insight.Irrelevant(),
            isEqual(new $insight.Irrelevant(), filter_selected),
          ),
        ]),
      ),
      (() => {
        if (nb_insights === 0) {
          return $el.none();
        } else {
          return $h.div(
            toList([]),
            toList([
              $h.text(
                (() => {
                  if (filter_selected instanceof $insight.Duplicate) {
                    return "Click on the version you want to keep.";
                  } else if (filter_selected instanceof $insight.Conflict) {
                    return "Click on the version you want to keep.";
                  } else {
                    return "Decide if the document is relevant or not.";
                  }
                })(),
              ),
            ]),
          );
        }
      })(),
      $layout.column(
        toList([$layout.gap(16)]),
        toList([]),
        $list.index_map(
          insights,
          (insight, index) => {
            let insight_card = new InsightCard(
              conflicts_data_points,
              insight,
              index,
              nb_insights,
              selected_id,
              selected_insight_id,
              all_sources,
              tags,
            );
            let _pipe = (() => {
              let $1 = insight.kind;
              if ($1 instanceof $insight.Conflict) {
                return view_conflicts(insight_card);
              } else if ($1 instanceof $insight.Duplicate) {
                return view_duplicates(insight_card);
              } else {
                return view_irrelevant(insight_card);
              }
            })();
            return $result.unwrap(_pipe, $el.none());
          },
        ),
      ),
    ]),
  );
}
