/// <reference types="./proposal_builder.d.mts" />
import * as $list_ from "../../../common_common/gleam/list/extra.mjs";
import * as $form_data from "../../../gleam_fetch/gleam/fetch/form_data.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $timestamp from "../../../gleam_time/gleam/time/timestamp.mjs";
import * as $output from "../../../interfaces/schemas/outline/output.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import { find_list_element_index, find_nth } from "../../common/utils.mjs";
import * as $docx from "../../data/docx.mjs";
import * as $kind from "../../data/docx/kind.mjs";
import * as $document_selector from "../../data/ephemeral/document_selector.mjs";
import * as $file from "../../data/ephemeral/file.mjs";
import * as $one_trust_selector from "../../data/ephemeral/one_trust_selector.mjs";
import * as $pdf_selector from "../../data/ephemeral/pdf_selector.mjs";
import * as $spreadsheet_selector from "../../data/ephemeral/spreadsheet_selector.mjs";
import * as $project from "../../data/project.mjs";
import * as $proposal from "../../data/proposal.mjs";
import * as $proposal_element from "../../data/proposal_element.mjs";
import * as $block from "../../data/proposal_element/block.mjs";
import * as $one_trust from "../../data/proposal_element/portal/one_trust.mjs";
import * as $question from "../../data/proposal_element/question.mjs";
import * as $qualification_matrix from "../../data/qualification_matrix.mjs";
import * as $spreadsheet from "../../data/spreadsheet.mjs";
import {
  AdditionalInformationColumn,
  AnswerColumn,
  MultipleChoiceColumn,
  QuestionColumn,
  SectionRow,
  UndefinedColumn,
  UndefinedRow,
  Worksheet,
  YesNoColumn,
} from "../../data/spreadsheet.mjs";
import * as $status from "../../data/status.mjs";
import * as $tag from "../../data/tag.mjs";
import * as $language from "../../data/translate/language.mjs";
import { Ok, Error, toList, CustomType as $CustomType, makeError, isEqual } from "../../gleam.mjs";

export class ProposalBuilder extends $CustomType {
  constructor(step, project_id, project_client, project_collaborators, project_deadline, project_languages, project_default_language, project_tags, project_name, project_attachments, proposal_collaborators, proposal_languages, proposal_default_language, proposal_name, proposal_qualification_matrix, proposal_spreadsheet, proposal_document, proposal_pdf, proposal_one_trust, proposal_link_id) {
    super();
    this.step = step;
    this.project_id = project_id;
    this.project_client = project_client;
    this.project_collaborators = project_collaborators;
    this.project_deadline = project_deadline;
    this.project_languages = project_languages;
    this.project_default_language = project_default_language;
    this.project_tags = project_tags;
    this.project_name = project_name;
    this.project_attachments = project_attachments;
    this.proposal_collaborators = proposal_collaborators;
    this.proposal_languages = proposal_languages;
    this.proposal_default_language = proposal_default_language;
    this.proposal_name = proposal_name;
    this.proposal_qualification_matrix = proposal_qualification_matrix;
    this.proposal_spreadsheet = proposal_spreadsheet;
    this.proposal_document = proposal_document;
    this.proposal_pdf = proposal_pdf;
    this.proposal_one_trust = proposal_one_trust;
    this.proposal_link_id = proposal_link_id;
  }
}

export class Questionnaire extends $CustomType {}

export class Document extends $CustomType {}

export class Narrative extends $CustomType {}

export class Pending extends $CustomType {}

export function type_(proposal_builder) {
  let _pipe = proposal_builder.proposal_spreadsheet.file;
  let _pipe$1 = $option.map(_pipe, (_) => { return new Questionnaire(); });
  let _pipe$2 = $option.or(
    _pipe$1,
    (() => {
      let _pipe$2 = proposal_builder.proposal_document.file;
      return $option.map(_pipe$2, (_) => { return new Document(); });
    })(),
  );
  let _pipe$3 = $option.or(
    _pipe$2,
    (() => {
      let _pipe$3 = proposal_builder.proposal_pdf.file;
      return $option.map(_pipe$3, (_) => { return new Narrative(); });
    })(),
  );
  return $option.unwrap(_pipe$3, new Pending());
}

export function init(project_id) {
  return new ProposalBuilder(
    (() => {
      let _pipe = project_id;
      let _pipe$1 = $option.map(_pipe, (_) => { return 2; });
      return $option.unwrap(_pipe$1, 1);
    })(),
    project_id,
    "",
    toList([]),
    $timestamp.system_time(),
    toList([]),
    new $language.English(),
    toList([]),
    "",
    toList([]),
    toList([]),
    toList([]),
    new $language.English(),
    "",
    new None(),
    $spreadsheet_selector.new$(),
    $document_selector.new$(),
    $pdf_selector.new$(),
    $one_trust_selector.new$(),
    new None(),
  );
}

export function add_project_collaborator(user_id) {
  return (builder) => {
    let _pipe = builder.project_collaborators;
    let _pipe$1 = $list_.insert(_pipe, user_id);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        c,
        _record.project_deadline,
        _record.project_languages,
        _record.project_default_language,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_default_language,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
        _record.proposal_document,
        _record.proposal_pdf,
        _record.proposal_one_trust,
        _record.proposal_link_id,
      );
    })(_pipe$1);
  };
}

export function add_proposal_collaborator(user_id) {
  return (builder) => {
    let _pipe = builder.proposal_collaborators;
    let _pipe$1 = $list_.insert(_pipe, user_id);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        _record.project_languages,
        _record.project_default_language,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        c,
        _record.proposal_languages,
        _record.proposal_default_language,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
        _record.proposal_document,
        _record.proposal_pdf,
        _record.proposal_one_trust,
        _record.proposal_link_id,
      );
    })(_pipe$1);
  };
}

export function add_project_language(language) {
  return (builder) => {
    let _pipe = builder.project_languages;
    let _pipe$1 = $list_.insert(_pipe, language);
    return ((l) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        l,
        _record.project_default_language,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_default_language,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
        _record.proposal_document,
        _record.proposal_pdf,
        _record.proposal_one_trust,
        _record.proposal_link_id,
      );
    })(_pipe$1);
  };
}

export function add_proposal_language(language) {
  return (builder) => {
    let _pipe = builder.proposal_languages;
    let _pipe$1 = $list_.insert(_pipe, language);
    return ((l) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        _record.project_languages,
        _record.project_default_language,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        l,
        _record.proposal_default_language,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
        _record.proposal_document,
        _record.proposal_pdf,
        _record.proposal_one_trust,
        _record.proposal_link_id,
      );
    })(_pipe$1);
  };
}

export function add_project_default_language(language) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      language,
      _record.project_tags,
      _record.project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_default_language,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
      _record.proposal_document,
      _record.proposal_pdf,
      _record.proposal_one_trust,
      _record.proposal_link_id,
    );
  };
}

export function add_proposal_default_language(language) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_default_language,
      _record.project_tags,
      _record.project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      language,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
      _record.proposal_document,
      _record.proposal_pdf,
      _record.proposal_one_trust,
      _record.proposal_link_id,
    );
  };
}

export function insert_default_language(builder, language) {
  let _pipe = builder;
  let _pipe$1 = add_proposal_language(language)(_pipe);
  let _pipe$2 = add_project_language(language)(_pipe$1);
  let _pipe$3 = add_proposal_default_language(language)(_pipe$2);
  return add_project_default_language(language)(_pipe$3);
}

export function add_project_tag(tag) {
  return (builder) => {
    let _pipe = builder.project_tags;
    let _pipe$1 = $list_.insert(_pipe, tag);
    return ((t) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        _record.project_languages,
        _record.project_default_language,
        t,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_default_language,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
        _record.proposal_document,
        _record.proposal_pdf,
        _record.proposal_one_trust,
        _record.proposal_link_id,
      );
    })(_pipe$1);
  };
}

export function add_project_attachments(attachments) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_default_language,
      _record.project_tags,
      _record.project_name,
      attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_default_language,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
      _record.proposal_document,
      _record.proposal_pdf,
      _record.proposal_one_trust,
      _record.proposal_link_id,
    );
  };
}

export function remove_project_attachment(index) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_default_language,
      _record.project_tags,
      _record.project_name,
      (() => {
        let _pipe = builder.project_attachments;
        let _pipe$1 = $list.index_map(_pipe, $pair.new$);
        let _pipe$2 = $list.filter(
          _pipe$1,
          (file) => { return file[1] !== index; },
        );
        return $list.map(_pipe$2, $pair.first);
      })(),
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_default_language,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
      _record.proposal_document,
      _record.proposal_pdf,
      _record.proposal_one_trust,
      _record.proposal_link_id,
    );
  };
}

export function remove_project_language(language) {
  return (builder) => {
    let _pipe = builder.project_languages;
    let _pipe$1 = $list_.remove(_pipe, language);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        c,
        _record.project_default_language,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_default_language,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
        _record.proposal_document,
        _record.proposal_pdf,
        _record.proposal_one_trust,
        _record.proposal_link_id,
      );
    })(_pipe$1);
  };
}

export function remove_proposal_language(language) {
  return (builder) => {
    let _pipe = builder.project_languages;
    let _pipe$1 = $list_.remove(_pipe, language);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        c,
        _record.project_default_language,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_default_language,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
        _record.proposal_document,
        _record.proposal_pdf,
        _record.proposal_one_trust,
        _record.proposal_link_id,
      );
    })(_pipe$1);
  };
}

export function remove_project_tag(tag) {
  return (builder) => {
    let _pipe = builder.project_tags;
    let _pipe$1 = $list_.remove(_pipe, tag);
    return ((t) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        _record.project_languages,
        _record.project_default_language,
        t,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_default_language,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
        _record.proposal_document,
        _record.proposal_pdf,
        _record.proposal_one_trust,
        _record.proposal_link_id,
      );
    })(_pipe$1);
  };
}

export function remove_project_collaborator(user_id) {
  return (builder) => {
    let _pipe = builder.project_collaborators;
    let _pipe$1 = $list_.remove(_pipe, user_id);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        c,
        _record.project_deadline,
        _record.project_languages,
        _record.project_default_language,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        _record.proposal_collaborators,
        _record.proposal_languages,
        _record.proposal_default_language,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
        _record.proposal_document,
        _record.proposal_pdf,
        _record.proposal_one_trust,
        _record.proposal_link_id,
      );
    })(_pipe$1);
  };
}

export function remove_proposal_collaborator(user_id) {
  return (builder) => {
    let _pipe = builder.proposal_collaborators;
    let _pipe$1 = $list_.remove(_pipe, user_id);
    return ((c) => {
      let _record = builder;
      return new ProposalBuilder(
        _record.step,
        _record.project_id,
        _record.project_client,
        _record.project_collaborators,
        _record.project_deadline,
        _record.project_languages,
        _record.project_default_language,
        _record.project_tags,
        _record.project_name,
        _record.project_attachments,
        c,
        _record.proposal_languages,
        _record.proposal_default_language,
        _record.proposal_name,
        _record.proposal_qualification_matrix,
        _record.proposal_spreadsheet,
        _record.proposal_document,
        _record.proposal_pdf,
        _record.proposal_one_trust,
        _record.proposal_link_id,
      );
    })(_pipe$1);
  };
}

export function update_project_client(project_client) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_default_language,
      _record.project_tags,
      _record.project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_default_language,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
      _record.proposal_document,
      _record.proposal_pdf,
      _record.proposal_one_trust,
      _record.proposal_link_id,
    );
  };
}

export function update_project_deadline(project_deadline) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      project_deadline,
      _record.project_languages,
      _record.project_default_language,
      _record.project_tags,
      _record.project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_default_language,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
      _record.proposal_document,
      _record.proposal_pdf,
      _record.proposal_one_trust,
      _record.proposal_link_id,
    );
  };
}

export function update_project_name(project_name) {
  return (builder) => {
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_default_language,
      _record.project_tags,
      project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_default_language,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
      _record.proposal_document,
      _record.proposal_pdf,
      _record.proposal_one_trust,
      _record.proposal_link_id,
    );
  };
}

export function add_sections(sections) {
  return (builder) => {
    let proposal_one_trust = new $one_trust_selector.OneTrustSelector(sections);
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_default_language,
      _record.project_tags,
      _record.project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_default_language,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
      _record.proposal_document,
      _record.proposal_pdf,
      proposal_one_trust,
      _record.proposal_link_id,
    );
  };
}

export function update_link_id(link_id) {
  return (builder) => {
    let proposal_link_id = new Some(link_id);
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_default_language,
      _record.project_tags,
      _record.project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_default_language,
      _record.proposal_name,
      _record.proposal_qualification_matrix,
      _record.proposal_spreadsheet,
      _record.proposal_document,
      _record.proposal_pdf,
      _record.proposal_one_trust,
      proposal_link_id,
    );
  };
}

export function next_step(builder) {
  let step = builder.step + 1;
  let _record = builder;
  return new ProposalBuilder(
    step,
    _record.project_id,
    _record.project_client,
    _record.project_collaborators,
    _record.project_deadline,
    _record.project_languages,
    _record.project_default_language,
    _record.project_tags,
    _record.project_name,
    _record.project_attachments,
    _record.proposal_collaborators,
    _record.proposal_languages,
    _record.proposal_default_language,
    _record.proposal_name,
    _record.proposal_qualification_matrix,
    _record.proposal_spreadsheet,
    _record.proposal_document,
    _record.proposal_pdf,
    _record.proposal_one_trust,
    _record.proposal_link_id,
  );
}

export function update_qualification_matrix_item(id, answer) {
  return (builder) => {
    let proposal_qualification_matrix = builder.proposal_qualification_matrix;
    let _record = builder;
    return new ProposalBuilder(
      _record.step,
      _record.project_id,
      _record.project_client,
      _record.project_collaborators,
      _record.project_deadline,
      _record.project_languages,
      _record.project_default_language,
      _record.project_tags,
      _record.project_name,
      _record.project_attachments,
      _record.proposal_collaborators,
      _record.proposal_languages,
      _record.proposal_default_language,
      _record.proposal_name,
      $option.map(
        proposal_qualification_matrix,
        (qualification_matrix) => {
          return $list.map(
            qualification_matrix,
            (qm) => {
              return $bool.guard(
                qm[0].id !== id,
                qm,
                () => { return [qm[0], answer]; },
              );
            },
          );
        },
      ),
      _record.proposal_spreadsheet,
      _record.proposal_document,
      _record.proposal_pdf,
      _record.proposal_one_trust,
      _record.proposal_link_id,
    );
  };
}

export function use_qualification_matrix(builder, qualification_matrix) {
  let proposal_qualification_matrix = new Some(qualification_matrix);
  let _record = builder;
  return new ProposalBuilder(
    _record.step,
    _record.project_id,
    _record.project_client,
    _record.project_collaborators,
    _record.project_deadline,
    _record.project_languages,
    _record.project_default_language,
    _record.project_tags,
    _record.project_name,
    _record.project_attachments,
    _record.proposal_collaborators,
    _record.proposal_languages,
    _record.proposal_default_language,
    _record.proposal_name,
    proposal_qualification_matrix,
    _record.proposal_spreadsheet,
    _record.proposal_document,
    _record.proposal_pdf,
    _record.proposal_one_trust,
    _record.proposal_link_id,
  );
}

export function back_to_upload(builder) {
  let step = builder.step - 1;
  let proposal_spreadsheet = $spreadsheet_selector.new$();
  let _record = builder;
  return new ProposalBuilder(
    step,
    _record.project_id,
    _record.project_client,
    _record.project_collaborators,
    _record.project_deadline,
    _record.project_languages,
    _record.project_default_language,
    _record.project_tags,
    _record.project_name,
    _record.project_attachments,
    _record.proposal_collaborators,
    _record.proposal_languages,
    _record.proposal_default_language,
    _record.proposal_name,
    _record.proposal_qualification_matrix,
    proposal_spreadsheet,
    _record.proposal_document,
    _record.proposal_pdf,
    _record.proposal_one_trust,
    _record.proposal_link_id,
  );
}

function encode_elements(elements) {
  return $json.array(
    elements,
    (element) => {
      let element_type = $docx.kind(element);
      let index = $docx.index(element);
      let content = $element.to_string($docx.element_to_html(element));
      return $proposal_element.encode(
        new $proposal_element.Block(
          new $block.Block(
            element_type,
            "",
            new None(),
            content,
            index,
            new $block.Metadata(),
            "",
            -1,
          ),
        ),
      );
    },
  );
}

function encode_output_elements(outline) {
  let block = (element_type, content) => {
    return new $block.Block(
      element_type,
      "",
      new None(),
      content,
      0,
      new $block.Metadata(),
      "",
      -1,
    );
  };
  let _pipe = toList([
    toList([block(new $kind.OutlineTitle(), outline.proposal_outline.title)]),
    (() => {
      let _pipe = outline.proposal_outline.sections;
      return $list.flat_map(
        _pipe,
        (section) => {
          let _pipe$1 = toList([
            block(new $kind.OutlineSection(), section.section_title),
            block(new $kind.OutlineContent(), section.content),
          ]);
          return $list.append(
            _pipe$1,
            $list.flat_map(
              $option.unwrap(section.subsections, toList([])),
              (subsection) => {
                return toList([
                  block(
                    new $kind.OutlineSubsection(),
                    subsection.subsection_title,
                  ),
                  block(new $kind.OutlineContent(), subsection.content),
                ]);
              },
            ),
          );
        },
      );
    })(),
  ]);
  let _pipe$1 = $list.flatten(_pipe);
  let _pipe$2 = $list.index_map(
    _pipe$1,
    (b, i) => {
      let _record = b;
      return new $block.Block(
        _record.element_type,
        _record.id,
        _record.owner,
        _record.content,
        i,
        _record.metadata,
        _record.proposal_id,
        _record.qa_order,
      );
    },
  );
  let _pipe$3 = $list.map(
    _pipe$2,
    (var0) => { return new $proposal_element.Block(var0); },
  );
  return $json.array(_pipe$3, $proposal_element.encode);
}

export function build_project(builder, org_id, owner) {
  return new $project.Project(
    "",
    builder.project_name,
    owner,
    org_id,
    builder.project_deadline,
    builder.project_client,
    builder.project_collaborators,
    new $project.Metadata(
      builder.project_languages,
      new Some(builder.project_default_language),
    ),
    $timestamp.system_time(),
    new $status.InProgress(),
  );
}

export function encode_project(builder, org_id, owner) {
  return $project.encode(build_project(builder, org_id, owner));
}

function find_default_dict(dicts, name) {
  let _pipe = $list.key_find(dicts, name);
  return $result.unwrap(_pipe, $dict.new$());
}

function default_get(dict, key, default$) {
  let _pipe = $dict.get(dict, key);
  return $result.unwrap(_pipe, default$);
}

function find_choices_associated_to_question(types, row) {
  let _pipe = find_list_element_index(
    $dict.to_list(types),
    new MultipleChoiceColumn(),
  );
  let _pipe$1 = $result.then$(
    _pipe,
    (_capture) => {
      return find_nth(
        $list.map(row, (x) => { return x.dropdown; }),
        _capture,
        0,
      );
    },
  );
  return $result.unwrap(_pipe$1, toList([]));
}

function find_additional_information_associated_to_question(types, row) {
  let _pipe = find_list_element_index(
    $dict.to_list(types),
    new AdditionalInformationColumn(),
  );
  let _pipe$1 = $result.then$(
    _pipe,
    (_capture) => {
      return find_nth($list.map(row, (x) => { return x.value; }), _capture, 0);
    },
  );
  return $result.unwrap(_pipe$1, new None());
}

function encode_question_metadata(cell, choices, additional_information, x, y) {
  return $json.object(
    toList([
      ["x", $json.int(x)],
      ["y", $json.int(y)],
      ["question", $json.nullable(cell.value, $json.string)],
      ["choices", $json.array(choices, $json.string)],
      [
        "additional_information",
        $json.nullable(additional_information, $json.string),
      ],
    ]),
  );
}

function encode_question(types, row, cell, x, y) {
  let choices = find_choices_associated_to_question(types, row);
  let additional_information = find_additional_information_associated_to_question(
    types,
    row,
  );
  return encode_question_metadata(cell, choices, additional_information, x, y);
}

function encode_sheet_questions(content, invalid, types) {
  return $list.flatten(
    $list.index_map(
      content,
      (row, y) => {
        return $list.flatten(
          $list.index_map(
            row,
            (cell, x) => {
              let is_section = isEqual(
                default_get(invalid, y, new UndefinedRow()),
                new SectionRow()
              );
              let is_undefined = isEqual(
                default_get(invalid, y, new UndefinedRow()),
                new UndefinedRow()
              );
              let is_question = isEqual(
                default_get(types, x, new UndefinedColumn()),
                new QuestionColumn()
              );
              let is_invalid = (is_section || is_undefined) || !is_question;
              return $bool.guard(
                is_invalid,
                toList([]),
                () => {
                  return toList([encode_question(types, row, cell, x, y)]);
                },
              );
            },
          ),
        );
      },
    ),
  );
}

export function encode_questions(data, builder) {
  let proposal_spreadsheet = builder.proposal_spreadsheet;
  return $json.preprocessed_array(
    $list.filter_map(
      data,
      (_use0) => {
        let name = _use0.name;
        let start_index = _use0.start_index;
        let content = _use0.content;
        let types = find_default_dict(proposal_spreadsheet.columns_type, name);
        let invalid = find_default_dict(
          proposal_spreadsheet.invalid_lines,
          name,
        );
        let questions = encode_sheet_questions(content, invalid, types);
        return $bool.guard(
          $list.is_empty(questions),
          new Error(undefined),
          () => {
            let _pipe = $json.object(
              toList([
                ["sheet", $json.string(name)],
                ["start_index", $json.int(start_index)],
                ["questions", $json.preprocessed_array(questions)],
              ]),
            );
            return new Ok(_pipe);
          },
        );
      },
    ),
  );
}

export function require_qualification_matrix(builder, next) {
  let $ = builder.proposal_qualification_matrix;
  if ($ instanceof None) {
    throw makeError(
      "panic",
      "data/ephemeral/proposal_builder",
      704,
      "require_qualification_matrix",
      "Qualification matrix not selected",
      {}
    )
  } else {
    let qualification_matrix = $[0];
    return next(qualification_matrix);
  }
}

const valid_columns = /* @__PURE__ */ toList([
  /* @__PURE__ */ new AnswerColumn(),
  /* @__PURE__ */ new YesNoColumn(),
  /* @__PURE__ */ new MultipleChoiceColumn(),
]);

function encode_proposal_questionnaire_responses(builder) {
  let proposal_spreadsheet = builder.proposal_spreadsheet;
  return $list.flat_map(
    proposal_spreadsheet.columns_type,
    (_use0) => {
      let name = _use0[0];
      let columns = _use0[1];
      let is_valid_column = (_capture) => {
        return $list.contains(valid_columns, _capture);
      };
      let _pipe = columns;
      let _pipe$1 = $dict.to_list(_pipe);
      let _pipe$2 = $list.filter(
        _pipe$1,
        (a) => { return is_valid_column(a[1]); },
      );
      return $list.map(
        _pipe$2,
        (column) => {
          return new $proposal.QuestionnaireResponse(
            name,
            column[0],
            (() => {
              let $ = column[1];
              if ($ instanceof AnswerColumn) {
                return new $question.Long();
              } else if ($ instanceof YesNoColumn) {
                return new $question.YesNo();
              } else if ($ instanceof MultipleChoiceColumn) {
                return new $question.MultipleChoice();
              } else {
                throw makeError(
                  "panic",
                  "data/ephemeral/proposal_builder",
                  601,
                  "",
                  "Unexpected behaviour",
                  {}
                )
              }
            })(),
          );
        },
      );
    },
  );
}

export function build_proposal(
  builder,
  org_id,
  owner,
  xlsx,
  docx,
  onetrust,
  outline
) {
  return new $proposal.Proposal(
    "",
    new $proposal.Display("questionnaire"),
    org_id,
    builder.proposal_name,
    builder.project_deadline,
    owner,
    builder.project_client,
    builder.proposal_collaborators,
    0,
    new $status.InProgress(),
    (() => {
      if (xlsx instanceof Some) {
        let xlsx$1 = xlsx[0];
        return new $proposal.Questionnaire(
          builder.project_tags,
          builder.proposal_languages,
          builder.proposal_qualification_matrix,
          new Some(builder.proposal_default_language),
          false,
          (() => {
            let _pipe = builder.proposal_spreadsheet.xlsx;
            let _pipe$1 = $option.map(_pipe, (a) => { return a.data; });
            let _pipe$2 = $option.map(
              _pipe$1,
              (_capture) => {
                return $list.map(_capture, (a) => { return a.name; });
              },
            );
            return $option.unwrap(_pipe$2, toList([]));
          })(),
          encode_proposal_questionnaire_responses(builder),
          xlsx$1.data,
        );
      } else if (docx instanceof Some) {
        return new $proposal.Document(
          builder.project_tags,
          builder.proposal_languages,
          builder.proposal_qualification_matrix,
          new Some(builder.proposal_default_language),
          false,
        );
      } else if (onetrust instanceof Some) {
        return new $proposal.Portal(
          builder.project_tags,
          builder.proposal_languages,
          new None(),
          new Some(builder.proposal_default_language),
          false,
          new $proposal.OneTrust(),
        );
      } else if (outline instanceof Some) {
        return new $proposal.Narrative(
          builder.project_tags,
          builder.proposal_languages,
          builder.proposal_qualification_matrix,
          new Some(builder.proposal_default_language),
          false,
          new $option.None(),
        );
      } else {
        throw makeError(
          "panic",
          "data/ephemeral/proposal_builder",
          562,
          "build_proposal",
          "A proposal should be a questionnaire or a document",
          {}
        )
      }
    })(),
    $timestamp.system_time(),
    new $proposal.Sheets(),
    $option.unwrap(builder.project_id, ""),
    new None(),
    builder.proposal_link_id,
  );
}

export function encode_proposal(
  builder,
  org_id,
  owner,
  xlsx,
  docx,
  onetrust,
  outline
) {
  return $proposal.encode(
    build_proposal(builder, org_id, owner, xlsx, docx, onetrust, outline),
  );
}

export function to_form_data(builder, org_id, user_id) {
  let xlsx = builder.proposal_spreadsheet.xlsx;
  let document = builder.proposal_document.document;
  let file = builder.proposal_document.file;
  let pdf = builder.proposal_pdf.file;
  let outline = builder.proposal_pdf.outline;
  if (xlsx instanceof Some) {
    let sheet = xlsx[0];
    let filename = (() => {
      let _pipe = builder.proposal_spreadsheet.file;
      let _pipe$1 = $option.map(_pipe, (file) => { return file.name; });
      return $option.unwrap(_pipe$1, sheet.name);
    })();
    let _pipe = $json.object(
      toList([
        [
          "proposal",
          encode_proposal(
            builder,
            org_id,
            user_id,
            new Some(sheet),
            new None(),
            new None(),
            new None(),
          ),
        ],
        ["spreadsheet", $spreadsheet.encode(sheet)],
        ["questions", encode_questions(sheet.data, builder)],
      ]),
    );
    let _pipe$1 = $json.to_string(_pipe);
    let _pipe$2 = ((_capture) => {
      return $form_data.append($form_data.new$(), "content", _capture);
    })(_pipe$1);
    let _pipe$3 = $form_data.append(_pipe$2, "filename", filename);
    let _pipe$4 = $form_data.append_bits(_pipe$3, "blob", sheet.blob);
    let _pipe$5 = ((_capture) => {
      return $list.fold(
        builder.project_attachments,
        _capture,
        (data, file) => {
          let name = "attachment_" + file.name;
          return $form_data.append_bits(data, name, file.content);
        },
      );
    })(_pipe$4);
    return new Ok(_pipe$5);
  } else if (document instanceof Some && file instanceof Some) {
    let document$1 = document[0];
    let file$1 = file[0];
    let _pipe = $json.object(
      toList([
        [
          "proposal",
          encode_proposal(
            builder,
            org_id,
            user_id,
            new None(),
            new Some(document$1),
            new None(),
            new None(),
          ),
        ],
        ["styles", $docx.encode_styles(document$1.styles)],
        ["elements", encode_elements(document$1.elements)],
      ]),
    );
    let _pipe$1 = $json.to_string(_pipe);
    let _pipe$2 = ((_capture) => {
      return $form_data.append($form_data.new$(), "content", _capture);
    })(_pipe$1);
    let _pipe$3 = $form_data.append(_pipe$2, "filename", file$1.name);
    let _pipe$4 = $form_data.append_bits(_pipe$3, "blob", file$1.content);
    let _pipe$5 = ((_capture) => {
      return $list.fold(
        builder.project_attachments,
        _capture,
        (data, file) => {
          let name = "attachment_" + file.name;
          return $form_data.append_bits(data, name, file.content);
        },
      );
    })(_pipe$4);
    return new Ok(_pipe$5);
  } else if (outline instanceof Some && pdf instanceof Some) {
    let outline$1 = outline[0];
    let pdf$1 = pdf[0];
    let _pipe = $json.object(
      toList([
        [
          "proposal",
          encode_proposal(
            builder,
            org_id,
            user_id,
            new None(),
            new None(),
            new None(),
            new Some(outline$1),
          ),
        ],
        ["elements", encode_output_elements(outline$1)],
      ]),
    );
    let _pipe$1 = $json.to_string(_pipe);
    let _pipe$2 = ((_capture) => {
      return $form_data.append($form_data.new$(), "content", _capture);
    })(_pipe$1);
    let _pipe$3 = $form_data.append(_pipe$2, "filename", pdf$1.name);
    let _pipe$4 = $form_data.append_bits(_pipe$3, "blob", pdf$1.content);
    let _pipe$5 = ((_capture) => {
      return $list.fold(
        builder.project_attachments,
        _capture,
        (data, file) => {
          let name = "attachment_" + file.name;
          return $form_data.append_bits(data, name, file.content);
        },
      );
    })(_pipe$4);
    return new Ok(_pipe$5);
  } else {
    return new Error(undefined);
  }
}
