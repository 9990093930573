import { Node } from '@tiptap/core'
import * as h from '#editor/html'
import * as a from '#editor/html/attribute'

export const QuestionBlock = Node.create({
  name: 'steerlabQuestion',
  group: 'block',
  content: 'block*',
  addAttributes() {
    return {
      id: {
        parseHTML: element => element.getAttribute('data-id'),
        renderHTML: attributes => ({ ['data-id']: attributes.id }),
      },
      index: {
        default: -1,
        parseHTML: element => element.getAttribute('data-index'),
        renderHTML: attributes => ({ ['data-index']: attributes.index }),
      },
    }
  },
  parseHTML() {
    return [{ tag: 'steerlab-question' }]
  },
  renderHTML({ HTMLAttributes }) {
    return ['steerlab-question', HTMLAttributes, 0]
  },
  addNodeView() {
    return props => {
      const index = a.attribute('data-index', props.node.attrs.index)
      const id = a.id(props.node.attrs.id)
      const class_ = a.class_('steerlab-question-content')
      const contentDOM = h.div([class_], [])
      const dom = h.element('steerlab-question', [index, id], [contentDOM])
      return { dom, contentDOM }
    }
  },
})
