/// <reference types="./dom.d.mts" />
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import { CustomType as $CustomType } from "../../gleam.mjs";
import { subscribeDOMClick as subscribe_dom_click } from "./dom.ffi.ts";

class On extends $CustomType {
  constructor(click, once, cancel) {
    super();
    this.click = click;
    this.once = once;
    this.cancel = cancel;
  }
}

export function on_click(click) {
  return new On(click, false, new $option.None());
}

export function to_cancel(on, cancel) {
  let cancel$1 = new $option.Some(cancel);
  let _record = on;
  return new On(_record.click, _record.once, cancel$1);
}

export function once(on) {
  let _record = on;
  return new On(_record.click, true, _record.cancel);
}

export function subscribe(on) {
  return $effect.from(
    (dispatch) => {
      let unsubscriber = subscribe_dom_click(
        () => { return dispatch(on.click); },
        on.once,
      );
      let $ = on.cancel;
      if ($ instanceof $option.None) {
        return undefined;
      } else {
        let cancel = $[0];
        return dispatch(cancel($effect.from((_) => { return unsubscriber(); })));
      }
    },
  );
}
