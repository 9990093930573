/// <reference types="./organization.d.mts" />
import * as $connector from "../../../common/data/connector.mjs";
import * as $connector_settings from "../../../common/data/connector_settings.mjs";
import * as $qualification_matrix from "../../../common/data/qualification_matrix.mjs";
import * as $tag from "../../../common/data/tag.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { toList } from "../../gleam.mjs";

export function update_user_function(data, user_id, function$) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_org_id(
        data,
        (org_id) => {
          return $effect.from(
            (_) => {
              return $function.tap(
                undefined,
                (_) => {
                  let at = toList(["organizations", org_id, "members", user_id]);
                  let _pipe = $ask.to(new $ask.Heimdall(), at);
                  let _pipe$1 = $ask.bearing(_pipe, access_token);
                  let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
                  let _pipe$3 = $ask.expect(_pipe$2, $decode.dynamic);
                  let _pipe$4 = $ask.with$(
                    _pipe$3,
                    $json.object(
                      toList([["function", $user.encode_function(function$)]]),
                    ),
                  );
                  return $ask.run(_pipe$4);
                },
              );
            },
          );
        },
      );
    },
  );
}

export function fetch_connectors_status(_) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $list.each(
            toList([
              new $connector.GoogleDrive(),
              new $connector.Confluence(),
              new $connector.Notion(),
              new $connector.Slack(),
            ]),
            (connector) => {
              let on = (m) => {
                return dispatch(
                  new $msg.Connectors(
                    new $msg.ApiReturnedConnectorData(connector, m),
                  ),
                );
              };
              let _pipe = $ask.to(new $ask.Loki(), toList(["registered"]));
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.query(
                _pipe$1,
                "connector",
                $connector.to_string(connector),
              );
              let _pipe$3 = $ask.expect(
                _pipe$2,
                $decode.at(toList(["registered"]), $decode.bool),
              );
              let _pipe$4 = $ask.notify(_pipe$3, on);
              return $ask.run(_pipe$4);
            },
          );
        },
      );
    },
  );
}

export function fetch_organization_members(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_org_id(
        data,
        (org_id) => {
          return $effect.from(
            (dispatch) => {
              return $function.tap(
                undefined,
                (_) => {
                  let at = toList(["organizations", org_id, "members"]);
                  let _pipe = $ask.to(new $ask.Heimdall(), at);
                  let _pipe$1 = $ask.bearing(_pipe, access_token);
                  let _pipe$2 = $ask.expect(
                    _pipe$1,
                    $decode.at(toList(["users"]), $decode.list($user.decoder())),
                  );
                  let _pipe$3 = $ask.notify(
                    _pipe$2,
                    (users) => {
                      return dispatch(
                        new $msg.Organization(new $msg.ApiReturnedUsers(users)),
                      );
                    },
                  );
                  return $ask.run(_pipe$3);
                },
              );
            },
          );
        },
      );
    },
  );
}

export function fetch_connectors_settings(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_org_id(
        data,
        (org_id) => {
          let is_admin = $list.contains(data.permissions, "admin:organization");
          return $bool.guard(
            !is_admin,
            $effect.none(),
            () => {
              return $effect.from(
                (dispatch) => {
                  return $function.tap(
                    undefined,
                    (_) => {
                      let at = toList([
                        "organizations",
                        org_id,
                        "connectors-settings",
                      ]);
                      let _pipe = $ask.to(new $ask.Heimdall(), at);
                      let _pipe$1 = $ask.bearing(_pipe, access_token);
                      let _pipe$2 = $ask.expect(
                        _pipe$1,
                        $decode.list($connector_settings.decoder()),
                      );
                      let _pipe$3 = $ask.notify(
                        _pipe$2,
                        (d) => {
                          return dispatch(
                            new $msg.Connectors(
                              new $msg.ApiReturnedConnectorSettings(d),
                            ),
                          );
                        },
                      );
                      return $ask.run(_pipe$3);
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function fetch_tags(_) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              let _pipe = $ask.to(new $ask.Heimdall(), toList(["tags"]));
              let _pipe$1 = $ask.via(_pipe, new $http.Get());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.expect(_pipe$2, $decode.list($tag.decoder()));
              let _pipe$4 = $ask.notify(
                _pipe$3,
                (t) => {
                  return dispatch(
                    new $msg.Organization(new $msg.ApiReturnedTags(t)),
                  );
                },
              );
              return $ask.run(_pipe$4);
            },
          );
        },
      );
    },
  );
}

export function fetch_qualification_matrix(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_permission(
        data,
        "rfp:create",
        () => {
          return $effect.from(
            (dispatch) => {
              return $function.tap(
                undefined,
                (_) => {
                  let _pipe = $ask.to(
                    new $ask.Heimdall(),
                    toList(["qualification-matrix"]),
                  );
                  let _pipe$1 = $ask.via(_pipe, new $http.Get());
                  let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                  let _pipe$3 = $ask.expect(
                    _pipe$2,
                    $decode.list($qualification_matrix.decoder()),
                  );
                  let _pipe$4 = $ask.notify(
                    _pipe$3,
                    (q) => {
                      return dispatch(
                        new $msg.Organization(
                          new $msg.ApiReturnedQualificationMatrix(q),
                        ),
                      );
                    },
                  );
                  return $ask.run(_pipe$4);
                },
              );
            },
          );
        },
      );
    },
  );
}
