/// <reference types="./organization.d.mts" />
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import { Data } from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $organization from "../../data/organization.mjs";
import * as $loading from "../../data/ui/loading.mjs";
import * as $organization_effects from "../../frontend/effects/organization.mjs";

export function update(data, msg) {
  if (msg instanceof $msg.ApiSentUserFunctionUpdated) {
    let user_id = msg[0];
    let function$ = msg[2];
    let _pipe = $organization.update_user(data.organization, user_id, function$);
    let _pipe$1 = ((_capture) => {
      return $data.set_organization(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedTags) {
    let tags = msg.tags;
    let _pipe = (() => {
      let _record = data.organization;
      return new $organization.Organization(tags, _record.users);
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_organization(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedQualificationMatrix) {
    let qm = msg.qm;
    return [
      (() => {
        let _record = data;
        return new Data(
          _record.access_token,
          _record.client,
          _record.collaborators_proposal_opened,
          _record.connectors,
          _record.organization,
          _record.content_library,
          _record.copilot_input,
          _record.proposal_filters,
          _record.proposal_selected_questions,
          _record.copilot_threads,
          _record.custom_rewording_input,
          _record.modal,
          _record.modal_unsubscriber,
          _record.feed_opened,
          _record.loading,
          _record.more_proposal_opened,
          _record.more_proposal_unsubscriber,
          _record.proposal_builder,
          _record.filter_proposal_opened,
          _record.notifications,
          _record.notifications_unread,
          _record.permissions,
          _record.projects,
          _record.proposals,
          _record.proposal_block_page_size,
          _record.elements,
          _record.route,
          _record.language,
          _record.running_requests,
          _record.translations,
          _record.user,
          qm,
          _record.collapsed_navbar,
          _record.opened_projects,
          _record.proposal_toggles,
          _record.dropdown_unsubscriber,
          _record.analytics,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof $msg.UserSelectedFunction) {
    let user_id = msg.user_id;
    let function$ = msg.function;
    let _pipe = $organization.update_user(data.organization, user_id, function$);
    let _pipe$1 = ((_capture) => {
      return $data.set_organization(data, _capture);
    })(_pipe);
    return $pair.new$(
      _pipe$1,
      $organization_effects.update_user_function(data, user_id, function$),
    );
  } else {
    let users = msg[0];
    let _pipe = (() => {
      let _record = data.organization;
      return new $organization.Organization(_record.tags, users);
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_organization(data, _capture);
    })(_pipe);
    let _pipe$2 = $data.mark_as_loaded(_pipe$1, $loading.users);
    return $pair.new$(_pipe$2, $effect.none());
  }
}
