/// <reference types="./translated_text.d.mts" />
import * as $data_point from "../../../../common/data/data_point.mjs";
import * as $question from "../../../../common/data/proposal_element/question.mjs";
import * as $translate from "../../../../common/data/translate.mjs";
import * as $language from "../../../../common/data/translate/language.mjs";
import * as $iso639 from "../../../../common/data/translate/language/iso639.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../../gleam_stdlib/gleam/dict.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import * as $data from "../../../data/model/data.mjs";
import { Ok, toList } from "../../../gleam.mjs";
import { renderHtmlText as render_html_text } from "./translated_text.ffi.ts";

export function id_builder(id, l, k) {
  let language = $iso639.to_string(l);
  let kind = $translate.translation_target_to_small_string(k);
  let _pipe = toList([language, kind, id]);
  return $string.join(_pipe, "_");
}

export function translate_or_default(id, default$, data) {
  let default$1 = new Ok(default$);
  return $bool.guard(
    $data.is_translations_hidden(data),
    default$1,
    () => {
      let _pipe = data.translations;
      let _pipe$1 = $dict.get(_pipe, id);
      return $result.then$(_pipe$1, (t) => { return new Ok(t.content); });
    },
  );
}

export function translated_answer(question, model, language) {
  let _pipe = id_builder(question.id, language, new $translate.Answer());
  return translate_or_default(
    _pipe,
    $question.get_answer_text(question.answer),
    model,
  );
}

export function translated_data_point(point, model, language) {
  let content = $option.unwrap(point.display.as_html, point.text);
  let _pipe = id_builder(point.id, language, new $translate.DataPoint());
  let _pipe$1 = translate_or_default(_pipe, content, model);
  return $result.unwrap(_pipe$1, content);
}

export function translated_question(question, model, language) {
  let content = question.content;
  let content$1 = (() => {
    let _pipe = render_html_text(content);
    return $result.unwrap(_pipe, content);
  })();
  let _pipe = id_builder(question.id, language, new $translate.Question());
  let _pipe$1 = translate_or_default(_pipe, content$1, model);
  return $result.unwrap(_pipe$1, content$1);
}
