/// <reference types="./model.d.mts" />
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $editor from "../../elements/editor/tiptap/editor.mjs";
import * as $style from "../../elements/editor/tiptap/editor/style.mjs";
import * as $crypto from "../../frontend/browser/crypto.mjs";
import { CustomType as $CustomType } from "../../gleam.mjs";

export class Model extends $CustomType {
  constructor(editor, bold, italic, underline, ratio, content, editable, modal, custom_prompt, id) {
    super();
    this.editor = editor;
    this.bold = bold;
    this.italic = italic;
    this.underline = underline;
    this.ratio = ratio;
    this.content = content;
    this.editable = editable;
    this.modal = modal;
    this.custom_prompt = custom_prompt;
    this.id = id;
  }
}

export class ModalAiRewrite extends $CustomType {
  constructor(content, block_id) {
    super();
    this.content = content;
    this.block_id = block_id;
  }
}

export class ModalAiRespond extends $CustomType {
  constructor(content, block_id) {
    super();
    this.content = content;
    this.block_id = block_id;
  }
}

export function init() {
  return new Model(
    new $option.None(),
    false,
    false,
    false,
    1.0,
    "",
    true,
    new $option.None(),
    "",
    $crypto.uuid(),
  );
}

export function set_editor(model, editor) {
  let editor$1 = new $option.Some(editor);
  let _record = model;
  return new Model(
    editor$1,
    _record.bold,
    _record.italic,
    _record.underline,
    _record.ratio,
    _record.content,
    _record.editable,
    _record.modal,
    _record.custom_prompt,
    _record.id,
  );
}

export function update_style(model) {
  let $ = model.editor;
  if ($ instanceof $option.None) {
    return model;
  } else {
    let editor = $[0];
    let bold = $style.is_active(editor, new $style.Bold());
    let italic = $style.is_active(editor, new $style.Italic());
    let underline = $style.is_active(editor, new $style.Underline());
    let _record = model;
    return new Model(
      _record.editor,
      bold,
      italic,
      underline,
      _record.ratio,
      _record.content,
      _record.editable,
      _record.modal,
      _record.custom_prompt,
      _record.id,
    );
  }
}

export function require_editor(model, next) {
  let $ = model.editor;
  if ($ instanceof $option.Some) {
    let editor = $[0];
    return next(editor);
  } else {
    return [model, $effect.none()];
  }
}

export function close_modal(model) {
  let _record = model;
  return new Model(
    _record.editor,
    _record.bold,
    _record.italic,
    _record.underline,
    _record.ratio,
    _record.content,
    _record.editable,
    new $option.None(),
    "",
    _record.id,
  );
}
