/// <reference types="./websocket.d.mts" />
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $io from "../../gleam_stdlib/gleam/io.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $lustre from "../../lustre/lustre.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import { Ok, Error } from "../gleam.mjs";
import {
  connect as do_connect,
  storeDispatch as store_dispatch,
  readDispatch as read_dispatch,
} from "./websocket.ffi.mjs";

function new_dispatch(dispatch, decoder, dyn) {
  let $ = $decode.run(dyn, decoder);
  if ($.isOk()) {
    let msg = $[0];
    return dispatch($lustre.dispatch(msg));
  } else {
    let e = $[0];
    return $io.println($string.inspect(e));
  }
}

export function connect(to, access_token) {
  return $effect.from(
    (_) => {
      let to$1 = $uri.to_string(to);
      let _pipe = do_connect(to$1, access_token);
      return $result.unwrap_error(_pipe, undefined);
    },
  );
}

export function setup(decoder, dispatch) {
  let error = () => { return new Error("setup has already been called"); };
  return $bool.lazy_guard(
    $result.is_ok(read_dispatch()),
    error,
    () => {
      return new Ok(
        store_dispatch(
          (_capture) => { return new_dispatch(dispatch, decoder, _capture); },
        ),
      );
    },
  );
}
