/// <reference types="./modal.d.mts" />
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $dom from "../../frontend/effects/dom.mjs";

export function subscribe_close() {
  let _pipe = $dom.on_click(new $msg.UserClosedModal());
  let _pipe$1 = $dom.to_cancel(
    _pipe,
    (var0) => { return new $msg.ModalReturnedUnsubscriber(var0); },
  );
  let _pipe$2 = $dom.once(_pipe$1);
  return $dom.subscribe(_pipe$2);
}

export function close() {
  return $effect.from(
    (dispatch) => { return dispatch(new $msg.UserClosedModal()); },
  );
}
