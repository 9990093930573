/// <reference types="./proposal.d.mts" />
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $proposal_element from "../../../common/data/proposal_element.mjs";
import * as $question from "../../../common/data/proposal_element/question.mjs";
import { Question } from "../../../common/data/proposal_element/question.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $global from "../../../ds/plinth/javascript/global.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $toast from "../../../grille_pain/grille_pain/lustre/toast.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $model from "../../data/model.mjs";
import * as $data from "../../data/model/data.mjs";
import { Data } from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $route from "../../data/route.mjs";
import * as $loading from "../../data/ui/loading.mjs";
import * as $content_library_effects from "../../frontend/effects/content_library.mjs";
import * as $dom from "../../frontend/effects/dom.mjs";
import * as $modal_effects from "../../frontend/effects/modal.mjs";
import * as $proposal_effects from "../../frontend/effects/proposal.mjs";
import * as $question_effects from "../../frontend/effects/question.mjs";
import * as $translated_text from "../../frontend/view/components/translated_text.mjs";
import { toList, isEqual } from "../../gleam.mjs";

export function update(data, msg) {
  if (msg instanceof $msg.ApiRejectedProposal) {
    let proposal = msg.proposal;
    let _pipe = data;
    let _pipe$1 = $data.upsert_proposals(_pipe, toList([proposal]));
    return $pair.new$(
      _pipe$1,
      $toast.error(
        "Your proposal modification has not been handled. Please, retry later.",
      ),
    );
  } else if (msg instanceof $msg.ApiRejectedProposalElement) {
    let element = msg.element;
    let _pipe = data;
    let _pipe$1 = $data.upsert_proposal_element(_pipe, element);
    return $pair.new$(
      _pipe$1,
      $toast.error("Unable to update question. Please, retry later."),
    );
  } else if (msg instanceof $msg.ApiReturnedProposalElement) {
    let element = msg.element;
    return [$data.upsert_proposal_element(data, element), $effect.none()];
  } else if (msg instanceof $msg.ApiReturnedProposals) {
    let proposals = msg[0];
    return [$data.upsert_proposals(data, proposals), $effect.none()];
  } else if (msg instanceof $msg.ApiReturnedProposalsElements) {
    let elements = msg.elements;
    let data_sources = msg.data_sources;
    let data_points = msg.data_points;
    let filters = new $proposal.ProposalFilters(
      new $proposal.AllStatus(),
      new None(),
    );
    let _pipe = data;
    let _pipe$1 = $data.mark_as_loaded(_pipe, $loading.questions_loaded);
    let _pipe$2 = $data.add_proposal_questions(_pipe$1, elements);
    let _pipe$3 = $data.set_proposal_filters(_pipe$2, filters);
    let _pipe$4 = $data.close_filter_proposal(_pipe$3);
    let _pipe$5 = $data.add_questions_data_sources(_pipe$4, data_sources);
    let _pipe$6 = $data.add_questions_data_points(_pipe$5, data_points);
    return $pair.new$(_pipe$6, $effect.none());
  } else if (msg instanceof $msg.ApiSentElementUpdated) {
    let element = msg[0];
    let data_sources = msg[1];
    let data_points = msg[2];
    let id = $proposal_element.id(element);
    let data_sources$1 = $dict.from_list(toList([[id, data_sources]]));
    let data_points$1 = $dict.from_list(toList([[id, data_points]]));
    let _pipe = $data.upsert_proposal_element(data, element);
    let _pipe$1 = $data.add_questions_data_sources(_pipe, data_sources$1);
    let _pipe$2 = $data.add_questions_data_points(_pipe$1, data_points$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedTranslations) {
    let t = msg[0];
    return [$data.upsert_translations(data, t), $effect.none()];
  } else if (msg instanceof $msg.ApiTimeoutedProposalsQuestions) {
    let _pipe = data;
    let _pipe$1 = $data.mark_as_loaded(_pipe, $loading.questions_loaded);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.ApiSentElementDeleted) {
    let proposal_id = msg.proposal_id;
    let element_id = msg.element_id;
    let _pipe = $data.delete_proposal_element(data, proposal_id, element_id);
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof $msg.UserSelectedElementOwner) {
    let element_id = msg.element_id;
    let proposal_id = msg.proposal_id;
    let user_id = msg.user_id;
    let uid = new $option.Some(user_id);
    let $ = $model.find_element(data, proposal_id, element_id);
    if (!$.isOk()) {
      return [data, $effect.none()];
    } else if ($.isOk() && $[0] instanceof $proposal_element.Block) {
      return [data, $effect.none()];
    } else if ($.isOk() &&
    $[0] instanceof $proposal_element.Question &&
    $[0].question instanceof Question &&
    (isEqual($[0].question.owner, uid))) {
      let owner = $[0].question.owner;
      return [data, $effect.none()];
    } else {
      let question = $[0].question;
      let _pipe = (() => {
        let _record = question;
        return new Question(
          _record.id,
          _record.proposal_id,
          _record.data_points_id,
          _record.display,
          uid,
          _record.kind,
          _record.choices,
          _record.sheet,
          _record.content,
          _record.answer,
          false,
          _record.metadata,
          _record.ai_processing,
          _record.assign_processing,
          _record.qa_order,
          _record.doc_id_order,
        );
      })();
      let _pipe$1 = new $proposal_element.Question(_pipe);
      let _pipe$2 = ((_capture) => {
        return $data.upsert_proposal_element(data, _capture);
      })(_pipe$1);
      return $pair.new$(
        _pipe$2,
        (() => {
          let elements_ids = toList([element_id]);
          return $question_effects.update_elements_owner(
            data,
            elements_ids,
            proposal_id,
            user_id,
          );
        })(),
      );
    }
  } else if (msg instanceof $msg.UserUpdatedProposalQuestion) {
    let question = msg.question;
    let content = msg.content;
    let language = msg.language;
    let target_id = question.id;
    let id = $translated_text.id_builder(
      target_id,
      language,
      new $translate.Answer(),
    );
    let _pipe = (() => {
      let $ = $dict.get(data.translations, id);
      if (!$.isOk()) {
        return data;
      } else {
        let metadata = new $translate.TranslationMetadata(
          new $translate.Answer(),
        );
        let translation = new $translate.Translation(
          id,
          target_id,
          content,
          language,
          metadata,
        );
        return $data.upsert_translations(data, toList([translation]));
      }
    })();
    let _pipe$1 = $question_effects.prepare_updating_question(
      _pipe,
      question,
      content,
    );
    let _pipe$2 = $data.upsert_proposal_element(
      _pipe$1,
      new $proposal_element.Question(
        $question.update_custom_answer(question, content),
      ),
    );
    return $pair.new$(
      _pipe$2,
      $question_effects.update_question(
        data,
        question,
        content,
        new $option.Some(language),
      ),
    );
  } else if (msg instanceof $msg.UserUpdatedProposalQuestionYesNo) {
    let question = msg.question;
    let yes_no = msg.yes_no;
    let _pipe = data;
    let _pipe$1 = $question_effects.prepare_updating_question_yes_no(
      _pipe,
      question,
      yes_no,
    );
    return $pair.new$(
      _pipe$1,
      $question_effects.update_question_yes_no(data, question, yes_no),
    );
  } else if (msg instanceof $msg.UserUpdatedProposalQuestionChoice) {
    let question = msg.question;
    let choice = msg.choice;
    let _pipe = data;
    let _pipe$1 = $question_effects.prepare_updating_question_choice(
      _pipe,
      question,
      choice,
    );
    return $pair.new$(
      _pipe$1,
      $question_effects.update_question_choice(data, question, choice),
    );
  } else if (msg instanceof $msg.UserDisplayedDeleteQuestionInProposalModal) {
    let proposal_id = msg.proposal_id;
    let question_id = msg.question_id;
    let modal = new $data.DeleteProposalQuestion(proposal_id, question_id);
    let data$1 = (() => {
      let _record = data;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        modal,
        _record.modal_unsubscriber,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.elements,
        _record.route,
        _record.language,
        _record.running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    })();
    return [data$1, $modal_effects.subscribe_close()];
  } else if (msg instanceof $msg.UserEdittedCustomRewording) {
    let content = msg[0];
    let _pipe = data;
    let _pipe$1 = $data.update_custom_rewording_input(_pipe, content);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserClickedProposal) {
    let proposal = msg.proposal;
    let sheet = msg.sheet;
    let tab = new $option.Some(new $route.Blocks());
    let _pipe = proposal.project_id;
    let _pipe$1 = new $route.Proposals(
      _pipe,
      new $route.ShowProposal(proposal.id, sheet, tab, 0),
    );
    let _pipe$2 = new $route.Projects(_pipe$1);
    let _pipe$3 = (() => {
      let $ = data.route;
      if ($ instanceof $route.Projects &&
      $[0] instanceof $route.Proposals &&
      $[0].proposal instanceof $route.ShowProposal &&
      ($[0].proposal.id === proposal.id)) {
        let pid = $[0].proposal.id;
        return $route.replace;
      } else {
        return $route.push;
      }
    })()(_pipe$2);
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe$3);
  } else if (msg instanceof $msg.UserDeletedQuestionInProposal) {
    let proposal_id = msg.proposal_id;
    let question_id = msg.question_id;
    let element = $model.find_element(data, proposal_id, question_id);
    let not_found = $result.is_error(element);
    return $bool.guard(
      not_found,
      [data, $effect.none()],
      () => {
        let updated_model = $data.remove_question(
          data,
          proposal_id,
          question_id,
        );
        let _pipe = updated_model;
        let _pipe$1 = $data.empty_popup(_pipe, "ai-" + question_id);
        return $pair.new$(
          _pipe$1,
          $effect.batch(
            toList([
              $model.unsubscribe_more_proposal(data),
              $modal_effects.close(),
              $proposal_effects.delete_element_in_proposal(
                data,
                proposal_id,
                question_id,
              ),
            ]),
          ),
        );
      },
    );
  } else if (msg instanceof $msg.UserClickedGlobalProposalCheckbox) {
    let all_selected = msg.all_selected;
    let questions = msg.questions;
    let proposal_selected_questions = $bool.guard(
      all_selected,
      $dict.new$(),
      () => {
        return $list.fold(
          questions,
          $dict.new$(),
          (selected, question) => {
            return $dict.insert(selected, question.id, true);
          },
        );
      },
    );
    let _pipe = (() => {
      let _record = data;
      return new $data.Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.modal,
        _record.modal_unsubscriber,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.elements,
        _record.route,
        _record.language,
        _record.running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    })();
    let _pipe$1 = $data.close_popups(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserToggledQuestionMoreButton) {
    let question_id = msg.question_id;
    let $ = data.more_proposal_opened;
    if ($ instanceof $option.Some && ($[0] === question_id)) {
      let qid = $[0];
      return [
        (() => {
          let _record = data;
          return new Data(
            _record.access_token,
            _record.client,
            _record.collaborators_proposal_opened,
            _record.connectors,
            _record.organization,
            _record.content_library,
            _record.copilot_input,
            _record.proposal_filters,
            _record.proposal_selected_questions,
            _record.copilot_threads,
            _record.custom_rewording_input,
            _record.modal,
            _record.modal_unsubscriber,
            _record.feed_opened,
            _record.loading,
            new None(),
            _record.more_proposal_unsubscriber,
            _record.proposal_builder,
            _record.filter_proposal_opened,
            _record.notifications,
            _record.notifications_unread,
            _record.permissions,
            _record.projects,
            _record.proposals,
            _record.proposal_block_page_size,
            _record.elements,
            _record.route,
            _record.language,
            _record.running_requests,
            _record.translations,
            _record.user,
            _record.qualification_matrix,
            _record.collapsed_navbar,
            _record.opened_projects,
            _record.proposal_toggles,
            _record.dropdown_unsubscriber,
            _record.analytics,
          );
        })(),
        $model.unsubscribe_more_proposal(data),
      ];
    } else if ($ instanceof $option.Some) {
      return [
        (() => {
          let _record = data;
          return new Data(
            _record.access_token,
            _record.client,
            _record.collaborators_proposal_opened,
            _record.connectors,
            _record.organization,
            _record.content_library,
            _record.copilot_input,
            _record.proposal_filters,
            _record.proposal_selected_questions,
            _record.copilot_threads,
            _record.custom_rewording_input,
            _record.modal,
            _record.modal_unsubscriber,
            _record.feed_opened,
            _record.loading,
            new $option.Some(question_id),
            _record.more_proposal_unsubscriber,
            _record.proposal_builder,
            _record.filter_proposal_opened,
            _record.notifications,
            _record.notifications_unread,
            _record.permissions,
            _record.projects,
            _record.proposals,
            _record.proposal_block_page_size,
            _record.elements,
            _record.route,
            _record.language,
            _record.running_requests,
            _record.translations,
            _record.user,
            _record.qualification_matrix,
            _record.collapsed_navbar,
            _record.opened_projects,
            _record.proposal_toggles,
            _record.dropdown_unsubscriber,
            _record.analytics,
          );
        })(),
        $model.unsubscribe_more_proposal(data),
      ];
    } else {
      return [
        (() => {
          let _record = data;
          return new Data(
            _record.access_token,
            _record.client,
            _record.collaborators_proposal_opened,
            _record.connectors,
            _record.organization,
            _record.content_library,
            _record.copilot_input,
            _record.proposal_filters,
            _record.proposal_selected_questions,
            _record.copilot_threads,
            _record.custom_rewording_input,
            _record.modal,
            _record.modal_unsubscriber,
            _record.feed_opened,
            _record.loading,
            new $option.Some(question_id),
            _record.more_proposal_unsubscriber,
            _record.proposal_builder,
            _record.filter_proposal_opened,
            _record.notifications,
            _record.notifications_unread,
            _record.permissions,
            _record.projects,
            _record.proposals,
            _record.proposal_block_page_size,
            _record.elements,
            _record.route,
            _record.language,
            _record.running_requests,
            _record.translations,
            _record.user,
            _record.qualification_matrix,
            _record.collapsed_navbar,
            _record.opened_projects,
            _record.proposal_toggles,
            _record.dropdown_unsubscriber,
            _record.analytics,
          );
        })(),
        (() => {
          let _pipe = $dom.on_click(
            new $msg.UserClosedPopup("ai-" + question_id),
          );
          let _pipe$1 = $dom.to_cancel(
            _pipe,
            (var0) => { return new $msg.MoreButtonReturnedUnsubscriber(var0); },
          );
          let _pipe$2 = $dom.once(_pipe$1);
          return $dom.subscribe(_pipe$2);
        })(),
      ];
    }
  } else if (msg instanceof $msg.UserClickedAiRewrite) {
    let proposal_id = msg.proposal_id;
    let question_id = msg.question_id;
    let asked = msg.asked;
    let question = $model.find_element(data, proposal_id, question_id);
    if (!question.isOk()) {
      return [data, $effect.none()];
    } else if (question.isOk() && question[0] instanceof $proposal_element.Block) {
      return [data, $effect.none()];
    } else {
      let question$1 = question[0].question;
      let question$2 = (() => {
        let _record = question$1;
        return new $question.Question(
          _record.id,
          _record.proposal_id,
          _record.data_points_id,
          _record.display,
          _record.owner,
          _record.kind,
          _record.choices,
          _record.sheet,
          _record.content,
          _record.answer,
          _record.validated,
          _record.metadata,
          true,
          _record.assign_processing,
          _record.qa_order,
          _record.doc_id_order,
        );
      })();
      let question$3 = new $proposal_element.Question(question$2);
      let _pipe = data;
      let _pipe$1 = $data.empty_popup(_pipe, "ai-" + question_id);
      let _pipe$2 = $data.upsert_proposal_element(_pipe$1, question$3);
      return $pair.new$(
        _pipe$2,
        $effect.batch(
          toList([
            $model.unsubscribe_more_proposal(data),
            $question_effects.ask_ai_rewording(
              data,
              proposal_id,
              question_id,
              asked,
              "",
            ),
          ]),
        ),
      );
    }
  } else if (msg instanceof $msg.UserSubmittedCustomRewording) {
    let proposal_id = msg.proposal_id;
    let question_id = msg.question_id;
    let custom_rewording_input = data.custom_rewording_input;
    let no_input = custom_rewording_input === "";
    return $bool.guard(
      no_input,
      [data, $effect.none()],
      () => {
        let question = $model.find_element(data, proposal_id, question_id);
        if (!question.isOk()) {
          return [data, $effect.none()];
        } else if (question.isOk() &&
        question[0] instanceof $proposal_element.Block) {
          return [data, $effect.none()];
        } else {
          let question$1 = question[0].question;
          let question$2 = (() => {
            let _record = question$1;
            return new $question.Question(
              _record.id,
              _record.proposal_id,
              _record.data_points_id,
              _record.display,
              _record.owner,
              _record.kind,
              _record.choices,
              _record.sheet,
              _record.content,
              _record.answer,
              _record.validated,
              _record.metadata,
              true,
              _record.assign_processing,
              _record.qa_order,
              _record.doc_id_order,
            );
          })();
          let element = new $proposal_element.Question(question$2);
          let _pipe = data;
          let _pipe$1 = $data.empty_popup(_pipe, "ai-" + question_id);
          let _pipe$2 = $data.reset_custom_rewording_input(_pipe$1);
          let _pipe$3 = $data.upsert_proposal_element(_pipe$2, element);
          return $pair.new$(
            _pipe$3,
            $effect.batch(
              toList([
                $model.unsubscribe_more_proposal(data),
                $question_effects.ask_ai_rewording(
                  data,
                  proposal_id,
                  question_id,
                  "custom",
                  custom_rewording_input,
                ),
              ]),
            ),
          );
        }
      },
    );
  } else if (msg instanceof $msg.UserClickedAddToQuestionBank) {
    let proposal_id = msg.proposal_id;
    let question_id = msg.question_id;
    let question = $model.find_element(data, proposal_id, question_id);
    if (!question.isOk()) {
      return [data, $effect.none()];
    } else if (question.isOk() && question[0] instanceof $proposal_element.Block) {
      return [data, $effect.none()];
    } else {
      let question$1 = question[0].question;
      let answer = (() => {
        let _pipe = question$1.answer.custom;
        let _pipe$1 = $option.or(_pipe, question$1.answer.long);
        return $option.unwrap(_pipe$1, "");
      })();
      let question$2 = question$1.content;
      let new_qna = new $content_library.NewQna(
        answer,
        new None(),
        false,
        question$2,
      );
      let _pipe = (() => {
        let _record = data.content_library;
        return new $content_library.ContentLibrary(
          _record.change_expiration_date_input,
          _record.change_expiration_date_popup_opened,
          _record.change_owner_popup_opened,
          _record.change_status_popup_opened,
          _record.change_tags_popup_opened,
          _record.conflicts_data_points,
          _record.content_library_block_page_size,
          _record.dropdown_unsubscriber,
          _record.filter_selected,
          _record.ingestion_loading,
          _record.ingestion_spreadsheet,
          _record.insights,
          _record.insight_filter_selected,
          new_qna,
          _record.non_qna_sources,
          _record.non_qna_status,
          _record.qna_sources,
          _record.qna_status,
          _record.search,
          _record.selected,
          _record.selected_insight_data_point_id,
          _record.selected_insight_id,
        );
      })();
      let _pipe$1 = ((_capture) => {
        return $data.set_content_library(data, _capture);
      })(_pipe);
      let _pipe$2 = $data.empty_popup(_pipe$1, "ai-" + question_id);
      return $pair.new$(
        _pipe$2,
        $effect.batch(
          toList([
            $model.unsubscribe_more_proposal(data),
            $content_library_effects.submit_question_bank_edit(),
          ]),
        ),
      );
    }
  } else if (msg instanceof $msg.UserValidatedQuestion) {
    let question = msg.question;
    let $ = $list.contains(data.running_requests, question.id);
    if ($) {
      return [
        data,
        $effect.from(
          (dispatch) => {
            return $function.tap(
              undefined,
              (_) => {
                return $global.set_timeout(
                  1000,
                  () => {
                    let _pipe = new $msg.UserValidatedQuestion(question);
                    let _pipe$1 = new $msg.Proposal(_pipe);
                    return dispatch(_pipe$1);
                  },
                );
              },
            );
          },
        ),
      ];
    } else {
      let proposal_id = question.proposal_id;
      let by_proposal = $dict.get(data.elements.by_proposal, proposal_id);
      let not_found = $result.is_error(by_proposal);
      return $bool.guard(
        not_found,
        [data, $effect.none()],
        () => {
          let _pipe = (() => {
            let _record = question;
            return new $question.Question(
              _record.id,
              _record.proposal_id,
              _record.data_points_id,
              _record.display,
              _record.owner,
              _record.kind,
              _record.choices,
              _record.sheet,
              _record.content,
              _record.answer,
              true,
              _record.metadata,
              _record.ai_processing,
              _record.assign_processing,
              _record.qa_order,
              _record.doc_id_order,
            );
          })();
          let _pipe$1 = new $proposal_element.Question(_pipe);
          let _pipe$2 = ((_capture) => {
            return $data.upsert_proposal_element(data, _capture);
          })(_pipe$1);
          return $pair.new$(
            _pipe$2,
            (() => {
              let elements_ids = toList([question.id]);
              return $question_effects.update_elements_status(
                data,
                elements_ids,
                proposal_id,
                true,
              );
            })(),
          );
        },
      );
    }
  } else if (msg instanceof $msg.UserSelectedProposalPage) {
    let page = msg.page;
    return [
      data,
      (() => {
        let $ = data.route;
        if ($ instanceof $route.Projects &&
        $[0] instanceof $route.Proposals &&
        $[0].proposal instanceof $route.ShowProposal) {
          let id = $[0].id;
          let r = $[0].proposal;
          let _pipe = new $route.ShowProposal(r.id, r.sheet, r.tab, page);
          let _pipe$1 = ((_capture) => {
            return new $route.Proposals(id, _capture);
          })(_pipe);
          let _pipe$2 = new $route.Projects(_pipe$1);
          return $route.replace(_pipe$2);
        } else {
          return $effect.none();
        }
      })(),
    ];
  } else if (msg instanceof $msg.UserSelectedQuestion) {
    let id = msg.id;
    let checked = msg.checked;
    let handler = (() => {
      if (checked) {
        return $data.select_proposal_question;
      } else {
        return $data.deselect_proposal_question;
      }
    })();
    let _pipe = data;
    let _pipe$1 = handler(_pipe, id);
    let _pipe$2 = $data.close_popups(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserToggledAssignUserPopupInProposal) {
    let user_id = msg.user_id;
    let proposal_id = msg.proposal_id;
    let elements_ids = $data.selected_elements_ids(data);
    return [
      data,
      $question_effects.update_elements_owner(
        data,
        elements_ids,
        proposal_id,
        user_id,
      ),
    ];
  } else if (msg instanceof $msg.UserToggledChangeOwnerPopupInProposal) {
    let data$1 = $data.toggle_change_owner_in_proposal(data);
    return [data$1, $proposal_effects.subscribe_dom_click(data$1)];
  } else if (msg instanceof $msg.UserAssignedStatusInProposal) {
    let validated = msg.validated;
    let proposal_id = msg.proposal_id;
    let elements_ids = $data.selected_elements_ids(data);
    return [
      data,
      $question_effects.update_elements_status(
        data,
        elements_ids,
        proposal_id,
        validated,
      ),
    ];
  } else if (msg instanceof $msg.UserToggledChangeStatusPopupInProposal) {
    let data$1 = $data.toggle_change_status_in_proposal(data);
    return [data$1, $proposal_effects.subscribe_dom_click(data$1)];
  } else if (msg instanceof $msg.UserClickedOutsideInProposal) {
    let data$1 = $data.close_popups(data);
    return [data$1, $effect.none()];
  } else if (msg instanceof $msg.UserChoseAttachment) {
    let proposal = msg.proposal;
    let question = msg.question;
    let files = msg.files;
    let $ = $list.first(files);
    if (!$.isOk()) {
      return [data, $effect.none()];
    } else {
      let file = $[0];
      return [
        $data.empty_popup(data, "ai-" + question.id),
        $effect.batch(
          toList([
            $question_effects.add_attachment(data, proposal, question, file),
            (() => {
              let _pipe = data.more_proposal_unsubscriber;
              return $option.unwrap(_pipe, $effect.none());
            })(),
          ]),
        ),
      ];
    }
  } else if (msg instanceof $msg.UserDeletedAttachment) {
    let proposal = msg.proposal;
    let question = msg.question;
    let attachment = msg.attachment;
    return [
      data,
      $question_effects.remove_attachment(data, proposal, question, attachment),
    ];
  } else if (msg instanceof $msg.UserDisplayedDeleteProposalModal) {
    let id = msg.id;
    let modal = new $data.DeleteProposal(id);
    let data$1 = (() => {
      let _record = data;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        modal,
        _record.modal_unsubscriber,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.elements,
        _record.route,
        _record.language,
        _record.running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    })();
    return [data$1, $modal_effects.subscribe_close()];
  } else if (msg instanceof $msg.UserDeletedProposal) {
    let id = msg.id;
    let found = (() => {
      let _pipe = $list.key_find(data.proposals, id);
      return $result.is_ok(_pipe);
    })();
    return $bool.guard(
      !found,
      [data, $effect.none()],
      () => {
        let _pipe = data.proposals;
        let _pipe$1 = $list.filter(
          _pipe,
          (proposal) => { return proposal[1].id !== id; },
        );
        let _pipe$2 = ((proposals) => {
          let _record = data;
          return new Data(
            _record.access_token,
            _record.client,
            _record.collaborators_proposal_opened,
            _record.connectors,
            _record.organization,
            _record.content_library,
            _record.copilot_input,
            _record.proposal_filters,
            _record.proposal_selected_questions,
            _record.copilot_threads,
            _record.custom_rewording_input,
            _record.modal,
            _record.modal_unsubscriber,
            _record.feed_opened,
            _record.loading,
            _record.more_proposal_opened,
            _record.more_proposal_unsubscriber,
            _record.proposal_builder,
            _record.filter_proposal_opened,
            _record.notifications,
            _record.notifications_unread,
            _record.permissions,
            _record.projects,
            proposals,
            _record.proposal_block_page_size,
            _record.elements,
            _record.route,
            _record.language,
            _record.running_requests,
            _record.translations,
            _record.user,
            _record.qualification_matrix,
            _record.collapsed_navbar,
            _record.opened_projects,
            _record.proposal_toggles,
            _record.dropdown_unsubscriber,
            _record.analytics,
          );
        })(_pipe$1);
        return $pair.new$(
          _pipe$2,
          $effect.batch(
            toList([
              $proposal_effects.delete_proposal(data, id),
              $modal_effects.close(),
            ]),
          ),
        );
      },
    );
  } else if (msg instanceof $msg.UserUpdatedProposalFiltersStatus) {
    let status = msg.status;
    let _pipe = (() => {
      let _record = data.proposal_filters;
      return new $proposal.ProposalFilters(status, _record.owner);
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_proposal_filters(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedProposalFiltersOwner) {
    let owner = msg.owner;
    let _pipe = (() => {
      let _record = data.proposal_filters;
      return new $proposal.ProposalFilters(_record.status, owner);
    })();
    let _pipe$1 = ((_capture) => {
      return $data.set_proposal_filters(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserToggledFilterProposal) {
    let _pipe = new $proposal.ProposalFilters(
      new $proposal.AllStatus(),
      new None(),
    );
    let _pipe$1 = ((_capture) => {
      return $data.set_proposal_filters(data, _capture);
    })(_pipe);
    let _pipe$2 = $data.toggle_filter_proposal_opened(_pipe$1);
    return $pair.new$(
      _pipe$2,
      (() => {
        let $ = data.filter_proposal_opened;
        if (!$) {
          return $effect.none();
        } else {
          let _pipe$3 = $dom.on_click(
            new $msg.UserClosedPopup("proposal-filter"),
          );
          let _pipe$4 = $dom.once(_pipe$3);
          return $dom.subscribe(_pipe$4);
        }
      })(),
    );
  } else {
    let language = msg.language;
    let _pipe = (() => {
      let _record = data;
      return new Data(
        _record.access_token,
        _record.client,
        _record.collaborators_proposal_opened,
        _record.connectors,
        _record.organization,
        _record.content_library,
        _record.copilot_input,
        _record.proposal_filters,
        _record.proposal_selected_questions,
        _record.copilot_threads,
        _record.custom_rewording_input,
        _record.modal,
        _record.modal_unsubscriber,
        _record.feed_opened,
        _record.loading,
        _record.more_proposal_opened,
        _record.more_proposal_unsubscriber,
        _record.proposal_builder,
        _record.filter_proposal_opened,
        _record.notifications,
        _record.notifications_unread,
        _record.permissions,
        _record.projects,
        _record.proposals,
        _record.proposal_block_page_size,
        _record.elements,
        _record.route,
        language,
        _record.running_requests,
        _record.translations,
        _record.user,
        _record.qualification_matrix,
        _record.collapsed_navbar,
        _record.opened_projects,
        _record.proposal_toggles,
        _record.dropdown_unsubscriber,
        _record.analytics,
      );
    })();
    return $pair.new$(_pipe, $effect.none());
  }
}
