/// <reference types="./content_library.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $timestamp_ from "../../../common_common/gleam/time/timestamp/extra.mjs";
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $checkbox from "../../../ds/ds/components/checkbox.mjs";
import * as $input from "../../../ds/ds/components/input.mjs";
import * as $label from "../../../ds/ds/components/label.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $loader from "../../../ds/ds/components/loader.mjs";
import * as $pp from "../../../ds/ds/components/profile_picture.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $ds_utils from "../../../ds/ds/utils.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $duration from "../../../gleam_time/gleam/time/duration.mjs";
import * as $timestamp from "../../../gleam_time/gleam/time/timestamp.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $model from "../../data/model.mjs";
import * as $computed from "../../data/model/computed.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $route from "../../data/route.mjs";
import * as $tab_bar from "../../frontend/view/components/tab_bar.mjs";
import * as $ingestion from "../../frontend/view/content_library/ingestion.mjs";
import * as $insights from "../../frontend/view/content_library/insights.mjs";
import * as $new_question from "../../frontend/view/content_library/new_question.mjs";
import * as $question_bank from "../../frontend/view/content_library/question_bank.mjs";
import * as $show from "../../frontend/view/content_library/show.mjs";
import * as $s from "../../frontend/view/styles/content_library.mjs";
import { toList, prepend as listPrepend, makeError } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import { stop_propagation_ as on_click } from "../../utils.mjs";

function change_owner_popup(data) {
  return $s.change_owner_popup(
    toList([]),
    listPrepend(
      $s.change_popup_title(toList([]), toList([$el.text("Select one owner")])),
      (() => {
        let _pipe = data.organization.users;
        let _pipe$1 = $list.filter(
          _pipe,
          (user) => {
            return $list.any(
              user.roles,
              (r) => { return r.name === "Content Curator"; },
            );
          },
        );
        return $list.map(
          _pipe$1,
          (user) => {
            let name = user.name;
            let picture = user.picture;
            let initials = user.initials;
            return $s.change_popup_owner_select(
              toList([
                $e.on_click(
                  new $msg.UserToggledAssignUserPopupInContentLibrary(user.id),
                ),
              ]),
              toList([
                $layout.row(
                  toList([$layout.gap(8), $layout.align("center")]),
                  toList([]),
                  toList([
                    $pp.from_user(picture, initials, new $pp.Medium()),
                    $el.text(name),
                  ]),
                ),
              ]),
            );
          },
        );
      })(),
    ),
  );
}

function status_label(status) {
  let label = (() => {
    if (status instanceof $data_source.Verified) {
      return $label.success();
    } else if (status instanceof $data_source.Pending) {
      return $label.current();
    } else if (status instanceof $data_source.NotVerified) {
      return $label.neutral();
    } else if (status instanceof $data_source.Outdated) {
      return $label.error();
    } else {
      return $label.neutral();
    }
  })();
  let name = (() => {
    let _pipe = $data_source.status_to_string(status);
    let _pipe$1 = $string.replace(_pipe, "-", " ");
    return $label.content(_pipe$1);
  })();
  let effect = new $msg.UserAssignedStatusInContentLibrary(status);
  return $label.label(toList([label, $label.on_click(effect), name]));
}

function change_status_popup() {
  return $s.change_status_popup(
    toList([]),
    toList([
      status_label(new $data_source.Verified()),
      status_label(new $data_source.Pending()),
      status_label(new $data_source.NotVerified()),
      status_label(new $data_source.Outdated()),
    ]),
  );
}

function content_library_menu(model, page) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let computed = $[1];
  let selected = data.content_library.selected;
  let size = $dict.size(selected);
  let hidden = size === 0;
  let in_question_bank = $route.is_question_bank(data.route);
  let all_selected = (() => {
    if (in_question_bank) {
      return $computed.all_qna_selected(data, computed, page);
    } else {
      return $computed.all_non_qna_selected(data, computed, page);
    }
  })();
  let check_state = (() => {
    if (all_selected) {
      return new $checkbox.Checked();
    } else if (!hidden) {
      return new $checkbox.Indeterminate();
    } else {
      return new $checkbox.Unchecked();
    }
  })();
  return $s.menu_wrapper(
    hidden,
    toList([]),
    toList([
      $s.menu(
        toList([]),
        toList([
          $layout.row(
            toList([$layout.gap(16)]),
            toList([]),
            toList([
              $checkbox.checkbox(
                check_state,
                (_) => {
                  return new $msg.UserClickedGlobalCheckbox(
                    all_selected,
                    (() => {
                      if (in_question_bank) {
                        return $computed.filtered_qna_sources_in_page(
                          data,
                          computed,
                          page,
                        );
                      } else {
                        return $computed.filtered_non_qna_sources_page(
                          data,
                          computed,
                          page,
                        );
                      }
                    })(),
                  );
                },
              ),
              $s.number_selected(
                toList([]),
                toList([$h.text($int.to_string(size) + " selected")]),
              ),
            ]),
          ),
          $s.change_button(
            toList([
              on_click(new $msg.UserToggledChangeOwnerPopupInContentLibrary()),
            ]),
            toList([
              $s.change_icon(false, $icons.user()),
              $h.text("Change owner"),
              $s.change_icon(
                !data.content_library.change_owner_popup_opened,
                $icons.arrow_dropdown(),
              ),
              (() => {
                let $1 = data.content_library.change_owner_popup_opened;
                if (!$1) {
                  return $el.none();
                } else {
                  return change_owner_popup(data);
                }
              })(),
            ]),
          ),
          $s.change_button(
            toList([
              on_click(new $msg.UserToggledChangeStatusPopupInContentLibrary()),
            ]),
            toList([
              $s.change_icon(false, $icons.checkmark()),
              $h.text("Change status"),
              $s.change_icon(
                !data.content_library.change_status_popup_opened,
                $icons.arrow_dropdown(),
              ),
              (() => {
                let $1 = data.content_library.change_status_popup_opened;
                if (!$1) {
                  return $el.none();
                } else {
                  return change_status_popup();
                }
              })(),
            ]),
          ),
          $s.change_button(
            toList([on_click(new $msg.UserToggledChangeExpirationDatePopup())]),
            toList([
              $s.change_icon(false, $icons.calendar()),
              $h.text("Change expiration date"),
              $s.change_icon(
                !data.content_library.change_expiration_date_popup_opened,
                $icons.arrow_dropdown(),
              ),
              (() => {
                let $1 = data.content_library.change_expiration_date_popup_opened;
                if (!$1) {
                  return $el.none();
                } else {
                  return $s.change_expiration_date_popup(
                    toList([$ds_utils.stop_propagation()]),
                    toList([
                      $layout.row(
                        toList([$layout.align("center"), $layout.gap(8)]),
                        toList([]),
                        (() => {
                          let value = data.content_library.change_expiration_date_input;
                          let time = $timestamp_.from_time_input(value);
                          let disabled = $a.disabled($result.is_error(time));
                          let on_click$1 = (() => {
                            let _pipe = time;
                            let _pipe$1 = $result.map(
                              _pipe,
                              (var0) => {
                                return new $msg.UserAssignedExpirationDate(var0);
                              },
                            );
                            let _pipe$2 = $result.map(_pipe$1, $e.on_click);
                            return $result.unwrap(_pipe$2, $a.none());
                          })();
                          return toList([
                            $input.input(
                              toList([
                                $input.value(value),
                                $input.type_("date"),
                                $input.on_input(
                                  (var0) => {
                                    return new $msg.UserUpdatedExpirationDateInput(
                                      var0,
                                    );
                                  },
                                ),
                              ]),
                            ),
                            $button.primary(
                              toList([on_click$1, disabled]),
                              toList([$h.text("Submit")]),
                            ),
                          ]);
                        })(),
                      ),
                      $button.secondary(
                        toList([
                          (() => {
                            let _pipe = $timestamp.system_time();
                            let _pipe$1 = $timestamp.add(
                              _pipe,
                              $duration.seconds(6 * 30 * 24 * 60 * 60),
                            );
                            let _pipe$2 = new $msg.UserAssignedExpirationDate(
                              _pipe$1,
                            );
                            return $e.on_click(_pipe$2);
                          })(),
                        ]),
                        toList([$h.text("6 months")]),
                      ),
                      $button.secondary(
                        toList([
                          (() => {
                            let _pipe = $timestamp.system_time();
                            let _pipe$1 = $timestamp.add(
                              _pipe,
                              $duration.seconds(3 * 30 * 24 * 60 * 60),
                            );
                            let _pipe$2 = new $msg.UserAssignedExpirationDate(
                              _pipe$1,
                            );
                            return $e.on_click(_pipe$2);
                          })(),
                        ]),
                        toList([$h.text("3 month")]),
                      ),
                      $button.secondary(
                        toList([
                          (() => {
                            let _pipe = $timestamp.system_time();
                            let _pipe$1 = $timestamp.add(
                              _pipe,
                              $duration.seconds(1 * 30 * 24 * 60 * 60),
                            );
                            let _pipe$2 = new $msg.UserAssignedExpirationDate(
                              _pipe$1,
                            );
                            return $e.on_click(_pipe$2);
                          })(),
                        ]),
                        toList([$h.text("1 month")]),
                      ),
                    ]),
                  );
                }
              })(),
            ]),
          ),
          $s.change_button(
            toList([on_click(new $msg.UserToggledChangeTags())]),
            toList([
              $s.change_icon(false, $icons.price_tag()),
              $h.text("Change tags"),
              $s.change_icon(
                !data.content_library.change_tags_popup_opened,
                $icons.arrow_dropdown(),
              ),
              (() => {
                let $1 = data.content_library.change_tags_popup_opened;
                if (!$1) {
                  return $el.none();
                } else {
                  return $s.change_tags_popup(
                    toList([]),
                    (() => {
                      let selected$1 = (() => {
                        let _pipe = data.content_library.selected;
                        let _pipe$1 = $dict.to_list(_pipe);
                        let _pipe$2 = $list.filter(
                          _pipe$1,
                          (a) => { return a[1]; },
                        );
                        return $list.filter_map(
                          _pipe$2,
                          (a) => {
                            return $list.find(
                              $list.append(
                                computed.qna_sources.all,
                                computed.non_qna_sources.all,
                              ),
                              (s) => { return s.id === a[0]; },
                            );
                          },
                        );
                      })();
                      return $list.map(
                        data.organization.tags,
                        (tag) => {
                          let selected_tags = $list.fold(
                            selected$1,
                            [true, false],
                            (acc, value) => {
                              let all_selected$1 = acc[0];
                              let some_selected = acc[1];
                              let $2 = $list.find(
                                value.tags,
                                (t) => { return t === tag.id; },
                              );
                              if (!$2.isOk()) {
                                return [false, some_selected];
                              } else {
                                return [all_selected$1 && true, true];
                              }
                            },
                          );
                          let $2 = (() => {
                            if (selected_tags[0]) {
                              return [
                                new $checkbox.Checked(),
                                new $msg.Remove(),
                              ];
                            } else if (!selected_tags[0] && selected_tags[1]) {
                              return [
                                new $checkbox.Indeterminate(),
                                new $msg.Add(),
                              ];
                            } else {
                              return [new $checkbox.Unchecked(), new $msg.Add()];
                            }
                          })();
                          let state = $2[0];
                          let add_remove = $2[1];
                          let on_click$1 = new $msg.UserAssignedTag(
                            tag,
                            add_remove,
                          );
                          return $layout.row(
                            toList([
                              $layout.align("center"),
                              $layout.gap(8),
                              $layout.justify("start"),
                            ]),
                            toList([$ds_utils.stop_propagation()]),
                            toList([
                              $checkbox.checkbox(
                                state,
                                (_) => { return on_click$1; },
                              ),
                              $label.label(
                                toList([
                                  $label.neutral(),
                                  $label.on_click(on_click$1),
                                  $label.content(tag.name),
                                ]),
                              ),
                            ]),
                          );
                        },
                      );
                    })(),
                  );
                }
              })(),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function spreadsheet_loader_screen() {
  return $layout.column(
    toList([$layout.flex("1"), $layout.justify("center")]),
    toList([]),
    toList([
      $loader.loader(
        toList([
          "Reading questionnaire",
          "Analyzing content",
          "Detecting questions",
          "Polishing render",
          "Improving format",
        ]),
      ),
    ]),
  );
}

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $bool.lazy_guard(
    data.content_library.ingestion_spreadsheet.processing,
    spreadsheet_loader_screen,
    () => {
      let page = $route.extract_page($route.to_uri(data.route));
      let $1 = data.route;
      if (!($1 instanceof $route.ContentLibrary)) {
        throw makeError(
          "let_assert",
          "frontend/view/content_library",
          48,
          "",
          "Pattern match failed, no pattern matched the value.",
          { value: $1 }
        )
      }
      let route = $1[0];
      let tabs = (() => {
        let tabs = toList([
          (() => {
            let _pipe = new $route.ContentLibrary(
              new $route.DataSources(0, new $option.None()),
            );
            return $tab_bar.tab(_pipe, toList([$el.text("Sources")]));
          })(),
          (() => {
            let _pipe = new $route.ContentLibrary(
              new $route.QuestionBank(0, new $option.None()),
            );
            return $tab_bar.tab(_pipe, toList([$el.text("Question Bank")]));
          })(),
        ]);
        return $bool.guard(
          $data.are_insights_hidden(data),
          tabs,
          () => {
            return $list.append(
              tabs,
              toList([
                (() => {
                  let _pipe = new $route.ContentLibrary(new $route.Insights());
                  return $tab_bar.tab(_pipe, toList([$el.text("Insights")]));
                })(),
              ]),
            );
          },
        );
      })();
      return $el.fragment(
        toList([
          (() => {
            if (route instanceof $route.NewQuestion) {
              return $el.none();
            } else if (route instanceof $route.Ingestion) {
              return $el.none();
            } else {
              return $tab_bar.tab_bar(tabs);
            }
          })(),
          (() => {
            if (route instanceof $route.DataSources) {
              let page$1 = route.page;
              return $show.view(model, page$1);
            } else if (route instanceof $route.QuestionBank) {
              let page$1 = route.page;
              return $question_bank.view(model, page$1);
            } else if (route instanceof $route.Insights) {
              return $insights.view(model);
            } else if (route instanceof $route.NewQuestion) {
              return $new_question.view(model);
            } else {
              return $ingestion.view(model);
            }
          })(),
          (() => {
            let _pipe = content_library_menu(model, page);
            return $el.map(
              _pipe,
              (var0) => { return new $msg.ContentLibrary(var0); },
            );
          })(),
        ]),
      );
    },
  );
}
