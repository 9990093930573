/// <reference types="./overview.d.mts" />
import * as $colors from "../../../../ds/ds/colors.mjs";
import * as $display from "../../../../ds/ds/typography/display.mjs";
import * as $text from "../../../../ds/ds/typography/text.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $common from "../../../frontend/view/common.mjs";
import { toList } from "../../../gleam.mjs";

export function section(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.align_items("start"),
      $css.gap(px(16)),
    ]),
  );
  return $h.section(_pipe, attrs, children);
}

export function section_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

export function section_content(attrs, children) {
  let _pipe = $css.class$(toList([$css.width(percent(100))]));
  return $h.div(_pipe, attrs, children);
}

export function onboarding_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($common.card()),
      $css.display("grid"),
      $css.grid_template_rows("auto 1fr auto"),
      $css.grid_template_columns("auto 1fr 1fr auto"),
      $css.grid_template_areas(
        toList([
          "stepper   stepper   stepper   stepper",
          "icon      content   .......   .......",
          "....      .......   .......   skip",
        ]),
      ),
      $css.row_gap(px(24)),
      $css.column_gap(px(30)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function onboarding_icon(attrs, children) {
  let _pipe = $css.class$(toList([$css.grid_area("icon")]));
  return $h.div(_pipe, attrs, children);
}

export function onboarding_action(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.grid_area("content"),
      $css.align_self("center"),
      $css.display("flex"),
      $css.align_items("start"),
      $css.flex_direction("column"),
      $css.gap(px(24)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function onboarding_action_titles(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function onboarding_action_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

export function onboarding_action_subtitle(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($text.md($text.regular))]));
  return $h.div(_pipe, attrs, children);
}

export function onboarding_skip(attrs, children) {
  let _pipe = $css.class$(toList([$css.grid_area("skip")]));
  return $h.div(_pipe, attrs, children);
}

export function statistics_content(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($common.card()),
      $css.padding(px(32)),
      $css.display("flex"),
      $css.gap(px(48)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(16)),
      $css.justify_content("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_empty_text(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.color($colors.light.grey_800),
      $css.max_width(px(200)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_value(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("space-between"),
      $css.gap(px(48)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_label(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.regular)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(12)),
      $css.color($colors.light.paragraph),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_color(color) {
  let _pipe = $css.class$(
    toList([
      $css.background(color),
      $css.width(px(16)),
      $css.height(px(16)),
      $css.border_radius(px(4)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function statistics_colored_label(color, attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose($text.xs($text.regular)), $css.color(color)]),
  );
  return $h.div(_pipe, attrs, children);
}

export function statistics_content_wrapper(attrs, children) {
  let _pipe = $css.class$(toList([$css.display("flex"), $css.gap(px(32))]));
  return $h.div(_pipe, attrs, children);
}

export function icon_wrapper(attrs, children) {
  let _pipe = $css.class$(toList([$css.width(px(20)), $css.height(px(20))]));
  return $h.div(_pipe, attrs, children);
}
