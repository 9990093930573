/// <reference types="./document.d.mts" />
import * as $bright from "../../../../../bright/bright.mjs";
import * as $project from "../../../../../common/data/project.mjs";
import { Project } from "../../../../../common/data/project.mjs";
import * as $proposal from "../../../../../common/data/proposal.mjs";
import { Proposal } from "../../../../../common/data/proposal.mjs";
import * as $proposal_element from "../../../../../common/data/proposal_element.mjs";
import * as $dict from "../../../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../../../gleam_stdlib/gleam/string.mjs";
import * as $el from "../../../../../lustre/lustre/element.mjs";
import * as $model from "../../../../data/model.mjs";
import * as $msg from "../../../../data/msg.mjs";
import * as $editor from "../../../../elements/editor.mjs";
import * as $projects_common from "../../../../frontend/view/projects/show/common.mjs";
import { toList, isEqual } from "../../../../gleam.mjs";

function editor_content(model, proposal) {
  let data = $bright.data(model);
  let _pipe = data.elements.by_proposal;
  let _pipe$1 = $dict.get(_pipe, proposal.id);
  let _pipe$2 = $result.unwrap(_pipe$1, toList([]));
  let _pipe$3 = $list.map(
    _pipe$2,
    (element) => {
      let element$1 = element[1];
      let owner = $proposal_element.owner(element$1);
      let user = $list.find(
        data.organization.users,
        (user) => { return isEqual(new Some(user.id), owner); },
      );
      let user$1 = $option.from_result(user);
      let profile_picture = $option.then$(user$1, (u) => { return u.picture; });
      let initials = $option.map(user$1, (u) => { return u.initials; });
      return $proposal_element.to_editor(element$1, profile_picture, initials);
    },
  );
  return $string.join(_pipe$3, "\n");
}

export function view(model, project, proposal) {
  let elements = editor_content(model, proposal);
  let on_event = $editor.on_event(
    (_capture) => {
      return new $msg.UserUpdatedDocument(project, proposal, _capture);
    },
  );
  let content = $editor.content(elements);
  return $el.fragment(
    toList([
      $projects_common.header(project, proposal),
      $projects_common.tab_actions_bar(model, project, proposal),
      (() => {
        let _pipe = $editor.view(toList([content, on_event]));
        return $el.map(_pipe, (var0) => { return new $msg.Narrative(var0); });
      })(),
    ]),
  );
}
