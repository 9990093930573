/// <reference types="./pdf_selector.d.mts" />
import { File } from "../../../../common/data/ephemeral/file.mjs";
import * as $pdf_selector from "../../../../common/data/ephemeral/pdf_selector.mjs";
import { PdfSelector } from "../../../../common/data/ephemeral/pdf_selector.mjs";
import * as $file from "../../../../ds/plinth/browser/file.mjs";
import * as $form_data from "../../../../gleam_fetch/gleam/fetch/form_data.mjs";
import * as $http from "../../../../gleam_http/gleam/http.mjs";
import * as $promise from "../../../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $function from "../../../../gleam_stdlib/gleam/function.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../../../lustre/lustre/effect.mjs";
import * as $mime_types from "../../../../mime_types/mime_types.mjs";
import * as $data from "../../../data/model/data.mjs";
import * as $msg from "../../../data/msg.mjs";
import * as $ask from "../../../frontend/ask.mjs";
import * as $crypto from "../../../frontend/browser/crypto.mjs";
import * as $middleware from "../../../frontend/middleware.mjs";
import { toList, makeError, isEqual } from "../../../gleam.mjs";

export function is_pdf_fetched(msg) {
  if (msg instanceof $msg.ApiFetchedOutline) {
    return true;
  } else {
    return false;
  }
}

function send_pdf_decoding_request(data, file, session_id) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_org_id(
        data,
        (org_id) => {
          return $middleware.require_user_id(
            data,
            (user_id) => {
              return $effect.from(
                (_) => {
                  return $function.tap(
                    undefined,
                    (_) => {
                      let _pipe = $ask.to(
                        new $ask.Heimdall(),
                        toList(["decode-pdf"]),
                      );
                      let _pipe$1 = $ask.via(_pipe, new $http.Post());
                      let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                      let _pipe$3 = $ask.data(
                        _pipe$2,
                        (() => {
                          let _pipe$3 = $json.object(
                            toList([
                              ["org_id", $json.string(org_id)],
                              ["user_id", $json.string(user_id)],
                              ["session_id", $json.string(session_id)],
                            ]),
                          );
                          let _pipe$4 = $json.to_string(_pipe$3);
                          let _pipe$5 = ((_capture) => {
                            return $form_data.append(
                              $form_data.new$(),
                              "content",
                              _capture,
                            );
                          })(_pipe$4);
                          return $form_data.append_bits(
                            _pipe$5,
                            "blob",
                            file.content,
                          );
                        })(),
                      );
                      return $ask.run(_pipe$3);
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function update(data, selector, msg) {
  if (msg instanceof $msg.ApiFetchedOutline) {
    let outline = msg.outline;
    let is_session = isEqual(new Some(outline.session_id), selector.session_id);
    return $bool.guard(
      !is_session,
      [selector, $effect.none()],
      () => {
        let outline$1 = new Some(outline);
        let _pipe = (() => {
          let _record = selector;
          return new PdfSelector(
            _record.file,
            _record.session_id,
            false,
            _record.content,
            outline$1,
          );
        })();
        return $pair.new$(_pipe, $effect.none());
      },
    );
  } else if (msg instanceof $msg.BrowserDecodedPdfFile) {
    let file_ = msg.file;
    let file = new Some(file_);
    let id = $crypto.uuid();
    let session_id = new Some(id);
    let _pipe = (() => {
      let _record = selector;
      return new PdfSelector(
        file,
        session_id,
        true,
        _record.content,
        _record.outline,
      );
    })();
    return $pair.new$(_pipe, send_pdf_decoding_request(data, file_, id));
  } else if (msg instanceof $msg.UserSelectedPdf) {
    let file = msg.file;
    return [
      selector,
      $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              return $promise.map(
                $file.bytes(file),
                (content) => {
                  return $result.map(
                    $mime_types.from_string($file.mime(file)),
                    (mime_type) => {
                      let file$1 = new File(
                        $file.name(file),
                        content,
                        mime_type,
                      );
                      return dispatch(new $msg.BrowserDecodedPdfFile(file$1));
                    },
                  );
                },
              );
            },
          );
        },
      ),
    ];
  } else {
    throw makeError(
      "panic",
      "frontend/update/proposal_builder/pdf_selector",
      48,
      "update",
      "Unhandled document_selector msg",
      {}
    )
  }
}
