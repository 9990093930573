/// <reference types="./copilot_question.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $calendar from "../../gleam_time/gleam/time/calendar.mjs";
import * as $timestamp from "../../gleam_time/gleam/time/timestamp.mjs";
import * as $data_point from "../data/data_point.mjs";
import * as $decrypt from "../decrypt.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Display extends $CustomType {
  constructor(score) {
    super();
    this.score = score;
  }
}

export class Answer extends $CustomType {
  constructor(short, long, yes_no, custom) {
    super();
    this.short = short;
    this.long = long;
    this.yes_no = yes_no;
    this.custom = custom;
  }
}

export class CopilotQuestion extends $CustomType {
  constructor(id, thread_id, display, content, user_id, answer, org_id, loading, data_points_id, created_at, ai_processing) {
    super();
    this.id = id;
    this.thread_id = thread_id;
    this.display = display;
    this.content = content;
    this.user_id = user_id;
    this.answer = answer;
    this.org_id = org_id;
    this.loading = loading;
    this.data_points_id = data_points_id;
    this.created_at = created_at;
    this.ai_processing = ai_processing;
  }
}

export function display_decoder() {
  return $decode.field(
    "score",
    $decode.optional($decode.float),
    (score) => { return $decode.success(new Display(score)); },
  );
}

export function answer_decoder() {
  return $decode.map(
    $decode.optional(
      $decode.field(
        "short",
        $decode.optional($decode.string),
        (short) => {
          return $decode.field(
            "long",
            $decode.optional($decode.string),
            (long) => {
              return $decode.field(
                "yes_no",
                $decode.optional($decode.string),
                (yes_no) => {
                  return $decode.field(
                    "custom",
                    $decode.optional($decode.string),
                    (custom) => {
                      return $decode.success(
                        new Answer(short, long, yes_no, custom),
                      );
                    },
                  );
                },
              );
            },
          );
        },
      ),
    ),
    (content) => {
      let _pipe = new Answer(new None(), new None(), new None(), new None());
      return ((_capture) => { return $option.unwrap(content, _capture); })(
        _pipe,
      );
    },
  );
}

export function decoder() {
  return $decode.field(
    "id",
    (() => {
      let _pipe = $decode.string;
      return $decode.map(_pipe, $string.lowercase);
    })(),
    (id) => {
      let thread_id = $decode.map($decode.string, $string.lowercase);
      return $decode.field(
        "thread_id",
        thread_id,
        (thread_id) => {
          return $decode.field(
            "display",
            $decrypt.json(display_decoder()),
            (display) => {
              return $decode.field(
                "content",
                $decode.string,
                (content) => {
                  return $decode.field(
                    "user_id",
                    $decode.string,
                    (user_id) => {
                      return $decode.field(
                        "answer",
                        $decrypt.json(answer_decoder()),
                        (answer) => {
                          return $decode.field(
                            "loading",
                            $decode.bool,
                            (loading) => {
                              return $decode.field(
                                "org_id",
                                $decode.string,
                                (org_id) => {
                                  return $decode.field(
                                    "data_points_id",
                                    (() => {
                                      let _pipe = $decode.optional(
                                        $decrypt.json($data_point.ids_decoder()),
                                      );
                                      return $decode.map(
                                        _pipe,
                                        (_capture) => {
                                          return $option.unwrap(
                                            _capture,
                                            toList([]),
                                          );
                                        },
                                      );
                                    })(),
                                    (data_points_id) => {
                                      return $decode.field(
                                        "created_at",
                                        $decrypt.timestamp(),
                                        (created_at) => {
                                          return $decode.field(
                                            "ai_processing",
                                            $decode.bool,
                                            (ai_processing) => {
                                              return $decode.success(
                                                new CopilotQuestion(
                                                  id,
                                                  thread_id,
                                                  display,
                                                  content,
                                                  user_id,
                                                  answer,
                                                  org_id,
                                                  loading,
                                                  data_points_id,
                                                  created_at,
                                                  ai_processing,
                                                ),
                                              );
                                            },
                                          );
                                        },
                                      );
                                    },
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function encode_answer(answer) {
  return $json.object(
    toList([
      ["short", $json.nullable(answer.short, $json.string)],
      ["long", $json.nullable(answer.long, $json.string)],
      ["custom", $json.nullable(answer.custom, $json.string)],
      ["yes_no", $json.nullable(answer.yes_no, $json.string)],
    ]),
  );
}

export function encode_display(display) {
  return $json.object(
    toList([["score", $json.nullable(display.score, $json.float)]]),
  );
}

export function encode(question) {
  return $json.object(
    toList([
      ["id", $json.string(question.id)],
      ["thread_id", $json.string(question.thread_id)],
      ["display", encode_display(question.display)],
      ["content", $json.string(question.content)],
      ["user_id", $json.string(question.user_id)],
      ["answer", encode_answer(question.answer)],
      ["org_id", $json.string(question.org_id)],
      ["loading", $json.bool(question.loading)],
      [
        "created_at",
        $json.string(
          $timestamp.to_rfc3339(question.created_at, $calendar.utc_offset),
        ),
      ],
      [
        "data_points_id",
        $json.array(
          question.data_points_id,
          (_use0) => {
            let id = _use0[0];
            let score = _use0[1];
            return $json.object(
              toList([
                ["data_point_id", $json.string(id)],
                ["score", $json.float(score)],
              ]),
            );
          },
        ),
      ],
      ["ai_processing", $json.bool(question.ai_processing)],
    ]),
  );
}

export function answer(question) {
  let _pipe = question.answer.long;
  let _pipe$1 = $option.or(_pipe, question.answer.short);
  return $option.or(_pipe$1, question.answer.yes_no);
}
