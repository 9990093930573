import { Node, NodeViewRendererProps } from '@tiptap/core'
import * as h from '#editor/html'
import * as a from '#editor/html/attribute'

export const SubjectBlock = Node.create({
  name: 'steerlabSubject',
  group: 'block',
  content: 'block*',
  addAttributes() {
    return {
      id: {
        parseHTML: element => element.getAttribute('data-id'),
        renderHTML: attributes => ({ ['data-id']: attributes.id }),
      },
      status: {
        default: 'in-review',
        parseHTML: element => element.getAttribute('data-status'),
        renderHTML: attributes => ({ ['data-status']: attributes.status }),
      },
      picture: {
        parseHTML: element => element.getAttribute('data-picture'),
        renderHTML: attributes => ({ ['data-picture']: attributes.picture }),
      },
      initials: {
        parseHTML: element => element.getAttribute('data-initials'),
        renderHTML: attributes => ({ ['data-initials']: attributes.initials }),
      },
    }
  },
  parseHTML() {
    return [{ tag: 'steerlab-subject' }]
  },
  renderHTML({ HTMLAttributes }) {
    return ['steerlab-subject', HTMLAttributes, 0]
  },
  addNodeView() {
    // prettier-ignore
    return props => {
      const contentDOM = h.div([], [])
      const editable = a.attribute("contenteditable", "false")
      const dom = h.element('steerlab-subject', [editable], [
        h.div([a.class_('steerlab-subject-metadata')], [
          profilePicture(props),
          status(props),
          separator(props),
        ]),
        contentDOM,
      ])
      return { dom, contentDOM }
    }
  },
})

function profilePicture(props: NodeViewRendererProps) {
  const { initials, picture } = props.node.attrs
  const class_ = a.class_('steerlab-subject-pp')
  if (picture) return h.img([a.src(picture), class_])
  if (initials) return h.div([class_], [h.text(initials)])
  return h.text('')
}

function status(props: NodeViewRendererProps) {
  const status: string = props.node.attrs.status
  const background = status === 'in-review' ? '#EEF4FE' : '#F0FDD1'
  const border_ = status === 'in-review' ? '#B7CFF2' : '#CBF26C'
  const color = status === 'in-review' ? '#3B75CA' : '#87B024'
  const border = `1px solid ${border_}`
  const content = status.replace('-', ' ')
  const class_ = a.class_(`question-${status}`)
  const baseClass = a.class_('question-status')
  // prettier-ignore
  return h.div([baseClass, class_, a.style({ background, color, border })], [
    h.text(content),
  ])
}

function separator(props: NodeViewRendererProps) {
  const status = props.node.attrs.status
  const background = status === 'in-review' ? '#B7CFF2' : '#CBF26C'
  const class_ = a.class_(`question-separator`)
  const style = a.style({ background })
  // prettier-ignore
  return h.div([class_], [
    h.div([style, a.class_("question-separator-line")], []),
    h.div([style, a.class_('question-separator-dot')], []),
  ])
}
