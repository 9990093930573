/// <reference types="./notion.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Token extends $CustomType {
  constructor(api_key, page_ids) {
    super();
    this.api_key = api_key;
    this.page_ids = page_ids;
  }
}

export function new$() {
  let api_key = "";
  let page_ids = toList([]);
  return new Token(api_key, page_ids);
}

export function encode(token) {
  return $json.object(
    toList([
      ["api_key", $json.string(token.api_key)],
      ["page_ids", $json.array(token.page_ids, $json.string)],
    ]),
  );
}

export function decoder() {
  return $decode.field(
    "api_key",
    $decode.string,
    (api_key) => {
      return $decode.field(
        "page_ids",
        $decode.list($decode.string),
        (page_ids) => { return $decode.success(new Token(api_key, page_ids)); },
      );
    },
  );
}

export function is_submit_disabled(token) {
  return (token.api_key === "") || $list.is_empty(token.page_ids);
}
