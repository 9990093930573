/// <reference types="./stepper.d.mts" />
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $element from "../../../../lustre/lustre/element.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../../ds/colors.mjs";
import * as $icons from "../../../ds/icons.mjs";
import * as $text from "../../../ds/typography/text.mjs";
import { toList, CustomType as $CustomType } from "../../../gleam.mjs";

class Pending extends $CustomType {}

class Active extends $CustomType {}

class Done extends $CustomType {}

class Step extends $CustomType {
  constructor(description, hide) {
    super();
    this.description = description;
    this.hide = hide;
  }
}

export function step() {
  return new Step(new $option.None(), false);
}

export function description(step, description) {
  let _record = step;
  return new Step(new $option.Some(description), _record.hide);
}

export function hide(step, hide) {
  let _record = step;
  return new Step(_record.description, hide);
}

function view_steps(children) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.align_items("center"), $css.gap(px(16))]),
  );
  return $h.div(_pipe, toList([]), children);
}

function is_active_progress(step, expected) {
  return $bool.guard(
    step === expected,
    new Active(),
    () => {
      return $bool.guard(
        step > expected,
        new Done(),
        () => { return new Pending(); },
      );
    },
  );
}

function view_step_wrapper(state, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.lg($text.medium)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(8)),
      $css.color(
        (() => {
          if (state instanceof Pending) {
            return $colors.light.grey_600;
          } else if (state instanceof Active) {
            return $colors.light.ai_800;
          } else {
            return $colors.light.white;
          }
        })(),
      ),
    ]),
  );
  return $h.div(_pipe, toList([]), children);
}

function view_description(child, state) {
  let _pipe = $css.class$(
    toList([
      $css.color(
        (() => {
          if (state instanceof Done) {
            return $colors.light.grey_600;
          } else {
            return "inherit";
          }
        })(),
      ),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([$h.text(child)]));
}

function view_step(index, state) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xl($text.medium)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.width(px(36)),
      $css.height(px(36)),
      $css.property("flex-shrink", "0"),
      $css.border_radius(px(500)),
      $css.background(
        (() => {
          if (state instanceof Pending) {
            return $colors.light.grey_100;
          } else if (state instanceof Active) {
            return $colors.light.ai_100;
          } else {
            return $colors.light.grey_400;
          }
        })(),
      ),
    ]),
  );
  return $h.div(
    _pipe,
    toList([]),
    (() => {
      if (state instanceof Done) {
        return toList([$icons.checkmark()]);
      } else {
        return toList([$h.text($int.to_string(index))]);
      }
    })(),
  );
}

function view_step_description(at, acc, step) {
  let description$1 = step.description;
  let hide$1 = step.hide;
  let index = acc[0];
  let skipped = acc[1];
  let view = acc[2];
  return $bool.guard(
    hide$1,
    [index, skipped + 1, view],
    () => {
      let state = is_active_progress(at, index + skipped);
      let _pipe = view_step_wrapper(
        state,
        toList([
          (() => {
            if (description$1 instanceof $option.None) {
              return view_step(index, state);
            } else {
              let description$2 = description$1[0];
              return $element.fragment(
                toList([
                  view_step(index, state),
                  view_description(description$2, state),
                ]),
              );
            }
          })(),
        ]),
      );
      let _pipe$1 = ((_capture) => { return $list.prepend(view, _capture); })(
        _pipe,
      );
      return ((view) => { return [index + 1, skipped, view]; })(_pipe$1);
    },
  );
}

function separator() {
  let _pipe = $css.class$(
    toList([
      $css.flex_grow(1),
      $css.flex_basis("0"),
      $css.height(px(2)),
      $css.background($colors.light.grey_400),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function steps(at, children) {
  let _pipe = children;
  let _pipe$1 = $list.fold(
    _pipe,
    [1, 0, toList([])],
    (acc, val) => { return view_step_description(at, acc, val); },
  );
  let _pipe$2 = ((triple) => { return triple[2]; })(_pipe$1);
  let _pipe$3 = $list.reverse(_pipe$2);
  let _pipe$4 = $list.intersperse(_pipe$3, separator());
  return view_steps(_pipe$4);
}
