/// <reference types="./language.d.mts" />
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class English extends $CustomType {}

export class French extends $CustomType {}

export class German extends $CustomType {}

export class Spanish extends $CustomType {}

export class Dutch extends $CustomType {}

export class Portuguese extends $CustomType {}

export class Italian extends $CustomType {}

export class Finnish extends $CustomType {}

export class Swedish extends $CustomType {}

export class Nynorsk extends $CustomType {}

export class Bokmal extends $CustomType {}

export class Danish extends $CustomType {}

export class Estonian extends $CustomType {}

export class Latvian extends $CustomType {}

export class Lithuanian extends $CustomType {}

export class Icelandic extends $CustomType {}

export function to_string(language) {
  if (language instanceof English) {
    return "english";
  } else if (language instanceof French) {
    return "french";
  } else if (language instanceof German) {
    return "german";
  } else if (language instanceof Spanish) {
    return "spanish";
  } else if (language instanceof Dutch) {
    return "dutch";
  } else if (language instanceof Portuguese) {
    return "portuguese";
  } else if (language instanceof Italian) {
    return "italian";
  } else if (language instanceof Finnish) {
    return "finnish";
  } else if (language instanceof Swedish) {
    return "swedish";
  } else if (language instanceof Nynorsk) {
    return "nynorsk";
  } else if (language instanceof Bokmal) {
    return "bokmal";
  } else if (language instanceof Danish) {
    return "danish";
  } else if (language instanceof Estonian) {
    return "estonian";
  } else if (language instanceof Latvian) {
    return "latvian";
  } else if (language instanceof Lithuanian) {
    return "lithuanian";
  } else {
    return "icelandic";
  }
}

export function to_emoji(language) {
  if (language instanceof English) {
    return "🇬🇧";
  } else if (language instanceof French) {
    return "🇫🇷";
  } else if (language instanceof Spanish) {
    return "🇪🇸";
  } else if (language instanceof German) {
    return "🇩🇪";
  } else if (language instanceof Dutch) {
    return "🇳🇱";
  } else if (language instanceof Portuguese) {
    return "🇵🇹";
  } else if (language instanceof Italian) {
    return "🇮🇹";
  } else if (language instanceof Finnish) {
    return "🇫🇮";
  } else if (language instanceof Swedish) {
    return "🇸🇪";
  } else if (language instanceof Nynorsk) {
    return "🇳🇴";
  } else if (language instanceof Bokmal) {
    return "🇳🇴";
  } else if (language instanceof Danish) {
    return "🇩🇰";
  } else if (language instanceof Estonian) {
    return "🇪🇪";
  } else if (language instanceof Latvian) {
    return "🇱🇻";
  } else if (language instanceof Lithuanian) {
    return "🇱🇹";
  } else {
    return "🇮🇸";
  }
}

export function to_yes(language) {
  if (language instanceof English) {
    return "Yes";
  } else if (language instanceof French) {
    return "Oui";
  } else if (language instanceof Spanish) {
    return "Sí";
  } else if (language instanceof German) {
    return "Ja";
  } else if (language instanceof Dutch) {
    return "Ja";
  } else if (language instanceof Portuguese) {
    return "Sim";
  } else if (language instanceof Italian) {
    return "Sì";
  } else if (language instanceof Finnish) {
    return "Kyllä";
  } else if (language instanceof Swedish) {
    return "Ja";
  } else if (language instanceof Nynorsk) {
    return "Ja";
  } else if (language instanceof Bokmal) {
    return "Ja";
  } else if (language instanceof Danish) {
    return "Ja";
  } else if (language instanceof Estonian) {
    return "Jah";
  } else if (language instanceof Latvian) {
    return "Jā";
  } else if (language instanceof Lithuanian) {
    return "Taip";
  } else {
    return "Já";
  }
}

export function to_no(language) {
  if (language instanceof English) {
    return "No";
  } else if (language instanceof French) {
    return "Non";
  } else if (language instanceof Spanish) {
    return "No";
  } else if (language instanceof German) {
    return "Nein";
  } else if (language instanceof Dutch) {
    return "Nee";
  } else if (language instanceof Portuguese) {
    return "Não";
  } else if (language instanceof Italian) {
    return "No";
  } else if (language instanceof Finnish) {
    return "Ei";
  } else if (language instanceof Swedish) {
    return "Nej";
  } else if (language instanceof Nynorsk) {
    return "Nei";
  } else if (language instanceof Bokmal) {
    return "Nei";
  } else if (language instanceof Danish) {
    return "Nej";
  } else if (language instanceof Estonian) {
    return "Ei";
  } else if (language instanceof Latvian) {
    return "Nē";
  } else if (language instanceof Lithuanian) {
    return "Ne";
  } else {
    return "Nei";
  }
}

export function to_na(language) {
  if (language instanceof English) {
    return "N/A";
  } else if (language instanceof French) {
    return "N/A";
  } else if (language instanceof Spanish) {
    return "N/A";
  } else if (language instanceof German) {
    return "N/V";
  } else if (language instanceof Dutch) {
    return "N/B";
  } else if (language instanceof Portuguese) {
    return "N/D";
  } else if (language instanceof Italian) {
    return "N/D";
  } else if (language instanceof Finnish) {
    return "E/T";
  } else if (language instanceof Swedish) {
    return "E/T";
  } else if (language instanceof Nynorsk) {
    return "I/T";
  } else if (language instanceof Bokmal) {
    return "I/T";
  } else if (language instanceof Danish) {
    return "I/T";
  } else if (language instanceof Estonian) {
    return "P/V";
  } else if (language instanceof Latvian) {
    return "N/P";
  } else if (language instanceof Lithuanian) {
    return "N/A";
  } else {
    return "N/A";
  }
}

export const all = /* @__PURE__ */ toList([
  /* @__PURE__ */ new English(),
  /* @__PURE__ */ new French(),
  /* @__PURE__ */ new Spanish(),
  /* @__PURE__ */ new German(),
  /* @__PURE__ */ new Dutch(),
  /* @__PURE__ */ new Portuguese(),
  /* @__PURE__ */ new Italian(),
  /* @__PURE__ */ new Finnish(),
  /* @__PURE__ */ new Swedish(),
  /* @__PURE__ */ new Nynorsk(),
  /* @__PURE__ */ new Bokmal(),
  /* @__PURE__ */ new Danish(),
  /* @__PURE__ */ new Estonian(),
  /* @__PURE__ */ new Latvian(),
  /* @__PURE__ */ new Lithuanian(),
  /* @__PURE__ */ new Icelandic(),
]);
