/// <reference types="./insight.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $decrypt from "../decrypt.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Insight extends $CustomType {
  constructor(id, org_id, source_id, content, kind, chosen_id, status) {
    super();
    this.id = id;
    this.org_id = org_id;
    this.source_id = source_id;
    this.content = content;
    this.kind = kind;
    this.chosen_id = chosen_id;
    this.status = status;
  }
}

export class ConflictContent extends $CustomType {
  constructor(data_point_id, candidate_data_point_id, candidate_data_source_id, details) {
    super();
    this.data_point_id = data_point_id;
    this.candidate_data_point_id = candidate_data_point_id;
    this.candidate_data_source_id = candidate_data_source_id;
    this.details = details;
  }
}

export class DuplicateContent extends $CustomType {
  constructor(candidate_source_id) {
    super();
    this.candidate_source_id = candidate_source_id;
  }
}

export class IrrelevantContent extends $CustomType {
  constructor(details) {
    super();
    this.details = details;
  }
}

export class Conflict extends $CustomType {}

export class Duplicate extends $CustomType {}

export class Irrelevant extends $CustomType {}

export class Pending extends $CustomType {}

export class Skipped extends $CustomType {}

export class Fixed extends $CustomType {}

function conflict_content_decoder() {
  return $decode.field(
    "data_point_id",
    $decode.string,
    (data_point_id) => {
      return $decode.field(
        "candidate_data_point_id",
        $decode.string,
        (candidate_data_point_id) => {
          return $decode.field(
            "candidate_data_source_id",
            $decode.string,
            (candidate_data_source_id) => {
              return $decode.field(
                "details",
                $decode.string,
                (details) => {
                  return $decode.success(
                    new ConflictContent(
                      data_point_id,
                      candidate_data_point_id,
                      candidate_data_source_id,
                      details,
                    ),
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function duplicate_content_decoder() {
  return $decode.field(
    "candidate_source_id",
    $decode.string,
    (candidate_source_id) => {
      return $decode.success(new DuplicateContent(candidate_source_id));
    },
  );
}

function irrelevant_content_decoder() {
  return $decode.field(
    "details",
    $decode.string,
    (details) => { return $decode.success(new IrrelevantContent(details)); },
  );
}

function kind_decoder() {
  return $decode.then$(
    $decode.string,
    (kind) => {
      if (kind === "conflict") {
        return $decode.success(new Conflict());
      } else if (kind === "duplicate") {
        return $decode.success(new Duplicate());
      } else if (kind === "irrelevant") {
        return $decode.success(new Irrelevant());
      } else {
        return $decode.failure(new Conflict(), "Kind");
      }
    },
  );
}

function status_decoder() {
  return $decode.then$(
    $decode.string,
    (status) => {
      if (status === "pending") {
        return $decode.success(new Pending());
      } else if (status === "skipped") {
        return $decode.success(new Skipped());
      } else if (status === "fixed") {
        return $decode.success(new Fixed());
      } else {
        return $decode.failure(new Pending(), "Status");
      }
    },
  );
}

export function decoder() {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "org_id",
        $decode.string,
        (org_id) => {
          return $decode.field(
            "source_id",
            $decode.string,
            (source_id) => {
              return $decode.field(
                "content",
                $decode.one_of(
                  $decrypt.json(conflict_content_decoder()),
                  toList([
                    $decrypt.json(duplicate_content_decoder()),
                    $decrypt.json(irrelevant_content_decoder()),
                  ]),
                ),
                (content) => {
                  return $decode.field(
                    "kind",
                    kind_decoder(),
                    (kind) => {
                      return $decode.field(
                        "chosen_id",
                        $decode.optional($decode.string),
                        (chosen_id) => {
                          return $decode.field(
                            "status",
                            status_decoder(),
                            (status) => {
                              return $decode.success(
                                new Insight(
                                  id,
                                  org_id,
                                  source_id,
                                  content,
                                  kind,
                                  chosen_id,
                                  status,
                                ),
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function encode_content(content) {
  if (content instanceof ConflictContent) {
    let data_point_id = content.data_point_id;
    let candidate_data_point_id = content.candidate_data_point_id;
    let candidate_data_source_id = content.candidate_data_source_id;
    let details = content.details;
    return $json.object(
      toList([
        ["data_point_id", $json.string(data_point_id)],
        ["candidate_data_point_id", $json.string(candidate_data_point_id)],
        ["candidate_data_source_id", $json.string(candidate_data_source_id)],
        ["details", $json.string(details)],
      ]),
    );
  } else if (content instanceof DuplicateContent) {
    let candidate_source_id = content.candidate_source_id;
    return $json.object(
      toList([["candidate_source_id", $json.string(candidate_source_id)]]),
    );
  } else {
    let details = content.details;
    return $json.object(toList([["details", $json.string(details)]]));
  }
}

export function encode_kind(kind) {
  if (kind instanceof Conflict) {
    return "conflict";
  } else if (kind instanceof Duplicate) {
    return "duplicate";
  } else {
    return "irrelevant";
  }
}

export function encode_status(status) {
  if (status instanceof Pending) {
    return "pending";
  } else if (status instanceof Skipped) {
    return "skipped";
  } else {
    return "fixed";
  }
}

export function encode(insight) {
  return $json.object(
    toList([
      ["id", $json.string(insight.id)],
      ["org_id", $json.string(insight.org_id)],
      ["source_id", $json.string(insight.source_id)],
      ["content", encode_content(insight.content)],
      ["kind", $json.string(encode_kind(insight.kind))],
      ["chosen_id", $json.nullable(insight.chosen_id, $json.string)],
      ["status", $json.string(encode_status(insight.status))],
    ]),
  );
}
