/// <reference types="./analytics.d.mts" />
import * as $metrics from "../../../common/data/metrics.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { toList } from "../../gleam.mjs";

export function fetch_analytics(_) {
  return $middleware.require_access_token(
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let _pipe = $ask.to(new $ask.Heimdall(), toList(["analytics"]));
          let _pipe$1 = $ask.bearing(_pipe, access_token);
          let _pipe$2 = $ask.expect(
            _pipe$1,
            $decode.list($metrics.metrics_curve_decoder()),
          );
          let _pipe$3 = $ask.notify(
            _pipe$2,
            (m) => { return dispatch(new $msg.ApiReturnedAnalytics(m)); },
          );
          $ask.run(_pipe$3)
          return undefined;
        },
      );
    },
  );
}
